import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMPagination from "../../atoms/ATMPagination/ATMPagination";
import {
  useGetAllMyCreatedJobsQuery,
  useGetAllMyInactiveJobsQuery,
  useGetAllMyManagedJobsQuery,
  useGetAllMyOrgJobsQuery,
  useGetAllMySharedJobsQuery,
} from "../../app/DashboardServices/DashboardServices";
import JobCard from "../../components/recruiters/jobCard/JobCard";
import { useFetchData } from "../../hooks/useFetchData";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";

const optionData = [
  { value: "", label: "All" },
  { value: "title", label: "Title" },
  { value: "description", label: "Description" },

  { value: "aspect_of_work_location", label: "Aspect Of Work Location" },

  { value: "experience", label: "Experience" },

  { value: "external_company", label: "External Company" },

  { value: "employment_type", label: "Employment Type" },

  { value: "duration", label: "Duration" },

  { value: "duration_type", label: "Duration Type" },

  { value: "company_name", label: "Company Name" },

  // { value: "currency", label: "Currency" },

  { value: "salary_from", label: "Salary From" },

  { value: "salary_to", label: "Salary To" },

  { value: "salary_type", label: "Salary Type" },

  { value: "country", label: "Country" },

  { value: "state", label: "State" },

  { value: "city", label: "City" },

  // { value: "zipcode", label: "Zipcode" },

  { value: "managed_by", label: "Managed By" },

  { value: "account_manager__first_name", label: "Account Manager" },
];

function Operations() {
  const [jobType, setJobType] = useState("All Organization Jobs");
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState("");
  const [selectedOption, setSelectedOption] = useState(optionData[0]);
  const [sortOrder, setSortOrder] = useState("Descending");

  // let valueGet = selectedOption?.map((el) => `SearchVector(${el.value})`);
  // let valueGet = selectedOption?.map((el) => el.value);
  let valueGet = selectedOption?.value;
  // let resultString = `"${valueGet}"`;

  useEffect(() => {
    setSearchData("");
    setSearchValue("");
    setSortOrder("Descending");
  }, [jobType]);

  const paginationStates = {
    "All Organization Jobs": { currentPage: 1, itemsPerPage: 8 },
    "My Created Jobs": { currentPage: 1, itemsPerPage: 8 },
    "My Managed Jobs": { currentPage: 1, itemsPerPage: 8 },
    "My Shared Jobs": { currentPage: 1, itemsPerPage: 8 },
    "Inactive Jobs": { currentPage: 1, itemsPerPage: 8 },
  };
  const [pagination, setPagination] = useState(paginationStates[jobType]);

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleItemsPerPageChange = (itemsPerPage) => {
    setPagination((prev) => ({ ...prev, itemsPerPage, currentPage: 1 })); // Reset to page 1 when changing items per page
  };

  const {
    data: createdJobsData,
    isLoading: isLoadingCreatedJobs,
    totalData: createdJobsTotalData,
  } = useFetchData(useGetAllMyCreatedJobsQuery, {
    body: {
      page: pagination.currentPage,
      size: pagination.itemsPerPage,
      search: jobType === "My Created Jobs" ? searchData : "",
      fields: jobType === "My Created Jobs" && searchData && { valueGet },
      ordering:
        jobType === "My Created Jobs" && sortOrder === "Descending"
          ? "desc"
          : "asc",
    },
  });
  const {
    data: orgJobsData,
    isLoading: isLoadingOrgJobs,
    totalData: orgJobsTotalData,
  } = useFetchData(useGetAllMyOrgJobsQuery, {
    body: {
      page: pagination.currentPage,
      size: pagination.itemsPerPage,
      search: jobType === "All Organization Jobs" ? searchData : "",
      fields: jobType === "All Organization Jobs" && searchData && { valueGet },
      ordering:
        jobType === "All Organization Jobs" && sortOrder === "Descending"
          ? "desc"
          : "asc",
    },
  });

  const {
    data: managedJobsData,
    isLoading: isLoadingManagedJobs,
    totalData: managedJobsTotalData,
  } = useFetchData(useGetAllMyManagedJobsQuery, {
    body: {
      page: pagination.currentPage,
      size: pagination.itemsPerPage,
      search: jobType === "My Managed Jobs" ? searchData : "",
      fields: jobType === "My Managed Jobs" && searchData && { valueGet },
      ordering:
        jobType === "My Managed Jobs" && sortOrder === "Descending"
          ? "desc"
          : "asc",
    },
  });
  const {
    data: sharedJobsData,
    isLoading: isLoadingSharedJobs,
    totalData: sharedJobsTotalData,
  } = useFetchData(useGetAllMySharedJobsQuery, {
    body: {
      page: pagination.currentPage,
      size: pagination.itemsPerPage,
      search: jobType === "My Shared Jobs" ? searchData : "",
      fields: jobType === "My Shared Jobs" && searchData && { valueGet },
      ordering:
        jobType === "My Shared Jobs" && sortOrder === "Descending"
          ? "desc"
          : "asc",
    },
  });
  const {
    data: inactiveJobsData,
    isLoading: isLoadingInactiveJobs,
    totalData: inactiveJobsTotalData,
  } = useFetchData(useGetAllMyInactiveJobsQuery, {
    body: {
      page: pagination.currentPage,
      size: pagination.itemsPerPage,
      search: jobType === "Inactive Jobs" ? searchData : "",
      fields: jobType === "Inactive Jobs" && searchData && { valueGet },
      ordering:
        jobType === "Inactive Jobs" && sortOrder === "Descending"
          ? "desc"
          : "asc",
    },
  });

  const jobs = {
    "All Organization Jobs": orgJobsData,
    "My Created Jobs": createdJobsData,
    "My Managed Jobs": managedJobsData,
    "My Shared Jobs": sharedJobsData,
    "Inactive Jobs": inactiveJobsData,
  };
  const jobLoading = {
    "All Organization Jobs": isLoadingOrgJobs || false,
    "My Created Jobs": isLoadingCreatedJobs || false,
    "My Managed Jobs": isLoadingManagedJobs || false,
    "My Shared Jobs": isLoadingSharedJobs || false,
    "Inactive Jobs": isLoadingInactiveJobs || false,
  };

  const totalCounts = {
    "All Organization Jobs": orgJobsTotalData || 0,
    "My Created Jobs": createdJobsTotalData || 0,
    "My Managed Jobs": managedJobsTotalData || 0,
    "My Shared Jobs": sharedJobsTotalData || 0,
    "Inactive Jobs": inactiveJobsTotalData || 0,
  };

  const handleJobTypeChange = (type) => {
    setJobType(type);
    setPagination(paginationStates[type]);
  };

  const animatedComponents = makeAnimated();
  return (
    <>
      <div className="w-full flex flex-col gap-3 font-inter text-xs p-2">
        <div className="flex flex-col md:flex-row items-center justify-between gap-2 flex-wrap">
          <div className="  w-full h-fit gap-2 flex justify-between items-center ">
            <ATMHeading> {jobType}</ATMHeading>
            <div className=" flex justify-end">
              <Link
                to={"/recruit/create-job"}
                className="border flex gap-2 items-center border-[#1CACBD] text-white w-[140px] bg-[#1CACBD] py-1 font-semibold rounded-md px-4"
              >
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="20"
                    height="20"
                    rx="10"
                    stroke="#D0D5DD"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="1 3"
                  />
                  <path
                    d="M12.0002 7.33398V16.6673M7.3335 12.0007H16.6668"
                    stroke="#F5F6F7"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Create Job
              </Link>
            </div>
          </div>
        </div>
        <div className=" border rounded-md w-full h-fit ">
          <label className="flex items-center ">
            <div className="">
              <Select
                value={selectedOption}
                onChange={(newValue) => setSelectedOption(newValue)}
                options={optionData}
                placeholder=""
                components={animatedComponents}
                className=" min-w-[200px] w-full"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                    "&:focus": {
                      border: "none",
                      boxShadow: "none",
                    },
                  }),
                }}
              />
            </div>

            <div className="relative w-full">
              <div className="relative w-full">
                <input
                  minLength={2}
                  maxLength={50}
                  className=" block bg-white border-0 outline-none rounded-r-md py-3 pl-10 w-full"
                  placeholder="Start typing to search for a JD"
                  type="text"
                  name="searchValue"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchData(searchValue === "" ? "" : "");
                    setSearchValue(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      setSearchData(searchValue);
                    }
                  }}
                />
                {!searchData && searchValue && (
                  <div
                    className="absolute left-0 right-0 pl-2 py-1 rounded-md mt-[5px] bg-blue-50 border cursor-pointer overflow-auto"
                    onClick={() => {
                      setSearchData(searchValue);
                    }}
                  >
                    {searchValue}
                  </div>
                )}
              </div>
              <span className="absolute top-2 left-1">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer"
                  onClick={() => {
                    setSearchData(searchValue);
                  }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.802 14.2644H14.9922L14.7051 13.9876C15.7097 12.819 16.3145 11.3018 16.3145 9.65141C16.3145 5.97131 13.3315 2.98828 9.65141 2.98828C5.97131 2.98828 2.98828 5.97131 2.98828 9.65141C2.98828 13.3315 5.97131 16.3145 9.65141 16.3145C11.3018 16.3145 12.819 15.7097 13.9876 14.7051L14.2644 14.9922V15.802L19.3898 20.9172L20.9172 19.3898L15.802 14.2644ZM9.65108 14.2643C7.09859 14.2643 5.03814 12.2039 5.03814 9.65138C5.03814 7.09889 7.09859 5.03845 9.65108 5.03845C12.2036 5.03845 14.264 7.09889 14.264 9.65138C14.264 12.2039 12.2036 14.2643 9.65108 14.2643Z"
                    fill="#222222"
                  />
                </svg>
              </span>
            </div>
          </label>
        </div>

        <div className="flex gap-3 border-b border-[#D0D5DD] justify-between items-start">
          <ul className="flex gap-6 font-medium text-[#667085] cursor-pointer">
            {[
              "All Organization Jobs",
              "My Created Jobs",
              "My Managed Jobs",
              "My Shared Jobs",
              "Inactive Jobs",
            ].map((type) => (
              <li
                key={type}
                className={`pb-2 ${
                  jobType === type ? "border-b-2 border-[#101828]" : ""
                }`}
                onClick={() => handleJobTypeChange(type)}
              >
                {type}
                <span className="text-[#6E6E6E] font-medium px-2 rounded-xl ml-1 bg-[#F0F0F0]">
                  {totalCounts[type]}
                </span>
              </li>
            ))}
          </ul>
          <div className="flex gap-1">
            <label htmlFor="sortOrder" className="font-semibold">
              Short by:{" "}
            </label>
            <select
              className="border outline-none px-2 py-1  rounded-md mt-[-4px] "
              id="sortOrder"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="Descending">Descending</option>
              <option value="Ascending">Ascending</option>
            </select>
          </div>
        </div>

        {jobLoading[jobType] ? (
          <div className="h-full flex justify-center items-center">
            <ATMCircularProgress size="medium" />
          </div>
        ) : (
          <div>
            {jobs[jobType].length <= 0 ? (
              <div className="text-center py-8">
                <p className="text-xl text-gray-500">No data found</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                {jobs[jobType]?.map((job) => (
                  <JobCard key={job.id} data={job} />
                ))}
              </div>
            )}
          </div>
        )}

        <div className="flex justify-end">
          {jobs[jobType].length <= 0 || jobLoading[jobType] ? (
            ""
          ) : (
            <ATMPagination
              totalItems={totalCounts[jobType]}
              itemsPerPage={pagination.itemsPerPage}
              currentPage={pagination.currentPage}
              onPageChange={handlePageChange}
              itemsPerPageOptions={[4, 8, 12, 16, 20, 24, 48, 96]}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </div>
      </div>
    </>
  );
}
export default Operations;
