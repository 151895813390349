import * as Yup from "yup";

export const signUpSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  firstName: Yup.string().required("Please enter your First Name"),
  lastName: Yup.string().required("Please enter your Last Name"),
  contactNumber: Yup.number().required("Please enter your Contact Number"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

export const loginSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export const forgetPassword = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
});

export const basicDetailsSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  firstName: Yup.string().required("Please enter your First Name"),
  lastName: Yup.string().required("Please enter your Last Name"),
  contactNumber: Yup.number().required("Please enter your Contact Number"),
  // countryCode: Yup.string().required("Please enter your Country Code"),
  // password: Yup.string()
  //   .min(6, "Password must be at least 6 characters")
  //   .required("Password is required"),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Passwords must match")
  //   .required("Please confirm your password"),
});

const validateImage = (file) => {
  if (!file) return false;

  const validTypes = ["image/svg+xml", "image/png", "image/jpeg", "image/gif"];
  if (!validTypes.includes(file.type)) return false;

  // Create an image element to check dimensions
  const img = new Image();
  img.src = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      if (img.width <= 800 && img.height <= 400) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      resolve(false);
    };
  });
};
export const ProfileDetailsSchema = Yup.object({
  photo: Yup.mixed()
    .required("A photo is required")
    .test(
      "fileType",
      "Only SVG, PNG, JPG, and GIF formats are allowed",
      (value) => {
        if (!value) return false;
        return [
          "image/svg+xml",
          "image/png",
          "image/jpeg",
          "image/gif",
        ].includes(value.type);
      }
    )
    .test(
      "fileSize",
      "Image must be 800x400 pixels or smaller",
      async (value) => {
        if (!value) return false;
        return await validateImage(value);
      }
    ),
  // skillsData: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       id: Yup.string().required("ID is required"),
  //       name: Yup.string().required("Name is required"),
  //       selected: Yup.boolean().required("Selected is required"),
  //     })
  //   )
  //   .test(
  //     "at-least-one-selected",
  //     "At least one skill must be selected",
  //     (skills) => skills.some((skill) => skill.selected === true)
  //   ),
  // languageData: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       id: Yup.string().required("ID is required"),
  //       name: Yup.string().required("Name is required"),
  //       selected: Yup.boolean().required("Selected is required"),
  //     })
  //   )
  //   .test(
  //     "at-least-one-selected",
  //     "At least one language must be selected",
  //     (skills) => skills.some((skill) => skill.selected === true)
  //   ),
  dob: Yup.date()
    .required("Please enter your Date of Birth")
    .typeError("Date must be a valid date")
    .test("date-format", "Date must be in the format YYYY-MM-DD", (value) => {
      if (!value) return false;
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      return regex.test(value.toISOString().split("T")[0]);
    }),
  title: Yup.string().required("Please enter your Title"),
  uploadvideo: Yup.string().required("Please make video"),
  profilesummary: Yup.string().required("Please provide a profile summary"),
  country: Yup.object()
    .shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
    .nullable()
    .required("Please select a country"),

  state: Yup.object()
    .shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
    .nullable()
    .required("Please select a state"),

  city: Yup.object()
    .shape({
      value: Yup.string().required(),
      label: Yup.string().required(),
    })
    .nullable()
    .required("Please select a city"),
  zipCode: Yup.string().required("enter zipcode"),
});

export const applyDetails = Yup.object({
  noticePeriod: Yup.number().required("Notice Period is required"),
  salaryExpectation: Yup.object({
    minimum: Yup.number().when("asPerStandard", {
      is: false,
      then: Yup.number().required("Minimum salary is required"),
    }),
    maximum: Yup.number().when("asPerStandard", {
      is: false,
      then: Yup.number().required("Maximum salary is required"),
    }),
    currency: Yup.string().when("asPerStandard", {
      is: false,
      then: Yup.string().required("Currency is required"),
    }),
  }),
  availableFrom: Yup.date()
    .nullable()
    .required("Available From date is required"),
});

export const WorkExperienceSchema = Yup.object({
  experienceType: Yup.string().required("Experience type is required"),
  designation: Yup.string().required("Designation is required"),

  companyName: Yup.string().required("Designation is Company Name"),

  profileSummary: Yup.string().required("Duration to is Profile Summary"),
});

export const EducationSchema = Yup.object({
  education: Yup.string()
    .notOneOf([""], "Please select an education option")
    .required("Education is required"),
  Specialization: Yup.string().required("Specialization is required"),
  institution: Yup.string().required("Institute name is required"),
});
