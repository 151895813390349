import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import { axiosRequest } from "../../api/api";
import ApplicationTable from "../../components/recruiters/ApplicationTable";
import toast from "react-hot-toast";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function Application() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState(false);
  const [jobData, setJobData] = useState(null);
  const [applicantData, setApplicantData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axiosRequest(
          `${baseUrl}/api/recruiter/job/${id}/`,
          {
            method: "GET",
          }
        );
        if (response?.data?.success === false) {
          toast.error(response?.data?.message);
          setLoading(false);
          return;
        }
        if (response.status === 200) {
          setLoading(false);
          setJobData(response.data.data.job);
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    const fetchApplicantData = async () => {
      try {
        const response = await axiosRequest(
          `${baseUrl}/api/recruiter/job-applications/${id}/`,
          {
            method: "GET",
          }
        );

        if (response.status === 200) {
          setApplicantData(response.data.data.applicants);
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      }
    };

    fetchApplicantData();

    fetchData();
  }, [id]);
  const parts = jobData?.description?.split("\n");
  return (
    <div className="h-full text-xs font-inter p-2">
      {!loading ? (
        <div>
          {jobData ? (
            <div className="flex flex-col gap-4">
              <div className="">
                <h2 className="font-semibold text-[#101828] text-2xl bg-blue-100 capitalize px-1 py-1 rounded-md flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6  cursor-pointer"
                    onClick={handleBack}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                    />
                  </svg>
                  <ATMHeading> Job Applications - {jobData?.title} </ATMHeading>
                </h2>
              </div>

              <div className="">
                <div className="flex  w-full items-stretch gap-4 text-center ">
                  <div className=" w-[120px] p-2   bg-white border border-[#EBEEFF] rounded-lg">
                    <h2 className=" font-semibold text-xl text-[#515151]">
                      {jobData?.applicant_count}
                    </h2>
                    <p className="text-[#333333]  font-normal">
                      Total Candidates
                    </p>
                  </div>

                  <div className="  w-[120px] p-2 bg-white border border-[#EBEEFF]  rounded-lg">
                    <h2 className=" font-semibold text-xl text-[#515151]">
                      {jobData?.shortlisted}{" "}
                    </h2>
                    <p className="text-[#333333]  font-normal">Shortlisted</p>
                  </div>

                  <div className="  w-[120px] p-2 bg-white border border-[#EBEEFF]  rounded-lg">
                    <h2 className=" font-semibold text-xl text-[#515151]">
                      {jobData?.interviewed}{" "}
                    </h2>
                    <p className="text-[#333333]  font-normal ">Interviewed</p>
                  </div>
                </div>
              </div>

              <div className="border border-[#F0EDED] p-5 bg-[#FAFAFA] rounded-lg flex justify-between items-center">
                <div className="font-normal">
                  <div>
                    {parts?.map((part, index) => (
                      <div key={index}>{part}</div>
                    ))}
                  </div>

                  <div className="flex mt-7 justify-start flex-wrap items-start gap-y-5 gap-x-9 capitalize">
                    <div className="flex items-center gap-1">
                      <svg
                        width="18"
                        height="17"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_9369_11266)">
                          <path
                            d="M7.16775 8.80439C8.76435 8.80439 10.0587 7.6738 10.0587 6.27915C10.0587 4.8845 8.76435 3.75391 7.16775 3.75391C5.57115 3.75391 4.27686 4.8845 4.27686 6.27915C4.27686 7.6738 5.57115 8.80439 7.16775 8.80439Z"
                            stroke="#898989"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2.58457 15.0206H11.7509C12.7667 15.0206 13.5491 14.2577 13.0748 13.4732C12.377 12.3191 10.7863 10.9414 7.16776 10.9414C3.54925 10.9414 1.95851 12.3191 1.26075 13.4732C0.786402 14.2577 1.56882 15.0206 2.58457 15.0206Z"
                            stroke="#898989"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_9369_11266">
                            <rect
                              width="14.3309"
                              height="12.5183"
                              fill="white"
                              transform="translate(0.00244141 3.12891)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <p className="text-[#202020] ">
                        <span className="font-bold">Account Manager :</span>{" "}
                        {jobData?.account_manager?.first_name}{" "}
                        {jobData?.account_manager?.last_name}
                      </p>
                    </div>

                    <div className="flex items-center gap-2 ">
                      <svg
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.21783 3.5186V0.900391H0.868652V12.6823H15.567V3.5186H8.21783ZM3.80832 11.3736H2.33848V10.0645H3.80832V11.3736ZM3.80832 8.75637H2.33848V7.44727H3.80832V8.75637ZM3.80832 6.13723H2.33848V4.82812H3.80832V6.13723ZM3.80832 3.52004H2.33848V2.21094H3.80832V3.52004ZM6.74797 11.3736H5.27814V10.0645H6.74797V11.3736ZM6.74797 8.75637H5.27814V7.44727H6.74797V8.75637ZM6.74797 6.13723H5.27814V4.82812H6.74797V6.13723ZM6.74797 3.52004H5.27814V2.21094H6.74797V3.52004ZM14.0971 11.3736H8.21779V10.0645H9.68763V8.75543H8.21779V7.44633H9.68763V6.13723H8.21779V4.82812H14.0971V11.3736ZM12.6273 6.1368H11.1575V7.44591H12.6273V6.1368ZM12.6273 8.75586H11.1575V10.065H12.6273V8.75586Z"
                          fill="#898989"
                        />
                      </svg>

                      <p className="text-[#202020]">
                        <span className="font-bold">Company :</span>{" "}
                        {jobData?.external_company
                          ? jobData.company_name
                          : jobData?.company?.company_name}
                      </p>
                    </div>

                    <div className="flex items-center gap-2 ">
                      <svg
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.9072 2.88188H11.7025V1.57278C11.7025 0.846223 11.0484 0.263672 10.2326 0.263672H5.82313C5.00737 0.263672 4.3533 0.846223 4.3533 1.57278V2.88188H2.14855C1.33279 2.88188 0.678711 3.46443 0.678711 4.19098V11.3911C0.678711 12.1176 1.33279 12.7002 2.14855 12.7002H13.9072C14.723 12.7002 15.3771 12.1176 15.3771 11.3911V4.19098C15.3771 3.46443 14.723 2.88188 13.9072 2.88188ZM5.82322 1.57227H10.2327V2.88137H5.82322V1.57227ZM13.9075 11.3911H2.14885V10.082H13.9075V11.3911ZM13.9075 8.11872H2.14885V4.19141H4.3536V5.50051H5.82344V4.19141H10.2329V5.50051H11.7028V4.19141H13.9075V8.11872Z"
                          fill="#898989"
                        />
                      </svg>

                      <p className="text-[#202020]">
                        <span className="font-bold">Salary:</span>{" "}
                        {jobData?.salary_from === 0 ? (
                          "--"
                        ) : (
                          <span>
                            {" "}
                            {jobData?.currency} {jobData?.salary_from} To{" "}
                            {jobData?.salary_to}
                          </span>
                        )}
                      </p>
                    </div>

                    <div className="flex items-center gap-2 ">
                      <svg
                        width="11"
                        height="14"
                        viewBox="0 0 11 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M5.82167 0.369141C2.97754 0.369141 0.677246 2.41789 0.677246 4.951C0.677246 8.3874 5.82167 13.4602 5.82167 13.4602C5.82167 13.4602 10.9661 8.3874 10.9661 4.951C10.9661 2.41789 8.6658 0.369141 5.82167 0.369141ZM5.82128 6.58526C4.80709 6.58526 3.98398 5.85216 3.98398 4.94888C3.98398 4.0456 4.80709 3.3125 5.82128 3.3125C6.83546 3.3125 7.65857 4.0456 7.65857 4.94888C7.65857 5.85216 6.83546 6.58526 5.82128 6.58526Z"
                          fill="#898989"
                        />
                      </svg>

                      <p className="text-[#202020]">
                        {" "}
                        <span className="font-bold">Office:</span>{" "}
                        {jobData?.city || jobData?.state || jobData?.country}
                      </p>
                    </div>

                    <div className="flex items-center gap-2 leading-none">
                      <svg
                        width="16"
                        height="14"
                        viewBox="0 0 16 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.9072 2.88188H11.7025V1.57278C11.7025 0.846223 11.0484 0.263672 10.2326 0.263672H5.82313C5.00737 0.263672 4.3533 0.846223 4.3533 1.57278V2.88188H2.14855C1.33279 2.88188 0.678711 3.46443 0.678711 4.19098V11.3911C0.678711 12.1176 1.33279 12.7002 2.14855 12.7002H13.9072C14.723 12.7002 15.3771 12.1176 15.3771 11.3911V4.19098C15.3771 3.46443 14.723 2.88188 13.9072 2.88188ZM5.82322 1.57227H10.2327V2.88137H5.82322V1.57227ZM13.9075 11.3911H2.14885V10.082H13.9075V11.3911ZM13.9075 8.11872H2.14885V4.19141H4.3536V5.50051H5.82344V4.19141H10.2329V5.50051H11.7028V4.19141H13.9075V8.11872Z"
                          fill="#898989"
                        />
                      </svg>

                      <p className="text-[#202020]">
                        <span className="font-bold">Work Location:</span>{" "}
                        {jobData?.aspect_of_work_location}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col  mt-2 ">
                <ul className=" flex gap-6 border-b border-[#D0D5DD] font-medium text-[#667085] cursor-pointer">
                  <li className="pb-2 border-b-2 border-[#101828] text-[#101828]">
                    All Candidates
                  </li>
                  {/* <li className="pb-2">Shortlisted</li>
              <li className="pb-2">Interviewed</li>
              <li className="pb-2">Hired</li>
              <li className="pb-2">Saved Applications</li> */}
                </ul>

                <div className="">
                  <div className=" py-2">
                    <div className="flex justify-start items-center gap-4">
                      <div className="">
                        <label className="relative block">
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.802 14.2644H14.9922L14.7051 13.9876C15.7097 12.819 16.3145 11.3018 16.3145 9.65141C16.3145 5.97131 13.3315 2.98828 9.65141 2.98828C5.97131 2.98828 2.98828 5.97131 2.98828 9.65141C2.98828 13.3315 5.97131 16.3145 9.65141 16.3145C11.3018 16.3145 12.819 15.7097 13.9876 14.7051L14.2644 14.9922V15.802L19.3898 20.9172L20.9172 19.3898L15.802 14.2644ZM9.65108 14.2643C7.09859 14.2643 5.03814 12.2039 5.03814 9.65138C5.03814 7.09889 7.09859 5.03845 9.65108 5.03845C12.2036 5.03845 14.264 7.09889 14.264 9.65138C14.264 12.2039 12.2036 14.2643 9.65108 14.2643Z"
                                fill="#333333"
                              />
                            </svg>
                          </span>
                          <input
                            className="w-full placeholder:text-[#333333] outline-none block bg-white border border-[#EFEFEF] rounded-md py-2 pl-9 pr-3 shadow-sm sm:text-sm"
                            placeholder="search by keywords"
                            type="text"
                            name="search"
                          />
                        </label>
                      </div>
                    </div>

                    <div className=" mt-8 border ">
                      {applicantData.length > 0 && (
                        <ApplicationTable data={applicantData} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-8">
              <p className="text-xl text-gray-500">No data found</p>
            </div>
          )}
        </div>
      ) : (
        <div className=" h-full  flex justify-center  items-center">
          <ATMCircularProgress />
        </div>
      )}
    </div>
  );
}

export default Application;
