import React from "react";

const ATMCircularProgress = ({ size = "medium", color = "#1CACBD" }) => {
  // Define the styles for the loader
  const styles = {
    small: {
      width: "30px",
      height: "20px",
    },
    medium: {
      width: "40px",
      height: "40px",
    },
    large: {
      width: "50px",
      height: "50px",
    },
  };

  const radius = 15; // Circle radius
  const strokeWidth = 5; // Stroke width
  const circumference = 2 * Math.PI * radius; // Calculate circumference

  // Determine the size styles
  const sizeStyle = styles[size] || styles.small;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sizeStyle,
      }}
    >
      <svg
        viewBox="0 0 50 50"
        style={{ width: sizeStyle.width, height: sizeStyle.height }}
      >
        <circle
          r={radius}
          cx="25"
          cy="25"
          fill="transparent"
          stroke={color} // Use a solid color for visibility
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`} // Set dasharray
          strokeDashoffset={circumference} // Start with the offset
          style={{
            animation: "spin 1.5s linear infinite",
          }}
          className="text-blue-100"
        />
      </svg>
      <style>
        {`
          @keyframes spin {
            0% {
              stroke-dashoffset: 0;
            }
            100% {
              stroke-dashoffset: ${circumference};
            }
          }
        `}
      </style>
    </div>
  );
};

export default ATMCircularProgress;
