import React, { useEffect, useState } from "react";
import { FaRegBookmark } from "react-icons/fa";
import { useParams } from "react-router";
import toast from "react-hot-toast";
import "./Scores.css";
import img1 from "../../assets/Recruiter Invite/default.png";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import { formatDate } from "../candidate/ProfileDetails/View/ProfileDetails";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";
import {
  useGetJobJpplicationQuery,
  useShortListMutation,
} from "../../app/DashboardServices/DashboardServices";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function ApplicationView() {
  const { id, id1, id2 } = useParams();
  const [data, setData] = useState();
  const [submit, setSubmit] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedanswerRows, setExpandedAnswerRows] = useState({});
  const toggleLlmAnswerFull = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const toggleAnswerFull = (index) => {
    setExpandedAnswerRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const {
    data: view,
    isFetching,
    isLoading,
  } = useGetJobJpplicationQuery({
    pk: id,
    cand: id1,
    job: id2,
  });

  useEffect(() => {
    setData(view?.data["job application"]);
  }, [view]);

  const [shortlist] = useShortListMutation();

  const handleShortListed = () => {
    setSubmit(true);
    const shortQuery = {
      pk: id,
      cand: id1,
      job: id2,
    };
    shortlist(shortQuery).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.error);
        setSubmit(false);
      } else {
        if (res?.data?.success) {
          toast.success(res?.data?.message);
          setSubmit(false);
        } else {
          toast.error(res?.error?.data?.error);
          setSubmit(false);
        }
      }
    });
  };

  const truncateWords = (text, maxWords) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > maxWords
      ? words.slice(0, maxWords).join(" ") + "..........."
      : text;
  };
  const parts = data?.job?.description?.split("\n");

  const toggleSeeMore = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className="h-full text-xs font-inter px-2 ">
      {!isFetching || !isLoading ? (
        <div className="flex flex-col  gap-2 ">
          <div className="flex justify-between items-center gap-8 ">
            <ATMHeading>Application View</ATMHeading>

            <FaRegBookmark className="text-xl text-[#000000B2]" />
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2  justify-between items-center gap-4 border p-2 rounded-md flex-wrap ">
            <div>
              <img
                src={data?.candidate?.profile_pic || img1}
                alt="Candidate Profile"
                style={styles.profileCircle}
              />
            </div>

            <div>
              <strong>Candidate Name</strong>
              <p>
                {data?.candidate?.first_name} {data?.candidate?.last_name}
              </p>
            </div>

            <div>
              <strong>Email Id</strong>
              <p>{data?.candidate?.email}</p>
            </div>

            <div className="text-center p-4 rounded-md bg-slate-100">
              <h1 className="text-green-600 text-3xl font-semibold">
                {data?.avg_skill_score}
                <span className="text-sm">/100</span>
              </h1>
              <p>Avg. Skill Score</p>
            </div>

            <div className="text-center p-4 rounded-md bg-slate-100">
              <h1 className="text-green-600 text-3xl font-semibold">
                {data?.avg_comm_score}
                <span className="text-sm">/100</span>
              </h1>
              <p>Avg. Communication Score</p>
            </div>
          </div>

          <div className="py-4 border rounded-lg flex flex-col gap-2 items-start justify-between border-[#F0EFEF] shadow-div">
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2  gap-4  px-8  justify-between w-full border-b py-2 ">
              <div>
                <strong>Job Title</strong>
                <p className="mt-1">{data?.job?.title}</p>
              </div>
              <div>
                <strong>Salary Offered</strong>
                <p className="mt-1">
                  {data?.job?.currency} {data?.job?.salary_from}-{" "}
                  {data?.job?.currency} {data?.job?.salary_to}
                </p>
              </div>
              <div>
                <strong>Job Location</strong>
                <p className="mt-1">
                  {data?.candidate?.state} {data?.candidate?.country}
                </p>
              </div>
              <div>
                <strong>Account Manager</strong>
                <p className="mt-1">
                  {data?.job?.account_manager?.first_name}{" "}
                  {data?.job?.account_manager?.last_name}
                </p>
              </div>
            </div>
            <div className="font-normal px-8 ">
              {parts
                ?.slice(0, isExpanded ? parts.length : 8)
                ?.map((part, index) => (
                  <div key={index}>{part}</div>
                ))}
              <button onClick={toggleSeeMore} className="text-[#1CACBD]">
                {isExpanded ? "....See Less" : "See More...."}
              </button>
            </div>
          </div>

          <div className="border rounded-lg shadow-div overflow-x-auto ">
            <table className="min-w-full bg-white border border-[#F0EFEF] table-auto ">
              <thead>
                <tr className="bg-blue-50">
                  <th className="p-4 border-b border-[#F0EFEF] font-bold text-left w-1/5 min-w-40">
                    Questions Asked
                  </th>
                  <th className="p-4 border-b border-l-2 border-[#F0EFEF] font-bold text-center w-1/5 min-w-60">
                    AI-Generated Text Answer
                  </th>
                  <th className="p-4 border-b border-l-2 border-[#F0EFEF] font-bold text-center w-1/5 min-w-40">
                    Video/Text Answer
                  </th>
                  <th className="p-4 border-b border-l-2 border-[#F0EFEF] font-bold text-center w-1/5 min-w-40">
                    Video to Text
                  </th>
                  <th className="py-2 border-b max-w-[200px] border-l-2 border-[#F0EFEF] font-bold text-center w-1/5 min-w-60">
                    Scores
                    <div className="grid grid-cols-2 border-t-2 pt-2">
                      <span>Skill</span>
                      <span>Communication</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.ques_ans?.map((item, index) => (
                  <tr
                    key={index}
                    className={`border-b border-[#F0EFEF] hover:bg-[#F9F9F9] transition ${
                      index % 2 === 0 ? "bg-white" : "bg-slate-100"
                    }`}
                  >
                    <td className="p-4 break-words text-gray-700">
                      {index + 1}. {item.question}
                    </td>
                    <td
                      className="p-4 border-l border-[#F0EFEF] break-words text-gray-700"
                      title={item.llm_answer}
                    >
                      {expandedRows[index]
                        ? item.llm_answer
                        : truncateWords(item.llm_answer, 20)}{" "}
                      <button
                        onClick={() => toggleLlmAnswerFull(index)}
                        className="text-[#1CACBD]"
                        type="button"
                      >
                        {expandedRows[index] ? " See Less" : " See More"}
                      </button>
                    </td>
                    <td className="p-4 border-l border-[#F0EFEF]">
                      {item.ans_type === "video-based" ? (
                        <div className="w-full">
                          {item?.video_path && (
                            <video
                              src={`${baseUrl}/api/candidate/stream_video/${item?.video_path}`}
                              controls
                              className="w-full h-auto rounded-lg"
                            />
                          )}
                        </div>
                      ) : (
                        <p className="text-gray-700">{item.answer}</p>
                      )}
                    </td>
                    {/* <td className="p-4 border-l border-[#F0EFEF] break-words text-gray-700">
                      {item.ans_type !== "text-based" && <p>{item.answer}</p>}
                    </td> */}
                    <td
                      className="p-4 border-l border-[#F0EFEF] break-words text-gray-700"
                      title={item.answer}
                    >
                      {expandedanswerRows[index]
                        ? item.answer
                        : truncateWords(item.answer, 20)}{" "}
                      <button
                        onClick={() => toggleAnswerFull(index)}
                        className="text-[#1CACBD]"
                        type="button"
                      >
                        {expandedanswerRows[index] ? " See Less" : " See More"}
                      </button>
                    </td>
                    <td className="p-4 border-l max-w-[200px]  border-[#F0EFEF]">
                      <div className="grid grid-cols-2 justify-center items-center gap-5 text-center">
                        <div className="border border-b-green-500 border-b-2 p-1 text-lg font-semibold rounded-md">
                          <div className="">{item.score1}</div>
                        </div>
                        <div className="border border-b-orange-500 border-b-2 p-1 text-lg font-semibold rounded-md">
                          <div className="">{item.score2}</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="border rounded-lg flex flex-col gap-7 items-start justify-between border-[#F0EFEF] shadow-div w-full p-4">
            <div className="flex justify-between items-center gap-4 w-full">
              <h2 className="font-semibold text-sm">Timeline</h2>
              {!data?.Shortlisted ? (
                <button
                  type="button"
                  className="bg-orange-300 text-white  rounded-md h-[30px] w-[100px] flex justify-center items-center  "
                  onClick={handleShortListed}
                >
                  {submit ? (
                    <ATMCircularProgress size="small" color="white" />
                  ) : (
                    " ShortList"
                  )}
                </button>
              ) : (
                <button
                  type="button"
                  className="bg-[#1CACBD] text-white  rounded-md h-[30px] w-[100px] "
                >
                  ShortListed
                </button>
              )}
            </div>

            <ol className="items-center flex flex-col sm:flex-row w-full pr-10  px-4">
              {[
                {
                  event: "Jd Created",
                  date: `${formatDate(data?.job?.created_at)}`,
                  color: `${
                    data?.job?.created_at ? "bg-blue-200" : "bg-blue-50"
                  }`,
                },
                {
                  event: "Invitation sent",
                  date: `${formatDate(data?.Invited)}`,
                  color: `${data?.Invited ? "bg-blue-400" : "bg-blue-50"}`,
                },
                {
                  event: "Applied For Screening",
                  date: `${formatDate(data?.last_question_asked_at)}`,
                  color: `${
                    data?.last_question_asked_at ? "bg-blue-500" : "bg-blue-50"
                  }`,
                },
                {
                  event: "ShortListed",
                  date: `${formatDate(data?.Shortlisted)}`,
                  color: `${data?.Shortlisted ? "bg-blue-600" : "bg-blue-50"}`,
                },
              ]?.map((item, index) => (
                <li key={index} className="w-full">
                  <div className=" py-4 w-full">
                    <div className="flex items-start w-full">
                      <div
                        className={`py-1 rounded-r-md border w-full ml-[-5px] ${item?.color} `}
                      ></div>
                      <div className="flex justify-center">
                        <div className="w-3 ml-[-10px] h-3 bg-white rounded-full border z-10 relative"></div>
                        <div className="absolute mt-5 text-center text-[10px]">
                          <div>{item?.event}</div>
                          <div>{item?.date}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ol>

            <div className="border-t p-2 text-[#6D758F] ">
              <h1 className="text-justify">
                Your comprehensive profile is a crucial part of our Application
                Tracking System, empowering our analysis for better matches.
                Your information is securely stored in our database, ensuring
                future opportunities align with your skills and preferences.
                Rest assured, we'll leverage this data to connect you with
                relevant positions.
              </h1>
            </div>
          </div>
        </div>
      ) : (
        <div className=" h-full flex justify-center  items-center">
          <ATMCircularProgress />
        </div>
      )}
    </div>
  );
}
const styles = {
  profileCircle: {
    borderRadius: "50%",
    border: "2px solid #000",
    width: "100px",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
  },
  profileText: {
    textAlign: "center",
    transform: "rotate(-45deg)",
  },
};

export default ApplicationView;
