import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { axiosRequest } from "../../api/api";
import { axiosFromRequest } from "../../api/formApi";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";
import { useFetchData } from "../../hooks/useFetchData";
import { inviteSchema } from "../../schemas/recruiterSchema";
import { formatDate } from "../candidate/OtherInformation/View/OtherInformation";
import { useMagicSearchQuery } from "./TalentSearch/Service/TalentSearchService";
import TalentSearchCandidateView from "./TalentSearch/TalentSearchCandidateView/TalentSearchCandidateView";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function InviteCandidate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedProfiles, setSelectedProfiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [jobData, setJobData] = useState();
  const [inviteBy, setInviteBy] = useState("Matching-profile");
  // let { data } = useFetchData(useGetJDMatchQuery, {
  //   body: {
  //     page: 1,
  //     job_id: jobData?.id,
  //     recruiter_id: jobData?.account_manager?.id,
  //     org_id: jobData?.company?.id,
  //   },
  // });
  const { data } = useFetchData(useMagicSearchQuery, {
    body: {
      job_id: jobData?.id,
      org_id: jobData?.company?.id || "",
    },
    skip: !jobData?.id || !jobData?.company?.id,
  });

  const handleSelectAll = () => {
    const allSelected =
      Object.keys(selectedProfiles)?.length === data?.length &&
      data?.every((profile, ind) => selectedProfiles[ind] === true);

    const newSelection = allSelected
      ? Object.fromEntries(data?.map((profile, ind) => [ind, false])) // Unselect all
      : Object.fromEntries(data?.map((profile, ind) => [ind, true])); // Select all

    setSelectedProfiles(newSelection);
  };
  const handleCheckboxChange = (id) => {
    setSelectedProfiles((prevSelected) => ({
      ...prevSelected,
      [id]: !prevSelected[id],
    }));
  };
  const anySelected = Object.values(selectedProfiles).includes(true);
  const getSelectedProfilesEmails = data
    ?.filter((profile, index) => selectedProfiles[index])
    .map((profile) => profile?.email);
  const handleBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  const initialValues = {
    emails: [""],
  };
  // Handle submit logic here
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axiosRequest(
        `${baseUrl}/api/recruiter/invite/candidate/`,
        {
          method: "POST",
          data: {
            emails: values.emails,
            job_pk: jobData?.id,
          },
        }
      );

      if (response.status === 201) {
        resetForm();
        toast.success("Invitation Send Successfully");
        // dispatch(setRefresh(!refresh));
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };
  const handleSelectedIntvite = async () => {
    setLoading(true);
    try {
      const response = await axiosRequest(
        `${baseUrl}/api/recruiter/invite/candidate/`,
        {
          method: "POST",
          data: {
            emails: getSelectedProfilesEmails,
            job_pk: jobData?.id,
          },
        }
      );

      if (response.status === 201) {
        toast.success("Invitation Send Successfully");
        setLoading(false);
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosFromRequest(
          `${baseUrl}/api/recruiter/job/${id}/`,
          {
            method: "GET",
          }
        );

        if (response.status === 200) {
          setJobData(response.data.data.job);
        }
      } catch (error) {}
    };
    fetchData();
  }, [id]);

  const parts = jobData?.description?.split("\n");

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleSeeMore = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <>
      {!jobData ? (
        <div className=" h-full flex justify-center  items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        <div className="w-full  flex flex-col gap-8 text-xs p-2 font-inter">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6 text-blue-500 cursor-pointer"
                onClick={handleBack}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
              <ATMHeading>
                Invite Candidates For Interview{" "}
                <span className="font-normal">
                  {jobData?.title}{" "}
                  <span className="italic">({jobData?.id})</span>
                </span>
              </ATMHeading>
            </div>
            <p className="font-inter  text-[#475467] leading-5">
              Now you can invite candidate to apply for job. We will provide you
              best hiring experience through Jivahire. Streamline your hiring
              process! Jivahire lets you effortlessly invite candidates to apply
              for open positions. We empower you to find the perfect fit with
              our user-friendly tools and expert support.Jivahire simplifies
              your hiring with easy candidate invitations. Focus on reviewing
              top talent while we handle the technical aspects.
            </p>

            <div className="flex justify-between items-stretch gap-3">
              <div className=" bg-[#F7F8FD] p-4 flex flex-col gap-4">
                <div className="flex justify-between items-start">
                  <div className="flex justify-between basis-3/4 items-start flex-col font-inter">
                    <h1 className="font-bold">Job Title</h1>
                    <p className="font-normal text-[#414141]">
                      {jobData?.title}
                    </p>
                  </div>

                  <div className="flex justify-between basis-1/2 items-start flex-col font-inter">
                    <h1 className="font-bold">Job ID</h1>
                    <p className="font-normal text-[#414141]">{jobData?.id}</p>
                  </div>
                </div>

                <div className="flex justify-between items-start">
                  <div className="flex basis-3/4 justify-between items-start flex-col font-inter">
                    <h1 className="font-bold">Date Posted</h1>
                    <p className="font-normal text-[#414141]">
                      {formatDate(jobData?.created_at)}
                    </p>
                  </div>

                  <div className="flex basis-1/2 justify-between items-start flex-col font-inter">
                    <h1 className="font-bold">Client Name</h1>
                    <p className="font-normal text-[#414141]">
                      {jobData?.external_company
                        ? jobData.company_name
                        : jobData?.company?.company_name}
                    </p>
                  </div>
                </div>

                <div className="flex justify-between items-start">
                  <div className="flex justify-between items-start flex-col font-inter">
                    <h1 className=" font-semibold">Summary</h1>

                    <div className="font-normal">
                      {parts
                        .slice(0, isExpanded ? parts.length : 8)
                        .map((part, index) => (
                          <div key={index}>{part}</div>
                        ))}
                      <button
                        onClick={toggleSeeMore}
                        className="text-[#1CACBD]"
                      >
                        {isExpanded ? "....See Less" : "See More...."}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-start">
                  <div className="flex basis-1/3 justify-between items-start flex-col font-inter">
                    <h1 className="font-bold">Account Manager</h1>
                    <p className="font-normal text-[#414141]">
                      {jobData?.account_manager?.first_name}{" "}
                      {jobData?.account_manager?.last_name}
                    </p>
                  </div>

                  <div className="flex basis-1/3 justify-between items-start flex-col font-inter">
                    <h1 className="font-bold">Location</h1>
                    <p className="font-normal text-[#414141]">
                      {jobData?.country} {jobData?.state}
                    </p>
                  </div>

                  <div className="flex basis-1/3 justify-between items-start flex-col font-inter">
                    <h1 className="font-bold">Salary Range</h1>
                    {jobData?.salary_from === 0 ? (
                      "--"
                    ) : (
                      <p className="font-normal text-[#414141]">
                        {jobData?.currency} {jobData?.salary_from} -{" "}
                        {jobData?.salary_to}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="flex gap-3 border-b  border-[#D0D5DD]">
              <ul className=" flex gap-6 font-medium text-[#667085] cursor-pointer">
                <li
                  className={`pb-2 ${
                    inviteBy === "Matching-profile"
                      ? "border-b-2 border-[#101828]"
                      : ""
                  } text-[#101828]`}
                  onClick={() => setInviteBy("Matching-profile")}
                >
                  Matching Profile
                </li>
                <li
                  className={`pb-2 ${
                    inviteBy === "Email" ? "border-b-2 border-[#101828]" : ""
                  } text-[#101828]`}
                  onClick={() => setInviteBy("Email")}
                >
                  Invite Candidates By Email
                </li>
                <li
                  onClick={() => setInviteBy("CSV")}
                  className={`pb-2 ${
                    inviteBy === "CSV" ? "border-b-2 border-[#101828]" : ""
                  } text-[#101828]`}
                >
                  Invite Candidates By CSV
                </li>

                {/* <li className="pb-2">Saved Jobs</li> */}
              </ul>
            </div>

            <div className="flex w-full  gap-4 items-stretch">
              {inviteBy === "Email" && (
                <Formik
                  initialValues={initialValues}
                  validationSchema={inviteSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched }) => (
                    <Form className="w-full">
                      <FieldArray name="emails">
                        {({ push, remove }) => (
                          <div className=" flex flex-col gap-5 p-4 rounded-2xl  w-full">
                            {values.emails.map((email, index) => (
                              <div key={index}>
                                <ATMFieldLabel> Email *</ATMFieldLabel>
                                <div className="flex gap-4 items-center">
                                  <Field
                                    name={`emails.${index}`}
                                    size="lg"
                                    placeholder="olivia@untitledui.com"
                                    className={`border w-[300px]   bg-white outline-none p-3 rounded-lg`}
                                    labelProps={{
                                      className:
                                        "before:content-none after:content-none",
                                    }}
                                  />

                                  {/* Remove button for emails */}

                                  <button
                                    type="button"
                                    className={` cursor-pointer ${
                                      values.emails.length === 1
                                        ? "text-white"
                                        : "text-red-500"
                                    }`}
                                    onClick={() => remove(index)}
                                    disabled={values.emails.length === 1}
                                  >
                                    Remove
                                  </button>
                                  {/* Add More Email button */}
                                  {index === values.emails.length - 1 && (
                                    <button
                                      type="button"
                                      className={`min-w[250px] flex gap-2 px-3    py-1 rounded-lg font-inter font-semibold justify-center ${
                                        !values.emails[index] ||
                                        errors.emails?.[index]
                                          ? "opacity-50 cursor-not-allowed"
                                          : ""
                                      }`}
                                      onClick={() => push("")}
                                      disabled={
                                        !values.emails[index] ||
                                        errors.emails?.[index]
                                      }
                                    >
                                      <div className="flex items-center text-[#1CACBD] w-fit gap-2">
                                        <svg
                                          width="20 "
                                          height="20  "
                                          viewBox="0 0 32 32"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M16.0024 10V22M10.0016 16H22.0032M31.0043 16C31.0043 24.2843 24.2877 31 16.0024 31C7.71707 31 1.00049 24.2843 1.00049 16C1.00049 7.71573 7.71707 1 16.0024 1C24.2877 1 31.0043 7.71573 31.0043 16Z"
                                            stroke="#1CACBD"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        Add Email
                                      </div>
                                    </button>
                                  )}
                                </div>
                                <div>
                                  {errors.emails?.[index] &&
                                    touched.emails?.[index] && (
                                      <ATMFieldError>
                                        {errors.emails[index]}
                                      </ATMFieldError>
                                    )}
                                </div>
                              </div>
                            ))}

                            <button
                              type="submit"
                              className="w-fit flex gap-2 px-3 text-white bg-[#1CACBD] py-2 rounded-lg font-inter items-center font-semibold justify-center"
                            >
                              Send Invite
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="size-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                        )}
                      </FieldArray>
                    </Form>
                  )}
                </Formik>
              )}
              {inviteBy === "CSV" && (
                // <div className="bg-[#FFFFF8] border border-[#F5F5C9] p-10 flex justify-between flex-col w-full rounded-2xl gap-4">
                //   <div className="flex justify-between items-start flex-col font-inter">
                //     <h1 className="font-bold">Upload The File</h1>
                //     <p className="font-normal text-[#414141]">
                //       Simplify candidate invitation by uploading a CSV
                //       containing their contact information and relevant details.
                //     </p>
                //   </div>
                //   <label
                //     htmlFor="upload-csv"
                //     className="w-fit flex gap-2 px-3 text-white bg-[#1CACBD] py-2 items-center rounded-lg font-inter font-semibold justify-center cursor-pointer"
                //   >
                //     Upload CSV
                //     <span>
                //       <svg
                //         xmlns="http://www.w3.org/2000/svg"
                //         fill="none"
                //         viewBox="0 0 24 24"
                //         strokeWidth={1.5}
                //         stroke="currentColor"
                //         className="size-6"
                //       >
                //         <path
                //           strokeLinecap="round"
                //           strokeLinejoin="round"
                //           d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                //         />
                //       </svg>
                //     </span>
                //   </label>
                //   <input
                //     type="file"
                //     ref={fileInputRef}
                //     id="upload-csv"
                //     accept=".csv"
                //     className="hidden"
                //     onChange={handleFileUpload}
                //   />
                // </div>
                <div className="flex justify-center items-center w-full font-bold text-lg text-[#1cadbd4b] ">
                  Coming Soon...
                </div>
              )}
              {inviteBy === "Matching-profile" && data?.length === 0 && (
                <span className="w-full flex justify-center">
                  No Data Found
                </span>
              )}
              {inviteBy === "Matching-profile" && data?.length > 0 && (
                <div className="flex flex-col gap-4">
                  <div className="flex items-center gap-4">
                    <button
                      className="px-4 h-[38px] min-w-[100px] text-[#1CACBD] border border-[#1CACBD] rounded-md"
                      onClick={handleSelectAll}
                    >
                      {data?.every(
                        (profile, ind) => selectedProfiles[ind] === true
                      )
                        ? "Unselect All"
                        : "Select All"}
                    </button>
                    <button
                      type="button"
                      className=" h-[38px]  w-[170px] flex justify-center items-center text-white border bg-[#1CACBD] border-[#1CACBD] rounded-md disabled:bg-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed"
                      disabled={!anySelected}
                      // className=" flex text-sm gap-2 text-white bg-[#1CACBD] px-[12px] h-[38px] rounded-lg items-center font-semibold justify-center "
                      onClick={handleSelectedIntvite}
                    >
                      {loading ? (
                        <ATMCircularProgress size="small" color="white" />
                      ) : (
                        <span className="flex items-center gap-2 justify-between">
                          Invite Candidate
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          </span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className="flex gap-2">
                    <TalentSearchCandidateView
                      selectedProfiles={selectedProfiles}
                      handleCheckboxChange={handleCheckboxChange}
                      profiles={data}
                      score={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InviteCandidate;
