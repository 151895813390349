import React from "react";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <div className="  ">
      <div className="  mx-auto  w-[90%]  mt-10  py-10  border-[1px] border-[#1CACBD5E] bg-[#1CACBD0A] rounded-md">
        <div className=" flex justify-center">
          <svg
            width="62"
            height="62"
            viewBox="0 0 62 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M24.7999 48.0492L13.9499 37.1992L9.17885 50.35C9.04695 50.6951 9.01776 51.071 9.09482 51.4323C9.17189 51.7936 9.3519 52.1248 9.61312 52.386C9.87435 52.6473 10.2056 52.8273 10.5669 52.9043C10.9282 52.9814 11.3041 52.9522 11.6492 52.8203L24.7999 48.0492Z"
              fill="#1CACBD"
            />
            <path
              opacity="0.2"
              d="M18.5996 24.4124L37.5871 43.3999L47.5168 39.7914C47.8237 39.6798 48.0975 39.4925 48.3126 39.2468C48.5278 39.0012 48.6773 38.7051 48.7474 38.3861C48.8175 38.0672 48.8058 37.7357 48.7134 37.4224C48.6211 37.1092 48.451 36.8244 48.2191 36.5945L25.4051 13.7804C25.1752 13.5485 24.8903 13.3785 24.5771 13.2861C24.2639 13.1938 23.9324 13.1821 23.6134 13.2522C23.2945 13.3222 22.9984 13.4718 22.7527 13.6869C22.5071 13.9021 22.3197 14.1758 22.2082 14.4828L18.5996 24.4124Z"
              fill="#1CACBD"
            />
            <path
              d="M9.17885 50.3507L22.2085 14.4828C22.3201 14.1758 22.5074 13.9021 22.7531 13.6869C22.9987 13.4718 23.2948 13.3222 23.6138 13.2522C23.9327 13.1821 24.2642 13.1938 24.5775 13.2861C24.8907 13.3785 25.1755 13.5485 25.4054 13.7804L48.2195 36.5945C48.4514 36.8244 48.6214 37.1092 48.7138 37.4224C48.8061 37.7357 48.8178 38.0672 48.7477 38.3861C48.6777 38.7051 48.5281 39.0011 48.313 39.2468C48.0978 39.4925 47.8241 39.6798 47.5171 39.7914L11.6492 52.821C11.3041 52.9529 10.9282 52.9821 10.5669 52.9051C10.2056 52.828 9.87435 52.648 9.61312 52.3868C9.3519 52.1255 9.17189 51.7943 9.09482 51.433C9.01776 51.0717 9.04695 50.6958 9.17885 50.3507V50.3507Z"
              stroke="#1CACBD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M40.6875 17.4375C40.6875 17.4375 40.6875 11.625 46.5 11.625C52.3125 11.625 52.3125 5.8125 52.3125 5.8125"
              stroke="#1CACBD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M24.8002 48.0492L13.9502 37.1992"
              stroke="#1CACBD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M34.875 3.875V9.6875"
              stroke="#1CACBD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M52.3125 27.125L56.1875 31"
              stroke="#1CACBD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M52.3125 19.375L58.125 17.4375"
              stroke="#1CACBD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.5996 24.4121L37.5871 43.3996"
              stroke="#1CACBD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div>
          <p className=" mt-4 text-xl text-[#3E3E3E] font-inter font-semibold text-center">
            Congratulations! Thanks for choosing JivaHire. <br /> Now you can
            view your profile from here.
          </p>
          <p className=" mt-2 text-xl font-inter font-normal text-[#6D758F] text-center">
            Your Profile is{" "}
            <span className="font-inter font-bold text-[#6D758F]">20%</span>{" "}
            Complete.{" "}
            <Link
              to="/candidate/profile/basic-details"
              className="text-[#1CACBD] font-inter font-semibold"
            >
              Edit Your Profile
            </Link>
          </p>
        </div>

        <Link
          to="/candidate/profile/basic-details"
          className="flex items-center justify-center mt-[30px] mx-auto w-[184px] h-[38px] bg-[#1CACBD] rounded-md text-white text-sm font-inter font-semibold"
        >
          Complete Your Profile
        </Link>
      </div>
    </div>
  );
}

export default HomePage;
