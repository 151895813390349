import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { FaAnglesRight } from "react-icons/fa6";
import { useParams } from "react-router";
import { ClockLoader } from "react-spinners";
import Webcam from "react-webcam";
import { axiosCandidateRequest } from "../../api/api";
import { axiosCandidateFromRequest } from "../../api/formApi";
import Navbar from "../candidate/Navbars/Navbar";
import Dailog from "./Dailog";

const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;
const sThreeUrl = process.env.REACT_APP_VITE_VIDEO_API_URL;

const VideoRecorder = () => {
  const { id } = useParams();
  const [isRecording, setIsRecording] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(false);
  const [permissionLoading, setPermissionLoading] = useState(true);
  const [selectedCameraId, setSelectedCameraId] = useState("");
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(0);
  const [canProceed, setCanProceed] = useState(false);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunks = useRef([]);
  const timerInterval = useRef(null);
  const [questions, setQuestions] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [ansType, setAnsType] = useState("");
  const [textAns, setTextAns] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const muteAllAudioOutput = async () => {
      try {
        // Get all audio output devices
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioOutputs = devices.filter(
          (device) => device.kind === "audiooutput"
        );

        // Mute all audio elements on the page
        const audioElements = document.getElementsByTagName("audio");
        Array.from(audioElements).forEach((audio) => {
          audio.muted = true;
        });

        // If browser supports setSinkId, set it to 'none'
        if (typeof HTMLMediaElement.prototype.setSinkId === "function") {
          audioOutputs.forEach(async (device) => {
            const elements = document.querySelectorAll("audio, video");
            elements.forEach(async (element) => {
              try {
                await element.setSinkId("");
              } catch (err) {
                console.warn("Failed to set audio output:", err);
              }
            });
          });
        }
      } catch (err) {
        console.error("Error muting audio output:", err);
      }
    };

    muteAllAudioOutput();
  }, []);
  // Initial permission check
  useEffect(() => {
    const checkPermissions = async () => {
      setPermissionLoading(true);
      try {
        const result = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: selectedCameraId
              ? { exact: selectedCameraId }
              : undefined,
          },
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
            sampleRate: 48000,
            channelCount: 1,
          },
        });

        // Ensure audio output is muted
        if (webcamRef.current?.video) {
          webcamRef.current.video.muted = true;
          webcamRef.current.video.volume = 0;
        }
        // Stop the test stream
        result.getTracks().forEach((track) => track.stop());

        setHasPermissions(true);
        await fetchCameraDevices();
      } catch (err) {
        console.error("Permission error:", err);
        setError("Camera and microphone access is required");
        setHasPermissions(false);
      } finally {
        setPermissionLoading(false);
      }
    };

    checkPermissions();
  }, [selectedCameraId]);

  const fetchCameraDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter((device) => device.kind === "videoinput");
      if (cameras.length > 0) {
        setSelectedCameraId(cameras[0].deviceId);
      }
    } catch (err) {
      setError("Error fetching camera devices: " + err.message);
    }
  };

  const startRecording = async () => {
    try {
      if (webcamRef.current?.video?.srcObject) {
        webcamRef.current.video.srcObject
          .getTracks()
          .forEach((track) => track.stop());
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: selectedCameraId ? { exact: selectedCameraId } : undefined,
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          sampleRate: 48000,
          channelCount: 1,
        },
      });

      webcamRef.current.video.srcObject = stream;
      webcamRef.current.video.muted = true;
      webcamRef.current.video.volume = 0;

      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm",
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000,
      });

      recordedChunks.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.current.push(event.data);
        }
      };

      mediaRecorderRef.current.start(1000);
      setIsRecording(true);
      setError("");
      setCanProceed(false);
      startTimer();

      // Add event listener for when audio output devices change
      navigator.mediaDevices.ondevicechange = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        devices.forEach((device) => {
          if (device.kind === "audiooutput") {
            // Ensure any new audio output devices are also muted
            if (
              webcamRef.current?.video &&
              typeof webcamRef.current.video.setSinkId === "function"
            ) {
              webcamRef.current.video.setSinkId("");
            }
          }
        });
      };

      // Error handling for media recorder
      mediaRecorderRef.current.onerror = (event) => {
        console.error("MediaRecorder error:", event);
        setError("Recording error: " + event.error.message);
      };
    } catch (err) {
      console.error("Error starting recording:", err);
      setError("Error starting recording: " + err.message);

      // Attempt to clean up any partial streams
      if (webcamRef.current?.video?.srcObject) {
        webcamRef.current.video.srcObject
          .getTracks()
          .forEach((track) => track.stop());
      }
    }
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current?.state === "recording") {
      return new Promise((resolve) => {
        mediaRecorderRef.current.onstop = async () => {
          const blob = new Blob(recordedChunks.current, { type: "video/webm" });

          if (webcamRef.current?.video?.srcObject) {
            webcamRef.current.video.srcObject
              .getTracks()
              .forEach((track) => track.stop());
            webcamRef.current.video.srcObject = null;
          }

          resolve(blob);
        };

        mediaRecorderRef.current.stop();
        clearInterval(timerInterval.current);
      });
    }
    return null;
  };

  const startTimer = () => {
    clearInterval(timerInterval.current);
    setTimer(0);
    timerInterval.current = setInterval(() => {
      setTimer((prev) => {
        const newTime = prev + 1;
        if (newTime >= 5) {
          setCanProceed(true);
        }
        if (newTime >= 300) {
          // clearInterval(timerInterval.current);
          // stopRecording().then((blob) => {
          //   if (blob) uploadVideo(blob);
          // });
          stopRecording();
        }
        return newTime;
      });
    }, 1000);
  };

  const fetchQuestions = async () => {
    setQuestions([]);
    setIsLoading(true);
    try {
      const response = await axiosCandidateRequest(
        `${baseUrl}/api/candidate/start-test/${id}/`,
        {
          method: "GET",
        }
      );

      if (response.status === 200) {
        setIsRecording(true);
        setAnsType(response.data.data.ans_type);
        const updatedQuestions = [response.data.data.question];
        setQuestions(updatedQuestions);
        setTotalQuestions(response.data.data.total_questions);
        startTimer();
        if (response.data.data.ans_type !== "text-based") {
          startRecording();
        }
      } else {
        toast.error("Failed to fetch questions");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const uploadVideo = async () => {
    const blob = new Blob(recordedChunks.current, { type: "video/webm" });
    setIsLoading(true);
    clearInterval(timerInterval.current);
    setTimer(0);

    const formData = new FormData();
    formData.append("application_id", id);
    formData.append("candidate_id", id);
    formData.append("bucket_name", "jivahire-job-applications");
    formData.append("file", blob, `${questions[questions.length - 1]}.webm`);

    try {
      const response = await axiosCandidateFromRequest(
        `${sThreeUrl}/job-application-upload-video/?x_api_key=ZBFezMmBuh2pkEXttdz6SwOeMgGGsG2b`,
        {
          method: "POST",
          data: formData,
        }
      );

      if (response.status === 200) {
        await nextQuestion(response.data.s3_url);
      } else {
        toast.error("Failed to upload video");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };
  const nextQuestion = async (url) => {
    setIsLoading(true);
    try {
      const response = await axiosCandidateRequest(
        `${baseUrl}/api/candidate/start-test/${id}/`,
        {
          method: "PATCH",
          data: {
            qna_json: {
              question: questions[questions.length - 1],
              answer: "",
              video_path: url,
              thumbnail_path: "",
            },
          },
        }
      );

      if (response.status === 200) {
        if (questions.length < totalQuestions) {
          setQuestions((prevQuestions) => [
            ...prevQuestions,
            response.data.data.question,
          ]);
          setAnsType(response.data.data.ans_type);
          recordedChunks.current = [];

          if (response.data.data.ans_type !== "text-based") {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: true,
              audio: true,
            });
            webcamRef.current.video.srcObject = stream;
            await startRecording();
          }
          startTimer();
        } else {
          setIsRecording(false);
          setOpen(true);
        }
      } else {
        toast.error("Failed to update data");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const nextQuestionText = async () => {
    setIsLoading(true);
    clearInterval(timerInterval.current);
    setTimer(0);
    try {
      const response = await axiosCandidateRequest(
        `${baseUrl}/api/candidate/start-test/${id}/`,
        {
          method: "PATCH",
          data: {
            qna_json: {
              question: questions[questions.length - 1],
              answer: textAns,
              video_path: "",
              thumbnail_path: "",
            },
          },
        }
      );

      if (response.status === 200) {
        if (questions.length < totalQuestions) {
          setQuestions((prevQuestions) => [
            ...prevQuestions,
            response.data.data.question,
          ]);
          setTextAns("");
          setAnsType(response.data.data.ans_type);
          recordedChunks.current = [];

          if (response.data.data.ans_type !== "text-based") {
            await startRecording();
          }
          startTimer();
        } else {
          setIsRecording(false);
          setTextAns("");
          setOpen(true);
        }
      } else {
        toast.error("Failed to update data");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  // Cleanup on unmount
  // useEffect(() => {
  //   return () => {
  //     clearInterval(timerInterval.current);
  //     if (webcamRef.current?.video?.srcObject) {
  //       webcamRef.current.video.srcObject
  //         .getTracks()
  //         .forEach((track) => track.stop());
  //     }
  //     if (mediaRecorderRef.current?.state === "recording") {
  //       mediaRecorderRef.current.stop();
  //     }
  //   };
  // }, []);
  useEffect(() => {
    // Store the current webcamRef and mediaRecorderRef values in local variables
    const webcamRefCurrent = webcamRef.current;
    const mediaRecorderRefCurrent = mediaRecorderRef.current;

    // Cleanup on unmount
    return () => {
      clearInterval(timerInterval.current);
      if (webcamRefCurrent?.video?.srcObject) {
        webcamRefCurrent.video.srcObject
          .getTracks()
          .forEach((track) => track.stop());
      }
      if (mediaRecorderRefCurrent?.state === "recording") {
        mediaRecorderRefCurrent.stop();
      }
    };
  }, []);

  // Handle page visibility changes
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "hidden") {
        clearInterval(timerInterval.current);
        await stopRecording();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  if (permissionLoading) {
    return (
      <div className="fixed inset-0 bg-black/75 flex items-center justify-center">
        <ClockLoader color={"#F8EDED"} loading={true} size={150} />
      </div>
    );
  }

  return (
    <>
      <Dailog
        open={open}
        setOpen={setOpen}
        heading="Your application is successfully submitted"
        para="Your application is on its way! You can check your Job Applications for updates and manage your applications in one place."
        btnText="View Job Application"
        btnLink="/candidate/my-application"
      />

      <div className="w-full  min-h-screen relative ">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <ClockLoader color={"#F8EDED"} loading={true} size={150} />
          </div>
        )}

        <Navbar loginbutton={false} />
        <div className="flex justify-center flex-wrap font-inter text-xs max-w-screen-2xl mx-auto gap-3 h-full pt-2">
          <div className="flex    justify-center gap-2 flex-col p-4">
            <div className="w-[400px] gap-2 flex justify-center items-center flex-col">
              {error && <div className="text-red-500">{error}</div>}

              <h1 className="font-inter font-semibold  text-2xl">
                {isRecording ? "" : "Get Started"}
              </h1>

              {isRecording ? (
                // <div className="flex justify-between  items-center py-3 border-b w-full">
                //   <p className="bg-[#354657] text-white rounded-lg p-1 w-full text-center">

                //     <span className="text-lg">{totalQuestions}</span> Questions For This Job Application
                //   </p>
                // </div>
                ""
              ) : (
                <>
                  <p className="font-inter font-medium text-sm">
                    Setup your webcam and Audio before start the recording
                  </p>
                  <p className="font-inter font-medium text-[#ffb443f5] text-sm">
                    After clicking on start recording you will be asked few
                    questions
                  </p>
                </>
              )}

              <div className="flex justify-start text-start w-full items-center gap-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="9.69629"
                    cy="9.21484"
                    r="6.5"
                    fill="white"
                    stroke="#FF0000"
                    strokeWidth="5"
                  />
                </svg>
                <p className=" text-xs">Rec {formatTime(timer)}</p>
              </div>

              {isRecording && (
                <div className="text-black p-3 rounded-lg font-semibold bg-[#5D5D5D5E] w-full text-center">
                  {questions.length > 0 && (
                    <p>
                      {questions.length}. {questions[questions.length - 1]}
                    </p>
                  )}
                </div>
              )}

              <div className=" flex justify-center ">
                {ansType === "text-based" ? (
                  <div className="w-full h-full">
                    <textarea
                      value={textAns}
                      onChange={(e) => setTextAns(e.target.value)}
                      className="w-full h-full min-h-96 bg-transparent border text-white p-2 resize-none"
                      placeholder="Type your answer here..."
                    />
                  </div>
                ) : (
                  <Webcam
                    audio
                    ref={webcamRef}
                    className=" w-[100%] bg-slate-800"
                    screenshotFormat="image/jpeg"
                    muted={true} // Add this line
                    volume={0}
                  />
                )}
              </div>

              {isRecording ? (
                <div
                  className={`w-full mt-1 flex text-white ${
                    ansType === "text-based" ? "justify-end" : "justify-between"
                  } items-center justify-end`}
                >
                  {ansType !== "text-based" && (
                    <button
                      onClick={stopRecording}
                      disabled={!canProceed}
                      className={`bg-[#F8000F] w-[130px]  ${
                        canProceed ? "" : "cursor-not-allowed bg-red-400"
                      } flex gap-2 items-center text-center justify-center rounded-lg p-2 px-5`}
                    >
                      Stop Recording
                    </button>
                  )}

                  <button
                    onClick={
                      ansType === "text-based"
                        ? nextQuestionText
                        : () => stopRecording().then(uploadVideo)
                    }
                    disabled={!canProceed}
                    className={`bg-[#1CACBD] w-[130px]  ${
                      canProceed ? "" : "cursor-not-allowed bg-[#9ad6dc]"
                    } flex gap-2 items-center text-center justify-center rounded-lg p-2 px-5`}
                  >
                    {questions.length === totalQuestions
                      ? "Submit"
                      : "Save & Next"}{" "}
                    <FaAnglesRight />
                  </button>
                </div>
              ) : (
                <div className="w-full flex justify-center mt-2">
                  <button
                    onClick={fetchQuestions}
                    className="px-4 text-xs h-[40px] text-white bg-[#F8000F] rounded-md"
                    disabled={!hasPermissions}
                  >
                    Start Recording
                  </button>
                </div>
              )}
            </div>
          </div>

          {isRecording && (
            <div className=" h-fit  p-3 rounded-md">
              <>
                <div className="bg-[#81BAFF17] h-fit  p-3 rounded-md">
                  <div>
                    Questions ({questions.length}/{totalQuestions})
                  </div>
                  {questions.map((question, index) => (
                    <ul key={index} className="ml-4 py-3 flex flex-col gap-3">
                      <li
                        className={`bg-[#4A5767] ${
                          index === questions.length - 1
                            ? "text-white"
                            : "text-[#8e9194]"
                        } p-2 rounded-lg`}
                      >
                        {index + 1}. {question}
                      </li>
                    </ul>
                  ))}
                </div>

                <p className="m-2">
                  You have to answer{" "}
                  <strong>{totalQuestions - questions.length}</strong> more
                  questions before submitting the application
                </p>
              </>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VideoRecorder;
