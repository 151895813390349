import { City, Country, State } from "country-state-city";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMSelect from "../../../../atoms/ATMSelect/ATMSelect";
import ATMTextField from "../../../../atoms/ATMTextField/ATMTextField";
import { visasData } from "../../../../helpers/candidate";

const jobPreferences = ["Remote Working", "On-site", "Hybrid", "Flexible"];
const salarytype = [
  { value: 1, label: "Hourly" },
  { value: 2, label: "Yearly" },
];

export const hobbies = [
  { hobbiecategory: "Arts and Crafts", hobbiename: "" },
  { hobbiecategory: "Technology and Computing", hobbiename: "" },
  { hobbiecategory: "Music and Performing Arts", hobbiename: "" },
  { hobbiecategory: "Sports and Physical Activities", hobbiename: "" },
  { hobbiecategory: "Literature and Writing", hobbiename: "" },
  { hobbiecategory: "Cooking and Culinary Arts", hobbiename: "" },
  { hobbiecategory: "Science and Nature", hobbiename: "" },
  { hobbiecategory: "Travel and Adventure", hobbiename: "" },
  { hobbiecategory: "Mind and Body Wellness", hobbiename: "" },
  { hobbiecategory: "Learning and Education", hobbiename: "" },
  { hobbiecategory: "Games and Puzzles", hobbiename: "" },
  { hobbiecategory: "Social and Community Activities", hobbiename: "" },
  { hobbiecategory: "Collecting", hobbiename: "" },
  { hobbiecategory: "Film and Media", hobbiename: "" },
  { hobbiecategory: "Fashion and Beauty", hobbiename: "" },
];

const OtherInformationFormLayout = ({ formikProps, formType }) => {
  const userData = useSelector((state) => state.candidateData.candidateData);
  const visas = visasData[userData?.country]?.Visas || [];
  const { values, setFieldValue, handleBlur, isSubmitting, handleChange } =
    formikProps;
  const hobbiess = Array.isArray(values?.hobbie) ? values.hobbie : [];

  const [selectedHobbies, setSelectedHobbies] = useState(
    hobbiess?.map((el) => ({
      hobbiecategory: el?.hobbie?.hobbie_category || "",
      hobbiename: el?.hobbie?.hobbiename || "",
    })) || []
  );
  const addNewHobby = (hobbiecategory) => {
    if (
      !selectedHobbies.some((hobby) => hobby.hobbiecategory === hobbiecategory)
    ) {
      const newHobby = hobbies.find(
        (hobby) => hobby.hobbiecategory === hobbiecategory
      );
      setSelectedHobbies([...selectedHobbies, { ...newHobby }]);
    }
    setFieldValue("hobbie", "");
  };

  const removeHobby = (hobbiecategory) => {
    setSelectedHobbies(
      selectedHobbies.filter((hobby) => hobby.hobbiecategory !== hobbiecategory)
    );
  };

  const updateHobbyDescription = (hobbiecategory, description) => {
    setSelectedHobbies(
      selectedHobbies.map((hobby) =>
        hobby.hobbiecategory === hobbiecategory
          ? { ...hobby, hobbiename: description }
          : hobby
      )
    );
  };

  useEffect(() => {
    setFieldValue("hobbie", selectedHobbies);
  }, [selectedHobbies, setFieldValue]);

  return (
    <div className="flex flex-col gap-4">
      <div className=" grid grid-cols-1  md:grid-cols-2 p-4  justify-between  gap-10 border-[1px] rounded-lg border-[#E2DCEA]">
        <div className="flex flex-wrap gap-5 md:col-span-2 p-[20px] bg-[#F8F8F8]">
          <div>
            <ATMFieldLabel>
              If checked then no need to select Visa Authorization
            </ATMFieldLabel>
            <div className="mt-[15px] flex items-center space-x-2">
              <input
                type="checkbox"
                id="residence"
                name="residence"
                className="form-checkbox text-blue-600"
                checked={values.residence}
                onChange={() => setFieldValue("residence", !values?.residence)}
                onBlur={handleBlur}
              />
              <label className="font-inter text-sm font-medium text-[#344054]">
                Are you a resident of{" "}
                <span className=" text-[#588EE5]">{userData?.country}</span>?
              </label>
            </div>
          </div>

          <ATMSelect
            isDisabled={values.residence}
            name="visa"
            label=" Current Work Authorization / Visa status"
            onChange={(newValue) => setFieldValue("visa", newValue)}
            placeholder="Select Country"
            value={values.visa || ""}
            options={visas.map((country) => ({
              value: country,
              label: country,
            }))}
          />
        </div>

        <div>
          <ATMFieldLabel>Job Preference</ATMFieldLabel>
          <div className="flex items-center gap-4 flex-wrap mt-1">
            {jobPreferences?.map((el) => {
              return (
                <div key={el} className="flex items-center gap-2 min-w-40">
                  <div>
                    <input
                      type="checkbox"
                      value={el}
                      checked={values?.jobPreference === el}
                      onChange={(e) =>
                        setFieldValue("jobPreference", e.target.value)
                      }
                    />
                  </div>
                  <div className="capitalize">{el}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <ATMFieldLabel> Expected Salary</ATMFieldLabel>
          <div className="flex items-center gap-2 my-1">
            <input
              type="checkbox"
              id="germany"
              className="form-checkbox text-blue-600"
              value={values?.standard}
              checked={values?.standard}
              onClick={() => {
                setFieldValue("standard", !values?.standard);
                  setFieldValue("salary", "");
                  setFieldValue("salaryType", "");
              }}
            />
            <label className="font-inter text-xs font-bold text-[#344054] ">
              As per Standard
            </label>
          </div>

          <div className="grid grid-cols-2 items-center gap-4 ">
            <ATMTextField
              disabled={values?.standard}
              type="text"
              name="salary"
              value={values.salary}
              placeholder="$ 10000"
              onChange={(e) => setFieldValue("salary", e.target.value)}
              onBlur={handleBlur}
            />
            <ATMSelect
              isDisabled={values.standard}
              name="salaryType"
              onChange={(newValue) => setFieldValue("salaryType", newValue)}
              placeholder="Select Salary Type"
              value={values.salaryType}
              options={salarytype}
            />
          </div>
        </div>
      </div>

      <div className="  p-4 rounded-lg border-[1px] border-[#E2DCEA]">
        <div>
          <ATMFieldLabel> Interests/Hobbies</ATMFieldLabel>

          <div className="flex items-start justify-start flex-wrap">
            {selectedHobbies.map((hobby, index) => (
              <div className="basis-1/3 p-2" key={index}>
                <div className="w-full p-2  border-[#D0D5DD] border-[1px] rounded-xl">
                  <div className="flex justify-between">
                    <div className="my-2 text-[#667085] font-inter text-base font-normal">
                      {hobby.hobbiecategory}
                    </div>
                    <div
                      className="text-[#6D758F] cursor-pointer"
                      onClick={() => removeHobby(hobby.hobbiecategory)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                  <textarea
                    className="w-full h-[136px] border-t-[1px] border-[#667085] placeholder:text-[#667085] font-inter text-xs pt-2"
                    placeholder={`Tell us more about ${hobby.hobbiecategory}....`}
                    value={hobby.hobbiename}
                    onChange={(e) =>
                      updateHobbyDescription(
                        hobby.hobbiecategory,
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            ))}
            <div className="basis-1/3 p-2">
              <select
                className="rounded-lg w-full h-[48px] outline-none border-[1px] border-[#D0D5DD] placeholder:text-[#667085] font-inter font-normal text-sm px-2"
                name="interest"
                value={values.interest}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (values.interest.trim()) {
                      addNewHobby(values.interest);
                    }
                  }
                }}
                onBlur={handleBlur}
                id="interest"
              >
                <option value="" disabled>
                  Enter your interest here....
                </option>
                {hobbies.map((hobby, index) => (
                  <option key={index} value={hobby.hobbiecategory}>
                    {hobby.hobbiecategory}
                  </option>
                ))}
              </select>

              <div className="mt-3 flex flex-wrap gap-1">
                {selectedHobbies.map((hobby, index) => (
                  <button
                    key={index}
                    type="button"
                    className="flex items-center justify-center gap-1 px-2 py-2 rounded-xl bg-[#00B9291A] font-inter text-[8px] font-normal text-[#02B15D]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                    {hobby.hobbiecategory}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="  p-9 rounded-lg border-[1px] border-[#E2DCEA]">
        <div className="text-base font-semibold mb-5">Preferred Location</div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-around  gap-4">
          {/* Location */}
          <ATMSelect
            name={`country`}
            label="Country"
            options={Country.getAllCountries().map((country) => ({
              value: country.isoCode,
              label: country.name,
            }))}
            onChange={(newValue) => {
              setFieldValue(`country`, newValue);
              setFieldValue(`state`, null);
              setFieldValue(`city`, null);
            }}
            value={values?.country || null}
          />

          <ATMSelect
            name={`state`}
            label="State"
            options={
              values.country && values.country.value
                ? State.getStatesOfCountry(values.country.value).map(
                    (state) => ({
                      value: state.isoCode,
                      label: state.name,
                    })
                  )
                : []
            }
            onChange={(newValue) => {
              setFieldValue(`state`, newValue);
              setFieldValue(`city`, null);
            }}
            value={values?.state || null}
          />

          <ATMSelect
            name={`city`}
            label="City"
            value={values?.city || null}
            options={
              values.country?.value && values.state?.value
                ? City.getCitiesOfState(
                    values.country?.value,
                    values.state?.value
                  ).map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))
                : []
            }
            onChange={(newValue) => {
              setFieldValue(`city`, newValue);
            }}
          />
        </div>
      </div>

      {/* Submit and Skip Buttons */}
      <div className=" flex mb-3">
        <button
          type="submit"
          className="w-[124px] flex justify-center items-center h-[48px] rounded-md bg-[#1CACBD] text-white"
        >
          {isSubmitting ? (
            <ATMCircularProgress size="small" color="white" />
          ) : (
            <span> {formType === "ADD" ? "Submit" : "Save"}</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default OtherInformationFormLayout;
