import React from "react";
import TagJdList from "./TagJdList";

const TagJdListWrapper = ({setSelectedJobs , selectedJobs}) => {
  return (
    <div>
      <TagJdList selectedJobs ={selectedJobs} setSelectedJobs={setSelectedJobs}  />
    </div>
  );
};

export default TagJdListWrapper;
