import React, { useRef, useState } from "react";
import Select from "react-select";
import { ErrorMessage } from "formik";
import ATMFieldLabel from "../ATMFieldLabel/ATMFieldLabel";
import ATMFieldError from "../ATMFieldError/ATMFieldError";

const ATMSelect = ({
  name,
  value,
  onChange,
  label,
  options = [],
  placeholder = "Select...",
  isTouched = false,
  isValid = true,
  isClearable = true,
  closeMenuOnSelect = true,
  minMenuHeight = 300,
  maxMenuHeight = 300,
  isLoading = false,
  isDisabled = false,
  menuPlacement = "auto",
  menuPosition = "absolute",
  onBlur,
  required = false,
  ...props
}) => {
  const selectRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const isOutlined = props.variant === "outlined";

  const customStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      border: "none",
      outlineColor: "white",
      boxShadow: "none",
      backgroundColor: "transparent",
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 9999,
    }),
    menuPortal: (baseStyles) => ({
      ...baseStyles,
      zIndex: 9999,
    }),
  };

  return (
    <div
      onClick={() => {
        selectRef?.current?.focus();
        setFocused(true);
      }}
      className="relative font-inter text-xs "
    >
      <ATMFieldLabel required={required} htmlFor={name} hidden={isOutlined}>
        {label}
      </ATMFieldLabel>

      <div
        className={`relative rounded-md flex flex-col min-w-[200px] ${
          isOutlined && "justify-end"
        } ${isDisabled && "opacity-60"} border ${
          focused && !isDisabled ? "border-primary" : "border-neutral-80"
        }`}
      >
        <Select
          name={name}
          value={
            options?.find((option) => option?.value === value) || value || null
          }
          onChange={(newValue) => {
            onChange(newValue); // Pass the whole selected object
            selectRef?.current?.focus?.(true);
          }}
          options={options}
          isClearable={isClearable}
          closeMenuOnSelect={closeMenuOnSelect}
          getOptionLabel={(option) => option.label} // Directly access label
          getOptionValue={(option) => option.value} // Directly access value
          isDisabled={isDisabled}
          isLoading={isLoading}
          minMenuHeight={minMenuHeight}
          maxMenuHeight={maxMenuHeight}
          menuPlacement={menuPlacement}
          menuPosition={menuPosition}
          placeholder={isOutlined ? (focused ? placeholder : "") : placeholder}
          onBlur={(e) => {
            onBlur?.(e);
            setFocused(false);
          }}
          ref={selectRef}
          styles={customStyles}
          {...props}
        />
      </div>

      <ErrorMessage name={name}>
        {(errorMessage) => <ATMFieldError>{errorMessage}</ATMFieldError>}
      </ErrorMessage>
    </div>
  );
};

export default ATMSelect;
