import { Form, Formik } from "formik";
import React from "react";
import { object, string } from "yup";
import BasicDetailsForm from "../Layout/BasicDetailsForm";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../../../../features/refresh/refresh";
import { setCandidateData } from "../../../../../features/candidateData/candidateData";
import { axiosCandidateFromRequest } from "../../../../../api/formApi";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const EditBasicDetailsFormWrapper = ({ userData, setEdit }) => {
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresh.refresh);
  const initialValues = {
    email: userData?.email || "",
    firstName: userData?.first_name || "",
    lastName: userData?.last_name || "",
    contactNumber: userData?.contact_number || "",
  };

  const validationSchema = object().shape({
    email: string().email().required("Please enter your email"),
    firstName: string()
      .required("First name is required")
      .min(2, "First name must be at least 2 characters")
      .max(40, "First name must be less than 50 characters"),

    lastName: string()
      .required("Last name is required")
      .min(2, "Last name must be at least 2 characters")
      .max(40, "Last name must be less than 50 characters"),

    contactNumber: string()
      .required("Contact number is required")
      .min(9, "Contact number must be at least 10 digits")
      .max(15, "Contact number must be less than 15 digits"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("first_name", values.firstName);
      formData.append("last_name", values.lastName);
      formData.append("email", values.email);
      formData.append("contact_number", values.contactNumber);

      const response = await axiosCandidateFromRequest(
        `${baseUrl}/api/candidate/user/`,
        {
          method: "PATCH",
          data: formData,
        }
      );

      if (response.status === 200) {
        setSubmitting(false);
        toast.success("Data updated successfully");
        setEdit(false);
        localStorage.setItem(
          "candidate_profile",
          JSON.stringify(response.data.data.candidate)
        );
        dispatch(setCandidateData(response.data.data.candidate));
        dispatch(setRefresh(!refresh));
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <BasicDetailsForm formType="Edit" formikProps={formikProps} />
        </Form>
      )}
    </Formik>
  );
};

export default EditBasicDetailsFormWrapper;
