// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Scores.css */
.scores-container {
    display: flex;
    justify-content: space-around;
    padding: 10px;
  }
  
  .score-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .score {
    font-size: 32px;
    font-weight: bold;
  }
  
  .percentage {
    font-size: 18px;
    color: #555;
  }
  
  .label {
    font-size: 16px;
    text-align: center;
  }
  
  .green-border {
    border-bottom: 5px solid green;
  }
  
  .yellow-border {
    border-bottom: 5px solid orange;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/recruiters/Scores.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,aAAa;IACb,6BAA6B;IAC7B,aAAa;EACf;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,+BAA+B;EACjC","sourcesContent":["/* Scores.css */\n.scores-container {\n    display: flex;\n    justify-content: space-around;\n    padding: 10px;\n  }\n  \n  .score-column {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 120px;\n    border: 1px solid #ddd;\n    padding: 10px;\n    background-color: #f9f9f9;\n  }\n  \n  .score {\n    font-size: 32px;\n    font-weight: bold;\n  }\n  \n  .percentage {\n    font-size: 18px;\n    color: #555;\n  }\n  \n  .label {\n    font-size: 16px;\n    text-align: center;\n  }\n  \n  .green-border {\n    border-bottom: 5px solid green;\n  }\n  \n  .yellow-border {\n    border-bottom: 5px solid orange;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
