import React, { useState } from "react";
import { formatDate } from "../../../candidate/ProfileDetails/View/ProfileDetails";
import { useNavigate } from "react-router-dom";
import ResumeView from "../../TalentSearch/ResumeView/ResumeView";

const MyResmuesList = ({ listData, pagination }) => {
  const navigate = useNavigate();
  const [view, setView] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  // Check if there is no data to display the "No data found" message
  if (!listData || listData.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-xl text-gray-500">No data found</p>
      </div>
    );
  }
  const { currentPage, itemsPerPage } = pagination;
  return (
    <div className="w-full ">
      {/* Table Wrapper with Horizontal Scroll */}
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border">
          {/* Header */}
          <thead>
            <tr className="text-center text-xs font-semibold text-gray-700 bg-blue-50 border-b sticky top-0 z-10">
              <th className="py-2 px-4 border-r  text-start">S.No.</th>
              <th className="py-2 px-4 border-r  text-start">Job Title</th>
              <th className="py-2 px-4 border-r  text-start">
                {" "}
                Candidate Name
              </th>
              <th className="py-2 px-4 border-r  text-start">
                {" "}
                Candidate Email
              </th>
              <th className="py-2 px-4 border-r">Resume ID</th>

              <th className="py-2 px-4 border-r">Resume View</th>
              <th className="py-2 px-4 border-r col-span-2">Uploaded Date</th>
              {/* <th className="py-2 px-4 border-r">Job View</th> */}

              <th className="py-2 px-4 border-r col-span-2">Updated Date</th>
              <th className="py-2 px-4">Edit</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {listData?.map((el, ind) => (
              <tr key={el?.id} className="text-center text-xs border-b">
                {/* Job View */}
                <td className="py-2 px-4 border-r">
                  {" "}
                  {(currentPage - 1) * itemsPerPage + ind + 1}
                </td>
                <td className="py-2 px-4 border-r   ">
                  <span className="text-start flex flex-col gap-2">
                    {el?.tagged_jobs?.map((job) => {
                      return (
                        <p
                          key={job?.id}
                          onClick={() =>
                            navigate(
                              `/recruit/job-status/recruiter-view/${job?.id}`
                            )
                          }
                          className="text-[#1CACBD] border-[#1CACBD] font-medium cursor-pointer border-b h-full w-fit"
                        >
                          {job?.title || ""}
                        </p>
                      );
                    })}
                  </span>
                </td>
                <td className="py-2 px-4 border-r text-start">
                  {el?.candidate_form_data?.first_name}{" "}
                  {el?.candidate_form_data?.last_name}
                </td>
                <td className="py-2 px-4 border-r text-start">{el?.email}</td>
                <td className="py-2 px-4 border-r">{el?.id}</td>

                {/* Resume View */}
                <td className="py-2 px-4 border-r">
                  <div className="flex justify-center items-center">
                    <button
                      className="text-[#1CACBD]  flex justify-center"
                      onClick={() => {
                        setView(el?.resume_links);
                        setIsOpen(true);
                      }}
                      disabled={!el?.resume_links[0]}
                      // disabled={true}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
                {/* Uploaded Section */}
                <td className="py-2 px-4 border-r col-span-2">
                  {formatDate(el?.upload_date, true)}
                </td>

                {/* Updated Section */}
                <td className="py-2 px-4 border-r col-span-2">
                  {formatDate(el?.updated_at, true)}
                </td>

                {/* Edit Button */}
                <td className="py-2 px-4  ">
                  <span className="flex justify-center items-center">
                    <span
                      className="w-fit border px-4 py-1 rounded-md bg-blue-50 text-blue-500 flex items-center gap-1 cursor-pointer"
                      onClick={() => navigate(`/recruit/edit-resume/${el?.id}`)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                      Edit
                    </span>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Resume View Modal */}
      <div className="absolute top-10 z-50">
        <ResumeView
          htmlContent={view[view?.length - 1]?.resume_link}
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
        />
      </div>
    </div>
  );
};

export default MyResmuesList;
