import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditBasicDetailsFormWrapper from "../Edit/EditBasicDetailsFormWrapper";
import ATMFieldLabel from "../../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMHeading from "../../../../../atoms/ATMHeading/ATMHeading";

const BasicDetailsView = () => {
  const [edit, setEdit] = useState(false);
  const userData = useSelector((state) => state.candidateData.candidateData);
  return (
    <div className="text-xs font-inter  flex flex-col gap-4 px-2 ">
      <div>
        <ATMHeading> Basic Details</ATMHeading>
        <p className="text-slate-500 ">
          Enhance your job search — Create your Candidate Profile and connect
          with leading employers. Discover opportunities that align with your
          skills and experience, and utilize powerful tools to showcase your
          potential to your future team.
        </p>
      </div>
      {userData?.first_name && !edit ? (
        <div className=" p-9 justify-between w-full  border-[1px] border-[#E2DCEA] rounded-md relative">
          <div
            onClick={() => setEdit(true)}
            className="cursor-pointer absolute hover:text-black text-slate-600 top-[-8px] right-[-7px] bg-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </div>
          <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 font-inter items-center">
            <div className=" ">
              <ATMFieldLabel> First Name</ATMFieldLabel>
              <h1 className="text-slate-600">{userData?.first_name || ""}</h1>
            </div>
            <div>
              <ATMFieldLabel> Last Name</ATMFieldLabel>
              <h1 className="text-slate-600">{userData?.last_name || ""}</h1>
            </div>
            <div className="">
              <ATMFieldLabel>Email</ATMFieldLabel>
              <h1 className="text-slate-600">{userData?.email || ""}</h1>
            </div>
            <div className=" ">
              <ATMFieldLabel> Contact Number</ATMFieldLabel>
              <h1 className="text-slate-600">
                {userData?.contact_number || ""}
              </h1>
            </div>
          </div>
        </div>
      ) : (
        <EditBasicDetailsFormWrapper userData={userData} setEdit={setEdit} />
      )}
    </div>
  );
};

export default BasicDetailsView;
