// import React, { useEffect, useRef, useState } from "react";
// import Webcam from "react-webcam";
// import { axiosCandidateFromRequest } from "../../api/formApi";
// import toast from "react-hot-toast";
// import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";

// const VideoRecorderModal = ({
//   isOpen,
//   onClose,
//   onVideoUpload,
//   id,
//   videoNameRandom,
// }) => {
//   const [recording, setRecording] = useState(false);
//   const [isVideoUpload, setIsVideoUpload] = useState(false);
//   const webcamRef = useRef(null);
//   const mediaRecorderRef = useRef(null);
//   const [recordedChunks, setRecordedChunks] = useState([]);
//   const timerInterval = useRef(null);
//   const [timer, setTimer] = useState(0);
//   const [videoURL, setVideoURL] = useState("");

//   const sThreeUrl = process.env.REACT_APP_VITE_VIDEO_API_URL;
//   useEffect(() => {
//     if (!isOpen) {
//       if (webcamRef.current) {
//         const stream = webcamRef.current.stream;
//         if (stream) {
//           stream.getTracks().forEach((track) => track.stop());
//         }
//       }
//     }
//   }, [isOpen]);

//   useEffect(() => {
//     if (timer >= 300) {
//       stopRecording();
//     }
//   }, [timer]);

//   const startRecording = () => {
//     const stream = webcamRef.current.stream;
//     const options = { mimeType: "video/webm; codecs=vp9" };
//     const mediaRecorder = new MediaRecorder(stream, options);
//     mediaRecorderRef.current = mediaRecorder;
//     mediaRecorder.ondataavailable = handleDataAvailable;
//     mediaRecorder.start(1000);

//     setRecording(true);
//     timerInterval.current = setInterval(() => {
//       setTimer((prevTimer) => prevTimer + 1);
//     }, 1000);
//   };

//   const stopRecording = () => {
//     if (timer < 15) {
//       alert("Recording must be at least 15 seconds long.");
//       return;
//     }
//     setRecording(false);
//     mediaRecorderRef.current.stop();
//     clearInterval(timerInterval.current);
//   };

//   const handleDataAvailable = (event) => {
//     if (event.data.size > 0) {
//       setRecordedChunks((prev) => [...prev, event.data]);
//     }
//   };

//   const saveRecording = async () => {
//     const blob = new Blob(recordedChunks, { type: "video/webm" });

//     const videoURL = URL.createObjectURL(blob);
//     setVideoURL(videoURL); // Save the video URL for downloading
//     await uploadVideo(blob);
//   };

//   const uploadVideo = async (blob) => {
//     setIsVideoUpload(true);
//     clearInterval(timerInterval.current);
//     setTimer(0);
//     const formData = new FormData();

//     formData.append("candidate_id", id);
//     formData.append("bucket_name", "user-profile-videos");
//     formData.append("file", blob, videoNameRandom);

//     try {
//       const response = await axiosCandidateFromRequest(
//         `${sThreeUrl}/user-profile-video-upload/?x_api_key=ZBFezMmBuh2pkEXttdz6SwOeMgGGsG2b`,
//         { method: "POST", data: formData }
//       );

//       if (response.status === 200) {
//         toast.success(response?.data?.message);
//         onVideoUpload(response.data.s3_url); // Send the URL to the parent component
//         onClose();
//       } else {
//         toast.error(response?.data?.message);
//       }
//     } catch (error) {
//       toast.error(response?.data?.message);
//     } finally {
//       setIsVideoUpload(false);
//     }
//   };

//   const formatTime = (time) => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-75 flex justify-center items-center">
//       <div className=" bg-transparent p-5 rounded-lg w-[600px] h-[450px] flex justify-center items-center flex-col">
//         <Webcam
//           ref={webcamRef}
//           audio={true}
//           muted={true}
//           className="w-[580px] h-[394px]"
//         />
//         <div className="mt-4 flex gap-4">
//           {!recording ? (
//             <button
//               type="button"
//               onClick={startRecording}
//               className="btn px-4 py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d]"
//             >
//               Start Recording
//             </button>
//           ) : (
//             <button
//               type="button"
//               onClick={stopRecording}
//               className="btn px-4 py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d]"
//             >
//               Stop Recording
//             </button>
//           )}
//           <button
//             type="button"
//             onClick={saveRecording}
//             disabled={isVideoUpload}
//             className="btn px-4 py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d] flex justify-center w-[150px]"
//           >
//             {isVideoUpload ? (
//               <ATMCircularProgress size="small" color="white" />
//             ) : (
//               " Save Recording"
//             )}
//           </button>
//           {/* <button onClick={onClose} className="btn px-4 py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d]">Close</button> */}
//         </div>
//         <div className="mt-4">
//           <p className="text-lg text-white">{formatTime(timer)}</p>
//         </div>
//         {/* {videoURL && (
//           <div className="mt-4">
//             <a href={videoURL} download="recorded_video.webm" className="btn px-4 py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d]">Download Video</a>
//           </div>
//         )} */}
//       </div>
//     </div>
//   );
// };

// export default VideoRecorderModal;

import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { axiosCandidateFromRequest } from "../../api/formApi";
import toast from "react-hot-toast";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";

const VideoRecorderModal = ({
  isOpen,
  onClose,
  onVideoUpload,
  id,
  videoNameRandom,
}) => {
  const [recording, setRecording] = useState(false);
  const [isVideoUpload, setIsVideoUpload] = useState(false);
  const [showRecordedVideo, setShowRecordedVideo] = useState(false);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const timerInterval = useRef(null);
  const [timer, setTimer] = useState(0);
  const [videoURL, setVideoURL] = useState("");

  const sThreeUrl = process.env.REACT_APP_VITE_VIDEO_API_URL;
  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && recording) {
      mediaRecorderRef.current.stop();
      clearInterval(timerInterval.current);
      setRecording(false);
    }
  }, [recording]);

  useEffect(() => {
    if (!isOpen) {
      stopRecording();
    }
  }, [isOpen, stopRecording]);

  useEffect(() => {
    if (timer >= 300) {
      stopRecording();
    }
  }, [timer, stopRecording]);

  const startRecording = () => {
    const stream = webcamRef.current.stream;
    const options = { mimeType: "video/webm; codecs=vp9" };
    const mediaRecorder = new MediaRecorder(stream, options);
    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start(1000);

    setRecording(true);
    timerInterval.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setRecordedChunks((prev) => [...prev, event.data]);
    }
  };

  const saveRecording = async () => {
    if (recordedChunks.length === 0 || timer < 15) {
      alert("Recording must be at least 15 seconds long.");
      return;
    }

    stopRecording();
    clearInterval(timerInterval.current);
    setTimer(0); // Reset timer

    const blob = new Blob(recordedChunks, { type: "video/webm" });
    const videoURL = URL.createObjectURL(blob);
    setVideoURL(videoURL); // Save the video URL for displaying

    setShowRecordedVideo(true); // Show the recorded video
    resetRecording();
  };

  const resetRecording = () => {
    setRecordedChunks([]);
    clearInterval(timerInterval.current);
  };

  const uploadVideo = async (blob) => {
    setIsVideoUpload(true);
    const formData = new FormData();
    formData.append("candidate_id", id);
    formData.append("bucket_name", "user-profile-videos");
    formData.append("file", blob, videoNameRandom);

    try {
      const response = await axiosCandidateFromRequest(
        `${sThreeUrl}/user-profile-video-upload/?x_api_key=ZBFezMmBuh2pkEXttdz6SwOeMgGGsG2b`,
        { method: "POST", data: formData }
      );

      if (response.status === 200) {
        toast.success(response?.data?.message);
        onVideoUpload(response.data.s3_url);
        onClose();
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error("Upload error: " + error.message);
    } finally {
      setIsVideoUpload(false);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleReRecord = () => {
    setShowRecordedVideo(false); // Hide recorded video
    resetRecording(); // Reset the recording state
    setVideoURL(""); // Clear the video URL
    startRecording(); // Start a new recording
  };

  const handleSubmit = () => {
    const blob = new Blob(recordedChunks, { type: "video/webm" });
    uploadVideo(blob); // Call the upload function
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-75 flex justify-center items-center">
      <div className="bg-transparent p-5 rounded-lg w-[600px] h-[450px] flex justify-center items-center flex-col relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-white bg-[#1CACBD] rounded-full p-2 hover:bg-[#148a9d]"
        >
          ✖
        </button>
        {!showRecordedVideo ? (
          <>
            <Webcam
              ref={webcamRef}
              audio={true}
              muted={true}
              className="w-[580px] h-[394px]"
            />
            <div className="mt-4 flex gap-4">
              {!recording ? (
                <button
                  type="button"
                  onClick={startRecording}
                  className="btn px-4 py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d]"
                >
                  Start Recording
                </button>
              ) : (
                <button
                  type="button"
                  onClick={stopRecording}
                  className="btn px-4 py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d]"
                >
                  Stop Recording
                </button>
              )}
              <button
                type="button"
                onClick={saveRecording}
                disabled={isVideoUpload}
                className="btn px-4 py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d] flex justify-center w-[150px]"
              >
                {isVideoUpload ? (
                  <ATMCircularProgress size="small" color="white" />
                ) : (
                  " Save Recording"
                )}
              </button>
            </div>
            <div className="mt-4">
              <p className="text-lg text-white">{formatTime(timer)}</p>
            </div>
          </>
        ) : (
          <div className="mt-4 flex flex-col items-center">
            <video
              src={videoURL}
              controls
              className="w-[580px] h-[394px] rounded-lg"
            />
            <div className="mt-4 flex gap-4">
              <button
                type="button"
                onClick={handleReRecord}
                className="btn px-4 py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d]"
              >
                Re-record
              </button>
              <button
                type="button"
                onClick={handleSubmit} // Call the handleSubmit function here
                disabled={isVideoUpload}
                className="btn px-4 py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d]"
              >
                {isVideoUpload ? (
                  <ATMCircularProgress size="small" color="white" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoRecorderModal;
