import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { array, object, string } from "yup";
import { axiosCandidateFromRequest } from "../../../../api/formApi";
import { setRefresh } from "../../../../features/refresh/refresh";
import EducationDetailsFormLayout from "../Layout/EducationDetailsFormLayout";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const EditEducationDetailsFormWrapper = ({
  setEducationDetailsEdit,
  userData,
  educationDetailsEditId,
}) => {
  const editData = userData?.education_candidate?.[educationDetailsEditId];
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresh.refresh);
  const skillsArray =
    (userData?.education_candidate && JSON.parse(editData?.skills)) || [];
  const formattedSkills = skillsArray.map((skill) => ({ label: skill }));
  const initialValues = {
    education: { label: editData?.education } || "",
    specialization: editData?.specialization || "",
    institution: editData?.institution_name || "",
    addskills: formattedSkills || [],
    startDate: editData?.duration_start || "",
    endDate: editData?.duration_end || "",
    experiencevideo: editData?.upload_education_video || "",
  };

  const validationSchema = object().shape({
    addskills: array().max(100, "You can add a maximum of 100 skills"),
    specialization: string().max(
      100,
      "specialization cannot exceed 100 characters"
    ),
    institution: string().max(100, "institution cannot exceed 100 characters"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    try {
      const formData = {
        education: values.education?.label,
        specialization: values.specialization,
        institution_name: values.institution,
        duration_start: values.startDate,
        duration_end: values.endDate,
        skills: JSON.stringify(values.addskills.map((el) => el.label)),
        upload_education_video: values?.experiencevideo,
      };

      const formData2 = new FormData();

      formData2.append("hobby_list", values?.hobbie);
      let response = await axiosCandidateFromRequest(
        `${baseUrl}/api/candidate/education/${editData?.id}/`,
        {
          method: "PATCH",
          data: formData,
        }
      );

      let response2;
      if (userData?.candidatehobbies_candidate?.[0]) {
        // If there's an id, it's an update, so use PATCH
        response2 = await axiosCandidateFromRequest(
          `${baseUrl}/api/candidate/hobbies/${userData?.candidatehobbies_candidate?.[0]?.id}/`,
          {
            method: "PATCH",
            data: {
              hobby_list: values?.hobbie,
            },
          }
        );
      } else {
        // Otherwise, it's a new resource, so use POST
        response2 = await axiosCandidateFromRequest(
          `${baseUrl}/api/candidate/hobbies/`,
          {
            method: "POST",
            data: {
              hobby_list: values?.hobbie,
            },
          }
        );
      }
      if (response2)
        if (response.status === 201) {
          setSubmitting(false);
          toast.success(response?.data?.message);
          setEducationDetailsEdit(false);
          dispatch(setRefresh(!refresh));
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // toast.error(error.response.data.message);
      } else {
        // toast.error("An unexpected error occurred");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <EducationDetailsFormLayout
            formType="Edit"
            formikProps={formikProps}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditEducationDetailsFormWrapper;
