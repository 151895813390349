import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { array, object, string } from "yup";
import { axiosCandidateFromRequest } from "../../../../api/formApi";
import { setRefresh } from "../../../../features/refresh/refresh";
import EducationDetailsFormLayout from "../Layout/EducationDetailsFormLayout";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const AddEducationDetailsFormWrapper = ({ setEducationDetailsAdd }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refresh = useSelector((state) => state.refresh.refresh);

  const initialValues = {
    education: "",
    specialization: "",
    institution: "",
    addskills: [],
    startDate: "",
    endDate: "",
    experiencevideo: "",
  };

  const validationSchema = object().shape({
    addskills: array().max(100, "You can add a maximum of 100 skills"),
    specialization: string().max(
      100,
      "specialization cannot exceed 100 characters"
    ),
    institution: string().max(100, "institution cannot exceed 100 characters"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    try {
      const formData = {
        education: values.education?.label,
        specialization: values.specialization,
        institution_name: values.institution,
        duration_start: values.startDate,
        duration_end: values.endDate,
        skills: JSON.stringify(values.addskills.map((el) => el.label)),
        upload_education_video: values?.experiencevideo,
      };

      const formData2 = new FormData();

      formData2.append("hobby_list", values?.hobbie);

      let response = await axiosCandidateFromRequest(
        `${baseUrl}/api/candidate/education/`,
        {
          method: "POST",
          data: formData,
        }
      );

      if (response.status === 201) {
        setSubmitting(false);
        toast.success("Data updated successfully");
        setEducationDetailsAdd(false);
        dispatch(setRefresh(!refresh));
        navigate(`/candidate/profile/other-information`);
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // toast.error(error.response.data.message);
      } else {
        // toast.error("An unexpected error occurred");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <EducationDetailsFormLayout
            formType="ADD"
            formikProps={formikProps}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddEducationDetailsFormWrapper;
