import React, { useState } from "react";
import { useSelector } from "react-redux";
import EducationDetails from "./EducationDetails";
import EditEducationDetailsFormWrapper from "../Edit/EditEducationDetailsFormWrapper";
import AddEducationDetailsFormWrapper from "../Add/AddEducationDetailsFormWrapper";
import ATMHeading from "../../../../atoms/ATMHeading/ATMHeading";

const EducationDetailsWrapper = () => {
  const [educationDetailsEdit, setEducationDetailsEdit] = useState(false);
  const [educationDetailsEditId, setEducationDetailsEditId] = useState(0);
  const [educationDetailsAdd, setEducationDetailsAdd] = useState(false);
  const userData = useSelector((state) => state.candidateData.candidateData);
  const areBothFieldsEmpty = userData?.education_candidate.length > 0;
  return (
    <div className="text-xs font-inter  flex flex-col gap-4 px-2 ">
      <div>
        <ATMHeading>Education</ATMHeading>
        <p className="text-slate-500 ">
          Expand your profile by adding your educational background. Share your
          qualifications, degrees, and academic achievements to enhance your
          profile
        </p>
      </div>

      <div>
        {areBothFieldsEmpty && !educationDetailsEdit && !educationDetailsAdd ? (
          <EducationDetails
            setEducationDetailsEdit={setEducationDetailsEdit}
            setEducationDetailsAdd={setEducationDetailsAdd}
            setEducationDetailsEditId={setEducationDetailsEditId}
            userData={userData}
          />
        ) : !areBothFieldsEmpty || educationDetailsAdd ? (
          <AddEducationDetailsFormWrapper
            setEducationDetailsAdd={setEducationDetailsAdd}
            userData={userData}
          />
        ) : (
          educationDetailsEdit && (
            <EditEducationDetailsFormWrapper
              setEducationDetailsEdit={setEducationDetailsEdit}
              userData={userData}
              educationDetailsEditId={educationDetailsEditId}
            />
          )
        )}
      </div>
    </div>
  );
};

export default EducationDetailsWrapper;
