import React from "react";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import { useNavigate } from "react-router";

const JivahireProfile = () => {
  const navigate = useNavigate();

  return (
    <div className="h-full flex flex-col">
      {/* Heading Section */}
      <div className="border-b px-4 py-2 shadow-sm">
        <ATMHeading className="text-center">Jivahire Profile</ATMHeading>
      </div>

      {/* Coming Soon Message */}
      <div className="flex-grow flex justify-center items-center">
        <div className="font-bold text-2xl text-[#1cadbd4b]">
          Coming Soon...
        </div>
      </div>

      {/* Button Section */}
      <div className="flex justify-end pb-4 px-2">
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="border-[#1CACBD] border text-[#1CACBD] h-10 px-4 rounded-md text-center w-[100px] hover:bg-[#1cadbd4b] hover:text-white transition-colors"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default JivahireProfile;
