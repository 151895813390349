import React, { useState } from "react";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import {
  useResumeSubmitMutation,
  useTrackResumeMutation,
} from "../Services/FormServices";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

const UploadBulkResumeForm = ({ org_id }) => {
  const navigate = useNavigate();
  const [track] = useTrackResumeMutation();
  const [addSingle] = useResumeSubmitMutation();
  const rec_id = localStorage.getItem("recruiter_id");
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const uploadFile = async (file, index) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.map((f, i) => (i === index ? { ...f, isloading: true } : f))
    );
    const formData = new FormData();
    formData.append("resume_file", file);
    formData.append("org_id", org_id?.company?.id);

    try {
      const trackRes = await track(formData);

      if (trackRes?.error) {
        toast.error(trackRes?.error?.data?.message);
        setUploadedFiles((prevFiles) =>
          prevFiles.map((f, i) =>
            i === index
              ? { ...f, progress: 100, isloading: true, error: true }
              : f
          )
        );
        return;
      }

      if (trackRes?.data?.success) {
        if (trackRes?.data?.message !== "Resume uploaded successfully") {
          setUploadedFiles((prevFiles) =>
            prevFiles.map((f, i) =>
              i === index
                ? {
                    ...f,
                    progress: 100,
                    isloading: true,
                    id: trackRes?.data?.data[0]?.id,
                  }
                : f
            )
          );
          return;
        }
        if (!trackRes?.data?.response?.career_score_data) {
          setTimeout(async () => {
            return;
          }, 90000);
        }

        const formData1 = new FormData();
        formData1.append(
          "career_score_json",
          JSON.stringify(trackRes?.data?.response?.career_score_data)
        );
        formData1.append("s3_folder_id", "");
        formData1.append(
          "resume_link",
          trackRes?.data?.response?.resume_s3_url?.s3_url || ""
        );
        formData1.append("resume_uploaded_by_recruiter_id", rec_id);
        formData1.append(
          "candidate_form_data",
          JSON.stringify({
            first_name:
              trackRes?.data?.response?.career_score_data?.first_name || "",
            middle_name:
              trackRes?.data?.response?.career_score_data?.middle_name || "",
            last_name:
              trackRes?.data?.response?.career_score_data?.last_name || "",
            candidateEmailFromResume:
              trackRes?.data?.response?.career_score_data?.email || "",
            current_company: "",
            email: trackRes?.data?.response?.career_score_data?.email,
            contactNumber:
              trackRes?.data?.response?.career_score_data?.contact_number || "",
            experience: "",
            noticePeriod: "",
            currentSalaryCurrency: "",
            salaryCurrency: "",
            ctc: "",
            availableFrom: null,
            immediatelyAvailable: false,
            jobPreference: "",
            asPerStandard: true,
            salaryType: "",
            salaryTo: 0,
            salaryFrom: 0,
            information: "",
            country:
              {
                label:
                  trackRes?.data?.response?.career_score_data?.location
                    ?.country,
              } || null,
            state:
              {
                label:
                  trackRes?.data?.response?.career_score_data?.location?.state,
              } || null,
            city:
              {
                label:
                  trackRes?.data?.response?.career_score_data?.location?.city,
              } || null,
            zipCode: "",
            preferredLocations: [{ country: null, state: null, city: null }],
            candidate_linkdin_link: "",
            source: "",
            hiring: "",
            career_score_json: "",
            resume_link: "",
            recruiter_id: "",
            primarySkills:
              Object.keys(
                trackRes?.data?.response?.career_score_data?.skills?.primary ||
                  {}
              ).map((el) => {
                return {
                  label: el,
                  value: el,
                };
              }) || "",
            secondarySkills:
              Object.keys(
                trackRes?.data?.response?.career_score_data?.skills
                  ?.secondary || {}
              )?.map((el) => {
                return {
                  label: el,
                  value: el,
                };
              }) || "",
            tertiarySkills:
              Object.keys(
                trackRes?.data?.response?.career_score_data?.skills?.tertiary ||
                  {}
              )?.map((el) => {
                return {
                  label: el,
                  value: el,
                };
              }) || "",
          })
        );
        formData1.append("tagged_jobs", JSON.stringify([]));
        formData1.append("resume_file", file);
        formData1.append("form_data_uploaded", true);
        formData1.append(
          "email",
          trackRes?.data?.response?.career_score_data?.email
        );
        formData1.append("org_id", org_id?.company?.id);

        // Add the form data to the backend
        const addSingleRes = await addSingle(formData1);

        if (addSingleRes?.error) {
          toast.error(addSingleRes?.error?.data?.message);
          setUploadedFiles((prevFiles) =>
            prevFiles.map((f, i) =>
              i === index
                ? { ...f, progress: 100, isloading: true, error: true }
                : f
            )
          );
        } else {
          if (addSingleRes?.data?.success) {
            toast.success(addSingleRes?.data?.message);
            setUploadedFiles((prevFiles) =>
              prevFiles.map((f, i) =>
                i === index
                  ? { ...f, progress: 100, isloading: true, error: false }
                  : f
              )
            );
          } else {
            toast.error(addSingleRes?.error?.data?.message);
            setUploadedFiles((prevFiles) =>
              prevFiles.map((f, i) =>
                i === index
                  ? { ...f, progress: 100, isloading: true, error: true }
                  : f
              )
            );
          }
        }
      } else {
        toast.error("Unknown error occurred.");
        setUploadedFiles((prevFiles) =>
          prevFiles.map((f, i) =>
            i === index
              ? { ...f, progress: 100, isloading: true, error: true }
              : f
          )
        );
      }
    } catch (err) {
      console.error("Upload failed:", err);
      toast.error("Failed to upload the resume.");
      setUploadedFiles((prevFiles) =>
        prevFiles.map((f, i) =>
          i === index
            ? { ...f, progress: 100, isloading: true, error: true }
            : f
        )
      );
    } finally {
      setUploadedFiles((prevFiles) =>
        prevFiles.map((f, i) => (i === index ? { ...f, isloading: false } : f))
      );
    }
  };

  // Function to handle file uploads
  const handleFileUpload = async (files) => {
    const fileList = Array.from(files).map((file) => ({
      name: file.name,
      size: (file.size / (1024 * 1024)).toFixed(2),
      progress: 0,
      error: false,
      isloading: false,
    }));

    // Update the state with the initial file list
    setUploadedFiles((prevFiles) => [...prevFiles, ...fileList]);

    // Iterate through each file and upload them sequentially
    for (const [index, file] of Array.from(files).entries()) {
      await uploadFile(file, index);
    }
  };

  const handleFileChange = (e) => {
    setUploadedFiles([]);
    handleFileUpload(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFileUpload(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  return (
    <div className="flex flex-col p-2 w-full text-xs font-inter">
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="border-2 border-dashed border-gray-300 py-10 px-6 text-center mb-6 cursor-pointer lg:w-1/2"
      >
        <p className="text-gray-500 mb-4">
          Drag and drop multiple resume files here
        </p>
        <label className="bg-[#1CACBD] text-white py-2 px-4 rounded-lg cursor-pointer">
          Upload Files
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="hidden"
          />
        </label>
      </div>

      <div className="space-y-4 ">
        {uploadedFiles.map((file, index) => (
          <div
            key={index}
            className="flex justify-between items-center p-4 border rounded-lg shadow-sm bg-white"
          >
            <div className="flex items-center">
              {" "}
              <p className="mr-5">{index + 1}</p>
              <p className="font-medium w-full">{file.name}</p>
            </div>

            {file?.isloading ? (
              <ATMCircularProgress size="small" />
            ) : file.error ? (
              <span className="text-red-500 font-medium">❌ Upload Failed</span>
            ) : file?.progress === 100 && !file?.error ? (
              <span>
                {" "}
                {file?.id ? (
                  <span className="flex gap-2 w-full items-center">
                    <span className=" text-xs font-semibold  text-red-500 mr-10">
                      Duplicate entry. Candidate with this Resume already
                      exists.
                    </span>

                    <span
                      className="w-fit  underline rounded-md  text-blue-500 flex items-center gap-1 cursor-pointer"
                      onClick={() =>
                        navigate(`/recruit/edit-resume/${file?.id}`)
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                      Edit
                    </span>
                  </span>
                ) : (
                  <span className="text-green-500 text-xl">
                    {" "}
                    <span className="text-green-500 font-medium text-xs mr-5">
                      Upload complete
                    </span>
                    ✔
                  </span>
                )}
              </span>
            ) : (
              <span className="text-sm text-gray-500 mb-1">
                {" "}
                Uploading - {file.size} MB
                <span className="ml-5">Pending</span>
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadBulkResumeForm;
