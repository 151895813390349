import React, { useState } from "react";
import ProfileDetails from "./ProfileDetails";
import EditProfileDetailsFormWrapper from "../Edit/EditProfileDetailsFormWrapper";
import { useSelector } from "react-redux";
import ATMHeading from "../../../../atoms/ATMHeading/ATMHeading";

const ProfileDetailsWrapper = () => {
  const [profileEdit, setProfileEdit] = useState(false);
  const userData = useSelector((state) => state.candidateData.candidateData);
  const areBothFieldsNotEmpty =
    userData?.title ||
    userData?.date_of_birth ||
    userData?.country ||
    userData?.state ||
    userData?.city;
  return (
    <div className="text-xs font-inter  flex flex-col gap-4 px-2 ">
      <div>
        <ATMHeading> Profile Details</ATMHeading>
        <p className="text-slate-500 ">
          Enhance your job search — Create your Candidate Profile and connect
          with leading employers. Discover opportunities that align with your
          skills and experience, and utilize powerful tools to showcase your
          potential to your future team
        </p>
      </div>
      {areBothFieldsNotEmpty && !profileEdit ? (
        <ProfileDetails setProfileEdit={setProfileEdit} userData={userData} />
      ) : profileEdit ? (
        <EditProfileDetailsFormWrapper
          setProfileEdit={setProfileEdit}
          userData={userData}
        />
      ) : (
        <EditProfileDetailsFormWrapper
          setProfileEdit={setProfileEdit}
          userData={userData}
        />
      )}
    </div>
  );
};

export default ProfileDetailsWrapper;
