import React from "react";

const ATMPagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  onItemsPerPageChange,
  itemsPerPageOptions,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPageNumbers = () => {
    const pages = [];
    const range = 2;

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - range && i <= currentPage + range)
      ) {
        pages.push(i);
      }
    }

    const uniquePages = [];
    for (let i = 0; i < pages.length; i++) {
      uniquePages.push(pages[i]);
      if (i > 0 && pages[i] - pages[i - 1] > 1) {
        uniquePages.push("...");
      }
    }

    return uniquePages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className="flex flex-col items-center">
      <div className="flex space-x-2">
        <select
          onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
          value={itemsPerPage}
          className=" border rounded outline-none border-[#1CACBD] text-[#1CACBD] text-center "
        >
          {itemsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-4 py-1 text-white bg-[#1CACBD] rounded ${
            currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          Previous
        </button>

        <div className="flex flex-wrap justify-center gap-2">
          {pageNumbers.map((page, index) => (
            <button
              key={index}
              onClick={() => {
                if (typeof page === "number") {
                  onPageChange(page);
                }
              }}
              className={`px-3 py-1  text-sm border rounded ${
                currentPage === page
                  ? "bg-[#1CACBD] text-white border-[#1CACBD]"
                  : "bg-white text-[#1CACBD] border-[#1CACBD]"
              }`}
              disabled={page === "..."}
            >
              {page}
            </button>
          ))}
        </div>

        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-4 py-1 text-white bg-[#1CACBD] rounded ${
            currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ATMPagination;
