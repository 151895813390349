import React from "react";
import logo from "../../../assets/logo jiva hire.png";
// bg-[#1CACBD]
const Footer = () => {
  return (
    <footer className=" font-inter text-xs py-2 px-4 flex flex-col gap-2  max-w-screen-2xl mx-auto  ">
      <div className="text-center   flex justify-between items-center">
        <div className="font-bold text-slate-500 flex items-center gap-2">
          <div className="h-[30px] w-[80px] ">
            <img src={logo} alt="JivaHire Logo" className="h-full w-full" />
          </div>
          © {new Date().getFullYear()} Jivahire.
        </div>
        <p className="font-bold text-slate-500">
          Digital experiences that delight.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
