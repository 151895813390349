

import apiSlice from "../../../../app/ApiSlice";
export const reportServicesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    reportData: builder.query({
        query: (body) => ({
            url: `/api/recruiter/report/`,
          method: "POST",
          body,
       
        }),
      }),
  }),
});

export const {
useReportDataQuery 
} = reportServicesApi;