import { ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldError from "../../../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMMultiSelect from "../../../../atoms/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "../../../../atoms/ATMSelect/ATMSelect";
import ATMTextField from "../../../../atoms/ATMTextField/ATMTextField";
import VideoRecorderModal from "../../../../components/candidate/VideoRecorderModal";

const educationData = [
  { value: 1, label: "High School" },
  { value: 2, label: "Post Graduation" },
  { value: 3, label: "Doctorate" },
  { value: 4, label: "Diploma" },
  { value: 5, label: "Certificate" },
  { value: 6, label: "Graduation" },
];

const skills = [
  { value: 1, label: "Data Science" },
  { value: 2, label: "Market Research" },
  { value: 3, label: "Java++" },
  { value: 4, label: "User Research" },
  { value: 5, label: "Python" },
];

const EducationDetailsFormLayout = ({ formikProps, formType }) => {
  const userData = useSelector((state) => state.candidateData.candidateData);
  const { values, setFieldValue, handleBlur, isSubmitting } = formikProps;

  const [videoUrl, setVideoUrl] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleVideoUpload = (url) => {
    setVideoUrl(url);
  };

  useEffect(() => {
    setFieldValue("experiencevideo", videoUrl);
  }, [videoUrl, setFieldValue]);

  return (
    <div className="flex flex-col gap-4">
      <div className=" grid grid-cols-1  md:grid-cols-2 p-9  justify-between  gap-10 border-[1px] rounded-lg border-[#E2DCEA]">
        <div className="">
          <ATMSelect
            name="education"
            label="Education"
            onChange={(newValue) => setFieldValue("education", newValue)}
            placeholder="Select education"
            value={values.education || ""}
            options={educationData}
          />
        </div>

        <div>
          <ATMTextField
            type="text"
            name="institution"
            value={values.institution || ""}
            label="Institution Name"
            placeholder="Enter your Institution Name"
            onChange={(e) => setFieldValue("institution", e.target.value)}
            onBlur={handleBlur}
          />
        </div>
        {/* Duration */}
        <div className=" ">
          <ATMFieldLabel>Duration</ATMFieldLabel>

          <div className="flex items-center  flex-wrap gap-2 ">
            <input
              type="date"
              className="w-full px-3 py-2 border min-w-[200px] border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 "
              name="startDate"
              value={values.startDate}
              onChange={(e) => setFieldValue("startDate", e.target.value)}
              onBlur={handleBlur}
            />
            <div className="font-bold text-sm ">To</div>
            <input
              type="date"
              className="w-full px-3 py-2 border min-w-[200px] border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 "
              name="endDate"
              value={values.endDate}
              onChange={(e) => setFieldValue("endDate", e.target.value)}
              onBlur={handleBlur}
              min={values?.startDate}
            />
          </div>
          <div className="relative">
            <ErrorMessage name="startDate">
              {(errorMessage) => (
                <ATMFieldError> {errorMessage} </ATMFieldError>
              )}
            </ErrorMessage>
          </div>
        </div>
        {/* specialization */}
        <div>
          <ATMTextField
            type="text"
            name="specialization"
            value={values.specialization || ""}
            label="Specialization"
            placeholder="Enter your Job Title"
            onChange={(e) => setFieldValue("specialization", e.target.value)}
            onBlur={handleBlur}
          />
        </div>

        {/* addskills */}
        <div className="">
          <ATMMultiSelect
            name="addskills"
            value={values.addskills || []}
            onChange={(newValue) => setFieldValue("addskills", newValue)}
            options={skills}
            label=" Add Your Skills"
            placeholder="Select Skills"
            keysToSelectoption={["Enter", ","]}
          />
        </div>

        {/* Introduction Video */}
        <div className="min-w-[300px">
          <div>
            <ATMFieldLabel> Upload Your Experience Video</ATMFieldLabel>
          </div>{" "}
          <div className=" flex justify-around items-center p-8 w-full h-[200px] border-[1px] border-[#E2DCEA] rounded-md">
            <div className=" flex  items-center justify-center text-center flex-col gap-3">
              <button
                type="button"
                onClick={openModal}
                className=" flex items-center justify-around gap-1  font-inter text-sm text-[#1CACBD] font-medium bg-[#1CACBD0F] rounded-xl"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                    stroke="#1CACBD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                    stroke="#1CACBD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Record Video
              </button>
              <p className=" text-xs font-inter font-normal text-[#828799]">
                Tell us about your experience! You can record one right here
                using your webcam your journey.
              </p>
            </div>
            {modalIsOpen && (
              <VideoRecorderModal
                isOpen={modalIsOpen}
                onClose={closeModal}
                id={userData?.id}
                onVideoUpload={handleVideoUpload}
                videoNameRandom={`education_video${Math.floor(
                  ((Math.random(1) * 23) / 10) * 57
                )}`}
              />
            )}
          </div>
          <div className="relative">
            <ErrorMessage name="uploadvideo">
              {(errorMessage) => (
                <ATMFieldError> {errorMessage} </ATMFieldError>
              )}
            </ErrorMessage>
          </div>
        </div>
      </div>

      {/* Submit and Skip Buttons */}
      <div className=" flex mb-3">
        <button
          type="submit"
          className="w-[124px] flex justify-center items-center h-[48px] rounded-md bg-[#1CACBD] text-white"
        >
          {isSubmitting ? (
            <ATMCircularProgress size="small" color="white" />
          ) : (
            <span> {formType === "ADD" ? "Proceed" : "Save"}</span>
          )}
        </button>
        {formType === "ADD" && (
          <Link to="/candidate/profile/other-information">
            <div className="ml-4 flex items-center justify-center w-[124px] h-[48px] rounded-md border-[1px] border-[#1CACBD] text-[#1CACBD]">
              Skip
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default EducationDetailsFormLayout;
