import React from "react";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
export const formatDate = (dateString, showTime = false) => {
  if (dateString === null) {
    return "--";
  }

  const date = new Date(dateString);

  // Format the date as "dd MMM yy"
  const dateOptions = { day: "2-digit", month: "short", year: "2-digit" };
  let formattedDate = date
    .toLocaleDateString("en-GB", dateOptions)
    .replace(/ /g, " "); // "07 Nov 24"

  // If `showTime` is true, format the time as "HH:mm"
  if (showTime) {
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Use 24-hour time format
    };
    const formattedTime = date.toLocaleTimeString("en-GB", timeOptions); // "23:35"
    formattedDate = `${formattedDate} ${formattedTime}`;
  }

  return formattedDate;
};

const ProfileDetails = ({ setProfileEdit, userData }) => {
  const languages = userData?.languages ? JSON.parse(userData.languages) : [];
  const skills = JSON.parse(userData.skills) || [];

  return (
    <div>
      <div className="flex flex-col gap-4 font-inter text-xs  ">
        <div className=" p-4 border rounded-lg border-[#E2DCEA] relative">
          <div
            onClick={() => setProfileEdit(true)}
            className="cursor-pointer absolute hover:text-black text-slate-600 top-[-8px] right-[-7px] bg-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-5   p-4 ">
            {/* Profile Photo Section */}
            <div className="">
              <div className="w-32 h-32 border-[#373737]  border rounded-full overflow-hidden">
                <img
                  className="w-full h-full object-cover"
                  src={userData?.profile_pic || ""}
                  alt="Profile"
                />
              </div>

              <ATMFieldLabel> Your Photo</ATMFieldLabel>
              <br />
              <ATMFieldLabel>
                {" "}
                This will be displayed on your profile
              </ATMFieldLabel>
            </div>
            {/* Introduction Video */}
            <div>
              <ATMFieldLabel> Upload Your Introduction Video</ATMFieldLabel>
              <p className="text-sm font-normal text-[#667085]">
                This will be displayed on your profile.
              </p>
              <div className="mt-[9px] w-[316px] h-[221.77px] rounded-md overflow-hidden">
                <video controls src={userData?.introduction_video}></video>
              </div>
            </div>
            {/* title*/}
            <div className="">
              <ATMFieldLabel>Title</ATMFieldLabel>
              <h1 className="text-sm text-[#667085]">
                {userData?.title || ""}
              </h1>
            </div>
            {/* Date of Birth */}
            <div className="">
              <ATMFieldLabel> Date Of Birth</ATMFieldLabel>
              <h1 className="text-sm text-[#667085]">
                {formatDate(userData?.date_of_birth) || ""}
              </h1>
            </div>

            {/* Languages */}
            <div className=" ">
              <ATMFieldLabel>Languages</ATMFieldLabel>
              <div className="flex items-center gap-4 flex-wrap mt-1 ">
                {languages?.map((el, ind) => {
                  return (
                    <div
                      key={ind}
                      className="bg-blue-400 px-4 py-1 rounded-md text-white capitalize"
                    >
                      {el}
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Skills */}
            <div>
              <ATMFieldLabel>Your Skills</ATMFieldLabel>
              <div className="flex items-center flex-wrap gap-4 break-words mt-1 ">
                {skills?.map((el, ind) => {
                  return (
                    <div
                      key={ind}
                      className="bg-blue-400 px-4 py-1 rounded-md text-white"
                    >
                      {el}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="md:col-span-2">
              <ATMFieldLabel>Profile Summary</ATMFieldLabel>
              <h1 className="text-sm text-[#667085]">
                {userData?.profile_summary || ""}
              </h1>
            </div>
          </div>
        </div>

        {/* Additional Details */}
        <div className=" grid grid-cols-1 gap-5 p-7 md:grid-cols-3 border rounded-lg border-[#E2DCEA]">
          <div>
            <ATMFieldLabel> Current Location (Country)</ATMFieldLabel>
            <p className=" text-[#667085]">{userData?.country || ""}</p>
          </div>
          <div>
            <ATMFieldLabel> Current Location (State)</ATMFieldLabel>
            <p className=" text-[#667085]">{userData?.state || ""}</p>
          </div>
          <div>
            <ATMFieldLabel> Current Location (City)</ATMFieldLabel>
            <p className=" text-[#667085]">{userData?.city || ""}</p>
          </div>
          <div>
            <ATMFieldLabel> Zip Code</ATMFieldLabel>
            <p className=" text-[#667085]">{userData?.zipcode || ""}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
