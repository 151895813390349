import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recruiterJob: null,
};

export const recruiterJobSlice = createSlice({
  name: "recruiterJob",
  initialState,
  reducers: {
    setRecruiterJob(state, action) {
      state.recruiterJob = action.payload;
    },

   
  },
});

export const { setRecruiterJob } = recruiterJobSlice.actions;
export default recruiterJobSlice.reducer;
