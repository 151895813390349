export const visasData = {
  "United States": {
    Visas: [
      "H-1B (Specialty Occupations)",
      "H-4 (Dependents of H-1B Holders)",
      "L-1 (Intra-company Transfers)",
      "O-1 (Extraordinary Ability)",
      "E-3 (Australian Specialty Occupation)",
      "TN (NAFTA Professionals)",
      "H-2A (Temporary Agricultural Workers)",
      "H-2B (Temporary Non-agricultural Workers)",
      "J-1 (Exchange Visitor Program)",
      "R-1 (Religious Workers)",
      "P Visas (Athletes, Artists, and Entertainers)",
      "I Visa (Media and Journalists)",
      "E-1 (Treaty Traders)",
      "E-2 (Treaty Investors)",
      "B-1 (Business Visitor)",
      "F-1 OPT (Optional Practical Training for Students)",
      "H-3 (Trainee or Special Education visitor)",
      "Q-1 (Cultural Exchange)",
      "C-1/D (Crewmember Visa)",
      "K-1 (Fiancé(e) Visa)",
      "G visas (International Organizations)",
      "A visas (Diplomatic Visas)",
      "U Visa (Victims of Criminal Activity)",
      "T Visa (Victims of Human Trafficking)",
      "V Visa (Family Reunification)",
      "EB-1 Visa (Priority Workers)",
      "EB-2 Visa (Professionals with Advanced Degrees or Exceptional Ability)",
      "EB-3 Visa (Skilled Workers, Professionals, and Other Workers)",
      "EB-4 Visa (Special Immigrants)",
      "EB-5 Visa (Immigrant Investor Program)",
    ],
  },
  Canada: {
    Visas: [
      "Express Entry",
      "Provincial Nominee Program",
      "Open Work Permit",
      "Temporary Foreign Worker Program",
      "International Experience Canada",
    ],
  },
  "United Kingdom": {
    Visas: [
      "Tier 2 (General)",
      "Tier 1 (Exceptional Talent)",
      "Tier 5 (Temporary Worker)",
      "Health and Care Visa",
      "Innovator Visa",
    ],
  },
  Australia: {
    Visas: [
      "Temporary Skill Shortage Visa (Subclass 482)",
      "Skilled Independent Visa (Subclass 189)",
      "Working Holiday Visa (Subclass 417)",
      "Employer Nomination Scheme (Subclass 186)",
      "Regional Sponsored Migration Scheme (Subclass 187)",
    ],
  },
  Germany: {
    Visas: [
      "EU Blue Card",
      "Job Seeker Visa",
      "ICT Card (Intra-company Transfer)",
      "Freelancer Visa",
      "Researcher Visa",
    ],
  },
  Japan: {
    Visas: [
      "Highly Skilled Professional Visa",
      "Specified Skilled Worker Visa",
      "Intra-company Transferee",
      "Business Manager Visa",
      "Engineer/Specialist in Humanities/International Services Visa",
    ],
  },
  India: {
    Visas: [
      "Employment Visa",
      "Business Visa",
      "Project Visa",
      "Journalist Visa",
      "Research Visa",
    ],
  },
  China: {
    Visas: [
      "Z Visa (Work Visa)",
      "R Visa (Talent Visa)",
      "M Visa (Commercial Visa)",
      "F Visa (Exchange Visitor)",
      "Q Visa (Family Reunion)",
    ],
  },
  Brazil: {
    Visas: [
      "Temporary Visa V (Work)",
      "Permanent Visa (National Interest)",
      "Temporary Visa I (Researcher or Professor)",
      "Artist and Sportsman Visa",
      "Cultural Visa",
    ],
  },
  "South Africa": {
    Visas: [
      "General Work Visa",
      "Critical Skills Work Visa",
      "Intra-company Transfer Work Visa",
      "Corporate Visa",
      "Exchange Visa",
    ],
  },
  France: {
    Visas: [
      "Talent Passport",
      "Employee Visa",
      "Temporary Worker Visa",
      "Seasonal Worker Visa",
      "Intern Visa",
    ],
  },
  "New Zealand": {
    Visas: [
      "Essential Skills Work Visa",
      "Work to Residence Visa",
      "Silver Fern Job Search Visa",
      "Skilled Migrant Category Resident Visa",
      "Post-study Work Visa",
    ],
  },
  Mexico: {
    Visas: [
      "Temporary Resident Visa",
      "Permanent Resident Visa",
      "Work Visa for Offer of Employment",
      "Student Visa",
      "Humanitarian Visa",
    ],
  },
  "United Arab Emirates": {
    Visas: [
      "Employment Visa",
      "Long-term Visa (5 to 10 years)",
      "Mission Visa (Temporary Work)",
      "Investor Visa",
      "Student Visa",
    ],
  },
  Singapore: {
    Visas: [
      "Employment Pass",
      "S Pass",
      "Personalized Employment Pass",
      "EntrePass (Entrepreneur Pass)",
      "Tech.Pass",
    ],
  },
  "South Korea": {
    Visas: [
      "E-1 Visa (Professor)",
      "E-7 Visa (Special Occupation)",
      "D-8 Visa (Corporate Investor)",
      "E-3 Research Visa",
      "H-2 Visa (Working Holiday)",
    ],
  },
  Russia: {
    Visas: [
      "Highly Qualified Specialist Visa",
      "Work Visa",
      "Humanitarian Visa",
      "Private Visa",
      "Student Visa",
    ],
  },
  Italy: {
    Visas: [
      "Self-Employed Visa",
      "Subordinate Work Visa",
      "Seasonal Work Visa",
      "EU Blue Card",
      "Internship Visa",
    ],
  },
  Spain: {
    Visas: [
      "Highly Skilled Professional Visa",
      "Entrepreneur Visa",
      "Non-lucrative Visa",
      "Student Visa",
      "Work Visa",
    ],
  },
  Netherlands: {
    Visas: [
      "Highly Skilled Migrant Visa",
      "Startup Visa",
      "Seasonal Worker Visa",
      "Intra-company Transfer Visa",
      "Self-employed Visa",
    ],
  },
  Belgium: {
    Visas: [
      "Blue Card",
      "Professional Card",
      "Single Permit",
      "Seasonal Worker Visa",
      "Researcher Visa",
    ],
  },
  Sweden: {
    Visas: [
      "Work Permit",
      "Self-employed Visa",
      "EU Blue Card",
      "Intra-company Transfer Visa",
      "Artist Visa",
    ],
  },
  Switzerland: {
    Visas: [
      "Short-term Work Visa",
      "Long-term Work Visa",
      "EU/EFTA Work Visa",
      "Self-employed Visa",
      "Cross-border Commuter Permit",
    ],
  },
  Turkey: {
    Visas: [
      "Work Visa",
      "Ankara Agreement Visa",
      "Student Visa",
      "Touristic Visa",
      "Humanitarian Visa",
    ],
  },
  Thailand: {
    Visas: [
      "Non-immigrant Visa Type B (Business and Work)",
      "Smart Visa",
      "Education Visa",
      "Retirement Visa",
      "Elite Visa",
    ],
  },
  Vietnam: {
    Visas: [
      "Business Visa",
      "Work Visa",
      "Student Visa",
      "Tourist Visa",
      "Diplomatic Visa",
    ],
  },
  Chile: {
    Visas: [
      "Temporary Residence Visa",
      "Subject to Contract Visa",
      "Mercosur Visa",
      "Tech Visa",
      "Professional Visa",
    ],
  },
  Colombia: {
    Visas: [
      "Migrant Worker Visa",
      "Business Visa",
      "Mercosur Visa",
      "TP-4 Employee Visa",
      "Visa for Entrepreneurs",
    ],
  },
  "Costa Rica": {
    Visas: [
      "Temporary Residence Permit",
      "Work Visa",
      "Investor Visa",
      "Specific Category Worker Visa",
      "Freelancer Visa",
    ],
  },
  Peru: {
    Visas: [
      "Worker Visa",
      "Business Visa",
      "Investor Visa",
      "Temporary Worker Visa",
      "Artist Visa",
    ],
  },
  Bulgaria: {
    Visas: [
      "D Visa for Long Term",
      "EU Blue Card",
      "Self-employed Visa",
      "Freelancer Visa",
      "Seasonal Worker Visa",
    ],
  },
  "Czech Republic": {
    Visas: [
      "Employee Card",
      "Blue Card",
      "Long-term Business Visa",
      "Freelancer Visa",
      "Intra-company Employee Transfer Card",
    ],
  },
  Denmark: {
    Visas: [
      "Pay Limit Scheme Visa",
      "Positive List Scheme Visa",
      "Researcher Visa",
      "Green Card Scheme",
      "Corporate Scheme Visa",
    ],
  },
  Ireland: {
    Visas: [
      "General Employment Permit",
      "Critical Skills Employment Permit",
      "Intra-company Transfer Permit",
      "Researcher Visa",
      "Startup Visa",
    ],
  },
  Israel: {
    Visas: [
      "B-1 Work Visa",
      "Expert Worker Visa",
      "Hi-Tech Visa (HIT Visa)",
      "Internship Visa",
      "Volunteer Visa",
    ],
  },
  Poland: {
    Visas: [
      "Work Permit Type A",
      "Blue Card",
      "Seasonal Work Permit",
      "Intra-company Transfer Visa",
      "Self-employed Visa",
    ],
  },
  Portugal: {
    Visas: [
      "Residence Visa for Employees",
      "Highly Qualified Activity Visa",
      "Entrepreneurial Visa",
      "Tech Visa",
      "Seasonal Work Visa",
    ],
  },
  Romania: {
    Visas: [
      "Long Stay for Employment",
      "EU Blue Card",
      "ICT Permit",
      "Seasonal Worker Visa",
      "Trainee Visa",
    ],
  },
  Ukraine: {
    Visas: [
      "Temporary Residence Permit",
      "Work Visa",
      "IT Specialist Visa",
      "Cultural Exchange Visa",
      "Freelancer Visa",
    ],
  },
  "Hong Kong": {
    Visas: [
      "General Employment Policy Visa",
      "Admission Scheme for Mainland Talents and Professionals",
      "Technology Talent Admission Scheme",
      "Entrepreneur Visa",
      "Training Visa",
    ],
  },
  Malaysia: {
    Visas: [
      "Employment Pass",
      "Professional Visit Pass",
      "Resident Pass-Talent (RP-T)",
      "Tech Entrepreneur Visa",
      "Malaysia My Second Home Program",
    ],
  },
  Philippines: {
    Visas: [
      "Special Work Permit",
      "Alien Employment Permit",
      "9G Commercial Visa",
      "Special Visa for Employment Generation",
      "Special Investor's Resident Visa",
    ],
  },
  Taiwan: {
    Visas: [
      "Employment Gold Card",
      "Work Permit for Foreign Professionals",
      "Entrepreneur Visa",
      "Alien Resident Certificate",
      "Blue Card",
    ],
  },
};
