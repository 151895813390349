import React from "react";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";

const VerifiedExpert = () => {
  return (
    <div className=" my-2 flex-grow h-full">
      <div className="border-b pb-1 px-2">
        <ATMHeading>Verified Expert</ATMHeading>
      </div>
      <div className=" h-full flex items-center justify-center  ">
        <div className="flex justify-center items-center  font-bold text-4xl text-[#1cadbd4b]  ">
          Coming Soon...
        </div>
      </div>
    </div>
  );
};

export default VerifiedExpert;
