import React, { useEffect, useState } from "react";
import Select from "react-select";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import { useReportDataQuery } from "../services/ReportService";

const ReportsView = ({ view }) => {
  const [jdSelectValue, setJdSelectValue] = useState(null);
  const [recSelectValue, setRecSelectValue] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [onApply, setOnApply] = useState(false);

  let { data, isLoading, isFetching } = useReportDataQuery({
    body: {
      job_id: !onApply ? 0 : view === "Uploaded" ? "" : jdSelectValue?.value,
      recruiter_email: !onApply ? 0 : recSelectValue?.value,
      startDate: !onApply ? "" : startDate,
      endDate: !onApply ? "" : endDate,
      is_invited: view === "Uploaded" ? false : true,
    },
  });

  const jdOptions =
    data?.all_org_jobs_data?.map((el) => ({
      value: el?.id,
      label: el?.title,
    })) || [];
  const recOptions =
    data?.all_org_recruiters_data?.map((el) => ({
      value: el?.id,
      label: el?.email,
    })) || [];

  let steps = [];
  if (view === "Uploaded") {
    steps = [
      {
        step: 1,
        label: "TOTAL ORG RESUME",
        count: data?.total_org_resumes || "0",
        active: true,
        className: "border-green-500 text-green-500 ",
      },
      {
        step: 2,
        label: "TOTAL RESUMES Uploaded Today",
        count: data?.total_uploaded_today || "0",
        active: false,
        className: "border-orange-700 text-orange-700 ",
      },
      {
        step: 3,
        label: "TOTAL RESUMES Updated Today ",
        count: data?.total_updated_today || "0",
        active: false,
        className: "border-amber-600 text-amber-600  ",
      },
    ];
  } else {
    steps = [
      {
        step: 1,
        label: "TOTAL Invited",
        count: data?.total_invited_resumes || "0",
        active: true,
        className: "border-green-500 text-green-500 ",
      },
      {
        step: 2,
        label: "total applied",
        count: data?.total_applied_count || "0",
        active: false,
        className: "border-orange-700 text-orange-700 ",
      },
    ];
  }
  useEffect(() => {
    setEndDate("");
    setStartDate("");
    setOnApply(false);
  }, [view]);
  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <ATMCircularProgress />
        </div>
      ) : (
        <div className="font-inter  text-xs select-none">
          <div className="mt-4 px-2 py-1 rounded-sm text-md font-semibold bg-blue-50 ">
            Statistics : -
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center mt-2  pb-10 border-b ">
            {steps?.map((el) => {
              return (
                <div
                  className={`border py-3 w-full rounded-md  ${el.className} text-sm `}
                  key={el.step}
                >
                  <div className="text-center font-bold text-lg ">
                    {el?.count}
                  </div>
                  <div className=" text-center font-semibold uppercase ">
                    {el?.label}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-10 px-2 py-1 rounded-sm text-md font-semibold bg-blue-50 ">
            Generate Report : -
          </div>
          <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3  gap-4 mt-4 items-end">
            {view === "Invited" && (
              <div>
                <ATMFieldLabel>Select Job</ATMFieldLabel>
                <div className="flex items-center border  pl-2 rounded-md min-w-[200px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <Select
                    value={jdSelectValue}
                    onChange={(newValue) => {
                      setJdSelectValue(newValue);
                      setEndDate("");
                      setStartDate("");
                    }}
                    options={jdOptions}
                    placeholder="Select Job"
                    className="w-full h-full "
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        outline: "none",
                        boxShadow: "none",
                        border: "none",
                        "&:hover": {
                          border: "none",
                        },
                        "&:focus": {
                          border: "none",
                        },
                      }),
                    }}
                  />
                </div>
              </div>
            )}
            <div>
              <ATMFieldLabel>Select recruiter</ATMFieldLabel>
              <div className="flex items-center border pl-2 rounded-md min-w-[200px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                    clipRule="evenodd"
                  />
                </svg>

                <Select
                  value={recSelectValue}
                  onChange={(newValue) => {
                    setEndDate("");
                    setStartDate("");
                    setRecSelectValue(newValue);
                  }}
                  options={recOptions}
                  placeholder="Select recruiter"
                  className="w-full h-full "
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      outline: "none",
                      boxShadow: "none",
                      border: "none",
                      "&:hover": {
                        border: "none",
                      },
                      "&:focus": {
                        border: "none",
                      },
                    }),
                  }}
                />
              </div>
            </div>
            <div>
              <ATMFieldLabel>Select Date Range</ATMFieldLabel>
              <div className="grid grid-cols-9 items-center gap-2  justify-between">
                <div className=" col-span-4">
                  <input
                    type="Date"
                    value={startDate || ""}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setOnApply(false);
                    }}
                    className="w-full h-[40px] px-2 border  rounded-md outline-none "
                  />
                </div>
                <span className="text-sm font-semibold text-center">To</span>
                <div className=" col-span-4">
                  <input
                    type="Date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      setOnApply(false);
                    }}
                    min={startDate}
                    className="w-full  h-[40px] px-2 border  outline-none  rounded-md"
                  />
                </div>
              </div>
            </div>
            {onApply ? (
              <div
                className=" h-[40px] flex items-center cursor-pointer w-[100px]  justify-center bg-[#1CACBD] rounded-md text-white font-semibold "
                onClick={() => {
                  setOnApply(false);
                  setEndDate("");
                  setStartDate("");
                }}
              >
                Clear Result
              </div>
            ) : (
              <div
                className={`h-[40px] flex items-center justify-center  w-[100px]  rounded-md text-white font-semibold ${
                  startDate === "" || endDate === ""
                    ? "cursor-not-allowed  bg-[#addadf]"
                    : "cursor-pointer bg-[#1CACBD]"
                }`}
                onClick={() => {
                  setOnApply(true);
                }}
              >
                Apply
              </div>
            )}
          </div>
          {view === "Uploaded" ? (
            <div className="flex justify-center gap-4 flex-wrap mt-10">
              <div className="border h-[50px] min-w-[350px] text-center px-3 rounded-md border-orange-200 flex items-center justify-center  ">
                <span className=" font-medium text-xl mr-1 mt-[-7px] ">
                  {isFetching ? (
                    <ATMCircularProgress size="small" />
                  ) : (
                    data?.range_resumes_uploaded_count || "0"
                  )}
                </span>{" "}
                Resumes Uploaded
              </div>
              <div className="border h-[50px] min-w-[350px] text-center px-3 rounded-md border-orange-200 flex items-center justify-center  ">
                <span className=" font-medium text-xl mr-1 mt-[-7px]">
                  {isFetching ? (
                    <ATMCircularProgress size="small" />
                  ) : (
                    data?.range_resumes_updated_count || "0"
                  )}
                </span>{" "}
                Resumes Updated
              </div>
            </div>
          ) : (
            <div className="flex justify-center gap-4 flex-wrap mt-10">
              <div className="border h-[50px] min-w-[350px] text-center px-3 rounded-md border-orange-200 flex items-center justify-center  ">
                <span className=" font-medium text-xl mr-1 mt-[-7px]">
                  {isFetching ? (
                    <ATMCircularProgress size="small" />
                  ) : (
                    data?.range_resumes_invited_count || "0"
                  )}
                </span>{" "}
                Candidate Invited
              </div>
              <div className="border h-[50px] min-w-[350px] text-center px-3 rounded-md border-orange-200 flex items-center justify-center  ">
                <span className=" font-medium text-xl mr-1 mt-[-7px] ">
                  {isFetching ? (
                    <ATMCircularProgress size="small" />
                  ) : (
                    data?.range_resumes_applied_count || "0"
                  )}
                </span>{" "}
                Candidate Applied
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ReportsView;
