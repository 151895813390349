import axios from 'axios';
import Cookies from 'js-cookie';

export const axiosRequest = async (url, options = {}) => {
  // const token =   localStorage.getItem("accessToken")  ; // Retrieve token from localStorage
  const token =   Cookies.get("accessToken")
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`, // Include token in the Authorization header
    ...options.headers,
  };

  try {
    const response = await axios({
      url,
      ...options,
      headers,
    });

    if (response.status === 401) {
      // Handle token expiration
      handleTokenExpiry();
    }

    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Axios error:", error);
    }
    throw error;
  }
};

const handleTokenExpiry = () => {
  // Remove the token from localStorage
  localStorage.removeItem("accessToken");
  // Redirect the user to the login page or perform any other logout logic
  window.location.href = "/login";
};

export const axiosCandidateRequest = async (url, options = {}) => {
  const token = localStorage.getItem("candidateaccessToken") ; // Retrieve token from localStorage
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`, // Include token in the Authorization header
    ...options.headers,
  };

  try {
    const response = await axios({
      url,
      ...options,
      headers,
    });

    if (response.status === 401) {
      // Handle token expiration
      handleCandidateTokenExpiry();
    }

    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Axios error:", error);
    }
    throw error;
  }
};


const handleCandidateTokenExpiry = () => {
  // Remove the token from localStorage
  localStorage.removeItem("candidateaccessToken");
  // Redirect the user to the login page or perform any other logout logic
  window.location.href = "/login";
};


export const axiosSimple = async (url, options = {}) => {
  const headers = {
    "Content-Type": "application/json",
    ...options.headers,
  };
 
  try {
    const response = await axios({
      url,
      ...options,
      headers,
    });
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log("Request canceled:", error.message);
    } else {
      console.error("Axios error:", error);
    }
    throw error;
  }
};