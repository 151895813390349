import React from "react";

const ATMHeading = ({ children, htmlFor, className }) => {
  return (
    <label
      className={`text-lg font-semibold text-slate-900 font-inter ${className}`}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
};

export default ATMHeading;
