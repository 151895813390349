import React, { useState } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import axios from "axios";
import "react-international-phone/style.css";
import { Link, useNavigate } from "react-router-dom";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import Dailog from "../../components/common/Dailog";
import HeroHeading from "../../components/recruiters/HeroHeading";
import { signUpSchema } from "../../schemas/schema";
import { PhoneInput } from "react-international-phone";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const RegisterPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconPassword, setShowconPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowconPassword = () => {
    setShowconPassword(!showconPassword);
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signUpSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await axios.post(
          `${baseUrl}/api/candidate/registration/`,
          {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            contact_number: `${values.contactNumber}`,
            password: values.password,
            password2: values.confirmPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 201) {
          resetForm();
          setOpen(!open);
        } else if (response.status === 400) {
          console.log(response);
          toast.error("Failed to add member");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Dailog
        open={open}
        setOpen={setOpen}
        heading="We've sent an activation link to your email address for added security. Once you click the link, you'll be ready to log in and explore all the features available."
        para="Click the link to activate your account and start using our platform right away. It's that simple!"
        btnText="Login"
        btnLink="/candidate/login"
      />
      <div className=" mx-auto w-full  md:w-[800px] md:rounded-lg md:mt-5 font-inter text-xs bg-white p-4 ">
        {/* right side part */}
        <div className=" flex flex-col gap-4 ">
          <HeroHeading
            heading="Register as Candidate"
            para={`   Before applying for a job, creating an account is essential. Your
              account allows us to gather the necessary details to match you
              with the right opportunities. Providing your information ensures a
              seamless application process and enables us to tailor job
              recommendations to your skills and preferences.`}
          />

          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-5">
            <div className="border border-[#E2DCEA] rounded-lg flex flex-col gap-5 *: ">
              <div className="p-4 ">
                <div className="flex flex-col gap-5">
                  <div className="grid md:grid-cols-2 items-center gap-8 grid-cols-1">
                    <div className=" flex flex-col">
                      <ATMFieldLabel> First Name</ATMFieldLabel>
                      <input
                        size="lg"
                        placeholder="Brian"
                        className=" border outline-none p-3 rounded-lg"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="firstName"
                        name="firstName"
                      />
                      {formik.errors.firstName && formik.touched.firstName ? (
                        <div>
                          <ATMFieldError>
                            {" "}
                            {formik.errors.firstName}
                          </ATMFieldError>
                        </div>
                      ) : null}
                    </div>
                    <div className=" flex flex-col ">
                      <ATMFieldLabel> Last Name</ATMFieldLabel>
                      <input
                        size="lg"
                        placeholder="Clark"
                        className=" border outline-none p-3 rounded-lg"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="lastName"
                        name="lastName"
                      />
                      {formik.errors.lastName && formik.touched.lastName ? (
                        <div>
                          <ATMFieldError>
                            {" "}
                            {formik.errors.lastName}
                          </ATMFieldError>
                        </div>
                      ) : null}
                    </div>
                    <div className=" flex flex-col ">
                      <ATMFieldLabel> Email ID</ATMFieldLabel>
                      <div className=" relative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="text-gray-400 pointer-events-none  absolute top-2 left-2"
                          style={{ height: 24, width: 24 }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                          />
                        </svg>
                        <input
                          size="lg"
                          type="email"
                          placeholder="example@example.com"
                          className="pl-10 w-full border outline-none p-3 rounded-lg"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="email"
                          name="email"
                        />
                      </div>
                      {formik.errors.email && formik.touched.email ? (
                        <div>
                          <ATMFieldError> {formik.errors.email}</ATMFieldError>
                        </div>
                      ) : null}
                    </div>
                    <div className=" flex flex-col ">
                      <ATMFieldLabel> Contact Number</ATMFieldLabel>
                      <div className=" flex w-full">
                        <PhoneInput
                          defaultCountry="us"
                          value={formik.values.contactNumber}
                          onChange={(value) =>
                            formik.setFieldValue("contactNumber", value)
                          }
                          onBlur={formik.handleBlur}
                          id="contactNumber"
                          name="contactNumber"
                          className="border pl-2 w-full rounded-lg"
                          style={{
                            "--react-international-phone-height": "38px",
                            "--react-international-phone-border-color":
                              "transparent",
                            "--react-international-phone-border-radius": "8px",
                          }}
                        />
                      </div>
                      {formik.errors.contactNumber &&
                      formik.touched.contactNumber ? (
                        <div>
                          <ATMFieldError>
                            {" "}
                            {formik.errors.contactNumber}
                          </ATMFieldError>
                        </div>
                      ) : null}
                    </div>
                    <div className=" flex flex-col ">
                      <ATMFieldLabel> Set Your Password</ATMFieldLabel>

                      <div className="border rounded-lg flex justify-between items-center ">
                        <input
                          size="lg"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          className=" border-0 outline-none w-full py-3 px-2"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="password"
                          name="password"
                        />
                        <div
                          onClick={handleShowPassword}
                          className=" cursor-pointer pr-2"
                        >
                          {showPassword ? (
                            <IoMdEye size={20} className="text-slate-800" />
                          ) : (
                            <IoMdEyeOff size={20} className="text-slate-800" />
                          )}
                        </div>
                      </div>
                      {formik.errors.password && formik.touched.password ? (
                        <div>
                          <ATMFieldError>
                            {" "}
                            {formik.errors.password}
                          </ATMFieldError>
                        </div>
                      ) : null}
                    </div>
                    <div className=" flex flex-col ">
                      <ATMFieldLabel> Re-Enter Your Password</ATMFieldLabel>

                      <div className="border rounded-lg flex justify-between items-center  px-2">
                        <input
                          size="lg"
                          type={showconPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          className=" border-0 outline-none w-full  py-3 "
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="confirmPassword"
                          name="confirmPassword"
                        />
                        <div
                          onClick={handleShowconPassword}
                          className=" cursor-pointer pr-2"
                        >
                          {showconPassword ? (
                            <IoMdEye size={20} className="text-slate-800" />
                          ) : (
                            <IoMdEyeOff size={20} className="text-slate-800" />
                          )}
                        </div>
                      </div>
                      {formik.errors.confirmPassword &&
                      formik.touched.confirmPassword ? (
                        <div>
                          <ATMFieldError>
                            {" "}
                            {formik.errors.confirmPassword}
                          </ATMFieldError>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-[140px] flex gap-2 text-white bg-[#1CACBD] py-2 mx-4 rounded-lg font-semibold justify-center items-center"
                >
                  {formik.isSubmitting ? (
                    <ATMCircularProgress size="small" color="white" />
                  ) : (
                    <div className=" flex items-center gap-1">
                      Register Now
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </span>
                    </div>
                  )}
                </button>
                <p className="px-4 py-2 text-[#667085]">
                  If you have already created account then you can
                  <Link to="/candidate/login" className="text-[#444CE7]">
                    {" "}
                    Click Here To Login
                  </Link>
                </p>
              </div>
            </div>
          </form>

          <div className="font-inter text-xs">
            <ATMFieldLabel>Want to reach out directly?</ATMFieldLabel>
            <div className="text-slate-600">
              We're here to help!{" "}
              <span
                onClick={() => navigate("/candidate/support")}
                className="text-blue-500 cursor-pointer"
              >
                Contact our support
              </span>{" "}
              team with any questions.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
