import React from "react";
import { Outlet } from "react-router";
import { Toaster } from "react-hot-toast";
import Navbar from "../../components/recruiters/Navbar";

function Auth() {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          duration: 10000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 10000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div className="min-h-full h-screen">
        <div className="w-full flex flex-col h-full ">
          <Navbar />
          <div className="mx-auto w-full h-full  max-w-screen-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
