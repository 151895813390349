import React from "react";
import UploadBulkResumeForm from "./UploadBulkResumeForm";

const UploadBulkResumeFormWrapper = () => {
  const org = localStorage.getItem("recuriterProfile");
  const org_id = org ? JSON.parse(org) : "";
  return (
    <div className="flex  w-full h-full font-semibold font-inter text-xl">
      <UploadBulkResumeForm org_id={org_id} />
    </div>
  );
};

export default UploadBulkResumeFormWrapper;
