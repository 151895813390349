import React from "react";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import { formatDate } from "../../ProfileDetails/View/ProfileDetails";

const EducationDetails = ({
  setEducationDetailsEdit,
  userData,
  setEducationDetailsAdd,
  setEducationDetailsEditId,
}) => {
  return (
    <div className=" flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        {userData?.education_candidate.map((eduCan, index) => (
          <div className=" p-9 grid grid-cols-1 md:grid-cols-2 gap-5  border-[1px] rounded-lg border-[#E2DCEA] relative">
            <div
              onClick={() => {
                setEducationDetailsEdit(true);
                setEducationDetailsEditId(index);
              }}
              className="cursor-pointer absolute hover:text-black text-slate-600 top-[-8px] right-[-7px] bg-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </div>

            {/* Education */}
            <div>
              <ATMFieldLabel>Education</ATMFieldLabel>

              <p className="text-[#667085] font-inter font-normal text-sm">
                {eduCan.education}
              </p>
            </div>

            {/* Specialization*/}
            <div className=" mt-[11px]">
              <ATMFieldLabel>Specialization</ATMFieldLabel>

              <p className="text-[#667085] break-words font-inter font-normal text-sm">
                {eduCan.specialization}
              </p>
            </div>

            {/*Institution Name  */}
            <div className="">
              <ATMFieldLabel> Institution Name</ATMFieldLabel>
              <p className="font-inter break-words font-normal text-sm text-[#667085] capitalize">
                {eduCan.institution_name}
              </p>
            </div>

            {/* Skills */}
            <div>
              <ATMFieldLabel>Your Skills</ATMFieldLabel>
              <div className="flex items-center gap-4 flex-wrap ">
                {JSON.parse(eduCan?.skills)?.map((el) => {
                  return (
                    <div className="bg-blue-400 px-4 py-1 rounded-md text-white">
                      {el}
                    </div>
                  );
                })}
              </div>
            </div>

            {/* Duration */}
            <div className="">
              <ATMFieldLabel> Duration</ATMFieldLabel>
              <div className="text-[#667085] font-inter font-normal text-sm">
                {formatDate(eduCan.duration_start)} to{" "}
                {formatDate(eduCan.duration_end)}
              </div>
            </div>

            {/* Experience Video */}
            <div className="">
              <ATMFieldLabel>Upload Your Experience Video</ATMFieldLabel>
              <div className=" mt-[9px] w-[316px] h-[221.77px] rounded-md overflow-hidden">
                <video
                  controls
                  className=" object-cover"
                  src={eduCan.upload_education_video}
                  alt=""
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <button
          type="button"
          className="w-[200px] mb-3 h-[48px] rounded-md bg-[#1CACBD] text-white"
          onClick={() => setEducationDetailsAdd(true)}
        >
          Add Education
        </button>
      </div>
    </div>
  );
};

export default EducationDetails;
