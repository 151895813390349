import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";
import Navbar from "../../components/candidate/Navbars/Navbar";
import { formatDate } from "../candidate/ProfileDetails/View/ProfileDetails";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;
const ShareProfileView = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const code = searchParams.get("code");

  useEffect(() => {
    if (id === null || code === null) {
      navigate("/candidate/login");
      return;
    }

    const getAllJobs = async () => {
      setLoading(true);

      try {
        const url = `${baseUrl}/api/candidate/profile/${id}/?supersecret=${code}`;

        const response = await axios.get(url);

        if (response.status === 200) {
          setUserData(response?.data?.data?.["candidate"]);
        } else {
          toast.error("Failed to get data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    getAllJobs();
  }, [id, code, navigate]);
  return (
    <div className="h-full ">
      <Navbar loginbutton={false} />
      {loading ? (
        <div className="flex text-black h-full justify-center items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        <div className="  bg-white   font-inter text-xs text-slate-600">
          <div className="p-2">
            <ATMHeading>Profile</ATMHeading>
          </div>

          <div className=" p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4">
            {/* Left Section - Profile and Bio */}
            <div className=" bg-gray-800 text-white rounded-lg p-4">
              <div className="">
                <h2 className="text-2xl text-white font-bold capitalize">
                  HEY! I'm {userData?.first_name}, {userData?.title}
                </h2>
                <p className="text-sm ">{userData?.profile_summary}</p>
              </div>
            </div>

            <div className="border rounded-lg p-2 text-xs font-medium  ">
              <div className=" overflow-hidden   ">
                <img
                  className=" object-cover  w-full  h-[270px] rounded-lg"
                  src={userData?.profile_pic}
                  alt="profile"
                />
              </div>
              <div className="flex justify-between items-center flex-wrap gap-4">
                <div className="font-bold capitalize text-sm  ">
                  {userData?.first_name} {""}
                  {userData?.last_name}
                </div>
                <div className="text-xs font-medium border-l text-end pl-4 ">
                  {userData?.title}
                </div>
              </div>
              <div className=" ">@{userData?.email}</div>
              <div className="mt-2">{userData?.contact_number}</div>
            </div>
            <div className=""></div>

            <div className="border rounded-lg">
              <div className="bg-blue-100  rounded-t-lg px-4 py-2 font-semibold">
                Interest and Hobbies
              </div>
              <div className="flex flex-wrap gap-4 p-2">
                {userData?.candidatehobbies_candidate?.map((el) => {
                  return (
                    <div className="border rounded-xl px-4 py-1 text-center">
                      {el?.hobbie?.hobbie_category}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="border rounded-lg">
              <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
                About Interest
              </div>
              <div className=" p-2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
                doloribus quasi quo sed soluta. Qui sit cum ex, mollitia,
                praesentium, nihil laboriosam tempora laudantium pariatur quis
                nam atque quo eum.
              </div>
            </div>
            <div className="border rounded-lg">
              <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
                languages Profeciency
              </div>
              <div className="flex flex-wrap gap-4  p-2">
                {userData?.languages
                  ? JSON.parse(userData?.languages)?.map((el) => {
                      return (
                        <div className="border rounded-xl px-4 py-1 text-center">
                          {el}
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>

            <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
              Qualification
            </div>
            {userData?.education_candidate?.map((el) => {
              return (
                <div key={el?.id} className="border rounded-lg">
                  <div className="bg-gray-800 text-white py-5 rounded-t-lg px-4 font-semibold">
                    <span>{el?.institution_name}</span> <br />
                    <span>{el?.education}</span> <br />
                    <span>
                      ({formatDate(el?.duration_start)} To{" "}
                      {formatDate(el?.duration_end)})
                    </span>
                    <span></span>
                  </div>
                  <div className=" gap-4  p-2">
                    <span>-{el?.specialization}</span> <br />
                    <span>
                      {el?.skills
                        ? JSON.parse(el?.skills)?.map((el) => {
                            return <div className="">-{el}</div>;
                          })
                        : ""}
                    </span>
                  </div>
                </div>
              );
            })}

            <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
              Work Experience
            </div>
            {userData?.workexperience_candidate?.map((el) => {
              return (
                <div key={el?.id} className="border rounded-lg">
                  <div className="border-b  py-2 rounded-t-lg px-4">
                    <span className=" font-semibold">{el?.job_title}</span>{" "}
                    <br />
                    <span>{el?.company_name}</span> <br />
                    <span>{el?.experience}</span> <br />
                    <span>
                      ({formatDate(el?.company_start_duration)} To{" "}
                      {formatDate(el?.company_end_duration)})
                    </span>
                    <span></span>
                  </div>
                  <div className=" gap-4  p-2">
                    <span className="break-words">
                      {el?.work_experience_summary}
                    </span>{" "}
                    <br />
                    <span className="flex flex-wrap gap-2 mt-2">
                      {el?.skills
                        ? JSON.parse(el?.skills)?.map((el) => {
                            return (
                              <div className="border rounded-xl px-2 py-2 w-fit">
                                {el}
                              </div>
                            );
                          })
                        : ""}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="p-5">
            <span>
              Your comprehensive profile is a crucial part of our Application
              Tracking System, empowering our analysis for better matches. Your
              information is securely stored in our database, ensuring future
              opportunities align with your skills and preferences. Rest
              assured, we'll leverage this data to connect you with relevant
              positions.
            </span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareProfileView;
