import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../ProfileDetails/View/ProfileDetails";
import { useCandidateProfileViewQuery } from "../../../app/DashboardServices/DashboardServices";
import { useParams } from "react-router";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

// Data for Career Trajectory
const careerTrajectoryData = {
  labels: ["1985", "1990", "2000", "2005", "2010"],
  datasets: [
    {
      label: "Career Milestones",
      data: [1, 2, 3, 4, 5], // Example data for visualization
      backgroundColor: "rgba(59, 130, 246, 0.6)", // Tailwind 'blue-500'
      barThickness: 30,
    },
  ],
};

// Data for Skills Acquired
const skillsAcquiredData = {
  labels: ["Skill 1", "Skill 2", "Skill 3", "Skill 4"],
  datasets: [
    {
      label: "Percentage",
      data: [35, 25, 20, 20],
      backgroundColor: ["#3498db", "#2ecc71", "#e74c3c", "#9b59b6"],
    },
  ],
};

// Data for Skill Proficiency
const skillProficiencyData = {
  labels: ["Python", "CSS", "Java", "AI/ML"],
  datasets: [
    {
      label: "Proficiency Level",
      data: [5, 4, 3, 2],
      backgroundColor: "rgba(255, 99, 132, 0.6)",
    },
  ],
};
const MyProfileView = () => {
  const [userData, setUserData] = useState();
  const { id } = useParams();
  // Call the query hook here
  const { data, isLoading } = useCandidateProfileViewQuery(
    { pk: id },
    { skip: !id }
  );
  const userDataFromStore = useSelector(
    (state) => state.candidateData.candidateData
  );
  useEffect(() => {
    if (id && data) {
      setUserData(data?.data?.["candidate"]);
    } else {
      setUserData(userDataFromStore);
    }
  }, [id, data, userDataFromStore]);
  return (
    <div className="h-full">
      {isLoading ? (
        <div className="flex text-black h-full justify-center items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        <div className="  bg-white shadow-lg rounded-lg  font-inter text-xs text-slate-600">
          {/* <div className="px-2 py-1 border-b flex justify-between items-center">
            <ATMHeading>Profile</ATMHeading>
            {/* <div className="flex items-center gap-1 capitalize border rounded-md p-2 text-white bg-[#1CACBD]">
              share my profile
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
                />
              </svg>
            </div> */}
          {/* </div> */}
          <div className=" p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4">
            {/* Left Section - Profile and Bio */}
            <div className=" bg-gray-800 text-white rounded-lg p-4">
              <div className="">
                <h2 className="text-2xl text-white font-bold capitalize">
                  HEY! I'm {userData?.first_name}, {userData?.title}
                </h2>
                <p className="text-sm ">{userData?.profile_summary}</p>
              </div>
            </div>

            <div className="border rounded-lg p-2 text-xs font-medium  ">
              <div className=" overflow-hidden   ">
                <img
                  className=" object-cover  w-full  h-[200px] rounded-lg"
                  src={userData?.profile_pic}
                  alt="profile"
                />
              </div>
              <div className="flex justify-between items-center flex-wrap gap-4">
                <div className="font-bold capitalize text-sm  ">
                  {userData?.first_name} {""}
                  {userData?.last_name}
                </div>
                <div className="text-xs font-medium border-l text-end pl-4 ">
                  {userData?.title}
                </div>
              </div>
              <div className=" ">@{userData?.email}</div>
              <div className="mt-2">{userData?.contact_number}</div>
            </div>
            <div className=""></div>

            {/* Career Trajectory */}
            <div className="bg-white border rounded-lg p-4">
              <h3 className="text-lg font-semibold mb-4">Career Trajectory</h3>
              <Bar
                data={careerTrajectoryData}
                options={{ responsive: true }}
                height={200}
                width={400}
              />
            </div>

            {/* Skills Acquired */}
            <div className="bg-white border rounded-lg p-4 ">
              <h3 className="text-lg font-semibold mb-4">Skills Acquired</h3>
              {/* Pie Chart */}
              <div className="grid grid-cols-3 items-center gap-1 justify-between">
                <div className="col-span-2">
                  <Pie
                    data={skillsAcquiredData}
                    options={{
                      responsive: false,
                      plugins: {
                        legend: {
                          display: false, // Hides the legend
                        },
                      },
                    }}
                    height={200}
                    width={300}
                  />
                </div>
                <ul className="space-y-2 text-sm text-right">
                  {skillsAcquiredData.labels.map((label, index) => (
                    <li key={index}>
                      <span
                        className="font-bold"
                        style={{
                          color:
                            skillsAcquiredData.datasets[0].backgroundColor[
                              index
                            ],
                        }}
                      >
                        {label}:
                      </span>{" "}
                      {skillsAcquiredData.datasets[0].data[index]}%
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Skill Proficiency */}
            <div className="bg-white border rounded-lg p-4">
              <h3 className="text-lg font-semibold mb-4">Skill Proficiency</h3>
              <Bar
                data={skillProficiencyData}
                options={{ responsive: true }}
                height={200}
                width={400}
              />
            </div>

            <div className="border rounded-lg">
              <div className="bg-blue-100  rounded-t-lg px-4 py-2 font-semibold">
                Interest and Hobbies
              </div>
              <div className="flex flex-wrap gap-4 p-2">
                {userData?.candidatehobbies_candidate?.map((el) => {
                  return (
                    <div className="border rounded-xl px-4 py-1 text-center">
                      {el?.hobbie?.hobbie_category}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="border rounded-lg">
              <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
                About Interest
              </div>
              <div className=" p-2">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
                doloribus quasi quo sed soluta. Qui sit cum ex, mollitia,
                praesentium, nihil laboriosam tempora laudantium pariatur quis
                nam atque quo eum.
              </div>
            </div>
            <div className="border rounded-lg">
              <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
                languages Profeciency
              </div>
              <div className="flex flex-wrap gap-4  p-2">
                {userData?.languages
                  ? JSON.parse(userData?.languages)?.map((el) => {
                      return (
                        <div className="border rounded-xl px-4 py-1 text-center">
                          {el}
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>

            <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
              Qualification
            </div>
            {userData?.education_candidate?.map((el) => {
              return (
                <div key={el?.id} className="border rounded-lg">
                  <div className="bg-gray-800 text-white py-5 rounded-t-lg px-4 font-semibold">
                    <span>{el?.institution_name}</span> <br />
                    <span>{el?.education}</span> <br />
                    <span>
                      ({formatDate(el?.duration_start)} To{" "}
                      {formatDate(el?.duration_end)})
                    </span>
                    <span></span>
                  </div>
                  <div className=" gap-4  p-2">
                    <span>-{el?.specialization}</span> <br />
                    <span>
                      {el?.skills
                        ? JSON.parse(el?.skills)?.map((el) => {
                            return <div className="">-{el}</div>;
                          })
                        : ""}
                    </span>
                  </div>
                </div>
              );
            })}

            <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
              Work Experience
            </div>
            {userData?.workexperience_candidate?.map((el) => {
              return (
                <div key={el?.id} className="border rounded-lg">
                  <div className="border-b  py-2 rounded-t-lg px-4">
                    <span className=" font-semibold">{el?.job_title}</span>{" "}
                    <br />
                    <span>{el?.company_name}</span> <br />
                    <span>{el?.experience}</span> <br />
                    <span>
                      ({formatDate(el?.company_start_duration)} To{" "}
                      {formatDate(el?.company_end_duration)})
                    </span>
                    <span></span>
                  </div>
                  <div className=" gap-4  p-2">
                    <span className="break-words">
                      {el?.work_experience_summary}
                    </span>{" "}
                    <br />
                    <span className="flex flex-wrap gap-2 mt-2">
                      {el?.skills
                        ? JSON.parse(el?.skills)?.map((el) => {
                            return (
                              <div className="border rounded-xl px-2 py-2 w-fit">
                                {el}
                              </div>
                            );
                          })
                        : ""}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="p-5">
            <span>
              Your comprehensive profile is a crucial part of our Application
              Tracking System, empowering our analysis for better matches. Your
              information is securely stored in our database, ensuring future
              opportunities align with your skills and preferences. Rest
              assured, we'll leverage this data to connect you with relevant
              positions.
            </span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfileView;
