import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const FILE_MANAGER_URL = process.env.REACT_APP_VITE_VIDEO_API_URL;
export const fileExplorerSlice = createApi({
  reducerPath: "fileExplorerSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: `${FILE_MANAGER_URL}`,
  }),
  endpoints: () => ({}),
});

export const { useAddFileUrlMutation } = fileExplorerSlice;
