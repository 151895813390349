import React, { useState } from "react";
import ReportsView from "./ReportsView";

const ReportsViewWrapper = () => {
  const [view, setView] = useState("Uploaded");
  const user = localStorage.getItem("recuriterProfile");
  const isSuperAdmin = user ? JSON.parse(user) : "";
  return (
    <>
      {isSuperAdmin?.IS_SUPERUSER && (
        <div className="font-inter h-full px-2">
          <div className=" font-semibold text-lg">Reporting</div>
          <div className="flex gap-3 border-b  border-[#D0D5DD] text-xs mt-2">
            <ul className=" flex gap-6 font-medium text-[#667085] cursor-pointer">
              <li
                className={`pb-2 ${
                  view === "Uploaded" ? "border-b-2 border-[#101828]" : ""
                } text-[#101828]`}
                onClick={() => setView("Uploaded")}
              >
                Uploaded Resume Report
              </li>
              <li
                className={`pb-2 ${
                  view === "Invited" ? "border-b-2 border-[#101828]" : ""
                } text-[#101828]`}
                onClick={() => setView("Invited")}
              >
                Invited Candidate Report
              </li>
            </ul>
          </div>

          <ReportsView view={view} />
        </div>
      )}
    </>
  );
};

export default ReportsViewWrapper;
