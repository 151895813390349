import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { axiosCandidateRequest } from "../../api/api";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function Tabs() {
  const [isOpen, setIsOpen] = useState();
  const [isCopy, setIsCopy] = useState("Copy");
  const [profileUrl, setProfileUrl] = useState();

  const getAllJobs = async () => {
    try {
      let url = `${baseUrl}/api/candidate/profile_link/`;

      const response = await axiosCandidateRequest(url, {
        method: "GET",
      });

      if (response.status === 200) {
        setProfileUrl(response?.data?.link);
      } else if (response.status === 400) {
        toast.error("Failed to get data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
    }
  };

  const items = [
    {
      tabname: "Basic Details",
      href: "/candidate/profile/basic-details",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
          />
        </svg>
      ),
    },
    {
      tabname: "Personal Details",
      href: "/candidate/profile/profile-details",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
          />
        </svg>
      ),
    },
    {
      tabname: "Work Experience",
      href: "/candidate/profile/work-experience",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
          />
        </svg>
      ),
    },
    {
      tabname: "Education",
      href: "/candidate/profile/education-details",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
          />
        </svg>
      ),
    },
    {
      tabname: "Other Information",
      href: "/candidate/profile/other-information",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12"
          />
        </svg>
      ),
    },
    {
      tabname: "My Profile",
      href: "/candidate/profile/my-profile-view",

      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
      ),
    },
  ];

  // Function to handle copying content
  const handleCopy = () => {
    if (isCopy === "Copy") {
      const textToCopy = profileUrl;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => setIsCopy("Copied"))
        .catch((err) => {});
    } else {
      navigator.clipboard
        .writeText("")
        .then(() => setIsCopy("Copy"))
        .catch((err) => {});
    }
  };
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <div className="px-2 flex  justify-between my-2 gap-5 text-slate-500 border-b items-center">
        <ul className="  flex  font-inter gap-5">
          {items?.map((item, index) => (
            <li
              key={index}
              className="flex items-center font-inter text-base font-medium gap-2 "
            >
              <NavLink
                to={item?.href}
                className={({ isActive }) =>
                  `flex items-center  pb-2 ${
                    isActive
                      ? "text-[#101828] border-b-[#101828] border-b-[2px] "
                      : "text-[#7F7F7F] border-b-[3px] border-b-transparent"
                  }`
                }
              >
                {item.svg}
                <span className="flex items-center text-sm font-inter  font-medium">
                  {item.tabname}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
        <div ref={buttonRef} className="relative">
          <div
            className="flex items-center justify-end gap-1 min-w-[200px] capitalize cursor-pointer pb-1 text-sm font-inter text-slate-800 font-medium"
            onClick={() => {
              setIsOpen(!isOpen);
              getAllJobs();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
              />
            </svg>
            share my profile
          </div>
          {isOpen && (
            <div className="bg-blue-50 shadow-sm right-0 min-w-full z-20 border flex flex-col p-2  justify-between rounded-md absolute">
              <div className="border rounded-md overflow-hidden justify-between gap-1 w-[200px]  flex">
                <input
                  className="w-full outline-none px-2"
                  type="text"
                  value={profileUrl}
                />
                <button
                  className="bg-black px-2  text-white"
                  onClick={handleCopy}
                >
                  {isCopy}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Tabs;
