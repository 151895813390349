import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";
import Dailog from "../../components/common/Dailog";
import { resetPasswordSchema } from "../../schemas/recruiterSchema";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function CandidateResetPassword() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseUrl}/api/candidate/new_password/${id}`,
          {
            password: values.password,
            password2: values.confirmPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          resetForm();
          setOpen(true);
          setIsLoading(false);
        } else if (response.status === 400) {
          toast.error("Failed to add member");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <>
      <Dailog
        open={open}
        setOpen={setOpen}
        heading="Your password has been successfully reset! We recommend choosing a strong password to ensure your account security. Now, you can log in with your new password and continue using our platform."
        para="All set! Your password has been reset. Simply use your new password to log in and get right back to where you left off."
        btnText="Login"
        btnLink="/candidate/login"
      />
      <div className="flex flex-col gap-4 justify-between items-start w-full p-4 px-14 font-inter text-xs">
        <div className="">
          <ATMHeading>
            Login to our portal which is one Step Closer to Efficiency
          </ATMHeading>

          <p className="font-inter text-xs font-normal text-[#475467]">
            Welcome to our streamlined portal, where each login propels you into
            a world of effortless HR management.
          </p>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full border border-[#E2DCEA] p-4 flex flex-col gap-5 rounded-lg"
        >
          {/* <form onSubmit={formik.handleSubmit}> */}

          <div className="w-full">
            <ATMFieldLabel required>Set your password</ATMFieldLabel>
            <input
              size="lg"
              placeholder="Enter your password"
              className="border w-full p-3 rounded-lg outline-none"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="password"
              name="password"
            />
            {formik.errors.password && formik.touched.password ? (
              <ATMFieldError> {formik.errors.password}</ATMFieldError>
            ) : null}
          </div>
          <div className="w-full">
            <ATMFieldLabel required>Re-enter your password</ATMFieldLabel>
            <input
              size="lg"
              placeholder="Enter your password"
              className="border w-full p-3 rounded-lg outline-none"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="confirmPassword"
              name="confirmPassword"
            />
            {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
              <ATMFieldError> {formik.errors.confirmPassword}</ATMFieldError>
            ) : null}
          </div>
          <div className="w-full px-4">
            <ol className="list-decimal flex flex-col gap-2">
              <li>
                Choose a password that is at least 8 characters long for added
                security
              </li>
              <li>
                Make your password strong by including a mix of uppercase
                letters, lowercase letters, numbers, and special characters
              </li>
            </ol>
          </div>
          <div className="w-full flex justify-end items-center gap-4">
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="w-[120px] flex gap-2 border border-[#1CACBD]  text-[#1CACBD]  items-center h-[40px] rounded-lg font-inter font-semibold justify-center"
            >
              Back
            </button>
            <button
              disabled={isLoading}
              type="submit"
              className="w-[120px] flex gap-2 text-white bg-[#1CACBD]  items-center h-[40px] rounded-lg font-inter font-semibold justify-center"
            >
              <span>
                {isLoading ? (
                  <ATMCircularProgress size="small" color="white" />
                ) : (
                  <span className="flex items-center gap-2">
                    Submit
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </span>
                  </span>
                )}
              </span>
            </button>
          </div>
          {/* </form> */}
        </form>
        <div className="font-inter text-xs">
          <ATMFieldLabel>Want to reach out directly?</ATMFieldLabel>
          <div className="text-slate-600">
            We're here to help!{" "}
            <span
              onClick={() => navigate("/candidate/support")}
              className="text-blue-500 cursor-pointer"
            >
              Contact our support
            </span>{" "}
            team with any questions.
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateResetPassword;
