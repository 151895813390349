import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { array, date, object, string } from "yup";
import { axiosCandidateFromRequest } from "../../../../api/formApi";
import { setRefresh } from "../../../../features/refresh/refresh";
import ProfileDetailsForm from "../Layout/ProfileDetailsForm";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

// const validateImage = (file) => {
//   if (!file) return false;

//   const validTypes = ["image/svg+xml", "image/png", "image/jpeg", "image/gif"];
//   if (!validTypes.includes(file.type)) return false;

//   // Create an image element to check dimensions
//   const img = new Image();
//   img.src = URL.createObjectURL(file);

//   return new Promise((resolve, reject) => {
//     img.onload = () => {
//       URL.revokeObjectURL(img.src);
//       if (img.width <= 800 && img.height <= 400) {
//         resolve(true);
//       } else {
//         resolve(false);
//       }
//     };
//     img.onerror = () => {
//       URL.revokeObjectURL(img.src);
//       resolve(false);
//     };
//   });
// };

const EditProfileDetailsFormWrapper = ({ setProfileEdit, userData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refresh = useSelector((state) => state.refresh.refresh);
  const languagesArray =
    (userData?.languages && JSON.parse(userData?.languages)) || [];
  const formattedLanguages = languagesArray.map((lang) => ({
    label: lang,
    value: lang,
  }));
  const skillsArray = (userData?.skills && JSON.parse(userData?.skills)) || [];
  const formattedSkills = skillsArray.map((skill) => ({
    label: skill,
    value: skill,
  }));
  const initialValues = {
    dob: userData?.date_of_birth || null,
    title: userData?.title || "",
    skillsData: formattedSkills || [],
    languageData: formattedLanguages || [],
    uploadvideo: userData?.introduction_video || "",
    profilePhoto: userData?.profile_pic || "",
    profilesummary: userData?.profile_summary || "",
    city: { label: userData?.city } || null,
    state: { label: userData?.state } || null,
    country: { label: userData?.country } || null,
    zipCode: userData?.zipcode || "",
  };

  const validationSchema = object().shape({
    country: object().required("Country is required"),
    dob: date().test(
      "is-18",
      "You must be at least 18 years old",
      function (value) {
        if (!value) return false;

        const today = new Date();
        const birthDate = new Date(value);
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();
        if (
          monthDifference < 0 ||
          (monthDifference === 0 && dayDifference < 0)
        ) {
          return age - 1 >= 18;
        }

        return age >= 18;
      }
    ),
    title: string().max(30, "title cannot exceed 30 characters"),
    skillsData: array().max(100, "You can add a maximum of 100 skills"),
    languageData: array().max(100, "You can add a maximum of 100 languages"),
    profilesummary: string().max(
      200,
      "profilesummary cannot exceed 100 characters"
    ),
    zipCode: string().max(50, "zipCode cannot exceed 50 characters"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append(
        "date_of_birth",
        values.dob ? values.dob.toISOString().split("T")[0] : ""
      );
      if (values.profilePhoto !== userData?.profile_pic) {
        formData.append(
          "profile_pic",
          values.profilePhoto === userData?.profile_pic
            ? ""
            : values.profilePhoto
        );
      }
      formData.append(
        "skills",
        JSON.stringify(values?.skillsData?.map((el) => el.label))
      );
      formData.append(
        "languages",
        JSON.stringify(values?.languageData?.map((el) => el.label))
      );
      formData.append("introduction_video", values?.uploadvideo);
      formData.append("profile_summary", values.profilesummary);
      formData.append("city", values.city?.label || "");
      formData.append("state", values.state?.label || "");
      formData.append("country", values.country?.label || "");
      formData.append("zipcode", values.zipCode);

      const response = await axiosCandidateFromRequest(
        `${baseUrl}/api/candidate/user/`,
        {
          method: "PATCH",

          data: formData,
        }
      );

      if (response.status === 200) {
        toast.success("Data updated successfully");
        localStorage.setItem(
          "candidate_profile",
          JSON.stringify(response.data.data.candidate)
        );
        setProfileEdit(false);
        setSubmitting(false);
        dispatch(setRefresh(!refresh));
        if (!userData?.title) {
          navigate(`/candidate/profile/work-experience`);
        }
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <ProfileDetailsForm
            formType="Edit"
            formikProps={formikProps}
            userData={userData}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditProfileDetailsFormWrapper;
