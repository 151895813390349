import { useEffect, useState } from "react";

export const useFetchData = (query, otherProps = {}) => {
  const {
    body,
    options,
    isConcateData = false,
    dataType = "LIST",
    skip = false, // Add the skip parameter here
  } = otherProps;

  const [result, setResult] = useState(dataType === "LIST" ? [] : null);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);

  // Using RTK Query hook with skip option
  const { data, isLoading, isFetching } = query(body, {
    ...options,
    skip, // Conditionally skip the query
  });

  useEffect(() => {
    if (skip) {
      // If skip is true, stop the loading state and return
      setIsDataLoading(false);
      return;
    }

    if (!isLoading && !isFetching) {
      if (dataType === "LIST") {
        if (data && Array.isArray(data.results)) {
          setResult(isConcateData ? [...result, ...data.results] : data.results);
          setTotalPages(Math.ceil(data.count / 6));
          setTotalData(data.count || 0);
        } else {
          console.warn("Data is not in expected format:", data);
        }
      } else {
        setResult(data?.data || null);
      }
      setIsDataLoading(false);
    } else {
      setIsDataLoading(true);
    }
  }, [data, isLoading, isFetching, isConcateData, result, dataType, skip]); // Ensure skip is included in the dependency array

  return {
    data: result,
    totalPages,
    totalData,
    isLoading: isDataLoading,
  };
};
