import React from "react";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import { formatDate } from "../../ProfileDetails/View/ProfileDetails";

const WorkExperience = ({
  setWorkExperienceEdit,
  userData,
  setWorkExperienceAdd,
  setWorkExperienceEditId,
}) => {
  const workExperiences =
    userData?.workexperience_candidate.length > 0
      ? userData.workexperience_candidate
      : [];

  return (
    <div className=" flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        {workExperiences.map((workExp, index) => (
          <div
            key={index}
            className=" p-9 flex justify-around flex-wrap border-[1px] rounded-lg border-[#E2DCEA] relative"
          >
            <div
              onClick={() => {
                setWorkExperienceEdit(true);
                setWorkExperienceEditId(index);
              }}
              className="cursor-pointer absolute hover:text-black text-slate-600 top-[-8px] right-[-7px] bg-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 items-start  gap-10">
              <div>
                <ATMFieldLabel>
                  {" "}
                  What kind of experience do you have?
                </ATMFieldLabel>
                <p className="text-sm font-inter font-medium text-[#6D758F]">
                  {workExp.experience}
                </p>
              </div>

              {/* company name  */}
              <div className="">
                <ATMFieldLabel> Company Name</ATMFieldLabel>

                <p className="text-sm font-inter font-medium text-[#344054]">
                  {workExp.company_name}
                </p>
              </div>
              {/* Designation / Job Title */}
              <div>
                <ATMFieldLabel>Designation / Job Title</ATMFieldLabel>
                <p className="text-sm font-inter font-medium text-[#6D758F]">
                  {workExp.job_title}
                </p>
              </div>
              {/* Experience Summary */}
              <div>
                <ATMFieldLabel> Experience Summary</ATMFieldLabel>
                <p className="text-sm font-inter break-words font-medium text-[#6D758F]">
                  {workExp.work_experience_summary}
                </p>
              </div>
              {/* Job Role  */}
              <div>
                <ATMFieldLabel> Job Role</ATMFieldLabel>
                <div>
                  {workExp.full_or_part_time ? (
                    <p className="text-sm font-inter font-medium text-[#6D758F]">
                      Full Time
                    </p>
                  ) : (
                    <p className="text-sm font-inter font-medium text-[#6D758F]">
                      Part Time
                    </p>
                  )}
                </div>
              </div>
              {/* Duration  */}
              <div>
                <ATMFieldLabel> Duration</ATMFieldLabel>
                <div>
                  <div className=" flex items-center gap-4 text-sm font-inter font-medium text-[#6D758F]">
                    <p>{formatDate(workExp?.company_start_duration)}</p>
                    <p>to</p>
                    <p>{formatDate(workExp?.company_end_duration)}</p>
                  </div>
                </div>
              </div>
              {/* Experience Video */}
              <div className="">
                <ATMFieldLabel> Upload Your Experience Video</ATMFieldLabel>

                <div className="mt-1 w-[316px] h-[221.77px] rounded-md overflow-hidden">
                  <video controls src={userData?.introduction_video}></video>
                </div>

                <p className=" mt-2 text-[#475467] text-sm font-inter font-normal">
                  Video recording adds significant value to your job application
                  process; our recruiters prioritize it
                </p>
              </div>
              {/* Skills */}
              <div>
                <ATMFieldLabel>Your Skills</ATMFieldLabel>
                <div className="flex items-center gap-4 flex-wrap ">
                  {JSON.parse(workExp?.skills)?.map((el) => {
                    return (
                      <div className="bg-blue-400 px-4 py-1 rounded-md text-white">
                        {el}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <button
          type="button"
          className="w-[200px] h-[48px] mb-3 rounded-md bg-[#1CACBD] text-white"
          onClick={() => setWorkExperienceAdd(true)}
        >
          Add Work Experience
        </button>
      </div>
    </div>
  );
};

export default WorkExperience;
