import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../assets/Recruiter Invite/default.png";

function ApplicationTable({ data }) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border-collapse text-[#667085]   text-xs ">
        <thead>
          <tr className="font-bold">
            <th className="px-2 py-2 border">ID</th>
            <th className="px-2 py-2 border min-w-56">Data Details</th>
            <th className="px-2 py-2 border">Experience</th>
            <th className="px-2 py-2 border min-w-[350px]"> Skills</th>
            <th className="px-2 py-2 border min-w-[100px]"> Skill Score</th>
            <th className="px-2 py-2 border min-w-[150px]">
              Communication Score
            </th>
            <th className="px-2 py-2 border ">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="">
              <td className="px-4 py-0  border text-center">
                <div>{item?.id}</div>
              </td>
              <td className="px-4 py-0 border relative min-w-96">
                <div className="flex items-center gap-3">
                  <img
                    src={item?.candidate?.profile_pic || img1}
                    alt="Candidate Profile"
                    className="w-12 h-12 border-2 rounded-full border-[#373737]"
                  />{" "}
                  <div className="break-words">
                    <div className="font-bold text-[#101828] capitalize break-words">
                      {item?.candidate?.first_name} {item?.candidate?.last_name}
                    </div>
                    <div className=" text-gray-600 break-words ">
                      {item?.candidate?.email}
                    </div>
                    <div className=" text-gray-600 text-[8px]">
                      {/* {item?.candidate?.city},{" "} */}
                      {item?.candidate?.state} ,{item?.candidate?.country}
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-4 py-0  border text-center">
                <div>{item?.candidate?.experience_year}</div>
                <div>{item?.company}</div>
              </td>
              <td className="px-4 py-0  border  ">
                <div className="flex flex-wrap text-center gap-2">
                  {JSON.parse(item?.candidate?.skills)?.map((skill, idx) => (
                    <span
                      key={idx}
                      className="border text-gray-500 h-fit font-semibold mr-2 px-1 py-0.5 rounded-md"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </td>
              <td className="px-4 py-1  text-center border">
                <div className="flex flex-col items-center">
                  <div className=" border  border-b-2 p-2 border-b-[#03A400] flex justify-center items-center flex-col rounded-md  border-[#E8E8E8] font-bold">
                    {item?.avg_skill_score} / 100
                  </div>
                </div>
              </td>
              <td className="px-4 py-1  text-center border">
                <div className="flex flex-col items-center">
                  <div className=" border  border-b-2 p-2 border-b-[#FDA700] flex justify-center items-center flex-col rounded-md  border-[#E8E8E8] font-bold">
                    {item?.avg_comm_score}/100
                  </div>
                </div>
              </td>
              <td className="px-4 py-1 text-center border-t flex flex-col items-center gap-3">
                <Link
                  to={`/recruit/job-status/application/view/${item?.id}/${item?.candidate?.id}/${item?.job?.id}`}
                  className="bg-[#1CACBD] w-[150px]  text-white px-2 py-1 font-semibold flex items-center justify-center gap-2 rounded-lg"
                >
                  Application View
                  <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.88428 1.55664L9.19964 5.67585L4.88428 9.79506"
                      stroke="white"
                      strokeWidth="1.28571"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.19975 5.67578L0.799805 5.67578"
                      stroke="white"
                      strokeWidth="1.28571"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
                <Link
                  to={`/recruit/job-status/application/candidate/${item.candidate.id}`}
                  className="bg-blue-100  bg-[#1cadbd23]  w-[150px]  text-[#505050] font-semibold px-4 py-1 rounded-lg"
                >
                  Profile View
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ApplicationTable;
