export const data = {
    "domain_industry": [
      {
        "id": 1,
        "name": "Healthcare and Medicines",
        "subcategories": [
          {
            "id": 1,
            "name": "Pharmaceuticals"
          },
          {
            "id": 2,
            "name": "Medical Devices"
          },
          {
            "id": 3,
            "name": "Healthcare Services"
          },
          {
            "id": 4,
            "name": "Biotechnology"
          },
          {
            "id": 5,
            "name": "Clinical Trials"
          },
          {
            "id": 6,
            "name": "Healthcare IT"
          },
          {
            "id": 7,
            "name": "Genetics"
          },
          {
            "id": 8,
            "name": "Public Health"
          },
          {
            "id": 9,
            "name": "Mental Health Services"
          },
          {
            "id": 10,
            "name": "Dental Care"
          },
          {
            "id": 11,
            "name": "Veterinary Services"
          },
          {
            "id": 12,
            "name": "Others"
          }
        ]
      },
      {
        "id": 2,
        "name": "Information Technology",
        "subcategories": [
          {
            "id": 13,
            "name": "Software Development"
          },
          {
            "id": 14,
            "name": "Hardware"
          },
          {
            "id": 15,
            "name": "Cloud Computing"
          },
          {
            "id": 16,
            "name": "AI and Robotics"
          },
          {
            "id": 17,
            "name": "Data Analytics"
          },
          {
            "id": 18,
            "name": "Cybersecurity"
          },
          {
            "id": 19,
            "name": "IT Consulting"
          },
          {
            "id": 20,
            "name": "Networking"
          },
          {
            "id": 21,
            "name": "E-Commerce Solutions"
          },
          {
            "id": 22,
            "name": "Mobile Application Development"
          },
          {
            "id": 23,
            "name": "Virtual Reality"
          },
          {
            "id": 24,
            "name": "Gaming Technology"
          },
          {
            "id": 25,
            "name": "Others"
          }
        ]
      },
      {
        "id": 3,
        "name": "Manufacturing",
        "subcategories": [
          {
            "id": 26,
            "name": "Electronics"
          },
          {
            "id": 27,
            "name": "Automobiles"
          },
          {
            "id": 28,
            "name": "Textiles"
          },
          {
            "id": 29,
            "name": "Food and Beverages"
          },
          {
            "id": 30,
            "name": "Chemicals"
          },
          {
            "id": 31,
            "name": "Machinery"
          },
          {
            "id": 32,
            "name": "Consumer Goods"
          },
          {
            "id": 33,
            "name": "Industrial Equipment"
          },
          {
            "id": 34,
            "name": "Aerospace"
          },
          {
            "id": 35,
            "name": "Defense Manufacturing"
          },
          {
            "id": 36,
            "name": "Packaging"
          },
          {
            "id": 37,
            "name": "Metalworking"
          },
          {
            "id": 38,
            "name": "Plastics"
          },
          {
            "id": 39,
            "name": "Others"
          }
        ]
      },
      {
        "id": 4,
        "name": "Finance and Banking",
        "subcategories": [
          {
            "id": 40,
            "name": "Banking Services"
          },
          {
            "id": 41,
            "name": "Investment Banking"
          },
          {
            "id": 42,
            "name": "Insurance"
          },
          {
            "id": 43,
            "name": "Financial Consultancy"
          },
          {
            "id": 44,
            "name": "Wealth Management"
          },
          {
            "id": 45,
            "name": "Credit Services"
          },
          {
            "id": 46,
            "name": "Fintech"
          },
          {
            "id": 47,
            "name": "Stock Market Services"
          },
          {
            "id": 48,
            "name": "Accounting Services"
          },
          {
            "id": 49,
            "name": "Tax Advisory"
          },
          {
            "id": 50,
            "name": "Audit Services"
          },
          {
            "id": 51,
            "name": "Corporate Finance"
          },
          {
            "id": 52,
            "name": "Others"
          }
        ]
      },
      {
        "id": 5,
        "name": "Education and Training",
        "subcategories": [
          {
            "id": 53,
            "name": "K-12 Education"
          },
          {
            "id": 54,
            "name": "Higher Education"
          },
          {
            "id": 55,
            "name": "Specialized Training"
          },
          {
            "id": 56,
            "name": "E-learning"
          },
          {
            "id": 57,
            "name": "Vocational Training"
          },
          {
            "id": 58,
            "name": "Early Childhood Education"
          },
          {
            "id": 59,
            "name": "Continuing Education"
          },
          {
            "id": 60,
            "name": "Test Preparation"
          },
          {
            "id": 61,
            "name": "Educational Consulting"
          },
          {
            "id": 62,
            "name": "Library Services"
          },
          {
            "id": 63,
            "name": "Tutoring Services"
          },
          {
            "id": 64,
            "name": "Language Training"
          },
          {
            "id": 65,
            "name": "Others"
          }
        ]
      },
      {
        "id": 6,
        "name": "Retail and Wholesale",
        "subcategories": [
          {
            "id": 66,
            "name": "Fashion and Apparel"
          },
          {
            "id": 67,
            "name": "Consumer Electronics"
          },
          {
            "id": 68,
            "name": "Supermarkets"
          },
          {
            "id": 69,
            "name": "Specialty Goods"
          },
          {
            "id": 70,
            "name": "Online Retail"
          },
          {
            "id": 71,
            "name": "Home Furnishings"
          },
          {
            "id": 72,
            "name": "Automotive Sales"
          },
          {
            "id": 73,
            "name": "Jewelry"
          },
          {
            "id": 74,
            "name": "Sporting Goods"
          },
          {
            "id": 75,
            "name": "Wholesale Distributors"
          },
          {
            "id": 76,
            "name": "Import/Export"
          },
          {
            "id": 77,
            "name": "Pet Supplies"
          },
          {
            "id": 78,
            "name": "Others"
          }
        ]
      },
      {
        "id": 7,
        "name": "Telecommunications",
        "subcategories": [
          {
            "id": 79,
            "name": "Network Providers"
          },
          {
            "id": 80,
            "name": "Equipment Manufacturers"
          },
          {
            "id": 81,
            "name": "Service Providers"
          },
          {
            "id": 82,
            "name": "Wireless Communications"
          },
          {
            "id": 83,
            "name": "Satellite Communications"
          },
          {
            "id": 84,
            "name": "Internet Service Providers"
          },
          {
            "id": 85,
            "name": "Telecommunications Consulting"
          },
          {
            "id": 86,
            "name": "Unified Communications"
          },
          {
            "id": 87,
            "name": "Others"
          }
        ]
      },
      {
        "id": 8,
        "name": "Real Estate",
        "subcategories": [
          {
            "id": 88,
            "name": "Residential"
          },
          {
            "id": 89,
            "name": "Commercial"
          },
          {
            "id": 90,
            "name": "Industrial"
          },
          {
            "id": 91,
            "name": "Land Development"
          },
          {
            "id": 92,
            "name": "Real Estate Management"
          },
          {
            "id": 93,
            "name": "Real Estate Appraisals"
          },
          {
            "id": 94,
            "name": "Real Estate Investment"
          },
          {
            "id": 95,
            "name": "Real Estate Consulting"
          },
          {
            "id": 96,
            "name": "Property Management"
          },
          {
            "id": 97,
            "name": "Others"
          }
        ]
      },
      {
        "id": 9,
        "name": "Construction and Engineering",
        "subcategories": [
          {
            "id": 98,
            "name": "Building Construction"
          },
          {
            "id": 99,
            "name": "Civil Engineering"
          },
          {
            "id": 100,
            "name": "Architectural Services"
          },
          {
            "id": 101,
            "name": "Infrastructure Projects"
          },
          {
            "id": 102,
            "name": "Mechanical Engineering"
          },
          {
            "id": 103,
            "name": "Electrical Engineering"
          },
          {
            "id": 104,
            "name": "Construction Equipment"
          },
          {
            "id": 105,
            "name": "Construction Management"
          },
          {
            "id": 106,
            "name": "Urban Planning"
          },
          {
            "id": 107,
            "name": "Green Building"
          },
          {
            "id": 108,
            "name": "Others"
          }
        ]
      },
      {
        "id": 10,
        "name": "Transport and Logistics",
        "subcategories": [
          {
            "id": 109,
            "name": "Logistics Services"
          },
          {
            "id": 110,
            "name": "Public Transportation"
          },
          {
            "id": 111,
            "name": "Freight and Cargo"
          },
          {
            "id": 112,
            "name": "Supply Chain Management"
          },
          {
            "id": 113,
            "name": "Warehousing"
          },
          {
            "id": 114,
            "name": "Courier Services"
          },
          {
            "id": 115,
            "name": "Maritime Transport"
          },
          {
            "id": 116,
            "name": "Rail Transport"
          },
          {
            "id": 117,
            "name": "Air Transport"
          },
          {
            "id": 118,
            "name": "Fleet Management"
          },
          {
            "id": 119,
            "name": "Others"
          }
        ]
      },
      {
        "id": 11,
        "name": "Energy and Utilities",
        "subcategories": [
          {
            "id": 120,
            "name": "Renewable Energy"
          },
          {
            "id": 121,
            "name": "Oil and Gas"
          },
          {
            "id": 122,
            "name": "Water Supply"
          },
          {
            "id": 123,
            "name": "Electricity Generation"
          },
          {
            "id": 124,
            "name": "Nuclear Energy"
          },
          {
            "id": 125,
            "name": "Natural Gas Distribution"
          },
          {
            "id": 126,
            "name": "Sustainable Energy"
          },
          {
            "id": 127,
            "name": "Energy Consulting"
          },
          {
            "id": 128,
            "name": "Power Engineering"
          },
          {
            "id": 129,
            "name": "Others"
          }
        ]
      },
      {
        "id": 12,
        "name": "Agriculture and Forestry",
        "subcategories": [
          {
            "id": 130,
            "name": "Farming"
          },
          {
            "id": 131,
            "name": "Forestry"
          },
          {
            "id": 132,
            "name": "Fishery"
          },
          {
            "id": 133,
            "name": "Agrochemicals"
          },
          {
            "id": 134,
            "name": "Agribusiness"
          },
          {
            "id": 135,
            "name": "Agritech"
          },
          {
            "id": 136,
            "name": "Horticulture"
          },
          {
            "id": 137,
            "name": "Animal Husbandry"
          },
          {
            "id": 138,
            "name": "Crop Production"
          },
          {
            "id": 139,
            "name": "Sustainable Agriculture"
          },
          {
            "id": 140,
            "name": "Others"
          }
        ]
      },
      {
        "id": 13,
        "name": "Media and Entertainment",
        "subcategories": [
          {
            "id": 141,
            "name": "Broadcasting"
          },
          {
            "id": 142,
            "name": "Digital Media"
          },
          {
            "id": 143,
            "name": "Print Media"
          },
          {
            "id": 144,
            "name": "Entertainment Production"
          },
          {
            "id": 145,
            "name": "Film and Television Production"
          },
          {
            "id": 146,
            "name": "Music Production"
          },
          {
            "id": 147,
            "name": "Publishing"
          },
          {
            "id": 148,
            "name": "Event Management"
          },
          {
            "id": 149,
            "name": "News Media"
          },
          {
            "id": 150,
            "name": "Digital Content Creation"
          },
          {
            "id": 151,
            "name": "Others"
          }
        ]
      },
      {
        "id": 14,
        "name": "Hospitality and Tourism",
        "subcategories": [
          {
            "id": 152,
            "name": "Hotels"
          },
          {
            "id": 153,
            "name": "Travel Agencies"
          },
          {
            "id": 154,
            "name": "Recreation Services"
          },
          {
            "id": 155,
            "name": "Food Service"
          },
          {
            "id": 156,
            "name": "Event Planning"
          },
          {
            "id": 157,
            "name": "Tour Operators"
          },
          {
            "id": 158,
            "name": "Cruise Lines"
          },
          {
            "id": 159,
            "name": "Casinos"
          },
          {
            "id": 160,
            "name": "Catering Services"
          },
          {
            "id": 161,
            "name": "Others"
          }
        ]
      },
      {
        "id": 15,
        "name": "Legal Services",
        "subcategories": [
          {
            "id": 162,
            "name": "Corporate Law"
          },
          {
            "id": 163,
            "name": "Criminal Law"
          },
          {
            "id": 164,
            "name": "Intellectual Property"
          },
          {
            "id": 165,
            "name": "Litigation"
          },
          {
            "id": 166,
            "name": "Family Law"
          },
          {
            "id": 167,
            "name": "Estate Planning"
          },
          {
            "id": 168,
            "name": "Tax Law"
          },
          {
            "id": 169,
            "name": "Employment Law"
          },
          {
            "id": 170,
            "name": "Immigration Law"
          },
          {
            "id": 171,
            "name": "Environmental Law"
          },
          {
            "id": 172,
            "name": "Others"
          }
        ]
      },
      {
        "id": 16,
        "name": "Public Sector and Government",
        "subcategories": [
          {
            "id": 173,
            "name": "Public Administration"
          },
          {
            "id": 174,
            "name": "Defense"
          },
          {
            "id": 175,
            "name": "Urban Planning"
          },
          {
            "id": 176,
            "name": "Public Health"
          },
          {
            "id": 177,
            "name": "Public Safety"
          },
          {
            "id": 178,
            "name": "Government Relations"
          },
          {
            "id": 179,
            "name": "Regulatory Compliance"
          },
          {
            "id": 180,
            "name": "Policy Analysis"
          },
          {
            "id": 181,
            "name": "Civil Service"
          },
          {
            "id": 182,
            "name": "Others"
          }
        ]
      },
      {
        "id": 17,
        "name": "Science and Technology Research",
        "subcategories": [
          {
            "id": 183,
            "name": "Life Sciences"
          },
          {
            "id": 184,
            "name": "Physical Sciences"
          },
          {
            "id": 185,
            "name": "Engineering Research"
          },
          {
            "id": 186,
            "name": "Social Sciences"
          },
          {
            "id": 187,
            "name": "Biomedical Research"
          },
          {
            "id": 188,
            "name": "Material Science"
          },
          {
            "id": 189,
            "name": "Environmental Research"
          },
          {
            "id": 190,
            "name": "Computer Science Research"
          },
          {
            "id": 191,
            "name": "Astrophysics"
          },
          {
            "id": 192,
            "name": "Others"
          }
        ]
      },
      {
        "id": 18,
        "name": "Non-Profit and Charity",
        "subcategories": [
          {
            "id": 193,
            "name": "Human Services"
          },
          {
            "id": 194,
            "name": "Environment and Conservation"
          },
          {
            "id": 195,
            "name": "Education and Research"
          },
          {
            "id": 196,
            "name": "Health"
          },
          {
            "id": 197,
            "name": "Community Development"
          },
          {
            "id": 198,
            "name": "Advocacy and Human Rights"
          },
          {
            "id": 199,
            "name": "Philanthropy"
          },
          {
            "id": 200,
            "name": "Volunteer Management"
          },
          {
            "id": 201,
            "name": "Non-Profit Management"
          },
          {
            "id": 202,
            "name": "Others"
          }
        ]
      },
      {
        "id": 19,
        "name": "Environmental Services",
        "subcategories": [
          {
            "id": 203,
            "name": "Waste Management"
          },
          {
            "id": 204,
            "name": "Recycling"
          },
          {
            "id": 205,
            "name": "Environmental Consulting"
          },
          {
            "id": 206,
            "name": "Landscaping"
          },
          {
            "id": 207,
            "name": "Conservation"
          },
          {
            "id": 208,
            "name": "Environmental Engineering"
          },
          {
            "id": 209,
            "name": "Water Management"
          },
          {
            "id": 210,
            "name": "Sustainability Consulting"
          },
          {
            "id": 211,
            "name": "Others"
          }
        ]
      },
      {
        "id": 20,
        "name": "Arts and Crafts",
        "subcategories": [
          {
            "id": 212,
            "name": "Visual Arts"
          },
          {
            "id": 213,
            "name": "Performing Arts"
          },
          {
            "id": 214,
            "name": "Craftsmanship"
          },
          {
            "id": 215,
            "name": "Art Galleries"
          },
          {
            "id": 216,
            "name": "Art Restoration"
          },
          {
            "id": 217,
            "name": "Photography"
          },
          {
            "id": 218,
            "name": "Fashion Design"
          },
          {
            "id": 219,
            "name": "Sculpture"
          },
          {
            "id": 220,
            "name": "Others"
          }
        ]
      },
      {
        "id": 21,
        "name": "Others",
        "subcategories": [
          {
            "id": 221,
            "name": "Miscellaneous"
          },
          {
            "id": 222,
            "name": "Unspecified"
          },
          {
            "id": 223,
            "name": "General"
          }
        ]
      }
    ],
    "aspect_of_work_location": [
      "Hybrid",
      "Remote",
      "On-Site"
    ],
    "employment_type": [
      "Internship agreement",
      "Temporary employment contract",
      "Full-time employment contract",
      "Part-Time employment contract",
      "Volunteer agreement",
      "Freelance contract"
    ],
    "job_duration_type": [
      "Months",
      "Years",
      "Days",
      "Hours",
      "Weeks"
    ],
    "salary_range_type": [
      "Year",
      "Hour"
    ]
  }