import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router";

function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    const candidateaccessToken = Cookies.get("candidateaccessToken");
    const candidatetype = Cookies.get("candidate_user");
    const userType = Cookies.get("userType");

    if (!candidateaccessToken || !accessToken) navigate("/recruiter/login");

    if (!candidateaccessToken) {
    } else if (
      !accessToken &&
      candidateaccessToken &&
      candidatetype === "candidate"
    ) {
      navigate("/candidate/home");
    } else if (!accessToken && userType === "recruiter") {
      navigate("/recruiter/login");
    } else if (accessToken && userType === "recruiter") {
      navigate("/recruit/dashboard/");
    } else {
      navigate("/");
    }
  }, [navigate]);
  return null;
}

export default NotFound;

// import React, { useEffect } from "react";
// import { useNavigate } from "react-router";
// import Cookies from "js-cookie";

// function NotFound() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const accessToken = Cookies.get("accessToken");
//     const candidateaccessToken = Cookies.get("candidateaccessToken");
//     const candidatetype = Cookies.get("candidate_user");
//     const userType = Cookies.get("userType");

//     const navigationKey = `${!!candidateaccessToken}-${candidatetype}-${!!accessToken}-${userType}`;

//     switch (navigationKey) {
//       case "false-candidate-false-recruiter":
//         navigate("/recruiter/login");
//         break;
//       case "false-candidate-true-recruiter":
//         navigate("/recruiter-dashboard/");
//         break;
//       case "false-candidate-false-undefined":
//         navigate("/candidate/login");
//         break;
//       case "true-candidate-false-undefined":
//         navigate("/candidate/home");
//         break;
//       default:
//         navigate("/");
//         break;
//     }
//   }, [navigate]);

//   return null;
// }

// export default NotFound;
