import React, { useState } from "react";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import UploadBulkResumeFormWrapper from "./UploadBulkResume/UploadBulkResumeFormWrapper";
import UploadSingleResumeFormWrapper from "./UploadSingleResume/UploadSingleResumeFormWrapper";

const ResunmeUploadViewWrapper = () => {
  const [view, SetView] = useState("Single");

  return (
    <div className="px-2">
      <ATMHeading> Resume Upload</ATMHeading>
      <div className="flex gap-3 border-b  border-[#D0D5DD] font-inter text-xs mt-2">
        <ul className=" flex gap-6 font-medium text-[#667085] cursor-pointer">
          <li
            className={`pb-2 ${
              view === "Single" ? "border-b-2 border-[#101828]" : ""
            } text-[#101828]`}
            onClick={() => {
              SetView("Single");
            }}
          >
            {view === "EditSingle" ? "Edit Resume" : "Upload Single Resume"}
          </li>

          <li
            className={`pb-2  ${
              view === "Bulk" ? "border-b-2 border-[#101828]" : ""
            } text-[#101828]`}
            onClick={() => SetView("Bulk")}
          >
            Upload Bulk Resume
          </li>
        </ul>
      </div>
      {view === "Bulk" && <UploadBulkResumeFormWrapper />}
      {view === "Single" && <UploadSingleResumeFormWrapper setView={SetView} />}
    </div>
  );
};

export default ResunmeUploadViewWrapper;
