import React from "react";

const ATMFieldLabel = ({
  htmlFor,
  children,
  hidden = false,
  required = false,
  className = "",
}) => {
  return (
    <label
      className={`text-xs font-semibold text-slate-900 font-inter ${
        hidden && "hidden"
      } ${className}`}
      htmlFor={htmlFor}
    >
      {children} {required && <span className="text-red-500">*</span>}
    </label>
  );
};

export default ATMFieldLabel;
