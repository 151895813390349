import apiSlice from "../ApiSlice";

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllMyOrgJobs: builder.query({
      providesTags: ["dashboard"],
      query: (body) => ({
        url: `/api/recruiter/my_org_jobs/`,
        method: "POST",
        body: {
          query: body?.search,
          fields: body?.fields?.valueGet,
          ordering: body?.ordering,
        },
        params: { page: body?.page, page_size: body?.size },
      }),
    }),
    getAllMyCreatedJobs: builder.query({
      providesTags: ["dashboard"],
      query: (body) => ({
        url: `/api/recruiter/my_created_jobs/`,
        method: "POST",
        body: {
          query: body?.search,
          fields: body?.fields?.valueGet,
          ordering: body?.ordering,
        },
        params: { page: body?.page, page_size: body?.size },
      }),
    }),
    getAllMyManagedJobs: builder.query({
      providesTags: ["dashboard"],
      query: (body) => ({
        url: `/api/recruiter/managed_jobs/`,
        method: "POST",
        body: {
          query: body?.search,
          fields: body?.fields?.valueGet,
          ordering: body?.ordering,
        },
        params: { page: body?.page, page_size: body?.size },
      }),
    }),
    getAllMySharedJobs: builder.query({
      providesTags: ["dashboard"],
      query: (body) => ({
        url: `/api/recruiter/my_shared_jobs/`,
        method: "POST",
        body: {
          query: body?.search,
          fields: body?.fields?.valueGet,
          ordering: body?.ordering,
        },
        params: { page: body?.page, page_size: body?.size },
      }),
    }),
    getAllMyInactiveJobs: builder.query({
      providesTags: ["dashboard"],
      query: (body) => ({
        url: `/api/recruiter/inactive_jobs/`,
        method: "POST",
        body: {
          query: body?.search,
          fields: body?.fields?.valueGet,
          ordering: body?.ordering,
        },
        params: { page: body?.page, page_size: body?.size },
      }),
    }),
    createJob: builder.mutation({
      invalidatesTags: ["dashboard"],
      query: (body) => ({
        url: "/api/recruiter/job/",
        method: "POST",
        body,
      }),
    }),
    editJob: builder.mutation({
      invalidatesTags: ["dashboard"],
      query: ({ body, id }) => ({
        url: `/api/recruiter/job/${id}/`,
        method: "PATCH",
        body,
      }),
    }),
    getJobById: builder.query({
      invalidatesTags: ["dashboard"],
      query: (id) => ({
        url: `/api/recruiter/job/${id}/`,
        method: "GET",
      }),
    }),
    getJobJpplication: builder.query({
      providesTags: ["shortlist"],
      query: (body) => ({
        url: `/api/recruiter/job_application/`,
        method: "POST",
        body,
      }),
    }),
    shortList: builder.mutation({
      invalidatesTags: ["shortlist"],
      query: (body) => ({
        url: `/api/recruiter/shortlist/`,
        method: "POST",
        body,
      }),
    }),
    getResumeById: builder.query({
      query: ({ id }) => ({
        url: `/api/recruiter/get_resume/${id}/`,
        method: "GET",
      }),
    }),
    getDashboardData: builder.query({
      providesTags: ["dashboard"],
      query: () => ({
        url: `/api/recruiter/dashboard_recruiter/`,
        method: "GET",
      }),
    }),
    searchQuery: builder.mutation({
      // invalidatesTags: ["dashboard"],
      query: (data) => ({
        url: `/api/recruiter/recruit_search/`,
        method: "POST",
        data,
      }),
    }),
    support: builder.mutation({
      // invalidatesTags: ["dashboard"],
      query: (body) => ({
        url: `/api/recruiter/support/`,
        method: "POST",
        body,
      }),
    }),
    candidateProfileView: builder.query({
      // invalidatesTags: ["dashboard"],
      query: (body) => ({
        url: `/api/recruiter/profile_view/`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllMyOrgJobsQuery,
  useGetAllMyCreatedJobsQuery,
  useGetAllMyManagedJobsQuery,
  useGetAllMyInactiveJobsQuery,
  useGetAllMySharedJobsQuery,
  useCreateJobMutation,
  useEditJobMutation,
  useSearchQueryMutation,
  useGetResumeByIdQuery,
  useSupportMutation,
  useGetDashboardDataQuery,
  useGetJobByIdQuery,
  useGetJobJpplicationQuery,
  useShortListMutation,
  useCandidateProfileViewQuery
} = dashboardApi;
