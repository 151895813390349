import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import { axiosCandidateRequest } from "../../api/api";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function Logout() {
  const refreshToken = localStorage.getItem("candidaterefreshToken");
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      if (!refreshToken) return navigate("/candidate/login");
      try {
        const response = await axiosCandidateRequest(
          `${baseUrl}/api/candidate/logout/`,
          {
            method: "POST",
            data: { refresh_token: refreshToken },
          }
        );

        if (response.status === 205) {
          localStorage.removeItem("candidateaccessToken");
          localStorage.removeItem("candidaterefreshToken");
          localStorage.removeItem("candidate_user");
          localStorage.removeItem("candidate_profile");
          Cookies.remove("candidaterefreshToken", {
            secure: true,
            sameSite: "Strict",
          });
          Cookies.remove("candidate_user", {
            secure: true,
            sameSite: "Strict",
          });
          navigate("/candidate/login");
        } else if (response.status === 400) {
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
        }
      }
    };

    handleLogout();
  }, [navigate, refreshToken]);
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "",
          duration: 10000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 10000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </>
  );
}

export default Logout;
