import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import ATMFieldError from "../../../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import { object } from "yup";
import ATMTextField from "../../../../atoms/ATMTextField/ATMTextField";
import { useSendEmailMutation } from "../Service/TalentSearchService";
import toast from "react-hot-toast";

const SendEmail = ({ isOpen, onClose, getSelectedProfilesEmails }) => {
  const [send] = useSendEmailMutation();
  if (!isOpen) return null;

  const initialValues = {
    emails: getSelectedProfilesEmails || "",
    email_subject: "",
    email_body: "",
  };
  const validationSchema = object().shape({});

  const onSubmit = async (values, { setSubmitting }) => {
    send(values).then((res) => {
      setSubmitting(true);
      if (res?.error) {
        toast.error(res?.error?.data?.message);
        setSubmitting(false);
      } else {
        if (res?.data?.success) {
          toast.success(res?.data?.message);
          setSubmitting(false);
          onClose();
        } else {
          toast.error(res?.error?.data?.message);
          setSubmitting(false);
        }
      }
    });
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 font-inter"
      onClick={onClose}
    >
      <div
        className="bg-white p-4 rounded-md shadow-lg max-w-xl w-full "
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-semibold text-center">Send Email</h2>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className="">
                <ATMTextField
                  required
                  type="text"
                  name="email_subject"
                  value={values.email_subject || ""}
                  label="Subject"
                  placeholder="Enter Subject"
                  onChange={(e) =>
                    setFieldValue("email_subject", e.target.value)
                  }
                />
              </div>

              <div className="mt-4">
                <ATMFieldLabel required>Message</ATMFieldLabel>
                <textarea
                  className="w-full border   h-[100px] p-3 rounded-lg outline-none "
                  value={values.email_body}
                  onChange={(e) => setFieldValue("email_body", e.target.value)}
                  id="email_body"
                  name="email_body"
                  placeholder="Type Your Message here...."
                ></textarea>
                <ErrorMessage name={"email_body"}>
                  {(errorMessage) => (
                    <ATMFieldError> {errorMessage} </ATMFieldError>
                  )}
                </ErrorMessage>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full mt-4 flex gap-1 text-white bg-[#1CACBD] px-[12px] py-3 rounded-lg  font-semibold justify-center items-center"
                >
                  {isSubmitting ? "Sending..." : "Send Email"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SendEmail;
