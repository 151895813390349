import apiSlice from "../../../../app/ApiSlice";

export const formServicesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    trackResume: builder.mutation({
      query: (body) => ({
        url: "/api/recruiter/track_resume/",
        method: "POST",
        body,
      }),
    }),
    resumeSubmit: builder.mutation({
      invalidatesTags: ["candidate"],
      query: (body) => ({
        url: "/api/recruiter/candidate_resume_submit/",
        method: "POST",
        body,
      }),
    }),
    resumeUpdate: builder.mutation({
      invalidatesTags: ["candidate"],
      query: (body) => ({
        url: "/api/recruiter/candidate_resume_submit/",
        method: "PATCH",
        body,
      }),
    }),
    upLoadedResume: builder.query({
      providesTags: ["candidate"],
      query: (body) => ({
        url: `/api/recruiter/my_resume/`,
        method: "POST",
        body,
        params: { page: body?.page, page_size: body?.size },
      }),
    }),
    uploadBulkResumeData: builder.mutation({
      query: (body) => ({
        url: `/api/recruiter/bulk_resumes/`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useTrackResumeMutation,
  useResumeSubmitMutation,
  useUpLoadedResumeQuery,
  useResumeUpdateMutation,
  useUploadBulkResumeDataMutation,
} = formServicesApi;
