import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router";
import { axiosCandidateRequest } from "../../api/api";
import Tabs from "../../components/candidate/Tabs";
import { setCandidateData } from "../../features/candidateData/candidateData";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function UpdateProfileRoot() {
  const refresh = useSelector((state) => state.refresh.refresh);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await axiosCandidateRequest(
          `${baseUrl}/api/candidate/user/`,
          {
            method: "GET",
          }
        );

        if (response.status === 200) {
          dispatch(setCandidateData(response.data.data.candidate));
        } else if (response.status === 400) {
          toast.error("Failed to get data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      }
    };
    getUserDetails();
  }, [refresh, dispatch]);
  return (
    <>
      <Tabs />
      <Outlet />
    </>
  );
}

export default UpdateProfileRoot;
