import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
import { useGetDashboardDataQuery } from "../../../app/DashboardServices/DashboardServices";

const RecruiterDashboard = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetDashboardDataQuery();
  // Initialize job counts
  const [jobs, setJobs] = useState({
    "All Organization Jobs": { totalCount: 0 },
    "My Created Jobs": { totalCount: 0 },
    "My Managed Jobs": { totalCount: 0 },
    "My Shared Jobs": { totalCount: 0 },
    "Inactive Jobs": { totalCount: 0 },
  });

  useEffect(() => {
    setJobs({
      "All Organization Jobs": { totalCount: data?.org_jobs },
      "My Created Jobs": { totalCount: data?.my_created },
      "My Managed Jobs": { totalCount: data?.my_managed },
      "My Shared Jobs": { totalCount: data?.my_shared },
      "Inactive Jobs": { totalCount: data?.my_inactive },
    });
  }, [data]);
  const borderColors = {
    "All Organization Jobs": "border-b-blue-400",
    "My Created Jobs": "border-b-green-400",
    "My Managed Jobs": "border-b-yellow-400",
    "My Shared Jobs": "border-b-purple-400",
    "Inactive Jobs": "border-b-red-400",
  };

  return (
    <div className="font-inter py-4 h-full flex justify-center text-sm px-2 mt-10">
      {isLoading ? (
        <div className="h-full flex justify-center items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        <div className="border h-fit rounded-md flex flex-col gap-4 ">
          <div className="flex text-sm items-stretch gap-4 text-center justify-center p-6 flex-wrap">
            {[
              "All Organization Jobs",
              "My Created Jobs",
              "My Managed Jobs",
              "My Shared Jobs",
              "Inactive Jobs",
            ].map((type) => (
              <div
                key={type}
                className={`px-4 py-1 bg-white border border-b-4 ${borderColors[type]} w-[180px] rounded-lg`}
              >
                <h2 className="font-semibold text-lg text-[#515151]">
                  {jobs[type]?.totalCount || 0}
                </h2>
                <p className="text-[#333333] font-normal">{type}</p>
              </div>
            ))}
          </div>
          <div className="bg-blue-100 p-6 text-center">
            Welcome To Recruiter Dashboard <br />
            You Can Create a new Job with
            <span
              className="cursor-pointer text-blue-500 ml-1"
              onClick={() => navigate("/recruit/create-job")}
            >
              Create Job
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecruiterDashboard;

// import React from "react";
// import { formatDate } from "../../candidate/ProfileDetails/View/ProfileDetails";

// const RecruiterDashboard = () => {
//   return (
//     <div className="p-4 space-y-6 font-inter ">
//       {/* Top Stats Section */}
//       <div className="grid grid-cols-3 gap-4 ">
//         {/* <div className="grid grid-cols-3 gap-4 col-span-2 relative bg-gray-100 p-10  rounded-md">
//           <div className="absolute right-10 top-2 ">
//             {" "}
//             {formatDate(new Date())}

//           </div>
//           <StatsCard title="Total Jobs" count="24" percentage="2% increase" />
//           <StatsCard
//             title="Total Applicants"
//             count="10"
//             percentage="4 active hiring"
//           />
//           <StatsCard title="Hired" count="24" percentage="8% increase" />
//           <StatsCard
//             title="Total Candidates"
//             count="216"
//             percentage="3% increase"
//           />
//           <StatsCard
//             title="Talent Search Profile Counts"
//             count="16"
//             percentage="12% increase"
//           />
//           <div className="col-span-3">
//             <NotificationsSection />
//           </div>
//         </div> */}
//         <div className="bg-gray-100 col-span-2 p-6 ">
//           <div className="">
//             {/* Header */}
//             <div className="flex justify-between items-center mb-6">
//               <h1 className="text-xl font-semibold">Dashboard</h1>
//               <p className="text-gray-600">Today, 13 Sep 2021</p>
//             </div>

//             {/* Stats Cards */}
//             <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-6">
//               {/* Total Jobs */}
//               <div className="bg-white p-6 shadow rounded-lg">
//                 <h2 className="text-gray-600 text-sm font-medium">
//                   Total Jobs
//                 </h2>
//                 <p className="text-3xl font-bold mt-2">24</p>
//                 <span className="text-green-500 text-sm">+2% Past month</span>
//               </div>

//               {/* Total Applicants */}
//               <div className="bg-white p-6 shadow rounded-lg">
//                 <h2 className="text-gray-600 text-sm font-medium">
//                   Total Applicants
//                 </h2>
//                 <p className="text-3xl font-bold mt-2">10</p>
//                 <span className="text-sm text-gray-500">4 Active hiring</span>
//               </div>

//               {/* Hired */}
//               <div className="bg-white p-6 shadow rounded-lg">
//                 <h2 className="text-gray-600 text-sm font-medium">Hired</h2>
//                 <p className="text-3xl font-bold mt-2">24</p>
//                 <span className="text-sm text-gray-500">4 Department</span>
//               </div>
//             </div>

//             {/* More Stats */}
//             <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
//               {/* Total Candidates */}
//               <div className="bg-white p-6 shadow rounded-lg">
//                 <h2 className="text-gray-600 text-sm font-medium">
//                   Total Candidates
//                 </h2>
//                 <p className="text-3xl font-bold mt-2">216</p>
//                 <p className="text-sm text-gray-500">120 Male | 96 Female</p>
//               </div>
//               {/* Talent Search */}
//               <div className="bg-white p-6 shadow rounded-lg">
//                 <h2 className="text-gray-600 text-sm font-medium">
//                   Talent Search Profile Counts
//                 </h2>
//                 <p className="text-3xl font-bold mt-2">16</p>
//                 <p className="text-sm text-gray-500">6 Male | 10 Female</p>
//                 <span className="text-green-500 text-sm">+6% Past month</span>
//               </div>
//             </div>

//             {/* Notifications */}
//             <div className="mt-6 bg-white p-6 shadow rounded-lg">
//               <h2 className="text-gray-600 text-sm font-medium mb-4">
//                 Notifications
//               </h2>
//               <div className="space-y-4">
//                 {/* Notification 1 */}
//                 <div className="flex items-start justify-between">
//                   <div>
//                     <p className="text-gray-800">
//                       Candidate A applied for Designer Position
//                     </p>
//                     <p className="text-sm text-gray-500">5 Minutes ago</p>
//                   </div>
//                   <button className="text-blue-500">...</button>
//                 </div>
//                 {/* Notification 2 */}
//                 <div className="flex items-start justify-between">
//                   <div>
//                     <p className="text-gray-800">
//                       IT Department need two more talents for UX/UI Designer
//                       position
//                     </p>
//                     <p className="text-sm text-gray-500">Yesterday, 9:15 AM</p>
//                   </div>
//                   <button className="text-blue-500">...</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="flex flex-col gap-4 ">
//           <RecentActivitySection />
//           <UpcomingCard />
//         </div>
//       </div>

//       {/* Applicants Section */}
//       <div className="space-y-4">
//         <h2 className="text-lg font-semibold">Applicants</h2>
//         <div className="grid grid-cols-5 gap-4 justify-between">
//           <ApplicantCard
//             name="James Walker"
//             title="Designer"
//             date="23 Sept 2023"
//           />
//           <ApplicantCard
//             name="James Walker"
//             title="Designer"
//             date="23 Sept 2023"
//           />
//           <ApplicantCard
//             name="James Walker"
//             title="Designer"
//             date="23 Sept 2023"
//           />
//           <ApplicantCard
//             name="James Walker"
//             title="Designer"
//             date="23 Sept 2023"
//           />
//           <ApplicantCard
//             name="James Walker"
//             title="Designer"
//             date="23 Sept 2023"
//           />
//         </div>
//       </div>

//       {/* Interview Results Section */}
//       <InterviewResultsTable />
//     </div>
//   );
// };

// // Stats Card Component
// const StatsCard = ({ title, count, percentage }) => (
//   <div className="bg-white shadow rounded-lg p-4  font-inter h-fit flex flex-col gap-2">
//     <h3 className="text-sm font-medium">{title}</h3>
//     <div className="text-2xl font-bold">{count}</div>
//     <p className="text-xs text-gray-500">{percentage}</p>
//   </div>
// );

// // Notifications Section
// const NotificationsSection = () => (
//   <div className="">
//     <h3 className="text-sm font-medium pb-5">Notifications</h3>
//     <NotificationCard text="Candidate A applied for Designer Position" />
//     <NotificationCard text="IT Department needs more talents for UI/UX Designer position" />
//   </div>
// );

// // Notification Card Component
// const NotificationCard = ({ text }) => (
//   <div className="bg-purple-100 py-2 px-4 rounded-lg mb-2 flex justify-between ">
//     <div>
//       <div>{text}</div>
//       <div className="text-[9px] ">5 min ago</div>
//     </div>
//     <div className="text-2xl">...</div>
//   </div>
// );
// // upcoming Card Component
// const UpcomingCard = () => (
//   <div class="p-4 border rounded-lg  bg-gray-50">
//     <div class="flex justify-between items-center pb-4 border-b">
//       <h2 class="text-lg font-semibold">Upcoming Schedule</h2>
//       <p class="text-sm text-gray-500">Today, 13 Sep 2021</p>
//     </div>

//     <div class="mt-4">
//       <h3 class="text-sm font-semibold text-gray-700">Priority</h3>
//       <div class="mt-2 space-y-2">
//         <div class="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm">
//           <div>
//             <p class="text-sm font-medium">Review candidate applications</p>
//             <p class="text-xs text-gray-500">Today - 11:30 AM</p>
//           </div>
//           <div class="flex items-center space-x-2">
//             <button class="text-gray-400">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 class="h-5 w-5"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   stroke-width="2"
//                   d="M6 10h12M6 14h8"
//                 />
//               </svg>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>

//     <div class="mt-6">
//       <h3 class="text-sm font-semibold text-gray-700">Other</h3>
//       <div class="mt-2 space-y-2">
//         <div class="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm">
//           <div>
//             <p class="text-sm font-medium">Interview with candidates</p>
//             <p class="text-xs text-gray-500">Today - 10:30 AM</p>
//           </div>
//           <div class="flex items-center space-x-2">
//             <button class="text-gray-400">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 class="h-5 w-5"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   stroke-width="2"
//                   d="M6 10h12M6 14h8"
//                 />
//               </svg>
//             </button>
//           </div>
//         </div>
//         <div class="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm">
//           <div>
//             <p class="text-sm font-medium">This is Title</p>
//             <p class="text-xs text-gray-500">Today - 11:30 AM</p>
//           </div>
//           <div class="flex items-center space-x-2">
//             <button class="text-gray-400">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 class="h-5 w-5"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   stroke-linecap="round"
//                   stroke-linejoin="round"
//                   stroke-width="2"
//                   d="M6 10h12M6 14h8"
//                 />
//               </svg>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>

//     <div class="mt-6">
//       <button class="w-full py-2 text-sm font-medium bg-gray-100 rounded-lg hover:bg-gray-200">
//         Create a New Schedule
//       </button>
//     </div>
//   </div>
// );

// // Recent Activity Section
// const RecentActivitySection = () => (
//   <div className="bg-white shadow rounded-lg overflow-hidden text-xs">
//     <h3 className="text-sm font-medium bg-black text-white p-4 ">
//       Recent Activity
//     </h3>
//     <div className="p-4">
//       <p>{formatDate(new Date(), true)}</p>
//       <p className="font-semibold text-sm">You posted a new job</p>
//       <p className="pt-4">
//         Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni quasi
//         amet sunt quam! Architecto, facilis. Quas fugit facilis officiis quaerat
//         dignissimos non, dolorem aperiam consequuntur quae praesentium.
//         Deleniti, suscipit perspiciatis?
//       </p>
//     </div>
//     <div className="p-4 flex text-xs items-center justify-between gap-4">
//       <p>Today you makes 20 Activity </p>
//       <button className="text-white bg-[#1CACBD] h-[40px] w-[150px] rounded-md">
//         See All Activity
//       </button>
//     </div>
//   </div>
// );

// // Applicant Card Component
// const ApplicantCard = ({ name, title, date }) => (
//   <div class="p-4 bg-slate-50 border rounded-lg w-[250px] ">
//     {/* <!-- Profile Image --> */}
//     <div class="flex  gap-2 items-center">
//       <img
//         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWi4bk1s8Q2HPdq4fAPfLVKO6I4UrbUGW93w&s"
//         alt="Profile Picture"
//         class="w-20 h-20 rounded-full border-2 border-[#1CACBD]"
//       />
//       <div>
//         <h2 class="text-lg font-semibold mt-2">James Wallter</h2>
//         <p class="text-sm text-gray-600">UX Designer</p>
//         <p class="text-sm text-gray-600">6 years of Exp</p>
//       </div>
//     </div>

//     {/* <!-- Application Details --> */}
//     <div class="mt-4 ">
//       <p class="text-sm text-gray-500">
//         Applied on <span class="font-medium">2nd Sept 2023</span>
//       </p>
//       <p class="text-sm font-medium mt-1">Java Developer (ID56780GL)</p>
//     </div>

//     {/* <!-- Skills --> */}
//     <div class="mt-4 flex flex-wrap  gap-2">
//       <button class="px-4 py-1 text-sm bg-white rounded-md  border border-black">
//         User Research
//       </button>
//       <button class="px-4 py-1 text-sm bg-white border-black border rounded-md ">
//         UI/UX
//       </button>
//       <button class="px-4 py-1 text-sm bg-white border-black border rounded-md ">
//         User Experience Design
//       </button>
//     </div>

//     <div class="mt-6">
//       <button class="w-full py-2 text-white bg-[#1CACBD] rounded-lg ">
//         View Job Application
//       </button>
//     </div>
//   </div>
// );

// // Interview Results Table Component
// const InterviewResultsTable = () => (
//   <div class=" bg-white shadow-md rounded-lg overflow-hidden">
//     <div class="flex justify-between items-center p-4 border-b bg-gray-50">
//       <h2 class="text-lg font-semibold">Interview Results</h2>
//       <button class="px-4 py-1 text-sm font-medium bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200">
//         Today
//       </button>
//     </div>

//     <div class="overflow-x-auto">
//       <table class="min-w-full table-auto text-left border-collapse">
//         <thead class="bg-gray-50 text-gray-700">
//           <tr>
//             <th class="px-6 py-3 font-medium text-sm border-b">
//               Candidate Name
//             </th>
//             <th class="px-6 py-3 font-medium text-sm border-b">Post Name</th>
//             <th class="px-6 py-3 font-medium text-sm border-b">Designation</th>
//             <th class="px-6 py-3 font-medium text-sm border-b">
//               Hiring Manager
//             </th>
//             <th class="px-6 py-3 font-medium text-sm border-b">Time</th>
//             <th class="px-6 py-3 font-medium text-sm border-b">Project</th>
//             <th class="px-6 py-3 font-medium text-sm border-b">Status</th>
//           </tr>
//         </thead>
//         <tbody class="divide-y divide-gray-200">
//           <tr class="hover:bg-gray-50">
//             <td class="px-6 py-4 flex items-center space-x-3">
//               <img
//                 class="w-10 h-10 rounded-full object-cover"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWi4bk1s8Q2HPdq4fAPfLVKO6I4UrbUGW93w&s"
//                 alt="Profile"
//               />
//               <span class="text-gray-800 font-medium">Thomas Alva</span>
//             </td>
//             <td class="px-6 py-4 text-sm text-gray-700">Developer</td>
//             <td class="px-6 py-4 text-sm text-gray-700">Backend</td>
//             <td class="px-6 py-4 text-sm text-gray-700">Hara Aacharya</td>
//             <td class="px-6 py-4 text-sm text-gray-700">11:00 am</td>
//             <td class="px-6 py-4 text-sm text-gray-700">Intel Tech</td>
//             <td class="px-6 py-4">
//               <span class="px-3 py-1 text-sm text-gray-800 bg-gray-200 rounded-full">
//                 In completed
//               </span>
//             </td>
//           </tr>

//           <tr class="hover:bg-gray-50">
//             <td class="px-6 py-4 flex items-center space-x-3">
//               <img
//                 class="w-10 h-10 rounded-full object-cover"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWi4bk1s8Q2HPdq4fAPfLVKO6I4UrbUGW93w&s"
//                 alt="Profile"
//               />
//               <span class="text-gray-800 font-medium">Mohsin Baig</span>
//             </td>
//             <td class="px-6 py-4 text-sm text-gray-700">Designer</td>
//             <td class="px-6 py-4 text-sm text-gray-700">UI/UX</td>
//             <td class="px-6 py-4 text-sm text-gray-700">Nitin Dutt</td>
//             <td class="px-6 py-4 text-sm text-gray-700">11:40 am</td>
//             <td class="px-6 py-4 text-sm text-gray-700">Canada Post</td>
//             <td class="px-6 py-4">
//               <span class="px-3 py-1 text-sm text-white bg-green-500 rounded-full">
//                 Completed
//               </span>
//             </td>
//           </tr>

//           <tr class="hover:bg-gray-50">
//             <td class="px-6 py-4 flex items-center space-x-3">
//               <img
//                 class="w-10 h-10 rounded-full object-cover"
//                 src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWi4bk1s8Q2HPdq4fAPfLVKO6I4UrbUGW93w&s"
//                 alt="Profile"
//               />
//               <span class="text-gray-800 font-medium">Santosh Anand</span>
//             </td>
//             <td class="px-6 py-4 text-sm text-gray-700">Content Writer</td>
//             <td class="px-6 py-4 text-sm text-gray-700">Writer</td>
//             <td class="px-6 py-4 text-sm text-gray-700">Ankit Kumar</td>
//             <td class="px-6 py-4 text-sm text-gray-700">12:35 pm</td>
//             <td class="px-6 py-4 text-sm text-gray-700">UpSwap web</td>
//             <td class="px-6 py-4">
//               <span class="px-3 py-1 text-sm text-white bg-yellow-500 rounded-full">
//                 Processing
//               </span>
//             </td>
//           </tr>
//         </tbody>
//       </table>
//     </div>
//   </div>
// );

// export default RecruiterDashboard;
