import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo jiva hire.png";

function Navbar() {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full  bg-[#FFFFFF] shadow-sm  px-4  border-b border-b-[#EEEEEE] text-xs font-inter">
        <div className="w-full  mx-auto max-w-screen-2xl flex justify-between items-center py-1 flex-wrap">
          <div
            className="w-[120px] h-[40px] cursor-pointer"
            onClick={() => navigate("/recruiter/login")}
          >
            <img className="h-full w-full" src={logo} alt="" />
          </div>

          <div className="flex justify-between items-center gap-2">
            <Link
              to={"/recruiter/register-user"}
              className="min-w-[80px]  font-semibold py-2 flex justify-center items-center border border-[#373737] rounded-3xl"
            >
              Free Trial
            </Link>
            <Link
              to={"/recruiter/login"}
              className="min-w-[80px]  font-semibold py-2 bg-[#373737] text-white flex justify-center items-center border border-[#373737] rounded-3xl"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
