import React, { useEffect, useRef, useState } from "react";
import { BsSend } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
// import { axiosFromRequest } from "../../api/formApi";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { axiosRequest } from "../../api/api";
import { setRefresh } from "../../features/refresh/refresh";
import { commentSchema } from "../../schemas/recruiterSchema";
import EditJob from "./CreateJob/Edit/EditCreateJob";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import { Link } from "react-router-dom";
import ATMMultiSelect from "../../atoms/ATMMultiSelect/ATMMultiSelect";
import apiSlice from "../../app/ApiSlice";
import { formatDate } from "../candidate/ProfileDetails/View/ProfileDetails";
import { useGetJobByIdQuery } from "../../app/DashboardServices/DashboardServices";
import ATMPagination from "../../atoms/ATMPagination/ATMPagination";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function RecruiterView() {
  const recruiter = localStorage.getItem("recuriterProfile");
  const refresh = useSelector((state) => state.refresh.refresh);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [commentData, setCommentData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [jobData, setJobData] = useState();
  const [refreshData, setRefreshData] = useState(false);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const recruitertype = recruiter ? JSON.parse(recruiter) : [];
  const { data: jobDis, isLoading, refetch } = useGetJobByIdQuery(id);

  useEffect(() => {
    refetch();
  }, [edit, refetch]);
  useEffect(() => {
    setJobData(jobDis?.data?.job);
    setIsActive(jobDis?.data?.job?.status);
  }, [jobDis, edit]);

  const [isActive, setIsActive] = useState(jobData);
  const [selectOpen, setSelectOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [emails, setEmails] = useState([]);

  const toggle = async () => {
    if (
      recruitertype?.IS_SUPERUSER ||
      recruitertype?.email === jobData?.account_manager?.email
    ) {
      try {
        const newStatus = !isActive;
        const response = await axiosRequest(
          `${baseUrl}/api/recruiter/job/${jobData?.id}/`,
          {
            method: "PATCH",
            data: {
              status: newStatus,
            },
          }
        );

        if (response.status === 200) {
          setIsActive(newStatus);
          dispatch(apiSlice.util.invalidateTags(["dashboard"]));
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          // toast.error("An unexpected error occurred");
        }
      }
    } else {
      toast.error("You are Not Job Owner or SuperAdmin");
    }
  };

  const manageEmail = async (el) => {
    if (
      recruitertype?.IS_SUPERUSER ||
      recruitertype?.email === jobData?.account_manager?.email
    ) {
      try {
        const response = await axiosRequest(
          `${baseUrl}/api/recruiter/manage_emails/`,
          {
            method: "POST",
            data: {
              job_id: jobData?.id,
              email: el,
            },
          }
        );
        if (response.status === 200) {
          toast.success(response?.data?.message);
          dispatch(apiSlice.util.invalidateTags(["dashboard"]));
          refetch();
          setRefreshData(!refreshData);
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      }
    } else {
      toast.error("You are Not Job Owner or SuperAdmin");
    }
  };

  const handleInvite = async () => {
    setSubmitting(true);
    try {
      const response = await axiosRequest(
        `${baseUrl}/api/recruiter/sendinvite/`,
        // `${baseUrl}/api/recruiter/sendinvite/${emails?.map((el)=> el?.label)}/`,
        {
          method: "POST",
          data: { emails: emails?.map((el) => el?.label), job_id: id },
        }
      );
      if (response.success || response?.data?.success) {
        dispatch(apiSlice.util.invalidateTags(["dashboard"]));
        refetch();
        setTimeout(() => {
          if (response?.data?.self_invites?.length > 0) {
            toast.error(
              "Unable to invite job owner: " +
                response.data.self_invites.map((el) => el)
            );
          }
        }, 100);

        setTimeout(() => {
          if (response?.data?.invalid_emails?.length > 0) {
            toast.error(
              "Invalid Emails: " + response.data.invalid_emails.join(", ")
            );
          }
        }, 300);

        setTimeout(() => {
          if (response?.data?.invited_users?.length > 0) {
            toast.success(
              "User successfully invited: " +
                response.data.invited_users.map((el) => el)
            );
          }
        }, 500);

        setTimeout(() => {
          if (response?.data?.existing_users?.length > 0) {
            toast.success(
              `User exists: ${response.data.existing_users.join(", ")}`
            );
          }
        }, 700);
        setSubmitting(false);
        setSelectOpen(false);
        setEmails([]);
        setRefreshData(!refreshData);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
      }
    } finally {
      setSubmitting(false);
    }
  };
  const parts = jobData?.description?.split("\n");

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 8,
  });

  useEffect(() => {
    const fetchComment = async () => {
      try {
        const response = await axiosRequest(
          `${baseUrl}/api/recruiter/job-comments/${id}/?page=${pagination?.currentPage}&page_size=${pagination?.itemsPerPage}`,
          {
            method: "GET",
          }
        );

        if (response.status === 200) {
          setCommentData(response?.data);
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      }
    };

    fetchComment();
  }, [refresh, pagination, id]);

  const formik = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: commentSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await axiosRequest(
          `${baseUrl}/api/recruiter/comment/`,
          {
            method: "POST",
            data: {
              body: values.comment,
              job: jobData?.id,
            },
          }
        );

        if (response.status === 201) {
          resetForm();
          dispatch(setRefresh(!refresh));
          setSubmitting(false);
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const formikEdit = useFormik({
    initialValues: {
      comment: "",
    },
    validationSchema: commentSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axiosRequest(
          `${baseUrl}/api/recruiter/comment/${editingCommentId}/`,
          {
            method: "PATCH",
            data: {
              body: values.comment,
              job: jobData?.id,
            },
          }
        );

        if (response.status === 200) {
          resetForm();
          dispatch(setRefresh(!refresh));
          setEditingCommentId(null);
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      }
    },
  });

  const handleEditComment = (commentId) => {
    setEditingCommentId(commentId);
    const commentToEdit = commentData?.results?.find(
      (comment) => comment.id === commentId
    );
    if (commentToEdit) {
      formikEdit?.setValues({ comment: commentToEdit.body });
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await axiosRequest(
        `${baseUrl}/api/recruiter/comment/${commentId}/`,
        {
          method: "DELETE",
          data: {
            job: jobData?.id,
          },
        }
      );

      if (response.status === 204) {
        dispatch(setRefresh(!refresh));
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        // toast.error("An unexpected error occurred");
      }
    }
  };
  const [isOpen, setIsOpen] = useState();
  const [jobUrl, setJobUrl] = useState();
  const handleInviteToApply = async () => {
    try {
      const response = await axiosRequest(
        `${baseUrl}/api/recruiter/invite_apply/`,
        {
          method: "POST",
          data: {
            job_id: jobData?.id,
          },
        }
      );

      if (response.status === 200) {
        setJobUrl(response?.data?.link);
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleItemsPerPageChange = (itemsPerPage) => {
    setPagination((prev) => ({ ...prev, itemsPerPage, currentPage: 1 })); // Reset to page 1 when changing items per page
  };
  const [isCopy, setIsCopy] = useState("Copy");

  const handleCopy = () => {
    if (isCopy === "Copy") {
      const textToCopy = jobUrl;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => setIsCopy("Copied"))
        .catch((err) => {});
    } else {
      navigator.clipboard
        .writeText("")
        .then(() => setIsCopy("Copy"))
        .catch((err) => {});
    }
  };
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <>
      <div className="w-full  flex flex-col gap-8">
        {!isLoading ? (
          <div>
            {!edit && (
              <div>
                {jobData ? (
                  <div className="bg-[#4958AB] w-full p-4 flex gap-3 text-xs font-inter">
                    <div className=" flex flex-col gap-6 w-full">
                      <div className="flex justify-between items-start ">
                        <div className="flex flex-col gap-3">
                          <h1 className="font-inter font-semibold text-xl text-white flex items-center gap-4">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6  cursor-pointer"
                              onClick={handleBack}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                              />
                            </svg>
                            Full Job Description
                          </h1>
                          <div className="bg-[#D9D9D93D] w-fit p-1 px-3 font-inter font-semibold text-white rounded-md">
                            {jobData?.applicant_count} Applicants
                          </div>
                        </div>
                        <div className="flex gap-3">
                          <div
                            className="flex border border-white p-3 px-5 rounded-md gap-4 font-inter font-normal text-white cursor-pointer items-center"
                            onClick={() => {
                              if (
                                recruitertype?.IS_SUPERUSER ||
                                recruitertype?.email ===
                                  jobData?.account_manager?.email
                              ) {
                                setEdit(true);
                              } else {
                                toast.error(
                                  "You are Not Job Owner or SuperAdmin"
                                );
                              }
                            }}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.5 18.2919L6.48285 17.1195L17.3782 6.22413C17.836 5.76626 17.836 5.02392 17.3782 4.56605L15.2258 2.41372C14.768 1.95584 14.0256 1.95584 13.5678 2.41372L2.67244 13.309L1.5 18.2919Z"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M18.5002 18.291H12.0518"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Edit
                          </div>
                          <div className="relative">
                            <div
                              onClick={() => setSelectOpen(true)}
                              className="cursor-pointer flex border border-white p-3 px-5 rounded-md gap-4 font-inter font-normal text-white items-center"
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.5 18.2919L6.48285 17.1195L17.3782 6.22413C17.836 5.76626 17.836 5.02392 17.3782 4.56605L15.2258 2.41372C14.768 1.95584 14.0256 1.95584 13.5678 2.41372L2.67244 13.309L1.5 18.2919Z"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.5002 18.291H12.0518"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              Invite To Manage
                            </div>
                          </div>
                          {selectOpen && (
                            <div className="fixed inset-0 z-10  bg-black bg-opacity-50 flex justify-center items-center">
                              <div className="flex flex-col z-50  bg-white   w-[600px] gap-4 p-4 rounded-xl">
                                <div className="w-full">
                                  <ATMMultiSelect
                                    value={emails}
                                    onChange={(newValue) => setEmails(newValue)}
                                    options={[]}
                                    label="Emails"
                                    placeholder="Enter Email"
                                  />
                                </div>

                                <div className="flex justify-end gap-4 items-center">
                                  <button
                                    onClick={() => setSelectOpen(false)}
                                    className="w-[97px] h-[38px]   text-red-500 border rounded-md"
                                    type="button"
                                  >
                                    Close
                                  </button>
                                  <button
                                    onClick={handleInvite}
                                    className="w-[97px] h-[38px] rounded-md bg-[#1CACBD] text-white"
                                    type="button"
                                    disabled={emails.length === 0}
                                  >
                                    {submitting ? (
                                      <div className="flex justify-center">
                                        <ATMCircularProgress
                                          size="small"
                                          color="white"
                                        />
                                      </div>
                                    ) : (
                                      "Invite"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="font-inter flex justify-start gap-12">
                        <div className="text-white flex flex-col gap-2">
                          <h1 className=" font-semibold">Status</h1>
                          <div
                            className={`w-24 relative h-10 flex items-center rounded-full p-1 cursor-pointer ${
                              isActive ? "bg-[#15DB35]" : "bg-gray-300"
                            }`}
                            onClick={toggle}
                          >
                            <div
                              className={`bg-white w-8 h-8 rounded-full shadow-md transform duration-500 ease-in-out ${
                                isActive ? "translate-x-14" : ""
                              }`}
                            ></div>
                            <span
                              className={`text-white absolute font-inter font-medium left-2 ${
                                isActive ? "" : "hidden"
                              }`}
                            >
                              Active
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="font-inter flex justify-start  flex-wrap gap-12">
                        <div className="text-white flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                          <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                            Job Title
                          </h1>
                          <h1 className="font-medium p-2 ">{jobData?.title}</h1>
                        </div>
                        <div className="text-white flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                          <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                            Job ID
                          </h1>
                          <h1 className="font-medium p-2 ">{jobData?.id}</h1>
                        </div>
                        <div className="text-white flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                          <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                            Date Posted
                          </h1>
                          <h1 className="font-medium p-2 ">
                            {" "}
                            {formatDate(jobData?.created_at, true)}
                          </h1>
                        </div>
                      </div>

                      <div className="font-inter flex justify-start gap-12">
                        <div className="text-white flex flex-col gap-2">
                          <h1 className=" font-semibold">Summary</h1>
                          {parts?.map((part, index) => (
                            <div key={index}>{part}</div>
                          ))}
                        </div>
                      </div>
                      <div className="border-t "></div>
                      <div className="font-inter flex  gap-8 flex-wrap ">
                        <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                          <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                            Job Owner
                          </h1>
                          <h1 className="font-medium p-2 ">
                            {jobData?.account_manager?.email}
                          </h1>
                        </div>
                        <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                          <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                            Location
                          </h1>
                          <h1 className="font-medium p-2 ">
                            {jobData?.country}
                            {jobData?.state && (
                              <span>
                                ,{jobData?.state}, {jobData?.city}
                              </span>
                            )}
                          </h1>
                        </div>
                        <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                          <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                            Salary Range
                          </h1>
                          <h1 className="font-medium p-2 ">
                            {jobData?.salary_from === 0 ? (
                              "--"
                            ) : (
                              <p>
                                {" "}
                                {jobData?.currency} {jobData?.salary_from} -
                                {jobData?.salary_to} {jobData?.salary_type}
                              </p>
                            )}
                          </h1>
                        </div>
                        <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                          <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                            Number Vacancies
                          </h1>
                          <h1 className="font-medium p-2 ">
                            {jobData?.vacancies}
                          </h1>
                        </div>

                        <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                          <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                            Client Name
                          </h1>
                          <h1 className="font-medium p-2 ">
                            {jobData?.external_company
                              ? jobData.company_name
                              : jobData?.company?.company_name}
                          </h1>
                        </div>
                        <div className="text-white flex flex-col gap-2 w-fit min-w-[200px]  border rounded-md text-start ">
                          <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm text-center">
                            Managed By
                          </h1>
                          <h1 className="font-medium py-2 flex flex-col break-words px-4 gap-2">
                            {jobData?.managed_by?.emails?.map((el) => (
                              <div className="flex items-center gap-2 border border-slate-400 rounded-md py-1 px-2 justify-between">
                                <div>{el}</div>
                                {recruitertype?.IS_SUPERUSER ||
                                recruitertype?.email ===
                                  jobData?.account_manager?.email ? (
                                  <div
                                    className="bg-slate-400 text-red-950 px-1 rounded-full hover:bg-red-100 cursor-pointer "
                                    onClick={() => manageEmail(el)}
                                  >
                                    x
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </h1>
                        </div>
                      </div>

                      {isActive && (
                        <div className="flex items-center gap-4">
                          <Link
                            to={`/recruit/job-status/invite-candidate/${jobData?.id}/`}
                            className="w-fit mt-4 flex gap-1 text-[#4958AB] bg-white px-[12px] py-3 rounded-lg font-inter font-semibold justify-center items-center"
                          >
                            Invite Candidates for Interview
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.88477 1.88281L10.2001 6.00202L5.88477 10.1212"
                                stroke="#4958AB"
                                strokeWidth="1.28571"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.2002 6.00195L1.80029 6.00195"
                                stroke="#4958AB"
                                strokeWidth="1.28571"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Link>

                          <div ref={buttonRef} className="relative">
                            <div
                              onClick={() => {
                                setIsOpen(!isOpen);
                                handleInviteToApply();
                              }}
                              className="w-fit mt-4 flex gap-1 text-[#4958AB] bg-white px-[12px] py-3 cursor-pointer rounded-lg font-inter font-semibold justify-center items-center"
                            >
                              Share JD
                            </div>
                            {isOpen && (
                              <div className="bg-blue-50 shadow-sm left-0  min-w-full z-20 border flex flex-col p-2  justify-between rounded-md absolute">
                                <div className="border rounded-md overflow-hidden justify-between gap-1 w-[500px]  flex">
                                  <input
                                    className="w-full outline-none p-2"
                                    type="text"
                                    value={jobUrl}
                                  />
                                  <button
                                    type="button"
                                    className="bg-black px-2  text-white"
                                    onClick={handleCopy}
                                  >
                                    {isCopy}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="text-center py-8">
                    <p className="text-xl text-gray-500">No data found</p>
                  </div>
                )}
                <div className="bg-[#F7F8FD] w-full py-5 px-2 flex flex-col gap-3">
                  <div>
                    <h1 className="font-inter font-semibold text-2xl">
                      Comments ({commentData?.count})
                    </h1>
                  </div>

                  <form onSubmit={formik.handleSubmit} className="relative">
                    <input
                      type="text"
                      placeholder="Add comment ...."
                      value={formik.values.comment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="comment"
                      name="comment"
                      className="rounded-lg p-3 border-[#D0D5DD] shadow-input bg-[#FFFFFF] border w-full outline-none"
                    />
                    <button
                      type="submit"
                      className="absolute top-1/2 right-3 transform -translate-y-1/2 text-2xl text-[#1CACBD]"
                      disabled={formik?.isSubmitting}
                    >
                      {formik?.isSubmitting ? (
                        <ATMCircularProgress />
                      ) : (
                        <BsSend />
                      )}
                    </button>
                  </form>

                  {commentData?.results?.map((comment, index) => (
                    <div
                      key={index}
                      className={`${
                        editingCommentId === comment?.id
                          ? "bg-white border border-[#4958AB99] "
                          : "border-b"
                      } p-2 rounded-lg`}
                    >
                      <div>
                        <div className="flex justify-between items-center">
                          <div className="rounded-full flex items-center gap-3 font-inter">
                            <div className="uppercase  bg-blue-50 font-semibold text-[#1CACBD] border rounded-full w-9 h-9 flex justify-center items-center  ">
                              <span className="">
                                {comment?.owner?.first_name?.charAt(0)}
                              </span>
                              <span className="">
                                {comment?.owner?.last_name?.charAt(0)}
                              </span>
                            </div>
                            <p className="text-[#313B72] font-semibold">
                              {comment?.owner?.first_name}{" "}
                              {comment?.owner?.last_name}
                            </p>
                            <span className="text-[#00000066] font-inter font-semibold">
                              {formatDate(comment?.created_at, true)}
                            </span>
                          </div>

                          {recruitertype?.email === comment?.owner?.email && (
                            <div className="flex justify-center items-center gap-3">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={() => handleEditComment(comment?.id)}
                                xmlns="http://www.w3.org/2000/svg"
                                className="cursor-pointer"
                              >
                                <path
                                  d="M11 4.00023H4C3.46957 4.00023 2.96086 4.21094 2.58579 4.58601C2.21071 4.96109 2 5.46979 2 6.00023V20.0002C2 20.5307 2.21071 21.0394 2.58579 21.4144C2.96086 21.7895 3.46957 22.0002 4 22.0002H18C18.5304 22.0002 19.0391 21.7895 19.4142 21.4144C19.7893 21.0394 20 20.5307 20 20.0002V13.0002M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                                  stroke="#6D758F"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>

                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                onClick={() => handleDeleteComment(comment?.id)}
                                className="cursor-pointer text-red-400"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M10 11V17M14 11V17"
                                  stroke="#6D758F"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                        <form onSubmit={formikEdit.handleSubmit}>
                          <div className="pt-4">
                            {editingCommentId === comment?.id ? (
                              <textarea
                                name="comment"
                                id="comment"
                                className="border rounded-md w-full px-3 py-1 resize-none outline-none"
                                value={formikEdit.values.comment}
                                onChange={formikEdit.handleChange}
                                onBlur={formikEdit.handleBlur}
                                placeholder="Edit comment"
                              ></textarea>
                            ) : (
                              <span className="ml-12">{comment?.body}</span>
                            )}
                          </div>

                          <div className="flex justify-end items-center py-1">
                            {editingCommentId === comment?.id ? (
                              <div className="flex gap-3">
                                <button
                                  onClick={handleCancelEdit}
                                  className="border border-[#1CACBD] text-[#1CACBD] p-2 font-inter font-semibold rounded-lg px-4 select-none"
                                >
                                  Cancel
                                </button>
                                <button className="border border-[#1CACBD] text-white bg-[#1CACBD] p-2 font-inter font-semibold rounded-lg px-4">
                                  Update
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </form>
                      </div>
                    </div>
                  ))}

                  {commentData?.results?.length > 0 && (
                    <div className="flex gap-2 justify-end items-center text-center">
                      <ATMPagination
                        totalItems={commentData?.count}
                        itemsPerPage={pagination.itemsPerPage}
                        currentPage={pagination.currentPage}
                        onPageChange={handlePageChange}
                        itemsPerPageOptions={[2, 4, 8, 12, 16, 20, 24, 48, 96]}
                        onItemsPerPageChange={handleItemsPerPageChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            {edit && (
              <div className="   bg-white px-2">
                <EditJob jobData={jobData} setEdit={setEdit} />
              </div>
            )}
          </div>
        ) : (
          <div className=" h-screen flex justify-center  items-center">
            <ATMCircularProgress />
          </div>
        )}
      </div>
    </>
  );
}

export default RecruiterView;
