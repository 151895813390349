import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldLabel from "../../../atoms/ATMFieldLabel/ATMFieldLabel";
import { formatDate } from "../ProfileDetails/View/ProfileDetails";
import Navbar from "../../../components/candidate/Navbars/Navbar";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const CandidateJobView = () => {
  const navigate = useNavigate();
  const [jobData, setJobData] = useState();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const job_id = searchParams.get("job_id");
  const code = searchParams.get("code");
  useEffect(() => {
    const getAllJobs = async () => {
      setLoading(true);

      try {
        const url = `${baseUrl}/api/recruiter/job_view/${id}/${job_id}/?supersecret=${code}`;

        const response = await axios.get(url);

        if (response.status === 200) {
          setJobData(response?.data?.data);
        } else {
          toast.error("Failed to get data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    getAllJobs();
  }, [id , code, job_id]);

  const handleApply = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/api/recruiter/job_apply/${id}/${job_id}/${email}/?supersecret=${code}`
      );

      if (response.status === 200) {
        toast.success(response?.data?.message);
        navigate("/candidate/login");
      } else if (response.status === 400) {
        toast.error("Failed to apply");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const parts = jobData?.job?.description?.split("\n");

  return (
    <div className="h-svh ">
      <Navbar loginbutton={false} />
      {loading ? (
        <div className="min-h-full  flex justify-center  items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        <>
          <div className="">
            <div className="bg-[#4958AB] mx-auto max-w-screen-2xl w-full p-4 font-inter text-xs flex gap-3">
              <div className=" flex flex-col gap-6">
                <div className="flex justify-between items-start">
                  <div className="flex flex-col gap-3">
                    <h1 className="font-inter font-semibold text-xl text-white flex items-center gap-4">
                      {jobData?.job?.title}
                    </h1>
                  </div>
                </div>
                <div className="font-inter flex justify-start  flex-wrap gap-12">
                  <div className="text-white flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Job ID
                    </h1>
                    <h1 className="font-medium p-2 ">{jobData?.job?.id}</h1>
                  </div>
                  <div className="text-white flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Date Posted
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {" "}
                      {formatDate(jobData?.job?.created_at, true)}
                    </h1>
                  </div>
                </div>
                <div className="font-inter flex justify-start gap-12">
                  <div className="text-white flex flex-col gap-2">
                    <h1 className=" font-semibold">Summary</h1>
                    {parts?.map((part, index) => (
                      <div key={index}>{part}</div>
                    ))}
                  </div>
                </div>
                <div className="border-t "></div>
                <div className="font-inter flex  gap-8 flex-wrap ">
                  <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Job Owner
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {jobData?.job?.account_manager?.email}
                    </h1>
                  </div>
                  <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Location
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {jobData?.job?.country}
                      {jobData?.job?.state && (
                        <span>
                          ,{jobData?.job?.state}, {jobData?.job?.city}
                        </span>
                      )}
                    </h1>
                  </div>
                  <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Salary Range
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {jobData?.job?.salary_from === 0 ? (
                        "--"
                      ) : (
                        <p>
                          {" "}
                          {jobData?.job?.currency} {jobData?.job?.salary_from} -
                          {jobData?.job?.salary_to} {jobData?.job?.salary_type}
                        </p>
                      )}
                    </h1>
                  </div>
                  <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Number Vacancies
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {jobData?.job?.vacancies}
                    </h1>
                  </div>

                  <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Client Name
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {jobData?.job?.external_company
                        ? jobData?.job.company_name
                        : jobData?.job?.company?.company_name}
                    </h1>
                  </div>
                </div>
                <div className="w-full text-white ">
                  <ATMFieldLabel>
                    {" "}
                    Please fill your email and apply for this job
                  </ATMFieldLabel>
                  <div className="relative mt-2 w-[300px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="text-gray-400 pointer-events-none  absolute top-2 left-2"
                      style={{ height: 24, width: 24 }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                      />
                    </svg>
                    <input
                      size="lg"
                      placeholder="olivia@untitledui.com"
                      className="pl-10 w-full border outline-none p-3 rounded-lg bg-transparent"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                    />
                  </div>
                </div>
                <div className=" flex gap-3 items-center">
                  <button
                    disabled={!email}
                    onClick={handleApply}
                    className="  flex gap-1 text-[#4958AB] bg-white h-[40px] w-[150px] disabled:bg-slate-400 rounded-lg font-inter font-semibold justify-center items-center"
                  >
                    {isloading ? (
                      <ATMCircularProgress size="small" />
                    ) : (
                      <span className="flex  items-center gap-1">
                        {" "}
                        Apply Now
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.88477 1.88281L10.2001 6.00202L5.88477 10.1212"
                            stroke="#4958AB"
                            strokeWidth="1.28571"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.2002 6.00195L1.80029 6.00195"
                            stroke="#4958AB"
                            strokeWidth="1.28571"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    )}
                  </button>
                  {/* <Link
                    to={`/candidate/apply-details/${id}/${jobData?.job?.company?.id}`}
                    className="w-fit  flex gap-1 text-[#4958AB] bg-white px-[12px] py-3 rounded-lg font-inter font-semibold justify-center items-center"
                  >
                    Apply Now
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.88477 1.88281L10.2001 6.00202L5.88477 10.1212"
                        stroke="#4958AB"
                        strokeWidth="1.28571"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.2002 6.00195L1.80029 6.00195"
                        stroke="#4958AB"
                        strokeWidth="1.28571"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CandidateJobView;
