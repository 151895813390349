import React from 'react';
import styles from './SupportPage.module.css';

const SettingsPage = () => {
  return (
    <div className={styles.settingsPage}>
      <header className={styles.settingsHeader}>
        <h1>Settings</h1>
      </header>

      <section className={styles.profileSettings}>
        <h2>Profile Settings</h2>
        <form>
          <input type="text" placeholder="Full Name" required />
          <input type="email" placeholder="Email" required />
          <input type="text" placeholder="Username" required />
          <button type="submit">Update Profile</button>
        </form>
      </section>

      <section className={styles.accountSettings}>
        <h2>Account Settings</h2>
        <form>
          <label>
            Change Password
            <input type="password" placeholder="New Password" required />
          </label>
          <label>
            Privacy Settings
            <select>
              <option>Public</option>
              <option>Private</option>
            </select>
          </label>
          <label>
            Notifications
            <input type="checkbox" /> Enable Email Notifications
          </label>
          <button type="submit">Save Settings</button>
        </form>
      </section>

      <section className={styles.applicationSettings}>
        <h2>Application Settings</h2>
        <div>
          <label>
            Language
            <select>
              <option>English</option>
              <option>Spanish</option>
              <option>French</option>
            </select>
          </label>
          <label>
            Timezone
            <select>
              <option>GMT</option>
              <option>EST</option>
              <option>PST</option>
            </select>
          </label>
        </div>
      </section>

      <footer className={styles.settingsFooter}>
        <p>© 2024 Jivahire.com All rights reserved.</p>
        <nav>
          <a href="/#">Privacy Policy</a>
          <a href="/#">Terms of Service</a>
        </nav>
      </footer>
    </div>
  );
};

export default SettingsPage;
