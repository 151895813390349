import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
function ProtectedRoute({ Component }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const name = pathname.split("/").slice(1, 2)[0];
  const accessToken = localStorage.getItem("accessToken");
  const candidateaccessToken = localStorage.getItem("candidateaccessToken");
  useEffect(() => {
    if (!accessToken && name === "recruit") {
      navigate("/recruiter/login");
    } else if (!candidateaccessToken && name === "candidate") {
      navigate("/candidate/login");
    }
  }, [accessToken , name , candidateaccessToken , navigate]);
  return <Component />;
}

export default ProtectedRoute;
