import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  candidateData: null,
};

export const candidateDataSlice = createSlice({
  name: "candidateData",
  initialState,
  reducers: {
    setCandidateData(state, action) {
      state.candidateData = action.payload;
    },
  },
});

export const { setCandidateData } = candidateDataSlice.actions;
export default candidateDataSlice.reducer;
