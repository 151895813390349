import React from "react";
import Navbar from "../../components/candidate/Navbars/Navbar.jsx";
import { Outlet } from "react-router";
import { Toaster } from "react-hot-toast";

function Root() {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          duration: 10000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 10000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div className="w-full flex flex-col min-h-screen ">
        <Navbar />
        <div className="mx-auto max-w-screen-2xl w-full ">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Root;
