import axios from "axios";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import apiSlice from "../../app/ApiSlice";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import HeroHeading from "../../components/recruiters/HeroHeading";
import { loginSchema } from "../../schemas/schema";

const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;
function SignIn() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: loginSchema,

    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await axios.post(
          `${baseUrl}/api/recruiter/login/`,
          {
            email: values.email,
            password: values.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          // toast.success("Login successful!");
          resetForm();
          dispatch(apiSlice.util.invalidateTags(["dashboard"]));
          const { access, refresh, user_type, user } = response.data.data;
          localStorage.setItem("accessToken", access);
          localStorage.setItem("refreshToken", refresh);
          localStorage.setItem("userType", user_type);
          localStorage.setItem("recuriterProfile", JSON.stringify(user));
          localStorage.setItem("recruiter_id", user.id || "");
          Cookies.set("accessToken", access, {
            secure: true,
            sameSite: "Strict",
          });
          Cookies.set("userType", user_type, {
            secure: true,
            sameSite: "Strict",
          });
          navigate("/recruit/dashboard");
        } else if (response.status === 400) {
          toast.error(
            "Invalid credentials. Please check your email and password."
          );
        } else {
          toast.error(`Unexpected error occurred: ${response.statusText}`);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message || "Invalid credentials.");
          } else if (error.response.status === 401) {
            toast.error("Unauthorized. Please check your credentials.");
          } else if (error.response.status === 500) {
            toast.error("Internal server error. Please try again later.");
          } else {
            toast.error(
              `Error: ${error.response.status} - ${
                error.response.data.message || "Unknown error"
              }`
            );
          }
        } else if (error.request) {
          toast.error("Network error. Please check your internet connection.");
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div className=" w-full px-4 pb-4 h-full bg-white  text-xs font-inter">
        <div className="pb-2">
          <HeroHeading
            heading="Login to our portal as Recruiter"
            para="Welcome to our streamlined portal, where each login propels you into a world of effortless HR management."
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 items-start  select-none gap-2 h-fit">
          <div className=" h-full">
            <img
              src="https://jivahire-assets.b-cdn.net/Login%20Screen/1x/IMG%201_Login_Recruiter.png"
              alt="Recruiter Login"
              className="rounded-lg object-cover w-full h-full  md:block hidden md:h-[360px] lg:h-[360px] "
            />
          </div>
          <div className=" flex flex-col h-full">
            <form onSubmit={formik.handleSubmit}>
              <div className="border border-[#E2DCEA] flex flex-col gap-4 p-5 rounded-lg w-full">
                <div className="w-full">
                  <ATMFieldLabel required>Email ID</ATMFieldLabel>
                  <div className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="absolute top-2 left-3 text-gray-400 pointer-events-none"
                      style={{ height: 24, width: 24 }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                      />
                    </svg>
                    <input
                      size="lg"
                      placeholder="olivia@untitledui.com"
                      className=" pl-10 w-full border  outline-none p-3 rounded-lg"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="email"
                      name="email"
                    />
                  </div>
                  {formik.errors.email && formik.touched.email ? (
                    <ATMFieldError>{formik.errors.email}</ATMFieldError>
                  ) : null}
                </div>
                <div className="w-full">
                  <ATMFieldLabel required>Your Password</ATMFieldLabel>
                  <div className="relative">
                    <input
                      size="lg"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      className=" w-full border  outline-none p-3 rounded-lg"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="password"
                      name="password"
                    />
                    <div
                      onClick={handleShowPassword}
                      className="absolute top-1/2 transform -translate-y-1/2 text-2xl right-3 cursor-pointer"
                    >
                      {showPassword ? <IoMdEye /> : <IoMdEyeOff />}
                    </div>
                  </div>
                  {formik.errors.password && formik.touched.password ? (
                    <ATMFieldError>{formik.errors.password}</ATMFieldError>
                  ) : null}
                </div>
                <div className="w-full flex justify-between items-center">
                  <div className="w-fit flex justify-between items-center gap-1 font-medium">
                    <input
                      type="checkbox"
                      checked={formik.values.rememberMe}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="rememberMe"
                    />{" "}
                    Remember Me
                  </div>
                  <Link
                    to="/recruiter/forget-password"
                    className="capitalize text-[#444CE7] font-normal"
                  >
                    forget your password?
                  </Link>
                </div>
                <button
                  type="submit"
                  className="w-[100px] flex gap-2 text-white bg-[#1CACBD] py-2 rounded-lg font-semibold justify-center items-center"
                >
                  {formik.isSubmitting ? (
                    <ATMCircularProgress size="small" color="white" />
                  ) : (
                    <div className=" flex items-center gap-1">
                      Login
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </span>
                    </div>
                  )}
                </button>
              </div>
              <div className="p-5">
                <p className="font-normal text-[#667085]">
                  If you don’t have an account yet
                  <Link to="/recruiter/register" className="text-[#444CE7]">
                    {" "}
                    Register as Corporate Super Admin{" "}
                  </Link>
                  <strong>or</strong>
                  <br />
                  <Link
                    to="/recruiter/register-user"
                    className="text-[#444CE7]"
                  >
                    Register as Corporate User{" "}
                  </Link>
                </p>
              </div>
              <div className="font-inter text-xs px-5">
                <ATMFieldLabel>Want to reach out directly?</ATMFieldLabel>
                <div className="text-slate-600">
                  We're here to help!{" "}
                  <span
                    onClick={() => navigate("/recruiter/support")}
                    className="text-blue-500 cursor-pointer"
                  >
                    Contact our support
                  </span>{" "}
                  team with any questions.
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
