import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const formatDate = (date) => {
  if (!date) return "";
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(date).toLocaleDateString(undefined, options);
};

// Helper Functions
const getToday = () => {
  const now = new Date();
  return { startDate: now, endDate: now };
};

const getYesterday = () => {
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  return { startDate: yesterday, endDate: yesterday };
};

const getLastNDays = (days) => {
  const now = new Date();
  const start = new Date(now);
  start.setDate(now.getDate() - days);
  return { startDate: start, endDate: now };
};

const getLastNYears = (years) => {
  const now = new Date();
  const start = new Date(now);
  start.setFullYear(now.getFullYear() - years);
  return { startDate: start, endDate: now };
};

const ATMDateRangePicker = ({
  startDate,
  endDate,
  onSelectStartDate,
  onSelectEndDate,
  onClear,
}) => {
  const [datePortalOpen, setDatePortalOpen] = useState(false);
  const buttonRef = useRef(null);
  const dateRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        dateRef.current &&
        !dateRef.current.contains(event.target)
      ) {
        setDatePortalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleQuickPicker = (start, end) => {
    onSelectStartDate(start);
    onSelectEndDate(end);
    setDatePortalOpen(false);
  };
  const quickPickerOptions = [
    { label: "Today", ...getToday() },
    { label: "Yesterday", ...getYesterday() },
    { label: "Last 7 Days", ...getLastNDays(7) },
    { label: "Last 30 Days", ...getLastNDays(30) },
    { label: "Last 60 Days", ...getLastNDays(60) },
    { label: "Last 90 Days", ...getLastNDays(90) },
    { label: "Last 1 Year", ...getLastNYears(1) },
  ];

  return (
    <div className="relative font-inter text-xs">
      <div
        className={`flex gap-2  px-2 border  rounded-md h-[40px] w-full bg-white hover:bg-slate-50 items-center ${
          (startDate || endDate) && "border-[#1CACBD]"
        } `}
      >
        {(startDate || endDate) && (
          <div
            className=" text-lg absolute top-[-9px] border-red-400 bg-white cursor-pointer text-red-400 border w-5 h-5 text-center flex items-center justify-center rounded-full right-[-7px] z-auto"
            onClick={onClear}
          >
            x
          </div>
        )}

        <div
          ref={buttonRef}
          onClick={() => setDatePortalOpen((prev) => !prev)}
          className="flex items-center gap-1 py-1 cursor-pointer text-xs text-slate-400   p-2 rounded-md"
        >
          <span>Select Date</span>
        </div>

        {(startDate || endDate) && (
          <div className="flex gap-1 pl-2 border-l">
            <span className="px-1 text-xs rounded text-primary-30 bg-primary-95">
              {startDate ? formatDate(startDate) : "From"}
            </span>
            -
            <span className="px-1 text-xs rounded text-primary-30 bg-primary-95">
              {endDate ? formatDate(endDate) : "To"}
            </span>
          </div>
        )}
      </div>

      {datePortalOpen && (
        <div
          ref={dateRef}
          className="absolute right-0  mt-1 bg-white border border-gray-300 rounded-md z-50 "
        >
          <div className=" grid grid-cols-1 md:grid-cols-3">
            {/* Quick Picker */}
            <ul className="p-2 border-r w-32">
              {quickPickerOptions.map((option, index) => {
                const isSelected =
                  option.startDate.getTime() === startDate?.getTime() &&
                  option.endDate.getTime() === endDate?.getTime();
                return (
                  <li
                    key={index}
                    onClick={() =>
                      handleQuickPicker(option.startDate, option.endDate)
                    }
                    className={`cursor-pointer px-2 py-1.5 rounded-md text-sm text-[#1CACBD] transition-colors  duration-300 font-medium ${
                      isSelected ? "bg-blue-50" : ""
                    }`}
                  >
                    {option.label}
                  </li>
                );
              })}
            </ul>

            {/* Date Pickers */}
            <div className=" md:col-span-2 p-4 gap-4 flex flex-col md:flex-row">
              <div className="flex flex-col">
                <label className="text-sm text-slate-700">From</label>
                <ReactDatePicker
                  selected={startDate ? new Date(startDate) : null}
                  onChange={onSelectStartDate}
                  selectsStart
                  inline
                  calendarClassName="!border-none !rounded-none"
                />
              </div>

              <div className="flex flex-col">
                <label className="text-sm text-slate-700">To</label>
                <ReactDatePicker
                  selected={endDate ? new Date(endDate) : null}
                  onChange={onSelectEndDate}
                  selectsEnd
                  minDate={startDate}
                  maxDate={new Date()}
                  inline
                  calendarClassName="!border-none !rounded-none"
                />
              </div>
            </div>
          </div>

          {/* Clear Filter */}
          <div
            onClick={onClear}
            className="py-2 text-sm font-semibold text-center border-t cursor-pointer text-red-700 hover:bg-gray-100"
          >
            Clear Filter
          </div>
        </div>
      )}
    </div>
  );
};

export default ATMDateRangePicker;
