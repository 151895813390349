import React, { useEffect, useState } from "react";
import { FaRegBookmark } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import { axiosCandidateRequest } from "../../../api/api";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import { formatDate } from "../ProfileDetails/View/ProfileDetails";
import toast from "react-hot-toast";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function CandidateApplicationView() {
  const { id, id1 } = useParams();
  const [data, setData] = useState();
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosCandidateRequest(
          `${baseUrl}/api/candidate/job_application/`,
          {
            method: "POST",
            data: {
              pk: id,
              job_id: id1,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data.data["job application"]);
          setIsLoading(false);
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id , id1]);
  // data?.job?.
  const parts = data?.job?.description?.split("\n");

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleSeeMore = () => {
    setIsExpanded((prevState) => !prevState);
  };
  return (
    <>
      {isloading ? (
        <span className="h-full flex justify-center items-center">
          {" "}
          <ATMCircularProgress />
        </span>
      ) : (
        <div className="my-2 flex flex-col px-2 gap-4 font-inter text-xs">
          <div className="flex justify-between items-center gap-8 ">
            <h1 className="font-inter font-semibold   flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5  cursor-pointer"
                onClick={handleBack}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
              <ATMHeading> My Application</ATMHeading>
            </h1>

            <FaRegBookmark className="text-xl text-[#000000B2]" />
          </div>

          <div className=" py-4 border rounded-lg flex flex-col gap-2 items-start justify-between border-[#F0EFEF] shadow-div">
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2  gap-4  px-8  justify-between w-full border-b py-2 ">
              <div>
                <strong>Job Title</strong>
                <p className="mt-1">{data?.job?.title}</p>
              </div>

              <div>
                <strong>Salary Offered</strong>
                <p className="mt-1">
                  {data?.job?.currency} {data?.job?.salary_from}-{" "}
                  {data?.job?.currency} {data?.job?.salary_to}
                </p>
              </div>
              <div>
                <strong>Job Location</strong>
                <p className="mt-1">
                  {data?.job?.city} {data?.job?.state} {data?.job?.country}
                </p>
              </div>
              <div>
                <strong>Account Manager</strong>
                <p className="mt-1">
                  {data?.job?.account_manager?.first_name}{" "}
                  {data?.job?.account_manager?.last_name}
                </p>
              </div>
            </div>
            <div className="font-normal px-8  ">
              {parts
                ?.slice(0, isExpanded ? parts.length : 8)
                ?.map((part, index) => (
                  <div key={index}>{part}</div>
                ))}
              <button onClick={toggleSeeMore} className="text-[#1CACBD]">
                {isExpanded ? "....See Less" : "See More...."}
              </button>
            </div>
          </div>

          <div className="border rounded-lg  flex flex-col gap-2 items-start justify-between border-[#F0EFEF] shadow-div">
            <div className="flex flex-col">
              <div className="grid grid-cols-2 gap-4 items-center bg-[#F2F2F2] rounded-lg">
                <div className="col-span-1 border-r p-4 border-r-white">
                  <p className="font-bold">Questions Asked</p>
                </div>
                <div className="col-span-1 border-r p-4 border-r-white">
                  <p className="font-bold">Video Answer</p>
                </div>
              </div>
              {data?.ques_ans?.map((data, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-4 items-start bg-white rounded-lg"
                >
                  <div className="col-span-1 p-4 border-r h-full">
                    <p>
                      {index + 1}. {data.question}
                    </p>
                  </div>
                  <div className="col-span-1 p-4 border-r h-full">
                    {data.ans_type === "video-based" && (
                      <video
                        src={`${baseUrl}/api/candidate/stream_video/${data?.video_path}`}
                        controls
                        className="w-full object-cover h-[300px]  rounded-lg mb-4"
                      />
                    )}

                    {data.ans_type === "text-based" && <p>{data.answer}</p>}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="border rounded-lg  flex flex-col gap-7 items-start justify-between border-[#F0EFEF] shadow-div w-full p-4">
            <h2 className=" font-medium text-xl">Timeline</h2>

            <ol className="items-center flex flex-col sm:flex-row w-full pr-8  px-4">
              {[
                {
                  event: "Jd Created",
                  date: `${formatDate(data?.job?.created_at)}`,
                  color: `${
                    data?.job?.created_at ? "bg-blue-500" : "bg-blue-50"
                  }`,
                },
                {
                  event: "Invitation sent",
                  date: `${formatDate(data?.Invited)}`,
                  color: `${data?.Invited ? "bg-blue-500" : "bg-blue-50"}`,
                },
                {
                  event: "Applied For Screening",
                  date: `${formatDate(data?.last_question_asked_at)}`,
                  color: `${
                    data?.last_question_asked_at ? "bg-blue-500" : "bg-blue-50"
                  }`,
                },
                {
                  event: "ShortListed",
                  date: `${formatDate(data?.Shortlisted)}`,
                  color: `${data?.Shortlisted ? "bg-blue-500" : "bg-blue-50"}`,
                },
              ]?.map((item, index) => (
                <li key={index} className="w-full">
                  <div className=" py-4 w-full">
                    <div className="flex items-start w-full">
                      <div
                        className={`py-1 rounded-r-md border w-full ml-[-5px] ${item?.color} `}
                      ></div>
                      <div className="flex justify-center">
                        <div className="w-3 ml-[-10px] h-3 bg-white rounded-full border z-10 relative"></div>
                        <div className="absolute mt-5 text-center text-[10px]">
                          <div>{item.event}</div>
                          <div>{item.date}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
            <div className="border-t p-2 text-[#6D758F]  font-normal text-xs">
              <h1>
                Your comprehensive profile is a crucial part of our Application
                Tracking System, empowering our analysis for better matches.
                Your information is securely stored in our database, ensuring
                future opportunities align with your skills and preferences.
                Rest assured, we'll leverage this data to connect you with
                relevant positions.
              </h1>
            </div>
          </div>

          {/* <div className=" p-6 ">
          <h2 className="font-semibold text-[#414141] text-xl">
            If You have any queries. Want to reach out directly?{" "}
          </h2>
          <p className="text-[#6D758F] my-2">
            We're here to help! Reach out to our dedicated support team for any
            inquiries, assistance, or to schedule a demo. Your success is our
            priority.
          </p>
          <button className="w-fit mt-4 flex gap-1 text-white bg-[#1CACBD] px-7 py-3 rounded-lg  font-semibold justify-center items-center">
            Support
          </button>
        </div> */}
        </div>
      )}
    </>
  );
}

export default CandidateApplicationView;
