import React from 'react'
const ATMFieldError = ({ children }) => {
  return (
    <p className="absolute text-[11px] break-words font-inter text-red-500 ">
      {" "}
      {children}{" "}
    </p>
  );
};

export default ATMFieldError;

