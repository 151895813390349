import { ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import ATMFieldError from "../ATMFieldError/ATMFieldError";

const ATMFilePicker = ({
  name,
  onChange,
  placeholder = "Click to upload or drag and drop",
  accept = "image/jpeg,image/png,image/gif",
  value,
}) => {
  const [fileUrl, setFileUrl] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setFileUrl(url);
      onChange(file);

      return () => URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    // Cleanup file URL on component unmount
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [fileUrl]);

  return (
    <div className="relative h-full min-w-[200px] ">
      <div className="relative flex items-center gap-5 border border-gray-300 rounded-md p-3 h-full">
        <div className="relative    overflow-hidden flex items-center justify-center">
          {fileUrl || value ? (
            <img
              className="  w-28 h-28 object-cover rounded-full"
              src={fileUrl || value}
              alt="Preview"
            />
          ) : (
            <div className="flex items-center justify-center  w-28 h-28  bg-gray-100 rounded-full">
              <span className="text-gray-500 text-3xl">+</span>
            </div>
          )}

          <input
            id={name}
            name={name}
            type="file"
            accept={accept}
            onChange={handleFileChange}
            className="absolute inset-0 opacity-0 cursor-pointer"
            aria-labelledby={`${name}-label`}
          />
          <label
            htmlFor={name}
            className="absolute bottom-0 right-0 w-8 h-8 bg-gray-800 rounded-full flex items-center justify-center cursor-pointer"
            aria-label="Upload file"
          >
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.88871 3.48517H3.09745C2.73438 3.48517 2.38619 3.6294 2.12947 3.88613C1.87274 4.14285 1.72852 4.49104 1.72852 4.85411V14.4366C1.72852 14.7997 1.87274 15.1479 2.12947 15.4046C2.38619 15.6613 2.73438 15.8056 3.09745 15.8056H12.68C13.043 15.8056 13.3912 15.6613 13.648 15.4046C13.9047 15.1479 14.0489 14.7997 14.0489 14.4366V9.64537M13.0222 2.45848C13.2945 2.18618 13.6638 2.0332 14.0489 2.0332C14.434 2.0332 14.8033 2.18618 15.0756 2.45848C15.3479 2.73077 15.5009 3.10009 15.5009 3.48517C15.5009 3.87026 15.3479 4.23958 15.0756 4.51187L8.57318 11.0143L5.83531 11.6988L6.51978 8.9609L13.0222 2.45848Z"
                stroke="white"
                strokeWidth="0.75"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </label>
        </div>
        <div>
          <p id={`${name}-label`} className="font-inter font-normal text-sm">
            <span
              className="text-[#1CACBD] font-semibold cursor-pointer"
              onClick={() => document.getElementById(name).click()}
            >
              {placeholder}
            </span>
          </p>
          <p className="font-inter font-normal text-sm text-gray-500">
            SVG, PNG, JPG or GIF (max. 800x400px)
          </p>
        </div>
      </div>

      <ErrorMessage name={name}>
        {(errorMessage) => <ATMFieldError>{errorMessage}</ATMFieldError>}
      </ErrorMessage>
    </div>
  );
};

export default ATMFilePicker;
