import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { array, object, string } from "yup";
import { axiosCandidateFromRequest } from "../../../../api/formApi";
import { setRefresh } from "../../../../features/refresh/refresh";
import WorkExperienceFormLayout from "../Layout/WorkExperienceFormLayout";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const EditWorkExperienceFormWrapper = ({
  userData,
  workExperienceEditId,
  setWorkExperienceEdit,
}) => {
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresh.refresh);
  let work_experience =
    userData?.workexperience_candidate?.[workExperienceEditId];
  const skillsArray = JSON.parse(work_experience?.skills) || [];
  const formattedSkills = skillsArray.map((skill) => ({
    label: skill,
    value: skill,
  }));
  const initialValues = {
    experienceType: { label: work_experience?.experience } || "",
    companyName: work_experience?.company_name || "",
    employmentType: work_experience?.full_or_part_time
      ? "full-time"
      : "part-time",
    durationFrom: work_experience?.company_start_duration || null,
    durationTo: work_experience?.company_end_duration || null,
    profileSummary: work_experience?.work_experience_summary || "",
    experienceVideo: work_experience?.upload_work_video || "",
    designation: work_experience?.job_title || "",
    skillsData: formattedSkills || [],
  };

  const validationSchema = object().shape({
    skillsData: array().max(100, "You can add a maximum of 100 skills"),
    profileSummary: string().max(
      700,
      "profileSummary cannot exceed 700 characters"
    ),
    companyName: string().max(40, "companyName cannot exceed 40 characters"),
    designation: string().max(40, "designation cannot exceed 40 characters"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    try {
      const formData = {
        experience: values.experienceType?.label,
        company_name: values.companyName,
        company_start_duration: values.durationFrom,
        company_end_duration: values.durationTo,
        job_title: values.designation,
        full_or_part_time:
          values?.employmentType === "full-time" ? true : false,
        work_experience_summary: values.profileSummary,
        skills: JSON.stringify(values.skillsData.map((el) => el.label)),
        upload_work_video: values?.experienceVideo,
      };

      let response = await axiosCandidateFromRequest(
        `${baseUrl}/api/candidate/work_experience/${work_experience.id}/`,
        {
          method: "PATCH",
          data: formData,
        }
      );

      if (response.status === 201) {
        setSubmitting(false);
        toast.success("Data updated successfully");
        dispatch(setRefresh(!refresh));
        setWorkExperienceEdit(false);
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <WorkExperienceFormLayout formType="Edit" formikProps={formikProps} />
        </Form>
      )}
    </Formik>
  );
};

export default EditWorkExperienceFormWrapper;
