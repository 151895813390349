// import React, { useRef, useState } from "react";
// import Select from "react-select";
// import makeAnimated from "react-select/animated";
// import ATMFieldError from "../ATMFieldError/ATMFieldError";
// import { ErrorMessage } from "formik";
// import ATMFieldLabel from "../ATMFieldLabel/ATMFieldLabel";

// const animatedComponents = makeAnimated();

// const customStyles = {
//   control: (provided) => ({
//     ...provided,
//     border: "none",
//     outlineColor: "white",
//     boxShadow: "none",
//     backgroundColor: "transparent",
//   }),
//   menuPortal: (provided) => ({
//     ...provided,
//     zIndex: 10000,
//   }),
// };

// const ATMMultiSelect = ({
//   name,
//   value,
//   onChange,
//   options,
//   label,
//   placeholder = "Select...",
//   className = "",
//   showSelected = false,

//   keysToSelectoption = ["Enter", " ", ","],
// }) => {
//   const selectRef = useRef(null);
//   const [focused, setFocused] = useState(false);
//   const [inputValue, setInputValue] = useState("");
//   const [currentOptions, setCurrentOptions] = useState(options);
//   const [menuIsOpen, setMenuIsOpen] = useState(false);

//   // Handle input change and add new option
//   const handleInputChange = (newValue) => {
//     setInputValue(newValue);
//     return newValue;
//   };

//   const handleKeyDown = (event) => {
//     const keysToSelect = keysToSelectoption;

//     if (keysToSelect.includes(event.key) && inputValue.trim() !== "") {
//       event.preventDefault();

//       const existingOption = currentOptions.find(
//         (option) => option.label.toLowerCase() === inputValue.toLowerCase()
//       );

//       if (!existingOption) {
//         const newOption = {
//           value: `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
//           label: inputValue,
//         };

//         const updatedOptions = [...currentOptions, newOption];
//         setCurrentOptions(updatedOptions);

//         // Select the new option
//         const updatedValue = [...(value || []), newOption];
//         onChange(updatedValue, { action: "select-option" });
//         setInputValue("");
//       } else {
//         const updatedValue = [...(value || []), existingOption];
//         onChange(updatedValue, { action: "select-option" });
//         setInputValue("");
//       }
//       setMenuIsOpen(false);
//     }
//   };

//   const handleRemoveOption = (optionToRemove) => {
//     const updatedValue = value.filter(
//       (option) => option.value !== optionToRemove.value
//     );
//     onChange(updatedValue, { action: "remove-option" });
//   };

//   const handleFocus = () => {
//     setFocused(true);
//     setMenuIsOpen(true); // Open the dropdown when focused
//   };

//   return (
//     <>
//       {label && <ATMFieldLabel>{label}</ATMFieldLabel>}
//       <div
//         onClick={() => {
//           selectRef.current.focus();
//           setFocused(true);
//         }}
//         className={`relative ${className}`}
//       >
//         <div
//           className={`relative rounded border min-w-[200px] ${
//             focused ? "border-primary-light" : "border-gray-300"
//           }`}
//         >
//           <Select
//             name={name}
//             value={showSelected ? null : value}
//             onChange={(newValue, actionMeta) => {
//               onChange(newValue, actionMeta);
//               selectRef.current.focus();
//             }}
//             options={currentOptions}
//             isClearable
//             closeMenuOnSelect
//             placeholder={placeholder}
//             onBlur={() => setFocused(false)}
//             onInputChange={handleInputChange}
//             inputValue={inputValue}
//             onKeyDown={handleKeyDown}
//             ref={selectRef}
//             styles={customStyles}
//             isMulti
//             components={animatedComponents}
//             menuIsOpen={menuIsOpen}
//             onFocus={handleFocus}
//             className=""

//           />
//         </div>
//         {showSelected && value.length > 0 && (
//           <div className="mt-2">
//             <h4 className="font-semibold">Selected Options:</h4>
//             <ul className="mt-2 flex flex-wrap gap-4">
//               {value.map((selectedOption) => (
//                 <li key={selectedOption.value} className="flex w-fit relative">
//                   <p className="border px-3 py-1 rounded-md">
//                     {selectedOption.label}
//                   </p>
//                   <button
//                     onClick={() => handleRemoveOption(selectedOption)}
//                     className="absolute top-0 right-0 bg-white font-bold"
//                     style={{ transform: "translate(20%, -40%)" }}
//                   >
//                     X
//                   </button>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         )}
//         {name && (
//           <ErrorMessage name={name}>
//             {(errorMessage) => <ATMFieldError>{errorMessage}</ATMFieldError>}
//           </ErrorMessage>
//         )}
//       </div>
//     </>
//   );
// };

// export default ATMMultiSelect;

import React, { useRef, useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ATMFieldError from "../ATMFieldError/ATMFieldError";
import { ErrorMessage } from "formik";
import ATMFieldLabel from "../ATMFieldLabel/ATMFieldLabel";

const animatedComponents = makeAnimated();

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "none",
    outlineColor: "white",
    boxShadow: "none",
    backgroundColor: "transparent",
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 10000,
  }),
};

const ATMMultiSelect = ({
  name,
  value,
  onChange,
  options,
  label,
  placeholder = "Select...",
  className = "",
  showSelected = false,
  keysToSelectoption = ["Enter", " ", ","],
}) => {
  const selectRef = useRef(null);
  const containerRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [currentOptions, setCurrentOptions] = useState(options);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // Handle input change and add new option
  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };

  const handleKeyDown = (event) => {
    const keysToSelect = keysToSelectoption;

    if (keysToSelect.includes(event.key) && inputValue.trim() !== "") {
      event.preventDefault();

      const existingOption = currentOptions.find(
        (option) => option.label.toLowerCase() === inputValue.toLowerCase()
      );

      if (!existingOption) {
        const newOption = {
          value: `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
          label: inputValue,
        };

        const updatedOptions = [...currentOptions, newOption];
        setCurrentOptions(updatedOptions);

        // Select the new option
        const updatedValue = [...(value || []), newOption];
        onChange(updatedValue, { action: "select-option" });
        setInputValue("");
      } else {
        const updatedValue = [...(value || []), existingOption];
        onChange(updatedValue, { action: "select-option" });
        setInputValue("");
      }
      setMenuIsOpen(false);
    }
  };

  const handleRemoveOption = (optionToRemove) => {
    const updatedValue = value.filter(
      (option) => option.value !== optionToRemove.value
    );
    onChange(updatedValue, { action: "remove-option" });
  };

  const handleFocus = () => {
    setMenuIsOpen(true); // Open the dropdown when focused
  };

  // Close the menu when clicking outside the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setMenuIsOpen(false); // Close the menu if the click is outside the container
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {label && <ATMFieldLabel>{label}</ATMFieldLabel>}
      <div
        ref={containerRef} // Attach the containerRef to the wrapping div
        onClick={() => {
          selectRef.current.focus();
        }}
        className={`relative ${className}`}
      >
        <div
          className={`relative rounded-md border min-w-[200px]`}
        >
          <Select
            name={name}
            value={showSelected ? null : value}
            onChange={(newValue, actionMeta) => {
              onChange(newValue, actionMeta);
              selectRef.current.focus();
            }}
            options={currentOptions}
            isClearable
            closeMenuOnSelect
            placeholder={placeholder}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            onKeyDown={handleKeyDown}
            ref={selectRef}
            styles={customStyles}
            isMulti
            components={animatedComponents}
            menuIsOpen={menuIsOpen}
            onFocus={handleFocus}
            className=""
          />
        </div>
        {showSelected && value.length > 0 && (
          <div className="mt-2">
            <h4 className="font-semibold">Selected Options:</h4>
            <ul className="mt-2 flex flex-wrap gap-4">
              {value.map((selectedOption) => (
                <li key={selectedOption.value} className="flex w-fit relative">
                  <p className="border px-3 py-1 rounded-md">
                    {selectedOption.label}
                  </p>
                  <button
                    onClick={() => handleRemoveOption(selectedOption)}
                    className="absolute top-0 right-0 bg-white font-bold"
                    style={{ transform: "translate(20%, -40%)" }}
                  >
                    X
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {name && (
          <ErrorMessage name={name}>
            {(errorMessage) => <ATMFieldError>{errorMessage}</ATMFieldError>}
          </ErrorMessage>
        )}
      </div>
    </>
  );
};

export default ATMMultiSelect;
