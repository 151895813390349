import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { object, string } from "yup";
import { axiosRequest } from "../../../../api/api";
import apiSlice from "../../../../app/ApiSlice";
import { socialMedia } from "../../SignUp";
import MyProfileFormLayout from "../Layout/MyProfileFormLayout";
import { useDispatch } from "react-redux";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

// const validateImage = (file) => {
//   if (!file) return false;

//   const validTypes = ["image/svg+xml", "image/png", "image/jpeg", "image/gif"];
//   if (!validTypes.includes(file.type)) return false;

//   // Create an image element to check dimensions
//   const img = new Image();
//   img.src = URL.createObjectURL(file);

//   return new Promise((resolve, reject) => {
//     img.onload = () => {
//       URL.revokeObjectURL(img.src);
//       if (img.width <= 800 && img.height <= 400) {
//         resolve(true);
//       } else {
//         resolve(false);
//       }
//     };
//     img.onerror = () => {
//       URL.revokeObjectURL(img.src);
//       resolve(false);
//     };
//   });
// };

const EditMyProfileFormWrapper = ({ setEdit, profileData, recruitertype }) => {
  const dispatch = useDispatch();
  const initialValues = {
    firstName: profileData?.recruiter?.first_name || "",
    lastName: profileData?.recruiter?.last_name || "",
    email: profileData?.recruiter?.email || "",
    companyName: profileData?.recruiter?.company?.company_name || "",
    companywebUrl: profileData?.recruiter?.company?.website_url || "",
    profilePhoto: "",
    contactNumber: profileData?.recruiter?.contact_number || "",
    industry: { label: profileData?.recruiter?.company?.industry } || "",
    companyDomain:
      { label: profileData?.recruiter?.company?.company_domain } || "",
    companyIdentificationNumber:
      profileData?.recruiter?.company?.company_identification_numb || "",
    companySocialMedia: socialMedia[0],
    companySocialMediaurl: profileData?.recruiter?.company?.linkedin || "",
    logo: profileData?.recruiter?.company?.contact_number || "",
    companyMission:
      profileData?.recruiter?.company?.company_mission_statment || "",
    addressOne: profileData?.recruiter?.company?.address || "",
    addressTwo: profileData?.recruiter?.company?.address || "",
    country: { label: profileData?.recruiter?.company?.country } || "",
    totalEmployees: profileData?.recruiter?.company?.numb_employees || "",
  };

  const validationSchema = object().shape({
    // profilePhoto: mixed()
    //   .required("A photo is required")
    //   .test(
    //     "fileType",
    //     "Only SVG, PNG, JPG, and GIF formats are allowed",
    //     (value) => {
    //       if (!value) return false;
    //       const allowedTypes = [
    //         "image/svg+xml",
    //         "image/png",
    //         "image/jpeg",
    //         "image/gif",
    //       ];
    //       return allowedTypes.includes(value.type);
    //     }
    //   )
    //   .test(
    //     "fileSize",
    //     "Image must be 800x400 pixels or smaller",
    //     async (value) => {
    //       if (!value) return false;
    //       return await validateImage(value); // Define this function to check image dimensions
    //     }
    //   ),
    firstName: string()
      .required("First name is required")
      .min(2, "First name must be at least 2 characters")
      .max(50, "First name must be less than 50 characters"),

    lastName: string()
      .required("Last name is required")
      .min(2, "Last name must be at least 2 characters")
      .max(50, "Last name must be less than 50 characters"),

    contactNumber: string()
      .required("Contact number is required")
      .min(9, "Contact number must be at least 10 digits")
      .max(15, "Contact number must be less than 15 digits"),

    companyName: string().when("recruitertype", {
      is: (recruitertype) => recruitertype?.IS_SUPERUSER,
      then: string()
        .required("Company name is required")
        .max(100, "Company name must be less than 100 characters"),
    }),

    // companywebUrl: string()
    //   .matches(regex, 'Company URL must start with "www."')
    //   .required("Company URL is required"),
    companywebUrl: string().when("recruitertype", {
      is: (recruitertype) => recruitertype?.IS_SUPERUSER,
      then: string().required("Company URL is required"),
    }),

    // companyIdentificationNumber: string().when("recruitertype", {
    //   is: (recruitertype) => recruitertype?.IS_SUPERUSER,
    //   then: string().required("Company identification number is required"),
    // }),

    companySocialMediaurl: string()
      .url("Invalid URL")
      .when("recruitertype", {
        is: (recruitertype) => recruitertype?.IS_SUPERUSER,
        then: string().required("Company social media URL is required"),
      }),

    // logo: string().when("profileData", {
    //   is: (profileData) =>
    //     profileData?.recruiter?.company?.company_identification_numb,
    //   then: string().required("Logo is required"),
    // }),

    companyMission: string().when("recruitertype", {
      is: (recruitertype) => recruitertype?.IS_SUPERUSER,
      then: string().required("Company mission is required"),
    }),

    addressOne: string().when("recruitertype", {
      is: (recruitertype) => recruitertype?.IS_SUPERUSER,
      then: string().required("Address line 1 is required"),
    }),

    country: object().when("recruitertype", {
      is: (recruitertype) => recruitertype?.IS_SUPERUSER,
      then: object().required("Country is required"),
    }),

    totalEmployees: string().when("recruitertype", {
      is: (recruitertype) => recruitertype?.IS_SUPERUSER,
      then: string().required("Total employees is required"),
    }),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("first_name", values.firstName || "");
      formData.append("last_name", values.lastName || "");
      // formData.append("email", values.email);
      formData.append("contact_number", values.contactNumber || "");
      formData.append("company_name", values.companyName || "");
      formData.append("website_url", values.companywebUrl || "");
      // formData.append("profile_photo", values.profilePhoto);
      formData.append(
        "industry",
        values.industry?.label === undefined ? "" : values.industry?.label
      );
      formData.append(
        "company_domain",
        values.companyDomain?.label === undefined
          ? ""
          : values.companyDomain?.label
      );
      formData.append(
        "company_identification_numb",
        values.companyIdentificationNumber || ""
      );
      formData.append("linkedin", values.companySocialMediaurl || "");
      formData.append("stackoverflow", values.companySocialMediaurl || "");
      formData.append("google", values.companySocialMediaurl || "");
      formData.append("github", values.companySocialMediaurl || "");
      // formData.append("company_logo", values.logo);
      formData.append("company_mission_statment", values.companyMission || "");
      formData.append("address", values.addressOne || "");
      formData.append("country", values.country?.label || null);
      formData.append("numb_employees", values.totalEmployees || "");

      const response = await axiosRequest(
        `${baseUrl}/api/recruiter/recruiter/`,
        {
          method: "PATCH",
          data: formData,
        }
      );

      if (response.status === 200) {
        toast.success("Data updated successfully");
        setEdit(true);
        // resetForm();
        // dispatch(setRefresh(!refresh));

        dispatch(apiSlice.util.invalidateTags(["recruiter_profile"]));
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        // toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <MyProfileFormLayout
            formType="Add"
            formikProps={formikProps}
            profileData={profileData}
            setEdit={setEdit}
            recruitertype={recruitertype}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditMyProfileFormWrapper;
