import React from "react";
// import { currencyType } from "../../src/pages/recruiters/CreateJob";
import ATMFieldLabel from "../ATMFieldLabel/ATMFieldLabel";
import { ErrorMessage } from "formik";
import ATMFieldError from "../ATMFieldError/ATMFieldError.jsx";
import { currencyType } from "../../pages/recruiters/CreateJob.jsx";

const ATMCurrencyAmount = ({
  name,
  label,
  value,
  onCurrencyChange,
  onChange,
  currencyValue,
  placeholder,
  currencyhide = true,
  inputhide = true,
  disabled = false,
}) => {
  const handleAmountChange = (e) => {
    const newValue = e.target.value;
    const regex = /^\d*\.?\d*$/;
    if (regex.test(newValue) || newValue === "") {
      onChange(e);
    }
  };

  const handleCurrencyChange = (e) => {
    const newValue = e.target.value;
    onCurrencyChange(newValue);
  };

  const handleKeyPress = (e) => {
    const char = String.fromCharCode(e.which);
    const regex = /^[0-9]$/;

    // Prevent non-numeric characters
    if (!regex.test(char) && e.which !== 8) {
      e.preventDefault();
    }
  };

  return (
    <div className="grid grid-cols-1 w-full">
      {label ? (
        <ATMFieldLabel>{label}</ATMFieldLabel>
      ) : (
        <div className="text-white">.</div>
      )}

      <div className="border flex items-center rounded-md px-1">
        {currencyhide && (
          <select
            onChange={handleCurrencyChange}
            className={`h-[40px] outline-none ${inputhide && " border-r"} `}
            value={currencyValue}
            disabled={disabled}
          >
            <option value="" disabled className="">
              Currency
            </option>
            {currencyType.map((currency) => (
              <option key={currency.value} value={currency.value}>
                {currency.label}
              </option>
            ))}
          </select>
        )}
        {inputhide && (
          <input
            value={value}
            onChange={handleAmountChange}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
            className="h-[40px] px-2 border-0 outline-none w-full"
            disabled={disabled}
          />
        )}
      </div>
      {name && (
        <ErrorMessage name={name}>
          {(errorMessage) => (
            <div className="">
              <ATMFieldError> {errorMessage} </ATMFieldError>
            </div>
          )}
        </ErrorMessage>
      )}
    </div>
  );
};

export default ATMCurrencyAmount;
