import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditWorkExperienceFormWrapper from "../Edit/EditWorkExperienceFormWrapper";
import WorkExperience from "./WorkExperience";
import AddWorkExperienceFormWrapper from "../Add/AddWorkExperienceFormWrapper";
import ATMHeading from "../../../../atoms/ATMHeading/ATMHeading";

const WorkExperienceWrapper = () => {
  const [workExperienceEdit, setWorkExperienceEdit] = useState(false);
  const [workExperienceAdd, setWorkExperienceAdd] = useState(false);
  const [workExperienceEditId, setWorkExperienceEditId] = useState();
  const userData = useSelector((state) => state.candidateData.candidateData);
  const areBothFieldsEmpty = userData?.workexperience_candidate;

  return (
    <div className="text-xs font-inter  flex flex-col gap-4 px-2 ">
      <div>
        <ATMHeading> Work Experience</ATMHeading>
        <p className="text-slate-500 ">
          Document Your Professional Journey — This information helps potential
          employers understand your career path and envision how your skills and
          expertise can contribute to their team's success.
        </p>
      </div>
      <div>
        {areBothFieldsEmpty && !workExperienceEdit && !workExperienceAdd ? (
          <WorkExperience
            setWorkExperienceEdit={setWorkExperienceEdit}
            setWorkExperienceAdd={setWorkExperienceAdd}
            setWorkExperienceEditId={setWorkExperienceEditId}
            userData={userData}
          />
        ) : !areBothFieldsEmpty || workExperienceAdd ? (
          <AddWorkExperienceFormWrapper
            setWorkExperienceAdd={setWorkExperienceAdd}
          />
        ) : (
          workExperienceEdit && (
            <EditWorkExperienceFormWrapper
              setWorkExperienceEdit={setWorkExperienceEdit}
              workExperienceEditId={workExperienceEditId}
              userData={userData}
            />
          )
        )}
      </div>
    </div>
  );
};

export default WorkExperienceWrapper;
