import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ATMDatePicker = ({
  selectedDate,
  onChange,
  maxDate,
  minDate,
  placeholderText,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleIconClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="border rounded-md flex  px-4 w-full items-center justify-between">
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        dateFormat="dd MMM yyyy"
        placeholderText={placeholderText || "Select a date"}
        isClearable
        showPopperArrow={false}
        className="outline-none w-full h-[40px] "
        minDate={minDate || null}
        maxDate={maxDate || null}
        open={isOpen}
        onClickOutside={handleClose}
        onInputClick={handleIconClick}
        disabled={disabled}
      />
      <svg
        onClick={handleIconClick}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={`size-4  ${disabled ? "cursor-none" : "cursor-pointer" }`}
      
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
        />
      </svg>
    </div>
  );
};

export default ATMDatePicker;
