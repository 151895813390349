import React, { useState } from "react";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";

const ResumeView = ({ isOpen, onClose, htmlContent }) => {
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const fileKey = htmlContent?.split(
    "https://jivahire-resumes-database.s3.amazonaws.com/"
  )[1];

  if (!isOpen) return null;

  const handleLoad = () => {
    setIsLoading(false); // Resume has finished loading
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
      <div className="bg-white rounded-lg shadow-lg w-[80%] h-[600px] overflow-hidden ">
        <div className="flex justify-end p-2">
          {" "}
          <button
            onClick={onClose}
            className=" text-gray-600  hover:text-gray-900 cursor-pointer"
          >
            ✖
          </button>
        </div>

        <div className="w-full mt-8 h-full">
          {isLoading && (
            <div className="flex justify-center items-center h-full ">
              <ATMCircularProgress />
            </div>
          )}
          <embed
            src={`https://api.jivahire.com/api/recruiter/resume_view/?file_key=${fileKey}`}
            type="application/pdf"
            width="100%"
            height="90%"
            className="border-none"
            onLoad={handleLoad}
          />
        </div>
      </div>
    </div>
  );
};

export default ResumeView;
