import React, { useEffect, useState } from "react";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import { axiosRequest } from "../../../../api/api";
import EditMyProfileFormWrapper from "../Edit/EditMyProfileFormWrapper";
import toast from "react-hot-toast";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const RecruitProfileView = () => {
  const user = localStorage.getItem("recuriterProfile");
  const recruitertype = user ? JSON.parse(user) : "";
  const [profileData, setProfileData] = useState({});
  const [edit, setEdit] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosRequest(
          `${baseUrl}/api/recruiter/recruiter/`,
          {
            method: "GET",
          }
        );

        if (response.status === 200) {
          setProfileData(response.data?.data);
          localStorage.setItem(
            "recuriterProfile",
            JSON.stringify(response.data?.data?.recruiter)
          );
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      }
    };
    fetchData();
  }, [edit]);

  return (
    <div className="h-full font-inter text-xs px-2">
      {profileData?.recruiter ? (
        <div className=" flex flex-col gap-2">
          <div className="text-lg font-semibold  flex justify-between items-center gap-4">
            My Profile (Corporate{" "}
            {recruitertype?.IS_SUPERUSER ? "Super Admin" : "User"})
            {edit && (
              <div onClick={() => setEdit(false)} className="cursor-pointer ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </div>
            )}
          </div>
          <div>
            <p>
              Expand your profile by adding your educational background. Share
              your qualifications, degrees, and academic achievements to enhance
              your profile.
            </p>
          </div>

          {edit ? (
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 border p-10 gap-4 rounded-lg">
                <div>
                  <ATMFieldLabel>First Name</ATMFieldLabel>
                  <div>{profileData?.recruiter?.first_name || "--"}</div>
                </div>
                <div>
                  <ATMFieldLabel>Last Name</ATMFieldLabel>
                  <div>{profileData?.recruiter?.last_name || "--"}</div>
                </div>
                <div>
                  <ATMFieldLabel>Email Address</ATMFieldLabel>
                  <div>{profileData?.recruiter?.email || "--"}</div>
                </div>
                {profileData?.recruiter?.contact_number && (
                  <div>
                    <ATMFieldLabel>Contact Number</ATMFieldLabel>
                    <div>{profileData?.recruiter?.contact_number || "--"}</div>
                  </div>
                )}
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 border p-10 gap-4 rounded-lg">
                {profileData?.recruiter?.company?.company_name && (
                  <div>
                    <ATMFieldLabel>Company Name</ATMFieldLabel>
                    <div>
                      {profileData?.recruiter?.company?.company_name || "--"}
                    </div>
                  </div>
                )}
                <div>
                  <ATMFieldLabel>Company Website Url</ATMFieldLabel>
                  <div>
                    {profileData?.recruiter?.company?.website_url || "--"}
                  </div>
                </div>
                {recruitertype?.IS_SUPERUSER && (
                  <div>
                    <ATMFieldLabel>Address</ATMFieldLabel>
                    <div>
                      {profileData?.recruiter?.company?.address || "--"}
                    </div>
                  </div>
                )}
                {recruitertype?.IS_SUPERUSER && (
                  <div>
                    <ATMFieldLabel>Company Domain</ATMFieldLabel>
                    <div>
                      {profileData?.recruiter?.company?.company_domain || "--"}
                    </div>
                  </div>
                )}
                {recruitertype?.IS_SUPERUSER && (
                  <div>
                    <ATMFieldLabel>Company Identification Number</ATMFieldLabel>
                    <div>
                      {profileData?.recruiter?.company
                        ?.company_identification_numb || "--"}
                    </div>
                  </div>
                )}
                {recruitertype?.IS_SUPERUSER && (
                  <div>
                    <ATMFieldLabel>Company Mission</ATMFieldLabel>
                    <div>
                      {profileData?.recruiter?.company
                        ?.company_mission_statment || "--"}
                    </div>
                  </div>
                )}
                {recruitertype?.IS_SUPERUSER && (
                  <div>
                    <ATMFieldLabel>Country</ATMFieldLabel>
                    <div>
                      {profileData?.recruiter?.company?.country || "--"}
                    </div>
                  </div>
                )}
                {recruitertype?.IS_SUPERUSER && (
                  <div>
                    <ATMFieldLabel>Number of Employees</ATMFieldLabel>
                    <div>
                      {profileData?.recruiter?.company?.numb_employees || "--"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <EditMyProfileFormWrapper
              setEdit={setEdit}
              profileData={profileData}
              recruitertype={recruitertype}
            />
          )}
        </div>
      ) : (
        <div className=" h-full flex justify-center  items-center">
          <ATMCircularProgress />
        </div>
      )}
    </div>
  );
};

export default RecruitProfileView;
