import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosRequest, axiosSimple } from "../../api/api";
import apiSlice from "../../app/ApiSlice";
import Footer from "../../components/candidate/Navbars/Footer";
import Navbar from "../../components/recruiters/Navbar";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import toast from "react-hot-toast";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const Activation = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(""); // OTP input state
  const [isOtpVisible, setIsOtpVisible] = useState(false); // state to toggle OTP input visibility
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const code = searchParams.get("code");
  const errorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };

  const fetchQuestions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosRequest(
        `${baseUrl}/api/recruiter/otp-invite/${id}/?supersecret=${code}`,
        {
          method: "GET",
        }
      );

      if (response.status === 200) {
        setEmail(response.data.email);
        setIsLoading(false);
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        // Handle other errors
      }
    } finally {
      setIsLoading(false);
    }
  }, [id, code]); // Dependencies: id and code

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]); // Use memoized fetchQuestions as a dependency

  const handleGetOtp = async () => {
    setIsLoading(true);
    try {
      const response = await axiosSimple(
        `${baseUrl}/api/recruiter/otp/${email}/`,
        {
          method: "GET",
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        setIsOtpVisible(true); // Show OTP input after successful OTP request
      } else if (response.status === 400) {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false); // stop loading
    }
  };

  const handleOtpSubmit = async () => {
    // const f_email = email.replace("@", "%40");
    setIsLoading(true);
    try {
      if (otp.length !== 6) {
        errorMessage("Digits in OTP number should be equal to 6");
        return;
      }
      const response = await axiosSimple(
        `${baseUrl}/api/recruiter/otp-verify/${id}/${otp}/`,
        {
          method: "POST",
        }
      );
      if (response.status === 200) {
        dispatch(apiSlice.util.resetApiState());
        const { access, refresh, user_type, user } = response.data.data;
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        localStorage.setItem("userType", user_type);
        localStorage.setItem("recuriterProfile", JSON.stringify(user));
        localStorage.setItem("recruiter_id", user.id || "");
        Cookies.set("accessToken", access, {
          secure: true,
          sameSite: "Strict",
        });
        Cookies.set("userType", user_type, {
          secure: true,
          sameSite: "Strict",
        });

        navigate(`/recruit/job-status/operations`);
      } else if (response.status === 400) {
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // toast.error(error.response.data.message);
      } else {
        // toast.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen flex flex-col justify-between">
      <Navbar />
      <div className=" py-4 bg-blue-50 h-screen">
        {
          <div className="flex  justify-center  ">
            <div className="flex flex-col gap-4 justify-center min-w-80 border rounded-lg p-4 bg-white">
              <input
                value={email}
                type="email"
                placeholder="Enter your mail"
                className="w-full px-3 py-2 border border-gray-300 min-w-[200px] rounded-md shadow-sm outline-none "
                disabled
              />

              {isOtpVisible && (
                <input
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  type="text"
                  placeholder="Enter OTP"
                  className="w-full px-3 py-2 border border-gray-300 min-w-[200px] rounded-md shadow-sm outline-none "
                />
              )}

              {!isOtpVisible && (
                <div className="flex justify-center">
                  <button
                    onClick={handleGetOtp}
                    className="border w-[120px] h-[40px] py-1 rounded-md bg-[#1CACBD] text-white"
                  >
                    {isLoading ? (
                      <div className="flex justify-center">
                        {" "}
                        <ATMCircularProgress size="small" color="white" />
                      </div>
                    ) : (
                      "Get OTP"
                    )}
                  </button>
                </div>
              )}

              {isOtpVisible && (
                <div className="flex justify-center">
                  <button
                    onClick={handleOtpSubmit}
                    className="border  w-[120px] h-[40px] py-1 rounded-md bg-[#1CACBD] text-white"
                  >
                    {isLoading ? (
                      <div className="flex justify-center">
                        {" "}
                        <ATMCircularProgress size="small" color="white" />
                      </div>
                    ) : (
                      "Submit OTP"
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        }

        {/* OTP Input visible after Get OTP button is pressed */}

        <h3 className="error">{error}</h3>
      </div>
      <footer className="border-t shadow  ">
        <Footer />
      </footer>
    </div>
  );
};
export default Activation;
