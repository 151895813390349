import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import Footer from "../../components/candidate/Navbars/Footer";
import Navbar from "../../components/recruiters/Navbar";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function RecruiterActivateAccount() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleActive = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/api/candidate/account_activate/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success(response?.data?.message);
        navigate("/recruiter/login");
        setIsLoading(false);
      } else if (response.status === 400) {
        toast.error("Failed to add member");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error("Something went wrong");
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen flex flex-col justify-between">
      <Navbar />
      <div className="flex h-screen items-center justify-center">
        <div className="flex flex-col">
          <p className="font-inter text-[#1D2939] font-medium text-base">
            Click the button below to Activate Account!
          </p>
          <button
            onClick={handleActive}
            className="w-[330px] h-[70px] font-bold text-2xl font-inter text-[#FFFFFF] rounded-lg bg-[#1CACBD] mt-4"
          >
            {isLoading ? (
              <div className="flex justify-center">
                {" "}
                <ATMCircularProgress color="white" />
              </div>
            ) : (
              "Activate"
            )}
          </button>
        </div>
      </div>
      <footer className="border-t shadow  ">
        <Footer />
      </footer>
    </div>
  );
}

export default RecruiterActivateAccount;
