import React from "react";
import TalentSearchList from "./TalentSearchList";

const TalentSearchListWrapper = () => {
  return (
    <div className="px-2">
      <TalentSearchList />
    </div>
  );
};

export default TalentSearchListWrapper;
