import React from "react";
import { CiBookmark } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../ProfileDetails/View/ProfileDetails";

const InviteJobCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className=" flex flex-col gap-3 rounded-xl bg-[#F7F8FD] p-4">
        <div className="flex items-center justify-between w-full">
          <div
            className=" text-[#1CACBD] cursor-pointer"
            onClick={() =>
              navigate(
                `/candidate/aplication-view/${data?.application_id}/${data?.job?.id}`
              )
            }
          >
            {data?.application_status === "submitted" &&
              data?.applied &&
              "View Your Job Application"}
            {data?.application_status === "shortlist" &&
              data?.applied &&
              "View Your Job Application"}
          </div>
          <div className=" flex items-center gap-1 ">
            {data?.application_status === "submitted" && data?.applied && (
              <div className="py-[2px] px-[10px] text-[#027A48] text-xs font-medium  bg-green-200 rounded-2xl ">
                Applied
              </div>
            )}
            {data?.application_status === "shortlist" && data?.applied && (
              <div className="py-[2px] px-[10px] text-orange-500 text-xs font-medium  bg-orange-100 rounded-2xl ">
                Shortlist
              </div>
            )}
            <CiBookmark className=" text-[#898989] font-semibold size-5 text-end" />
          </div>
        </div>

        <div className="grid grid-cols-3 items-center gap-4">
          <div className="rounded-md ">
            <img
              src={data?.job?.company.company_logo || ""}
              alt=""
              className="w-[70px] h-[70px] object-contain rounded-md"
            />
          </div>
          <div className=" col-span-2">
            <h1 className="text-[#202020] font-inter font-semibold text-xs">
              {data?.job.title || ""}
            </h1>
            <p className=" text-[#202020] font-inter font-normal">
              {data?.job.company.website_url}
            </p>
            <p>
              {" "}
              <span>{data.applicant_count}</span> Applicants
            </p>
          </div>
        </div>

        <div className="flex  items-center gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#898989"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
            />
          </svg>

          <h1 className=" font-inter">
            Date Invited:{formatDate(data?.created_at)}
          </h1>
        </div>

        <div className="flex  items-center gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#898989"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
            />
          </svg>

          <h1 className="font-inter">
            Date Posted:{formatDate(data?.updated_at)}
          </h1>
        </div>

        <div className="flex  items-center gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#000000B2"
            className="size-5"
          >
            <path
              fillRule="evenodd"
              d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
              clipRule="evenodd"
            />
          </svg>

          <p className="text-[#202020] font-inter text-xs">
            Office: {data?.job.country}
          </p>
        </div>

        <div className="flex  items-center gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#898989"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
            />
            s
          </svg>

          <p className="text-[#202020] font-inter text-xs">
            {data?.job.employment_type}
          </p>
        </div>

        <div className="flex  items-center gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#898989"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
            />
            s
          </svg>

          <p className="text-[#202020] font-inter text-xs">
            Salary: {data?.job.currency}
            {data?.job.salary_from} - {data?.job.salary_to}{" "}
            {data?.job.salary_type}
          </p>
        </div>

        <div className="flex  items-center gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#898989"
            className="size-5"
          >
            <path
              fillRule="evenodd"
              d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
              clipRule="evenodd"
            />
          </svg>

          <p className="text-[#202020] font-inter text-xs">
            Account Manager: {data?.job.account_manager.first_name}{" "}
            {data?.job.account_manager.last_name}
          </p>
        </div>

        <div className="w-full h-[1px] bg-[#DBE1FF]"></div>

        <div className="flex justify-end">
          <div className="flex gap-3 items-center">
            <Link
              to={`/candidate/user-view/${data?.job.id}`}
              className="text-[#1CACBD] font-inter font-medium"
            >
              View Job Details
            </Link>

            <svg
              width="7"
              height="11"
              viewBox="0 0 7 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.02002 9.81836L5.35815 5.40942L1.02002 1.00047"
                stroke="#1CACBD"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteJobCard;
