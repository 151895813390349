import { ErrorMessage } from "formik";
import React from "react";
import ATMFieldError from "../ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../ATMFieldLabel/ATMFieldLabel";

const ATMTextField = ({
  name,
  value,
  label,
  placeholder,
  onChange,
  type = "text" | "number",
  disabled = false,
  size = "min-w-[200px]",
  required = false,
}) => {
  return (
    <div className="relative">
      {/* Label */}
      {label && (
        <ATMFieldLabel htmlFor={name} required={required}>
          {label}
        </ATMFieldLabel>
      )}

      {/* Input field */}
      <input
        disabled={disabled}
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`w-full px-2  h-[40px] rounded-md border  ${size}  outline-none`}
      />

      <ErrorMessage name={name}>
        {(errorMessage) => <ATMFieldError> {errorMessage} </ATMFieldError>}
      </ErrorMessage>
    </div>
  );
};

export default ATMTextField;
