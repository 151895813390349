import Cookies from "js-cookie";
import React, { useEffect, useRef } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { axiosRequest } from "../../api/api";
import apiSlice from "../../app/ApiSlice";
import { resetState } from "../../app/Slice/AuthSlice";

const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function RecruiterLogout() {
  const refreshToken = localStorage.getItem("refreshToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasCalledAPI = useRef(false); // To prevent multiple API calls in StrictMode

  useEffect(() => {
    const handleLogout = async () => {
      if (hasCalledAPI.current) return; // Skip if API already called
      hasCalledAPI.current = true; // Mark API as called

      if (!refreshToken) {
        navigate("/recruiter/login");
        return;
      }

      try {
        const response = await axiosRequest(
          `${baseUrl}/api/recruiter/logout/`,
          {
            method: "POST",
            data: { refresh_token: refreshToken },
          }
        );

        if (response.status === 205) {
          dispatch(resetState(""));
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("userType");
          localStorage.removeItem("recuriterProfile");
          localStorage.removeItem("recruiter_id");
          Cookies.remove("accessToken", { secure: true, sameSite: "Strict" });
          Cookies.remove("userType", { secure: true, sameSite: "Strict" });
          navigate("/recruiter/login");
          dispatch(apiSlice.util.resetApiState());
        } else if (response.status === 400) {
          // Handle error if needed
          // toast.error("Failed to logout");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // Handle error if needed
          // toast.error(error.response.data.message);
        }
      }
    };

    handleLogout();
  }, [refreshToken, navigate, dispatch]);

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          duration: 10000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 10000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </>
  );
}

export default RecruiterLogout;
