import { City, Country, State } from "country-state-city";
import { ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldError from "../../../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMFilePicker from "../../../../atoms/ATMFIlePicker/ATMFilePicker";
import ATMMultiSelect from "../../../../atoms/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "../../../../atoms/ATMSelect/ATMSelect";
import ATMTextField from "../../../../atoms/ATMTextField/ATMTextField";
import VideoRecorderModal from "../../../../components/candidate/VideoRecorderModal";
const languageOptions = [
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "Spanish",
    label: "Spanish",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "German",
    label: "German",
  },
  {
    value: "Chinese",
    label: "Chinese",
  },
  {
    value: "Japanese",
    label: "Japanese",
  },
  {
    value: "Russian",
    label: "Russian",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
  },
];

const ProfileDetailsForm = ({ formikProps, userData }) => {
  // const userData = useSelector((state) => state.candidateData.candidateData);
  const { values, setFieldValue, handleBlur, isSubmitting, handleChange } =
    formikProps;
  const [skills] = useState([
    { value: "User Research", label: "User Research" },
    { value: "Python", label: "Python" },
    { value: "UI Design", label: "UI Design" },
  ]);
  // const [isVideoRecorderOpen, setIsVideoRecorderOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  // const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const handleVideoUpload = (url) => {
    setVideoUrl(url);
  };

  useEffect(() => {
    setFieldValue("uploadvideo", videoUrl);
  }, [videoUrl, setFieldValue]);

  return (
    <div className="flex flex-col gap-4 my-4">
      <div className=" grid grid-cols-1  md:grid-cols-2 p-9  justify-between  gap-10 border-[1px] rounded-lg border-[#E2DCEA]">
        {/* Profile Photo Upload */}
        <div className="min-w-[300px]">
          <h1 className="text-sm font-inter font-medium text-[#344054]">
            Your Photo
          </h1>
          <p className="text-sm font-inter font-normal text-[#667085]">
            This will be displayed on your profile
          </p>
          <div className=" h-[200px] ">
            <ATMFilePicker
              name="profilePhoto"
              value={values.profilePhoto}
              onChange={(file) => setFieldValue("profilePhoto", file)}
              placeholder="Click to upload or drag and drop"
              accept="image/jpeg,image/png,image/gif"
            />
          </div>
        </div>

        {/* Introduction Video */}
        <div className="min-w-[300px] ">
          <div>
            <p className="text-[#344054] font-inter font-medium text-sm">
              Upload Your Introduction Video
            </p>
            <p className="text-[#667085] font-inter font-normal text-sm">
              This will be displayed on your profile.
            </p>
          </div>{" "}
          <div className=" flex justify-around items-center p-8 w-full h-[200px] border-[1px] border-[#E2DCEA] rounded-md">
            <div className=" flex  items-center justify-center text-center flex-col gap-3">
              <button
                type="button"
                onClick={openModal}
                className=" flex items-center justify-around gap-1  font-inter text-sm text-[#1CACBD] font-medium bg-[#1CACBD0F] rounded-xl"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                    stroke="#1CACBD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                    stroke="#1CACBD"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Record Video
              </button>
              <p className=" text-xs font-inter font-normal text-[#828799]">
                Tell us about your experience! You can record one right here
                using your webcam your journey.
              </p>
            </div>
            {modalIsOpen && (
              <VideoRecorderModal
                isOpen={modalIsOpen}
                onClose={closeModal}
                id={userData?.id}
                onVideoUpload={handleVideoUpload}
                videoNameRandom={`profile_video${Math.floor(
                  ((Math.random(1) * 23) / 10) * 78
                )}`}
              />
            )}
          </div>
          <div className="relative">
            <ErrorMessage name="uploadvideo">
              {(errorMessage) => (
                <ATMFieldError> {errorMessage} </ATMFieldError>
              )}
            </ErrorMessage>
          </div>
        </div>

        <div>
          <ATMTextField
            type="text"
            name="title"
            value={values.title}
            label="Title:"
            placeholder="Enter title"
            onChange={(e) => setFieldValue("title", e.target.value)}
            onBlur={handleBlur}
          />
        </div>

        {/* Date of Birth */}
        <div className="w-full">
          <ATMFieldLabel>Date Of Birth</ATMFieldLabel>

          <div className="w-full">
            <DatePicker
              id="dob"
              name="dob"
              selected={values.dob}
              onChange={(date) => setFieldValue("dob", date)}
              dateFormat="dd/MM/yyyy" // custom date format
              className="h-[40px] border px-2 w-full rounded-md outline-none"
            />
          </div>

          <div className="relative">
            <ErrorMessage name="dob">
              {(errorMessage) => (
                <ATMFieldError> {errorMessage} </ATMFieldError>
              )}
            </ErrorMessage>
          </div>
        </div>

        <div>
          <ATMMultiSelect
            name="languageData"
            value={values.languageData || []}
            onChange={(newValue) => setFieldValue("languageData", newValue)}
            options={languageOptions}
            label="Select language"
            placeholder="Select Language"
            keysToSelectoption={["Enter", ","]}
          />
        </div>

        <div className="">
          <ATMMultiSelect
            name="skillsData"
            value={values.skillsData || []}
            onChange={(newValue) => setFieldValue("skillsData", newValue)}
            options={skills}
            label="Add Your Skills"
            placeholder="Select Skills"
            keysToSelectoption={["Enter", ","]}
          />
        </div>

        {/* Profile Summary */}
        <div className="md:col-span-2 relative">
          <label className="mb-1 font-inter font-medium text-sm text-[#344054]">
            Profile Summary
          </label>
          <textarea
            placeholder="Write a brief summary about yourself..."
            className=" w-full h-[100px] border-[1px] border-[#E2DCEA] rounded-lg p-3 outline-none"
            name="profilesummary"
            value={values.profilesummary || ""}
            onChange={handleChange}
            onBlur={handleBlur}
          ></textarea>
          <ErrorMessage name="profilesummary">
            {(errorMessage) => <ATMFieldError> {errorMessage} </ATMFieldError>}
          </ErrorMessage>
        </div>
      </div>

      <div className="  p-9 rounded-lg border-[1px] border-[#E2DCEA]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-around  gap-4">
          {/* Location */}
          <ATMSelect
            required
            name={`country`}
            label="Country"
            options={Country.getAllCountries().map((country) => ({
              value: country.isoCode,
              label: country.name,
            }))}
            onChange={(newValue) => {
              setFieldValue(`country`, newValue);
              setFieldValue(`state`, null);
              setFieldValue(`city`, null);
            }}
            value={values?.country || null}
          />

          <ATMSelect
            name={`state`}
            label="State"
            options={
              values.country && values.country.value
                ? State.getStatesOfCountry(values.country.value).map(
                    (state) => ({
                      value: state.isoCode,
                      label: state.name,
                    })
                  )
                : []
            }
            onChange={(newValue) => {
              setFieldValue(`state`, newValue);
              setFieldValue(`city`, null);
            }}
            value={values?.state || null}
          />

          <ATMSelect
            name={`city`}
            label="City"
            value={values?.city || null}
            options={
              values.country?.value && values.state?.value
                ? City.getCitiesOfState(
                    values.country?.value,
                    values.state?.value
                  ).map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))
                : []
            }
            onChange={(newValue) => {
              setFieldValue(`city`, newValue);
            }}
          />
          <div>
            <ATMTextField
              type="number"
              name="zipCode"
              value={values.zipCode || ""}
              label="Zip Code"
              placeholder="Enter your zip code"
              onChange={(e) => setFieldValue("zipCode", e.target.value)}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>

      {/* Submit and Skip Buttons */}
      <div className=" flex ">
        <button
          type="submit"
          className="w-[124px] flex justify-center items-center h-[48px] rounded-md bg-[#1CACBD] text-white"
        >
          {isSubmitting ? (
            <ATMCircularProgress size="small" color="white" />
          ) : (
            <span> {userData?.date_of_birth ? "Save" : "Proceed"}</span>
          )}
        </button>
        {!userData?.date_of_birth && (
          <Link to="/candidate/profile/work-experience">
            <div className="ml-4 flex items-center justify-center w-[124px] h-[48px] rounded-md border-[1px] border-[#1CACBD] text-[#1CACBD]">
              Skip
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ProfileDetailsForm;
