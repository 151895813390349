import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Select from "react-select";
import { axiosCandidateRequest } from "../../../api/api";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMPagination from "../../../atoms/ATMPagination/ATMPagination";
import ATMFieldLabel from "../../../atoms/ATMFieldLabel/ATMFieldLabel";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const options = [
  { value: "", label: "All" },
  { value: "Applied", label: "Applied" },
  { value: "Shortlisted", label: "Shortlisted" },
];

const MyJobApplication = () => {
  const [userData, setuserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectValue, setSelectValue] = useState(options?.[0]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 8,
  });

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleItemsPerPageChange = (itemsPerPage) => {
    setPagination((prev) => ({ ...prev, itemsPerPage, currentPage: 1 }));
  };
  useEffect(() => {
    const getAllAplications = async () => {
      setIsLoading(true);
      try {
        const response = await axiosCandidateRequest(
          `${baseUrl}/api/candidate/job_applications/`,
          {
            method: "POST",
            data: { status: selectValue?.value },
          }
        );
        if (response.status === 200) {
          setuserData(response?.data);
          setIsLoading(false);
        } else if (response.status === 400) {
          toast.error("Failed to get data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    };
    getAllAplications();
  }, [selectValue]);

  return (
    <>
      <div className="p-2 flex justify-between border-b items-center ">
        <ATMHeading> My Applications</ATMHeading>

        <div className="flex items-center gap-3">
          <ATMFieldLabel>Status</ATMFieldLabel>
          <div className="border rounded-md  border-[#1CACBD]">
            <Select
              value={selectValue}
              onChange={(newValue) => setSelectValue(newValue)}
              options={options}
              placeholder=""
              isSearchable={false}
              className="custom-select  w-[135px]"
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "none",
                  boxShadow: "none",
                  "&:focus": {
                    border: "none",
                    boxShadow: "none",
                  },
                }),
              }}
            />
          </div>
        </div>
      </div>

      {/* <div className="px-2">
        <ul className="mt-5 flex text-xs border-b font-inter font-medium gap-5">
          {["Active Jobs", "Inactive Jobs"].map((type) => (
            <li
              key={type}
              className={`pb-2  cursor-pointer ${
                jobType === type ? "  border-b-2 border-[#101828]" : ""
              }`}
              onClick={() => handleJobTypeChange(type)}
            >
              {type}
            </li>
          ))}
        </ul>
      </div> */}
      {isLoading ? (
        <span className="flex justify-center h-full items-center">
          <ATMCircularProgress />
        </span>
      ) : (
        <div>
          {userData?.count === 0 ? (
            <span className="w-full flex justify-center mt-2">
              No Data Found
            </span>
          ) : (
            <div className="overflow-x-auto px-2 mt-2">
              <table className="w-full table-auto border-collapse text-[#667085]   text-xs ">
                <thead>
                  <tr className="border">
                    <th className="p-2  border-l  ">Job Title</th>
                    <th className="p-2  border-l  ">Job Status</th>
                    <th className="p-2  border-l  ">Company Details</th>
                    <th className="p-2  border-l  ">Account Manager</th>
                    <th className="p-2  border-l  ">Job Type</th>
                    <th className="p-2  border-l  ">Job Location</th>
                    <th className="p-2  border-l  ">Salary Offered</th>
                    <th className="p-2  border-l  ">Application Status</th>
                    <th className="p-2  border-l  "></th>
                  </tr>
                </thead>

                <tbody>
                  {userData?.results?.map((userData, index) => (
                    <tr
                      key={userData.id}
                      className={`odd:bg-white  hover:bg-blue-50 border-b text-center border-x text-xs font-normal`}
                    >
                      <td className="p-2 border-r border-b text-start ">
                        {userData?.job.title}
                      </td>
                      <td className="p-2 border-r border-b text-start ">
                        {userData?.job.status ? (
                          <span className=" text-green-500 px-4 rounded-md ">
                            Active
                          </span>
                        ) : (
                          <span className=" text-red-500 px-4 rounded-md ">
                            Inactive
                          </span>
                        )}
                      </td>
                      <td className="p-2 border-r ">
                        <div className="flex items-center gap-2 ">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          </div>
                          <div className="flex flex-col items-start">
                            <h1 className="text-slate-800">
                              {userData?.job.company.company_name}
                            </h1>
                            <p className="">
                              {userData?.job.company.website_url}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="p-2 border-r ">
                        {userData?.job.account_manager.first_name}{" "}
                        {userData?.job.account_manager.last_name}
                      </td>
                      <td className="p-2 border-r  ">
                        {userData?.job.aspect_of_work_location}
                      </td>
                      <td className="p-2 border-r  ">
                        {userData?.job.country}
                      </td>
                      <td className="p-2 border-r  ">
                        {userData?.job.currency} {userData?.job.salary_from} -
                        {userData?.job.salary_to} {userData?.job.salary_type}
                      </td>
                      <td className="p-2 border-r capitalize ">
                        {userData?.status === "shortlist" ? (
                          <span className="px-4  rounded-lg text-orange-500">
                            {userData?.status}
                          </span>
                        ) : (
                          <span className=" px-4  rounded-lg text-green-500">
                            {userData?.status}
                          </span>
                        )}
                      </td>
                      <td className="p-2 ">
                        <Link
                          to={`/candidate/aplication-view/${userData.id}/${userData?.job.id}`}
                          className="flex items-center text-[#1CACBD] text-xs gap-2"
                        >
                          See Details
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m8.25 4.5 7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      <div className="flex justify-end px-2 mt-2 ">
        {!isLoading && userData?.count !== 0 && (
          <ATMPagination
            totalItems={userData?.count}
            itemsPerPage={pagination.itemsPerPage}
            currentPage={pagination.currentPage}
            onPageChange={handlePageChange}
            itemsPerPageOptions={[4, 8, 12, 16, 20, 24, 48, 96]}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </div>
    </>
  );
};

export default MyJobApplication;
