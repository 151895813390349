import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  accessToken: localStorage.getItem("accessToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,

};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },

    resetState: () => {
      return initialState;
    },
  },
});

export const {
  setAccessToken,
  setRefreshToken,

  resetState,
} = authSlice.actions;

export default authSlice.reducer;
