import { configureStore } from "@reduxjs/toolkit";
import candidateDataReducer from "../features/candidateData/candidateData";
import recruiterJobReducer from "../features/recruiterJob/recruiterJob";
import refreshReducer from "../features/refresh/refresh";
import apiSlice from "./ApiSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import AuthSlice from "../app/Slice/AuthSlice";
import { fileExplorerSlice } from "./FileUploadSlice";
const store = configureStore({
  reducer: {
    auth: AuthSlice,
    candidateData: candidateDataReducer,
    recruiterJob: recruiterJobReducer,
    refresh: refreshReducer,

    [apiSlice.reducerPath]: apiSlice.reducer,
    [fileExplorerSlice.reducerPath]: fileExplorerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([apiSlice.middleware, fileExplorerSlice.middleware]),
});

setupListeners(store.dispatch);
export default store;
