import { Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { boolean, number, object, string } from "yup";
import UploadSingleResumeForm from "./UploadSingleResumeForm";
import { useResumeSubmitMutation } from "../Services/FormServices";

const UploadSingleResumeFormWrapper = ({ setView }) => {
  const rec_id = localStorage.getItem("recruiter_id");
  const org = localStorage.getItem("recuriterProfile");
  const org_id = org ? JSON.parse(org) : "";
  const [response, setResponse] = useState();
  const [careerData, setCareerData] = useState({});
  const [submit, setSubmit] = useState(false);
  const [resumeBinary, setResumeBinary] = useState(null);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [addSingle] = useResumeSubmitMutation();
  const [trackResumeData, setTrackResumeData] = useState([]);

  const initialValues = {
    first_name: careerData?.first_name || "",
    middle_name: careerData?.middle_name || "",
    last_name: careerData?.last_name || "",
    candidateEmailFromResume: careerData?.email || "",
    current_company: "",
    email: careerData?.email,
    contactNumber: careerData?.contact_number || "",
    experience: "",
    noticePeriod: "",
    currentSalaryCurrency: "",
    salaryCurrency: "",
    ctc: "",
    availableFrom: null,
    immediatelyAvailable: false,
    jobPreference: "",
    asPerStandard: true,
    salaryType: "",
    salaryTo: "",
    salaryFrom: "",
    information: "",
    country: { label: careerData?.location?.country } || null,
    state: { label: careerData?.location?.state } || null,
    city: { label: careerData?.location?.city } || null,
    zipCode: "",
    preferredLocations: [{ country: null, state: null, city: null }],
    candidate_linkdin_link: "",
    source: "",
    hiring: "",
    career_score_json: "",
    resume_link: "",
    recruiter_id: "",
    primarySkills:
      Object.keys(careerData?.skills?.primary || {}).map((el) => {
        return {
          label: el,
          value: el,
        };
      }) || "",
    secondarySkills:
      Object.keys(careerData?.skills?.secondary || {})?.map((el) => {
        return {
          label: el,
          value: el,
        };
      }) || "",
    tertiarySkills:
      Object.keys(careerData?.skills?.tertiary || {})?.map((el) => {
        return {
          label: el,
          value: el,
        };
      }) || "",
  };

  const validationSchema = object().shape({
    asPerStandard: boolean().required("Asperstander is required"),
    candidateEmailFromResume: string().email().required("Email is Required"),
    country: object().required("Country is required"),
    salaryFrom: number().test(
      "conditional-required",
      "Minimum Salary is required",
      function (value) {
        const { asPerStandard } = this.parent;
        if (!asPerStandard) {
          return value != null && value >= 0;
        }
        return true;
      }
    ),
    salaryTo: number()
      .test(
        "conditional-required",
        "Maximum Salary is required",
        function (value) {
          const { asPerStandard } = this.parent;
          // If asPerStandard is false, salaryTo must be defined
          if (!asPerStandard) {
            return value != null;
          }
          return true; // If asPerStandard is true, no requirement on salaryTo
        }
      )
      .test(
        "not-equal",
        "Maximum must be greater than Minimum",
        function (value) {
          const { salaryFrom, asPerStandard } = this.parent;
          // If asPerStandard is false, check that salaryTo is greater than salaryFrom
          if (!asPerStandard) {
            return value >= salaryFrom;
          }
          return true; // If asPerStandard is true, no comparison needed
        }
      ),
  });
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmit(true);
    const { career_score_json, resume_link, recruiter_id, ...filteredValues } =
      values;
    const formData = new FormData();
    formData.append(
      "career_score_json",
      JSON.stringify(trackResumeData?.response?.career_score_data)
    );
    formData.append("s3_folder_id", "");
    formData.append(
      "resume_link",
      trackResumeData?.response?.resume_s3_url?.s3_url || ""
    );
    formData.append("resume_uploaded_by_recruiter_id", rec_id);
    formData.append("candidate_form_data", JSON.stringify(filteredValues));
    formData.append("resume_file", resumeBinary);
    formData.append("form_data_uploaded", true);
    formData.append("email", values?.candidateEmailFromResume);
    formData.append("org_id", org_id?.company?.id);
    formData.append("tagged_jobs", JSON.stringify(selectedJobs));
    addSingle(formData).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.message);
        setSubmit(false);
      } else {
        if (res?.data?.success) {
          toast.success(res?.data?.message);

          setSubmit(false);
          if (res?.data?.Duplicate) {
            setResponse(res);
          } else {
            resetForm();
            setResumeBinary(null);
            setTrackResumeData([]);
            setSelectedJobs([]);
            setCareerData("");

            setView("Single");
          }
        } else {
          toast.error(res?.error?.data?.message);
          setSubmit(false);
        }
      }
    });
  };
  return (
    <div className="border p-4 mt-2 rounded-md flex flex-col gap-4 ">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <UploadSingleResumeForm
              formikProps={formikProps}
              setTrackResumeData={setTrackResumeData}
              submit={submit}
              setCareerData={setCareerData}
              resumeBinary={resumeBinary}
              setResumeBinary={setResumeBinary}
              trackResumeData={trackResumeData}
              selectedJobs={selectedJobs}
              setSelectedJobs={setSelectedJobs}
              org_id={org_id}
              response={response}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UploadSingleResumeFormWrapper;
