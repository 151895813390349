import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import Dailog from "../../components/common/Dailog";
import HeroHeading from "../../components/recruiters/HeroHeading";
import { resetPasswordSchema } from "../../schemas/recruiterSchema";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function ResetPassword() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await axios.post(
          `${baseUrl}/api/recruiter/new_password/${id}`,
          {
            password: values.password,
            password2: values.confirmPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 201) {
          resetForm();
          setOpen(true);
          setSubmitting(false);
        } else if (response.status === 400) {
          toast.error("Failed to add member");
        } else {
          toast.error("Unexpected status code: " + response.status);
        }
      } catch (error) {
        if (error.response) {
          // Server responded with a status code other than 2xx
          if (error.response.status === 400) {
            toast.error(
              error.response.data.message ||
                "Bad request. Please check your input."
            );
          } else if (error.response.status === 401) {
            toast.error(error.response?.data?.message);
          } else if (error.response.status === 404) {
            toast.error(error.response?.data?.message);
          } else if (error.response.status >= 500) {
            toast.error("Server error. Please try again later.");
          } else {
            toast.error("Unexpected error occurred: " + error.response.status);
          }
        } else if (error.request) {
          // No response was received (network error)
          toast.error("Network error. Please check your internet connection.");
        } else {
          // Something else went wrong in setting up the request
          toast.error("An error occurred while processing the request.");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <>
      <Dailog
        open={open}
        setOpen={setOpen}
        heading="Your password has been successfully reset! We recommend choosing a strong password to ensure your account security. Now, you can log in with your new password and continue using our platform."
        para="All set! Your password has been reset. Simply use your new password to log in and get right back to where you left off."
        btnText="Login"
        btnLink="/recruiter/login"
      />
      <div className="flex justify-between font-inter text-xs items-start w-full ">
        <div className="w-full h-full mx-auto max-w-screen-2xl flex justify-center mt-2 font-inter tex-xs ">
          <div className=" p-2 bg-white flex flex-col gap-4 w-full lg:w-[800px]">
            <div>
              <HeroHeading
                heading={
                  " Login to our portal which is one Step Closer to Efficiency"
                }
                para={` Welcome to our streamlined portal, where each login propels you
                into a world of effortless HR management.`}
              />
            </div>

            <div className="border border-[#E2DCEA]  rounded-lg ">
              <form
                onSubmit={formik.handleSubmit}
                className="w-full  flex flex-col gap-6 p-4"
              >
                <div className="w-full">
                  <ATMFieldLabel> Set your password</ATMFieldLabel>
                  <input
                    size="lg"
                    placeholder="Enter your password"
                    className="border w-full p-3 rounded-lg outline-none"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="password"
                    name="password"
                  />
                  {formik.errors.password && formik.touched.password ? (
                    <ATMFieldError>{formik.errors.password}</ATMFieldError>
                  ) : null}
                </div>
                <div className="w-full">
                  <ATMFieldLabel> Re-enter your password</ATMFieldLabel>
                  <input
                    size="lg"
                    placeholder="Enter your password"
                    className="border w-full p-3 rounded-lg outline-none"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="confirmPassword"
                    name="confirmPassword"
                  />
                  {formik.errors.confirmPassword &&
                  formik.touched.confirmPassword ? (
                    <ATMFieldError>
                      {formik.errors.confirmPassword}
                    </ATMFieldError>
                  ) : null}
                </div>
                <div className="w-full px-4">
                  <ol className="list-decimal flex flex-col gap-1 text-[#475467]">
                    <li>
                      Choose a password that is at least 8 characters long for
                      added security
                    </li>
                    <li>
                      Make your password strong by including a mix of uppercase
                      letters, lowercase letters, numbers, and special
                      characters
                    </li>
                  </ol>
                </div>
                <div className="w-full flex justify-end">
                  <button
                    type="submit"
                    className="w-[120px] flex gap-2 bg-[#1CACBD] items-center h-[40px] rounded-lg font-inter font-semibold justify-center text-white"
                  >
                    {formik.isSubmitting ? (
                      <ATMCircularProgress size="small" color="white" />
                    ) : (
                      <div className="flex items-center justify-center gap-2">
                        Submit
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 1 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        </span>
                      </div>
                    )}
                  </button>
                </div>
              </form>
              <div className="font-inter text-xs">
                <ATMFieldLabel>Want to reach out directly?</ATMFieldLabel>
                <div className="text-slate-600">
                  We're here to help!{" "}
                  <span
                    onClick={() => navigate("/recruiter/support")}
                    className="text-blue-500 cursor-pointer"
                  >
                    Contact our support
                  </span>{" "}
                  team with any questions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
