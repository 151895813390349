import { City, Country, State } from "country-state-city";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useGetAllMyOrgJobsQuery } from "../../../app/DashboardServices/DashboardServices";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMDateRangePicker from "../../../atoms/ATMDateRangePicker/ATMDateRangePicker";
import ATMFieldLabel from "../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import ATMMultiSelect from "../../../atoms/ATMMultiSelect/ATMMultiSelect";
import ATMPagination from "../../../atoms/ATMPagination/ATMPagination";
import { useFetchData } from "../../../hooks/useFetchData";
import { currencyType } from "../CreateJob";
import SendEmail from "./Send/SendEmail";
import {
  useGetTalentSearchBinayQuery,
  useMagicSearchQuery,
} from "./Service/TalentSearchService";
import TalentSearchCandidateView from "./TalentSearchCandidateView/TalentSearchCandidateView";
const formatDate = (dateString) => {
  if (dateString === null) return;
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const searchLabel = [
  {
    label: "Boolean Search",
    value: "Boolean Search",
  },
  {
    label: "Magic Search",
    value: "Magic Search",
  },
];
export const experienceData = [
  {
    label: "Less than Year",
    value: "Less than Year",
  },
  {
    label: "1 - 2 Year",
    value: "1 - 2 Year",
  },
  {
    label: "3 -5 Year",
    value: "3 -5 Year",
  },
  {
    label: "5 - 7 Year",
    value: "5 - 7 Year",
  },
  {
    label: "7 - 10 Year",
    value: "7 - 10 Year",
  },
  {
    label: "10 Year Above ",
    value: "10 Year Above ",
  },
];

// Options for the ATMCheckbox
const options = [
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
  { value: "Freelancer", label: "Freelancer" },
  { value: "Contract-Base", label: "Contract-Base" },
  { value: "Project-Base", label: "Project-Base" },
  { value: "Intern", label: "Intern" },
  { label: "On-Site", value: "On-Site" },
  { label: "Remote Working", value: "Remote Working" },
  { label: "Hybrid", value: "Hybrid" },
  { label: "Flexible", value: "Flexible" },
];
const customStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    border: "none",
    outlineColor: "white",
    boxShadow: "none",
    backgroundColor: "transparent",
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999,
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999,
  }),
};
const TalentSearchList = () => {
  const org = localStorage.getItem("recuriterProfile");
  const org_id = org ? JSON.parse(org) : "";
  const [selectValue, setSelectValue] = useState(searchLabel?.[0]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState(null);
  const [salaryFrom, setSalaryFrom] = useState(0);
  const [salaryTo, setSalaryTo] = useState(0);
  const [currency, setCurrency] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [job, setJob] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [boolensearchValue, setBooleanSearchValue] = useState("");
  const [jobData, setJobData] = useState("");
  const [searchData, setSearchData] = useState("");
  const [emailIsOpen, setEmailIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const buttonRef = useRef(null);
  const [filterData, setFilterData] = useState({
    salaryFrom: 0,
    currency: null,
    salaryTo: 0,
  });
  useEffect(() => {
    if (!boolensearchValue) {
      setSearchData("");
    }
  }, [boolensearchValue]);
  const paginationStates = {
    "Boolean Search": { currentPage: 1, itemsPerPage: 8 },
    "Magic Search": { currentPage: 1, itemsPerPage: 8 },
  };
  const [pagination, setPagination] = useState(
    paginationStates[selectValue?.label]
  );

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleItemsPerPageChange = (itemsPerPage) => {
    setPagination((prev) => ({ ...prev, itemsPerPage, currentPage: 1 })); // Reset to page 1 when changing items per page
  };

  const {
    data: profiles,
    isLoading,
    totalData,
  } = useFetchData(useGetTalentSearchBinayQuery, {
    body: {
      search: searchData,
      filter: {
        skills: skills?.map((el) => el.label),
        experience: experience,
        salaryFrom: Number(filterData?.salaryFrom) || 0,
        salaryCurrency: filterData?.currency || "",
        salaryTo: Number(filterData?.salaryTo) || 0,
        jobPreference: selectedValues || [],
        country: country?.label || null,
        state: state?.label || null,
        city: city?.label || null,
      },
      page: pagination.currentPage,
      size: pagination.itemsPerPage,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      is_filter:
        skills?.length === 0 &&
        experience === null &&
        filterData?.currency === null &&
        filterData?.salaryFrom === 0 &&
        country === null &&
        state === null &&
        city === null &&
        filterData?.salaryTo === 0 &&
        (selectedValues?.length === 0 || !selectedValues)
          ? false
          : true,
    },
    skip: selectValue?.label === "Magic Search",
  });
  const {
    data,
    isLoading: ismagicLoading,
    isFetching,
  } = useMagicSearchQuery(
    {
      job_id: jobData?.id,
      org_id: org_id?.company?.id || "",
      page: pagination.currentPage,
      size: pagination.itemsPerPage,
    },
    {
      skip:
        selectValue?.label === "Boolean Search" || jobData?.id === undefined,
    }
  );

  const {
    data: allOrgData,
    isLoading: orgisLoading,
    totalData: orgtotalData,
  } = useFetchData(useGetAllMyOrgJobsQuery, {
    body: {
      page: selectValue?.label === "Magic Search" ? pagination.currentPage : 1,
      size: selectValue?.label === "Magic Search" ? pagination.itemsPerPage : 8,
      search: selectValue?.label === "Magic Search" ? searchData : "",
    },
    skip:
      selectValue?.label === "Boolean Search" ||
      searchData === "" ||
      jobData?.title,
  });

  const [selectedProfiles, setSelectedProfiles] = useState({});

  const handleSelectAll = () => {
    const allSelected =
      Object.keys(selectedProfiles).length === profiles.length &&
      profiles.every((profile, ind) => selectedProfiles[ind] === true);

    const newSelection = allSelected
      ? Object.fromEntries(profiles.map((profile, ind) => [ind, false]))
      : Object.fromEntries(profiles.map((profile, ind) => [ind, true]));

    setSelectedProfiles(newSelection);
  };
  const handleMagicSelectAll = () => {
    const allSelected =
      Object.keys(selectedProfiles).length === data?.results?.length &&
      data?.results?.every((profile, ind) => selectedProfiles[ind] === true);

    const newSelection = allSelected
      ? Object.fromEntries(data?.results?.map((profile, ind) => [ind, false]))
      : Object.fromEntries(data?.results?.map((profile, ind) => [ind, true]));

    setSelectedProfiles(newSelection);
  };
  const anySelected = Object.values(selectedProfiles).includes(true);

  const handleCheckboxChange = (id) => {
    setSelectedProfiles((prevSelected) => ({
      ...prevSelected,
      [id]: !prevSelected[id],
    }));
  };

  const getSelectedProfilesEmails = profiles
    ?.filter((profile, index) => selectedProfiles[index])
    .map((profile) => profile?.email);
  const getmagicSelectedProfilesEmails = data?.results
    ?.filter((profile, index) => selectedProfiles[index])
    .map((profile) => profile?.email);

  useEffect(() => {
    setJobData("");
    setSearchValue("");
    setSearchData("");
    setSelectedProfiles("");
  }, [selectValue]);

  const handleSelectStartDate = (date) => {
    setStartDate(date);
  };

  const handleSelectEndDate = (date) => {
    setEndDate(date);
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div className="font-inter text-xs flex flex-col gap-4 py-1">
      <div className="flex justify-between items-center ">
        <ATMHeading> Talent Search</ATMHeading>
        <Link
          to={"/recruit/resume-upload "}
          className="border flex gap-2 items-center border-[#1CACBD] text-white  bg-[#1CACBD] py-1 font-semibold rounded-md px-4"
        >
          <svg
            width="26"
            height="26"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="2"
              y="2"
              width="20"
              height="20"
              rx="10"
              stroke="#D0D5DD"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="1 3"
            />
            <path
              d="M12.0002 7.33398V16.6673M7.3335 12.0007H16.6668"
              stroke="#F5F6F7"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Upload Resume
        </Link>
      </div>
      <div className="flex items-center justify-between gap-4">
        <div className="w-full">
          <label className="flex w-full items-center  bg-white border border-slate-400 rounded-md  focus:outline-1 ">
            <Select
              value={selectValue}
              onChange={(newValue) => setSelectValue(newValue)}
              options={searchLabel}
              placeholder=""
              isSearchable={false}
              className="custom-select min-w-[200px] border-r"
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "none",
                  boxShadow: "none",
                  "&:focus": {
                    border: "none",
                    boxShadow: "none",
                  },
                }),
              }}
            />
            <div className="relative w-full">
              <input
                className="w-full h-full py-3 pl-10 rounded-r-md outline-none"
                placeholder={
                  !searchData && "Search any job or any candidate....."
                }
                type="text"
                name="search"
                onChange={(e) =>
                  selectValue?.label === "Boolean Search"
                    ? setBooleanSearchValue(e.target.value)
                    : setSearchValue(e?.target?.value)
                }
                value={
                  selectValue?.label === "Boolean Search"
                    ? boolensearchValue
                    : searchValue
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    selectValue?.label === "Boolean Search"
                      ? setSearchData(boolensearchValue)
                      : setSearchData(searchValue);
                  }
                }}
              />
              <span className=" absolute left-1 top-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cursor-pointer"
                  onClick={() => {
                    selectValue?.label === "Boolean Search"
                      ? setSearchData(boolensearchValue)
                      : setSearchData(searchValue);
                  }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.802 14.2644H14.9922L14.7051 13.9876C15.7097 12.819 16.3145 11.3018 16.3145 9.65141C16.3145 5.97131 13.3315 2.98828 9.65141 2.98828C5.97131 2.98828 2.98828 5.97131 2.98828 9.65141C2.98828 13.3315 5.97131 16.3145 9.65141 16.3145C11.3018 16.3145 12.819 15.7097 13.9876 14.7051L14.2644 14.9922V15.802L19.3898 20.9172L20.9172 19.3898L15.802 14.2644ZM9.65108 14.2643C7.09859 14.2643 5.03814 12.2039 5.03814 9.65138C5.03814 7.09889 7.09859 5.03845 9.65108 5.03845C12.2036 5.03845 14.264 7.09889 14.264 9.65138C14.264 12.2039 12.2036 14.2643 9.65108 14.2643Z"
                    fill="#333333"
                  />
                </svg>
              </span>
              {jobData && (
                <div className="bg-gray-300 absolute top-2 px-2  py-1 rounded-md ml-9">
                  {jobData?.title}{" "}
                  <span
                    onClick={() => {
                      setJobData("");
                      setSearchValue("");
                      setSearchData("");
                    }}
                    className=" text-red-500  cursor-pointer px-1 font-semibold"
                  >
                    x
                  </span>
                </div>
              )}
              {searchValue && (
                <div
                  className="absolute left-0 right-0 pl-2 py-1 rounded-md mt-[5px] bg-blue-50 border cursor-pointer overflow-auto"
                  onClick={() => {
                    setSearchData(searchValue);
                  }}
                >
                  {searchValue}
                </div>
              )}
            </div>
          </label>
        </div>
      </div>
      {selectValue?.label === "Boolean Search" && (
        <div className="flex gap-3 border-b  border-[#D0D5DD]">
          <ul className=" flex gap-6 font-medium text-[#667085] cursor-pointer">
            <li
              className={`pb-2 flex items-center gap-1 ${
                job === "All" ? "border-b-2 border-[#101828]" : ""
              } text-[#101828]`}
              onClick={() => setJob("All")}
            >
              {searchData ? "Results" : " All Candidates "}

              {selectValue?.label === "Boolean Search" && (
                <span className="bg-slate-200 text-[9px] flex justify-center items-center  rounded-full w-5 h-5">
                  {totalData}
                </span>
              )}
            </li>
            {searchData && totalData > 0 && (
              <span className="ml-5 bg-gray-200 px-4 py-1 h-fit rounded-sm ">
                <span className="capitalize">query tree</span> :-{" "}
                <span className="text-orange-500">
                  {profiles[0]?.query_tree}
                </span>
              </span>
            )}
            {/* <li
              onClick={() => setJob("External")}
              className={`pb-2 ${
                job === "External" ? "border-b-2 border-[#101828]" : ""
              } text-[#101828]`}
            >
              External Candidates
            </li>
            <li
              className={`pb-2 ${
                job === "JivaHire" ? "border-b-2 border-[#101828]" : ""
              } text-[#101828]`}
              onClick={() => setJob("JivaHire")}
            >
              JivaHire Candidates
            </li>
            <li
              className={`pb-2 ${
                job === "Company Resource Pool"
                  ? "border-b-2 border-[#101828]"
                  : ""
              } text-[#101828]`}
              onClick={() => setJob("Company Resource Pool")}
            >
              Company Resource Pool
            </li>
            <li
              className={`pb-2 ${
                job === "My Uploaded Resumes"
                  ? "border-b-2 border-[#101828]"
                  : ""
              } text-[#101828]`}
              onClick={() => setJob("My Uploaded Resumes")}
            >
              My Uploaded Resumes
            </li> */}
          </ul>
        </div>
      )}
      {selectValue?.label === "Boolean Search" && (
        <div className="flex items-start gap-4 justify-between flex-wrap">
          <div>
            <div className="flex items-center gap-4 flex-wrap">
              <button
                className="px-4 h-[38px] min-w-[110px] text-[#1CACBD] border border-[#1CACBD] rounded-md"
                onClick={handleSelectAll}
              >
                {profiles.every(
                  (profile, ind) => selectedProfiles[ind] === true
                )
                  ? "Unselect All"
                  : "Select All"}
              </button>
              <button
                className="px-4 h-[38px] min-w-[110px] text-white border bg-[#1CACBD] border-[#1CACBD] rounded-md disabled:bg-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed"
                disabled={!anySelected}
                onClick={() => setEmailIsOpen(true)}
              >
                {" "}
                Send Email
              </button>
              {selectValue?.label === "Magic Search" && (
                <button
                  className="px-4 h-[38px] min-w-[110px] text-white border bg-[#1CACBD] border-[#1CACBD] rounded-md disabled:bg-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed"
                  disabled={true}
                >
                  Invite for Interview
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {selectValue?.label === "Boolean Search" && (
        <div className="border-t p-2">
          <div className="grid xl:grid-cols-12 lg:grid-cols-7 md:grid-cols-4 grid-cols-1 items-start gap-4  justify-between flex-wrap">
            <div
              className={`xl:col-span-3 lg:col-span-2 md:col-span-2 relative ${
                skills?.length !== 0 && "border-[#1CACBD] border rounded-md"
              }`}
            >
              {skills?.length !== 0 && (
                <div
                  className=" text-lg absolute top-[-9px] border-red-400 bg-white cursor-pointer text-red-400 border w-5 h-5 text-center flex items-center justify-center rounded-full right-[-7px] z-10"
                  onClick={() => setSkills([])}
                >
                  x
                </div>
              )}
              <ATMMultiSelect
                value={skills}
                onChange={(newValue) => setSkills(newValue)}
                options={[]}
                placeholder="Type your skills"
                keysToSelectoption={["Enter", ","]}
              />
            </div>

            <div
              className={`border relative rounded-md xl:col-span-2 lg:col-span-2 md:col-span-2 items-center   ${
                experience === null ? "" : "border-[#1CACBD]"
              }`}
            >
              {experience !== null && (
                <div
                  className=" text-lg absolute top-[-9px] border-red-400 bg-white cursor-pointer text-red-400 border w-5 h-5 text-center flex items-center justify-center rounded-full right-[-7px] z-10"
                  onClick={() => setExperience(null)}
                >
                  x
                </div>
              )}
              <Select
                options={experienceData}
                onChange={(newValue) => setExperience(newValue)}
                placeholder="Experience"
                value={experience || null}
                styles={customStyles}
                isSearchable={false}
              />
            </div>

            <div
              className={`border rounded-md  xl:col-span-2 lg:col-span-2 md:col-span-2  relative ${
                country === null ? "" : "border-[#1CACBD]"
              }`}
            >
              {country !== null && (
                <div
                  className=" text-lg absolute top-[-9px] border-red-400 bg-white cursor-pointer text-red-400 border w-5 h-5 text-center flex items-center justify-center rounded-full right-[-7px] z-10"
                  onClick={() => setCountry(null)}
                >
                  x
                </div>
              )}
              <Select
                required
                name={`country`}
                options={Country.getAllCountries().map((country) => ({
                  value: country.isoCode,
                  label: country.name,
                }))}
                onChange={(newValue) => {
                  setCountry(newValue);
                  setState(null);
                  setCity(null);
                }}
                value={country}
                styles={customStyles}
                placeholder="Country"
                className="w-full"
              />
            </div>

            <div
              className={`border rounded-md xl:col-span-2 lg:col-span-2 md:col-span-2   relative ${
                state === null ? "" : "border-[#1CACBD]"
              }`}
            >
              {state !== null && (
                <div
                  className=" text-lg absolute top-[-9px] border-red-400 bg-white cursor-pointer text-red-400 border w-5 h-5 text-center flex items-center justify-center rounded-full right-[-7px] z-10"
                  onClick={() => setState(null)}
                >
                  x
                </div>
              )}
              <Select
                name={`state`}
                label="State"
                options={
                  country && country.value
                    ? State.getStatesOfCountry(country.value).map((state) => ({
                        value: state.isoCode,
                        label: state.name,
                      }))
                    : []
                }
                onChange={(newValue) => {
                  setState(newValue);
                  setCity(null);
                }}
                value={state}
                styles={customStyles}
                placeholder="State"
              />
            </div>

            <div
              className={`border xl:col-span-2 lg:col-span-2 md:col-span-2   rounded-md relative  ${
                city === null ? "" : "border-[#1CACBD]"
              }`}
            >
              {city !== null && (
                <div
                  className=" text-lg absolute top-[-9px] border-red-400 bg-white cursor-pointer text-red-400 border w-5 h-5 text-center flex items-center justify-center rounded-full right-[-7px] z-10"
                  onClick={() => setCity(null)}
                >
                  x
                </div>
              )}
              <Select
                name={`city`}
                label="City"
                value={city}
                options={
                  country?.value && state?.value
                    ? City.getCitiesOfState(country?.value, state?.value).map(
                        (city) => ({
                          value: city.name,
                          label: city.name,
                        })
                      )
                    : []
                }
                onChange={(newValue) => {
                  setCity(newValue);
                }}
                placeholder="City"
                styles={customStyles}
              />
            </div>

            <div
              className={`border rounded-md xl:col-span-3 lg:col-span-2 md:col-span-2 relative ${
                selectedValues.length !== 0 && "border-[#1CACBD]"
              }`}
            >
              {selectedValues.length !== 0 && (
                <div
                  className=" text-lg absolute top-[-9px] border-red-400 bg-white cursor-pointer text-red-400 border w-5 h-5 text-center flex items-center justify-center rounded-full right-[-7px] z-10"
                  onClick={() => setSelectedValues([])}
                >
                  x
                </div>
              )}
              <Select
                options={options}
                onChange={(newValue) => setSelectedValues(newValue)}
                placeholder="Job Preference"
                value={selectedValues || null}
                styles={customStyles}
                isSearchable={false}
                isMulti
                isClearable={false}
              />
            </div>

            {/* <div className="relative flex gap-4 items-center flex-wrap"> */}
            <div
              ref={buttonRef}
              className="relative  xl:col-span-2 lg:col-span-2 md:col-span-2 "
            >
              <button
                className={`px-4 h-[40px] text-slate-500 text-xs border w-full rounded-md flex items-center ${
                  filterData?.currency === null &&
                  filterData?.salaryFrom === 0 &&
                  filterData?.salaryTo === 0
                    ? ""
                    : " border-[#1CACBD]"
                }  gap-2 ${filterOpen ? "bg-blue-100" : ""} `}
                onClick={() => setFilterOpen(!filterOpen)}
              >
                Salary Range
              </button>
              {filterData?.currency === null &&
              filterData?.salaryFrom === 0 &&
              filterData?.salaryTo === 0 ? (
                ""
              ) : (
                <div
                  className=" text-lg absolute top-[-9px] border-red-400 bg-white cursor-pointer text-red-400 border w-5 h-5 text-center flex items-center justify-center rounded-full right-[-7px] z-auto"
                  onClick={() => {
                    setSalaryFrom(0);
                    setCurrency(null);
                    setSalaryTo(0);
                    setFilterData({
                      salaryFrom: 0,
                      currency: null,
                      salaryTo: 0,
                    });
                  }}
                >
                  x
                </div>
              )}
              {filterOpen && (
                <div className=" z-20   border rounded-lg p-4   gap-4  bg-white top-10 overflow-auto  absolute right-0 ">
                  <div className="">
                    <div className="grid-cols-1 md:grid-cols-2 gap-4 grid  rounded-md">
                      <div className=" md:col-span-2">
                        <ATMFieldLabel>Select Currency</ATMFieldLabel>
                        <Select
                          value={currency}
                          onChange={(newValue) => setCurrency(newValue)}
                          options={currencyType}
                          placeholder=""
                          className=""
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              outline: "none",
                              boxShadow: "none",
                              border: "1px solid #ccc",
                              "&:hover": {
                                border: "1px solid #ccc",
                              },
                              "&:focus": {
                                border: "1px solid #ccc",
                              },
                            }),
                            menu: (provided) => ({
                              ...provided,
                              maxHeight: 100,
                              overflowY: "hidden",
                            }),
                          }}
                        />
                      </div>

                      <div className="">
                        <ATMFieldLabel>Minimum</ATMFieldLabel>
                        <input
                          placeholder="type here...."
                          className=" border border-gray-300 w-full p-[10px] rounded-md outline-none"
                          value={salaryFrom}
                          onChange={(e) => setSalaryFrom(e.target.value)}
                        />
                      </div>

                      <div className="">
                        <ATMFieldLabel>Maximum</ATMFieldLabel>
                        <input
                          type="number"
                          placeholder="type here..."
                          className=" border border-gray-300 w-full p-[10px] rounded-md outline-none"
                          value={salaryTo}
                          onChange={(e) => setSalaryTo(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1  md:grid-cols-3 flex-wrap gap-4 mt-4">
                    <div
                      className=" border border-blue-300 text-center h-8 flex items-center justify-center rounded-md text-blue-300  cursor-pointer"
                      onClick={() => {
                        setSalaryFrom(0);
                        setCurrency(null);
                        setSalaryTo(0);
                        setFilterData({
                          salaryFrom: 0,
                          currency: null,
                          salaryTo: 0,
                        });
                      }}
                    >
                      Clear
                    </div>
                    <div
                      className=" border border-red-500 text-center h-8 flex items-center justify-center rounded-md text-red-500  cursor-pointer"
                      onClick={() => setFilterOpen(false)}
                    >
                      Cancel
                    </div>
                    <div
                      className=" border border-[#1CACBD] text-center h-8 flex items-center justify-center rounded-md bg-[#1CACBD] text-white cursor-pointer"
                      onClick={() => {
                        setFilterData({
                          salaryFrom: Number(salaryFrom) || 0,
                          currency: currency?.value || "",
                          salaryTo: Number(salaryTo) || 0,
                        });
                        setFilterOpen(!filterOpen);
                        setPagination({ currentPage: 1, itemsPerPage: 8 });
                      }}
                    >
                      Apply
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="xl:col-span-3 lg:col-span-2 md:col-span-2">
              <ATMDateRangePicker
                startDate={startDate}
                endDate={endDate}
                onSelectStartDate={handleSelectStartDate}
                onSelectEndDate={handleSelectEndDate}
                onClear={handleClear}
                dateFilterKeys={[]}
              />
            </div>
            <div
              className="w-[100px] border text-[#1CACBD] text-center h-[40px] flex items-center justify-center rounded-md border-[#1CACBD] cursor-pointer"
              onClick={() => {
                setSkills([]);
                setSalaryFrom(0);
                setCurrency(null);
                setExperience(null);
                setSalaryTo(0);
                setSelectedValues([]);
                setCity(null);
                setCountry(null);
                setState(null);
                setFilterData({
                  salaryFrom: 0,
                  currency: null,
                  salaryTo: 0,
                });
                setStartDate(null);
                setEndDate(null);
              }}
            >
              Clear All Filter
            </div>
          </div>
        </div>
      )}

      {selectValue?.label === "Boolean Search" && (
        <div className="flex gap-2  ">
          {isLoading ? (
            <div className="flex justify-center items-center w-full  ">
              <ATMCircularProgress />
            </div>
          ) : (
            <div className="w-full">
              {profiles?.length > 0 ? (
                <TalentSearchCandidateView
                  selectedProfiles={selectedProfiles}
                  handleCheckboxChange={handleCheckboxChange}
                  profiles={profiles}
                />
              ) : (
                <span className="w-full flex justify-center">
                  No Data Found
                </span>
              )}
            </div>
          )}
        </div>
      )}

      {selectValue?.label === "Magic Search" && (
        <div>
          <div
            className={`pb-2  border-b font-semibold border-[#101828] text-[#101828] mb-2`}
          >
            <span className="flex items-center ">
              Results
              {jobData?.id && data?.count && (
                <span className="bg-slate-400 text-[9px] w-5 h-5 flex justify-center items-center ml-2 rounded-full ">
                  {data?.count || ""}
                </span>
              )}
            </span>
          </div>
          <div>
            {searchData && orgisLoading && (
              <div className="flex justify-center h-full">
                {" "}
                <ATMCircularProgress />
              </div>
            )}
            <div>
              {searchData &&
              !orgisLoading &&
              !jobData &&
              allOrgData?.length === 0 ? (
                <span className="w-full flex justify-center">
                  No Data Found
                </span>
              ) : (
                <div className=" text-xs font-inter grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {searchData &&
                    !orgisLoading &&
                    !jobData &&
                    allOrgData?.map((el) => {
                      return (
                        <div
                          key={el?.id}
                          className={`flex flex-col gap-2 rounded-xl bg-[#F7F8FD] border-2 cursor-pointer  p-5  ${
                            jobData?.id === el?.id
                              ? "border-blue-700 border-2"
                              : ""
                          }`}
                          onClick={() => {
                            setSearchValue("");
                            setJobData(el);
                            setSearchData(el?.title);
                          }}
                        >
                          <div className="">
                            <h1 className="text-[#202020] font-semibold text-xs">
                              {el?.title}
                            </h1>
                          </div>
                          <Link
                            to={`/recruit/job-status/recruiter-view/${el?.id}`}
                            className="text-[#1F42FA]  font-medium border-b-blue-400 border-b w-fit"
                          >
                            View Job Details
                          </Link>

                          <div className="flex items-center justify-between gap-2">
                            <div className="flex  items-center gap-1">
                              <svg
                                width="16"
                                height="14"
                                viewBox="0 0 11 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M5.10803 0.851562C2.30993 0.851562 0.046875 3.00535 0.046875 5.66834C0.046875 9.28092 5.10803 14.6138 5.10803 14.6138C5.10803 14.6138 10.1692 9.28092 10.1692 5.66834C10.1692 3.00535 7.90612 0.851562 5.10803 0.851562ZM5.1078 7.38945C4.11002 7.38945 3.30024 6.61876 3.30024 5.66917C3.30024 4.71958 4.11002 3.94889 5.1078 3.94889C6.10557 3.94889 6.91535 4.71958 6.91535 5.66917C6.91535 6.61876 6.10557 7.38945 5.1078 7.38945Z"
                                  fill="#898989"
                                />
                              </svg>
                              <p className="text-[#202020] mt-1  font-normal">
                                Office:{el?.city || el?.state || el?.country}
                              </p>
                            </div>
                            <div className="flex  items-center gap-1 ">
                              <svg
                                width="16"
                                height="14"
                                viewBox="0 0 16 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M13.8577 3.53565H11.6886V2.15943C11.6886 1.39562 11.0451 0.783203 10.2425 0.783203H5.90441C5.10186 0.783203 4.45837 1.39562 4.45837 2.15943V3.53565H2.2893C1.48675 3.53565 0.843262 4.14807 0.843262 4.91187V12.4811C0.843262 13.2449 1.48675 13.8573 2.2893 13.8573H13.8577C14.6602 13.8573 15.3037 13.2449 15.3037 12.4811V4.91187C15.3037 4.14807 14.6602 3.53565 13.8577 3.53565ZM5.9045 2.16056H10.2426V3.5373H5.9045V2.16056ZM13.8582 12.4824H2.28982V11.1056H13.8582V12.4824ZM13.8577 9.04006H2.28933V4.91139H4.4584V6.28762H5.90444V4.91139H10.2426V6.28762H11.6886V4.91139H13.8577V9.04006Z"
                                  fill="#898989"
                                />
                              </svg>
                              <p className="text-[#202020] mt-1 font-normal">
                                {el?.aspect_of_work_location}
                              </p>
                            </div>
                          </div>

                          <div className="flex  items-center gap-1">
                            <svg
                              width="16"
                              height="14"
                              viewBox="0 0 16 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.8577 3.53565H11.6886V2.15943C11.6886 1.39562 11.0451 0.783203 10.2425 0.783203H5.90441C5.10186 0.783203 4.45837 1.39562 4.45837 2.15943V3.53565H2.2893C1.48675 3.53565 0.843262 4.14807 0.843262 4.91187V12.4811C0.843262 13.2449 1.48675 13.8573 2.2893 13.8573H13.8577C14.6602 13.8573 15.3037 13.2449 15.3037 12.4811V4.91187C15.3037 4.14807 14.6602 3.53565 13.8577 3.53565ZM5.9045 2.16056H10.2426V3.5373H5.9045V2.16056ZM13.8582 12.4824H2.28982V11.1056H13.8582V12.4824ZM13.8577 9.04006H2.28933V4.91139H4.4584V6.28762H5.90444V4.91139H10.2426V6.28762H11.6886V4.91139H13.8577V9.04006Z"
                                fill="#898989"
                              />
                            </svg>
                            <p className="text-[#202020] mt-1  font-normal ">
                              Salary:{" "}
                              {el?.salary_from === 0 ? (
                                "--"
                              ) : (
                                <span>
                                  {el?.currency} {el?.salary_from}-
                                  {el?.salary_to}
                                </span>
                              )}
                            </p>
                          </div>

                          <div className="flex items-start gap-1">
                            <svg
                              width="16"
                              height="14"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_9326_55716)">
                                <path
                                  d="M7.82555 6.90904C9.39631 6.90904 10.6697 5.72048 10.6697 4.25432C10.6697 2.78817 9.39631 1.59961 7.82555 1.59961C6.25479 1.59961 4.98145 2.78817 4.98145 4.25432C4.98145 5.72048 6.25479 6.90904 7.82555 6.90904Z"
                                  stroke="#898989"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M3.3167 13.4446H12.3347C13.334 13.4446 14.1037 12.6426 13.6371 11.8178C12.9506 10.6046 11.3856 9.15625 7.8257 9.15625C4.26576 9.15625 2.70077 10.6046 2.01431 11.8178C1.54764 12.6426 2.31739 13.4446 3.3167 13.4446Z"
                                  stroke="#898989"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_9326_55716">
                                  <rect
                                    width="14.0989"
                                    height="13.1601"
                                    fill="white"
                                    transform="translate(0.776367 0.941406)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>

                            <p className="text-[#202020]  font-normal">
                              Account Manager: {el?.account_manager?.first_name}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          {jobData?.id && data?.results?.length > 0 && (
            <div className="flex items-center gap-4 justify-between">
              <div className="flex items-center gap-4">
                <button
                  className="px-4 h-[38px] min-w-[110px] text-[#1CACBD] border border-[#1CACBD] rounded-md"
                  onClick={handleMagicSelectAll}
                >
                  {data?.results?.every(
                    (profile, ind) => selectedProfiles[ind] === true
                  )
                    ? "Unselect All"
                    : "Select All"}
                </button>
                <button
                  className="px-4 h-[38px] min-w-[110px] text-white border bg-[#1CACBD] border-[#1CACBD] rounded-md disabled:bg-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed"
                  disabled={!anySelected}
                  onClick={() => setEmailIsOpen(true)}
                >
                  {" "}
                  Send Email
                </button>
                <button
                  className="px-4 h-[38px] min-w-[110px] text-white border bg-[#1CACBD] border-[#1CACBD] rounded-md disabled:bg-gray-300 disabled:border-gray-300 disabled:cursor-not-allowed"
                  disabled={true}
                >
                  Invite for Interview
                </button>
              </div>
            </div>
          )}

          {jobData?.id && (
            <div className="w-full mt-4">
              {ismagicLoading && isFetching ? (
                <div className="flex justify-center h-full">
                  {" "}
                  <ATMCircularProgress />
                </div>
              ) : (
                <div>
                  {data?.results?.length > 0 ? (
                    <TalentSearchCandidateView
                      selectedProfiles={selectedProfiles}
                      handleCheckboxChange={handleCheckboxChange}
                      profiles={data?.results}
                      score={true}
                    />
                  ) : (
                    <span className="w-full flex justify-center">
                      No Data Found
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {selectValue?.label === "Magic Search" ? (
        <div>
          <SendEmail
            onClose={() => setEmailIsOpen(false)}
            isOpen={emailIsOpen}
            getSelectedProfilesEmails={getmagicSelectedProfilesEmails}
          />
        </div>
      ) : (
        <div>
          <SendEmail
            onClose={() => setEmailIsOpen(false)}
            isOpen={emailIsOpen}
            getSelectedProfilesEmails={getSelectedProfilesEmails}
          />
        </div>
      )}
      {selectValue?.label === "Magic Search" ? (
        <div className="flex justify-end">
          {jobData?.id ? (
            <div>
              {data?.results.length > 0 && (
                <ATMPagination
                  totalItems={data?.count}
                  itemsPerPage={pagination.itemsPerPage}
                  currentPage={pagination.currentPage}
                  onPageChange={handlePageChange}
                  itemsPerPageOptions={[4, 8, 12, 16, 20, 24, 48, 96]}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              )}
            </div>
          ) : (
            <div>
              {searchData && allOrgData?.length > 0 && (
                <ATMPagination
                  totalItems={orgtotalData}
                  itemsPerPage={pagination.itemsPerPage}
                  currentPage={pagination.currentPage}
                  onPageChange={handlePageChange}
                  itemsPerPageOptions={[4, 8, 12, 16, 20, 24, 48, 96]}
                  onItemsPerPageChange={handleItemsPerPageChange}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-end">
          {isLoading || !totalData ? (
            ""
          ) : (
            <ATMPagination
              totalItems={totalData}
              itemsPerPage={pagination.itemsPerPage}
              currentPage={pagination.currentPage}
              onPageChange={handlePageChange}
              itemsPerPageOptions={[4, 8, 12, 16, 20, 24, 48, 96]}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TalentSearchList;
