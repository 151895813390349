import { City, Country, State } from "country-state-city";
import { FieldArray, Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { axiosCandidateFromRequest } from "../../../../api/formApi";
import ATMSelect from "../../../../atoms/ATMSelect/ATMSelect";
import ATMTextField from "../../../../atoms/ATMTextField/ATMTextField";
import ATMMultiSelect from "../../../../atoms/ATMMultiSelect/ATMMultiSelect";
import ATMCurrencyAmount from "../../../../atoms/ATMCurrencyAmount/ATMCurrencyAmount";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";

const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;
const options = [
  { label: "On-Site", value: "On-Site" },
  { label: "Remote Working", value: "Remote Working" },
  { label: "Hybrid", value: "Hybrid" },
  { label: "Flexible", value: "Flexible" },
];

const ApplyForm = () => {
  const { id, id1 } = useParams();

  const navigate = useNavigate();
  const userData = useSelector((state) => state.candidateData.candidateData);

  const initialValues = {
    jobPreference: "",
    miscellaneousInformation: "",
    noticePeriod: "",
    salaryExpectation: {
      asPerStandard: false,
      minimum: "",
      maximum: "",
      currency: "",
    },
    availableFrom: null,
    immediatelyAvailable: false,
    current_company: "",
    selectedHobbies: [],
    country: "",
    state: "",
    city: "",
    zipCode: "",
    preferredLocations: [{ country: "", state: "", city: "" }],
  };

  const validationSchema = Yup.object().shape({
    // jobPreference: Yup.string().required("Job preference is required"),
    // noticePeriod: Yup.number()
    //   .required("Notice period is required")
    //   .positive()
    //   .integer(),
    // availableFrom: Yup.date()
    //   .nullable()
    //   .required("Available from date is required"),
    // salaryExpectation: Yup.object({
    //   minimum: Yup.number().when("asPerStandard", {
    //     is: false,
    //     then: Yup.number().required("Minimum salary is required").positive(),
    //   }),
    //   maximum: Yup.number().when("asPerStandard", {
    //     is: false,
    //     then: Yup.number().required("Maximum salary is required").positive(),
    //   }),
    //   currency: Yup.string().when("asPerStandard", {
    //     is: false,
    //     then: Yup.string().required("Currency is required"),
    //   }),
    // }),
  });

  const onSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("job", id);
      formData.append("org_id", id1);

      formData.append("candidate_form_data", JSON.stringify(values));

      const response = await axiosCandidateFromRequest(
        `${baseUrl}/api/candidate/apply/job/`,
        { method: "POST", data: formData }
      );

      if (response.status === 201) {
        toast.success("Data updated successfully");
        navigate(`/recorder/${response.data.data.job.id}`);
      } else {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleChange }) => (
        <Form className=" flex flex-col gap-4 px-2 my-2">
          <div>
            <h1 className="text-[#333333] text-xl font-inter font-semibold capitalize">
              Hello, {userData?.first_name} {userData?.last_name}
            </h1>
            <div className="w-fit rounded-lg bg-[#F0EC913D] px-[12px] py-[8px]">
              <p className="text-[#303030] text-xs font-inter font-semibold">
                Below information is valid only for this job application and it
                will not be part of your profile.
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            {/* Form Fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 font-inter text-xs border p-5 rounded-md ">
              <div>
                <ATMSelect
                  name={`jobPreference`}
                  label="Job Preference"
                  options={options}
                  onChange={(newValue) => {
                    setFieldValue(`jobPreference`, newValue.value);
                  }}
                  value={values?.jobPreference}
                />
              </div>
              <div>
                <ATMTextField
                  type="text"
                  name="current_company"
                  value={values.current_company}
                  label="Current Company"
                  placeholder="Enter your Current Company"
                  onChange={(e) =>
                    setFieldValue("current_company", e.target.value)
                  }
                />
              </div>

              <div className="">
                <ATMMultiSelect
                  name="skill"
                  value={values.skill || []}
                  onChange={(newValue) => setFieldValue("skill", newValue)}
                  options={[]}
                  label="Skills"
                  placeholder="Select Skills"
                  keysToSelectoption={["Enter", ","]}
                />
              </div>

              <div>
                <ATMTextField
                  type="text"
                  name="experience"
                  value={values.experience || ""}
                  label=" Total Years of Experience"
                  placeholder="Enter your Total Years of Experience"
                  onChange={(e) => setFieldValue("experience", e.target.value)}
                />
              </div>

              <div>
                <ATMTextField
                  type="text"
                  name="noticePeriod"
                  value={values.noticePeriod || ""}
                  label="Notice Period (Days)"
                  placeholder="Enter your Notice Period (Days)"
                  onChange={(e) =>
                    setFieldValue("noticePeriod", e.target.value)
                  }
                />
              </div>
              <div>
                <ATMCurrencyAmount
                  currencyValue={values.currentSalaryCurrency}
                  onCurrencyChange={(newValue) =>
                    setFieldValue("currentSalaryCurrency", newValue)
                  }
                  name="ctc"
                  label="Current Salary (CTC)"
                  placeholder="Enter your Current Salary (CTC)"
                  value={values.ctc}
                  onChange={(e) => setFieldValue("ctc", e.target.value)}
                />
              </div>

              <div>
                <ATMFieldLabel>Available From</ATMFieldLabel>
                <input
                  type="date"
                  className="w-full px-3 h-[40px] border rounded-md outline-none"
                  name="availableFrom"
                  value={values.availableFrom}
                  onChange={(e) =>
                    setFieldValue("availableFrom", e.target.value)
                  }
                  disabled={values.immediatelyAvailable}
                />
                <div className="flex items-center mt-1">
                  <input
                    type="checkbox"
                    checked={values.immediatelyAvailable}
                    onChange={() =>
                      setFieldValue(
                        "immediatelyAvailable",
                        !values.immediatelyAvailable
                      )
                    }
                    className="mr-2"
                  />
                  <label className="font-medium text-[11px]">
                    Immediately Available
                  </label>
                </div>
              </div>

              <div className="md:col-span-2">
                <ATMFieldLabel>Salary Expectation </ATMFieldLabel>
                <div className=" border rounded-md p-4 flex flex-col gap-1">
                  <div className="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      name="asPerStandard"
                      checked={values.asPerStandard}
                      onChange={() => {
                        setFieldValue("asPerStandard", !values.asPerStandard);
                        setFieldValue("salaryCurrency", null);
                        setFieldValue("salaryTo", "");
                        setFieldValue("salaryFrom", "");
                      }}
                    />
                    <ATMFieldLabel>As Per Standard</ATMFieldLabel>
                  </div>

                  <p className="font-bold text-md text-slate-500">OR</p>
                  <div className="flex gap-2">
                    <ATMCurrencyAmount
                      label="Currency"
                      currencyValue={values.salaryCurrency}
                      onCurrencyChange={(newValue) =>
                        setFieldValue("salaryCurrency", newValue)
                      }
                      inputhide={false}
                      disabled={values.asPerStandard}
                    />
                    <ATMCurrencyAmount
                      name="salaryTo"
                      currencyhide={false}
                      label="Minimum"
                      value={values.salaryTo}
                      onChange={(e) =>
                        setFieldValue("salaryTo", e.target.value)
                      }
                      placeholder="400000"
                      disabled={values.asPerStandard}
                    />
                    <ATMCurrencyAmount
                      name="salaryFrom"
                      currencyhide={false}
                      label="Maximum"
                      value={values.salaryFrom || ""}
                      onChange={(e) =>
                        setFieldValue("salaryFrom", e.target.value)
                      }
                      placeholder="500000"
                      disabled={values.asPerStandard}
                    />
                  </div>
                </div>
              </div>
              <div className="md:col-span-2 lg:col-span-3">
                <ATMFieldLabel>Add Miscellaneous Information</ATMFieldLabel>
                <textarea
                  className="w-full h-[177px] border rounded-md p-2"
                  placeholder={`Tell us more Information`}
                  value={values?.information}
                  onChange={(e) => setFieldValue("information", e.target.value)}
                />
              </div>
            </div>
            {/* Current Location */}
            <div className="border rounded-md">
              <div className="bg-blue-100 px-5 py-1">
                <ATMFieldLabel>Current Location</ATMFieldLabel>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-5">
                <ATMSelect
                  name={`country`}
                  label="Country"
                  options={Country.getAllCountries().map((country) => ({
                    value: country.isoCode,
                    label: country.name,
                  }))}
                  onChange={(newValue) => {
                    setFieldValue(`country`, newValue);
                    setFieldValue(`state`, null);
                    setFieldValue(`city`, null);
                  }}
                  value={values?.country}
                />

                <ATMSelect
                  name={`state`}
                  label="State"
                  options={
                    values.country && values.country.value
                      ? State.getStatesOfCountry(values.country.value).map(
                          (state) => ({
                            value: state.isoCode,
                            label: state.name,
                          })
                        )
                      : []
                  }
                  onChange={(newValue) => {
                    setFieldValue(`state`, newValue);
                    setFieldValue(`city`, "");
                  }}
                  value={values?.state}
                />

                <ATMSelect
                  name={`city`}
                  label="City"
                  value={values?.city}
                  options={
                    values.country?.value && values.state?.value
                      ? City.getCitiesOfState(
                          values.country?.value,
                          values.state?.value
                        ).map((city) => ({
                          value: city.name,
                          label: city.name,
                        }))
                      : []
                  }
                  onChange={(newValue) => {
                    setFieldValue(`city`, newValue);
                  }}
                />
                <div className="mt-[-8px]">
                  <ATMTextField
                    type="number"
                    name="zipCode"
                    value={values.zipCode || ""}
                    label="Zip Code"
                    placeholder="Enter your zip code"
                    onChange={(e) => setFieldValue("zipCode", e.target.value)}
                  />
                </div>
              </div>
            </div>
            {/* Preferred Location */}
            <div className="border rounded-md">
              <div className="bg-blue-100 px-5 py-1">
                <ATMFieldLabel>Preferred Locations</ATMFieldLabel>
              </div>
              <FieldArray name="preferredLocations">
                {({ push, remove }) => (
                  <div className="p-5">
                    {values.preferredLocations.map((location, index) => (
                      <div key={index} className="flex flex-col gap-2 mb-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4                        gap-4">
                          <ATMSelect
                            name={`preferredLocations.${index}.country`}
                            value={values?.preferredLocations[index].country}
                            label="Country"
                            options={Country.getAllCountries().map(
                              (country) => ({
                                value: country.isoCode,
                                label: country.name,
                              })
                            )}
                            onChange={(newValue) => {
                              setFieldValue(
                                `preferredLocations.${index}.country`,
                                newValue
                              );
                              setFieldValue(
                                `preferredLocations.${index}.state`,
                                ""
                              );
                              setFieldValue(
                                `preferredLocations.${index}.city`,
                                ""
                              );
                            }}
                          />
                          <ATMSelect
                            name={`preferredLocations.${index}.state`}
                            value={values?.preferredLocations[index].state}
                            label="State"
                            options={
                              values.preferredLocations[index].country &&
                              values.preferredLocations[index].country.value
                                ? State.getStatesOfCountry(
                                    values.preferredLocations[index].country
                                      .value
                                  ).map((state) => ({
                                    value: state.isoCode,
                                    label: state.name,
                                  }))
                                : []
                            }
                            onChange={(newValue) => {
                              setFieldValue(
                                `preferredLocations.${index}.state`,
                                newValue
                              );
                              setFieldValue(
                                `preferredLocations.${index}.city`,
                                ""
                              );
                            }}
                          />
                          <ATMSelect
                            name={`preferredLocations.${index}.city`}
                            value={values?.preferredLocations[index].city}
                            label="City"
                            options={
                              values.preferredLocations[index].country?.value &&
                              values.preferredLocations[index].state?.value
                                ? City.getCitiesOfState(
                                    values.preferredLocations[index].country
                                      ?.value,
                                    values.preferredLocations[index].state
                                      ?.value
                                  ).map((city) => ({
                                    value: city.name,
                                    label: city.name,
                                  }))
                                : []
                            }
                            onChange={(newValue) => {
                              setFieldValue(
                                `preferredLocations.${index}.city`,
                                newValue
                              );
                            }}
                          />
                          {index > 0 && (
                            <button
                              type="button"
                              className="text-red-600 mt-2"
                              onClick={() => remove(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="mt-2 bg-[#1CACBD] text-white py-2 px-4 rounded-lg"
                      onClick={() => {
                        const lastLocation =
                          values.preferredLocations[
                            values.preferredLocations.length - 1
                          ];
                        if (
                          lastLocation.country &&
                          lastLocation.state &&
                          lastLocation.city
                        ) {
                          push({ country: "", state: "", city: "" });
                        } else {
                          toast.error(
                            "Please fill the last preferred location before adding more."
                          );
                        }
                      }}
                    >
                      Add More Location
                    </button>
                  </div>
                )}
              </FieldArray>
            </div>

            {/* Submit Button */}
            <div className="text-right">
              <button
                type="submit"
                className="bg-[#1CACBD] text-white py-2 px-6 rounded-md  "
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ApplyForm;
