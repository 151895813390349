import { City, Country, State } from "country-state-city";
import { ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import "react-international-phone/style.css";
import { useNavigate } from "react-router";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMCurrencyAmount from "../../../../atoms/ATMCurrencyAmount/ATMCurrencyAmount";
import ATMDatePicker from "../../../../atoms/ATMDatePicker/ATMDatePicker";
import ATMFieldError from "../../../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMMultiSelect from "../../../../atoms/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "../../../../atoms/ATMSelect/ATMSelect";
import ATMTextField from "../../../../atoms/ATMTextField/ATMTextField";
import { useTrackResumeMutation } from "../Services/FormServices";
import TagJdListWrapper from "../TagJd/TagJdListWrapper";

const options = [
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
  { value: "Freelancer", label: "Freelancer" },
  { value: "Contract-Base", label: "Contract-Base" },
  { value: "Project-Base", label: "Project-Base" },
  { value: "Intern", label: "Intern" },
  { label: "On-Site", value: "On-Site" },
  { label: "Remote Working", value: "Remote Working" },
  { label: "Hybrid", value: "Hybrid" },
  { label: "Flexible", value: "Flexible" },
];

const EditSingleResumeForm = ({
  formikProps,
  setTrackResumeData,
  submit,
  setCareerData,
  setResumeBinary,
  resumeBinary,
  setEmail,

  trackResumeData,
  selectedJobs,
  setSelectedJobs,
  org_id,
}) => {
  const navigate = useNavigate();
  const [isFileUpload, setIsFileUpload] = useState(false);

  const [track] = useTrackResumeMutation();
  const { values, setFieldValue } = formikProps;
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      setIsFileUpload(true);

      const formData = new FormData();
      // formData.append("email", values?.email);
      formData.append("resume_file", files[0]);
      // formData.append("job_id", job?.id);
      formData.append("org_id", org_id?.company?.id);
      track(formData).then((res) => {
        if (res?.error) {
          toast.error(res?.error?.data?.message);
          setIsFileUpload(false);
          setResumeBinary("");
        } else {
          if (res?.data?.success) {
            setCareerData(res?.data?.response?.career_score_data);

            setTrackResumeData(res?.data);
            setIsFileUpload(false);
            setResumeBinary(files[0]);
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.error?.data?.message);
            setIsFileUpload(false);
            setResumeBinary("");
          }
        }
      });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleUpload = async (e) => {
    const file = e.currentTarget.files[0];

    if (file) {
      setIsFileUpload(true);
      const formData = new FormData();
      // formData.append("email", values?.email);
      formData.append("resume_file", file);
      // formData.append("job_id", job?.id);
      formData.append("org_id", org_id?.company?.id);
      track(formData).then((res) => {
        if (res?.error) {
          toast.error(res?.error?.data?.message);
          setIsFileUpload(false);
          setResumeBinary("");
        } else {
          if (res?.data?.success) {
            setCareerData(res?.data?.response?.career_score_data);

            setTrackResumeData(res?.data);
            setIsFileUpload(false);
            setResumeBinary(file);
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.error?.data?.message);
            setIsFileUpload(false);
            setResumeBinary("");
          }
        }
      });
    }
  };

  useEffect(() => {
    setFieldValue("resume", resumeBinary);
  }, [resumeBinary, setFieldValue]);
  return (
    <div className="flex flex-col gap-4 relative">
      {/* File Upload Section */}
      <div className="">
        <span className="text-sm font-semibold text-[#1CACBD]">Step 1 - </span>
        <ATMFieldLabel required>Upload Resume</ATMFieldLabel>

        <div className="grid grid-cols-2 gap-6">
          <div
            className={`border-2 border-dashed border-gray-300 py-6 px-4 rounded-md text-center mb-4 ${
              isDragOver ? "bg-gray-100" : ""
            }`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            <p className="text-gray-500 mb-4">Drag and drop resume here or</p>

            <button
              type="button"
              className="bg-[#1CACBD] text-white h-10 w-[150px] rounded-md cursor-pointer"
              onClick={() => document.getElementById("resumeInput").click()}
            >
              {isFileUpload ? (
                <div className="flex justify-center">
                  <ATMCircularProgress size="small" color="white" />
                </div>
              ) : (
                " Upload File"
              )}
            </button>
            <input
              id="resumeInput"
              type="file"
              className="hidden"
              onChange={handleUpload}
              // disabled={values?.email === "" || !job}
            />
            <p className="text-orange-300 text-xs mt-1">
              Please upload only Pdf or Docx files
            </p>
          </div>
          <div className="text-gray-700 font-medium flex items-center justify-between">
            {resumeBinary ? (
              <div className="flex items-center">
                <span>{resumeBinary.name}</span>
                <div
                  className="ml-2 text-red-500 border border-red-500 cursor-pointer text-center flex justify-center items-center w-5 h-5 rounded-full"
                  onClick={() => setResumeBinary(null)} // Call the function to clear the uploaded file
                >
                  X
                </div>
              </div>
            ) : (
              "No file uploaded yet."
            )}
          </div>
        </div>
        <div className=" text-xs font-semibold  text-red-500">
          {trackResumeData?.Duplicate}
        </div>
      </div>

      {/* Form Fields */}
      <div className="text-sm font-semibold text-[#1CACBD]">
        Step 2 - <span className="text-black"> Form </span>
      </div>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 font-inter text-xs border p-5 rounded-md `}
      >
        {/* name  */}
        <div>
          <ATMTextField
            type="text"
            name="first_name"
            value={values.first_name || ""}
            label="First Name"
            placeholder="Enter your First Name"
            onChange={(e) => setFieldValue("first_name", e.target.value)}
          />
        </div>
        <div>
          <ATMTextField
            type="text"
            name="middle_name"
            value={values.middle_name || ""}
            label="Middle Name"
            placeholder="Enter your Middle Name"
            onChange={(e) => setFieldValue("middle_name", e.target.value)}
          />
        </div>
        <div>
          <ATMTextField
            type="text"
            name="last_name"
            value={values.last_name || ""}
            label="last Name"
            placeholder="Enter your last Name"
            onChange={(e) => setFieldValue("last_name", e.target.value)}
          />
        </div>
        <div className="">
          <ATMFieldLabel>Candidate Email</ATMFieldLabel>
          <div className="h-[50px]">
            <div className=" relative">
              <input
                size="lg"
                placeholder="Oliva@untitledui.com"
                className="border rounded-md pl-10  outline-none w-full h-[40px] "
                value={values.candidateEmailFromResume}
                onChange={(e) => {
                  setFieldValue("candidateEmailFromResume", e.target.value);
                  setEmail(e.target.value);
                }}
                id="candidateEmailFromResume"
                name="candidateEmailFromResume"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="text-gray-400 pointer-events-none absolute top-2 left-2"
                style={{ height: 24, width: 24 }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>
            </div>
            <ErrorMessage name="candidateEmailFromResume">
              {(errorMessage) => (
                <ATMFieldError> {errorMessage} </ATMFieldError>
              )}
            </ErrorMessage>
          </div>
        </div>

        <div>
          <ATMTextField
            type="text"
            name="current_company"
            value={values.current_company}
            label="Current Company"
            placeholder="Enter your Current Company"
            onChange={(e) => setFieldValue("current_company", e.target.value)}
          />
        </div>

        {/* <div className="">
          <ATMFieldLabel required>Contact Number</ATMFieldLabel>
          <div className="min-w-[200px]">
            <PhoneInput
              defaultCountry="us"
              value={values.contactNumber}
              onChange={(value) => setFieldValue("contactNumber", value)}
              onBlur={handleBlur}
              id="contactNumber"
              name="contactNumber"
              className="border w-full rounded-md "
              style={{
                "--react-international-phone-height": "40px",
                "--react-international-phone-border-color": "transparent",
                "--react-international-phone-border-radius": "8px",
              }}
            />
          </div>
        </div> */}
        <div>
          <ATMTextField
            name="contactNumber"
            value={values.contactNumber}
            label="Contact Number"
            placeholder="Enter your Contact Number"
            onChange={(e) => setFieldValue("contactNumber", e.target.value)}
          />
        </div>
        <div className="">
          <ATMMultiSelect
            name="primarySkills"
            value={values.primarySkills || []}
            onChange={(newValue) => setFieldValue("primarySkills", newValue)}
            options={[]}
            label="Primary Skills"
            placeholder="Select Primary  Skills"
            keysToSelectoption={["Enter", ","]}
          />
        </div>
        <div className="">
          <ATMMultiSelect
            name="secondarySkills "
            value={values.secondarySkills || []}
            onChange={(newValue) => setFieldValue("secondarySkills", newValue)}
            options={[]}
            label="Secondary Skills"
            placeholder="Select Secondary Skills"
            keysToSelectoption={["Enter", ","]}
          />
        </div>
        <div className="">
          <ATMMultiSelect
            name="tertiarySkills "
            value={values.tertiarySkills || []}
            onChange={(newValue) => setFieldValue("tertiarySkills", newValue)}
            options={[]}
            label="Tertiary Skills"
            placeholder="Select Tertiary Skills"
            keysToSelectoption={["Enter", ","]}
          />
        </div>

        <div>
          {/* <ATMFieldLabel>Year of Experience</ATMFieldLabel>
          <Select
            options={experienceData}
            onChange={(newValue) => setFieldValue("experience", newValue)}
            placeholder="Select Your Experience"
            value={values.experience || ""}
            styles={{
              control: (provided) => ({
                ...provided,
                height: "40px",
                outline: "none",
                boxShadow: "none",
                border: "1px solid #ccc",
                "&:hover": {
                  border: "1px solid #ccc",
                },
                "&:focus": {
                  border: "1px solid #ccc",
                },
              }),
            }}
          /> */}
          <div>
            <ATMTextField
              name="experience"
              value={values.experience || ""}
              label="Year of Experience"
              placeholder="Enter your Year of Experience"
              onChange={(e) =>
                setFieldValue("experience", Number(e.target.value))
              }
            />
          </div>
        </div>

        <div>
          <ATMTextField
            type="text"
            name="noticePeriod"
            value={values.noticePeriod || ""}
            label="Notice Period (Days)"
            placeholder="Enter your Notice Period (Days)"
            onChange={(e) => setFieldValue("noticePeriod", e.target.value)}
          />
        </div>
        <div>
          <ATMCurrencyAmount
            currencyValue={values.currentSalaryCurrency}
            onCurrencyChange={(newValue) =>
              setFieldValue("currentSalaryCurrency", newValue)
            }
            name="ctc"
            label="Current Salary (CTC)"
            placeholder="Enter your Current Salary (CTC)"
            value={values.ctc}
            onChange={(e) => setFieldValue("ctc", e.target.value)}
          />
        </div>

        <div>
          <ATMFieldLabel>Available From</ATMFieldLabel>
          <ATMDatePicker
            selectedDate={values.availableFrom}
            onChange={(date) => setFieldValue("availableFrom", date)}
            minDate={new Date()}
            disabled={values.immediatelyAvailable}
          />
          <div className="flex items-center mt-1">
            <input
              type="checkbox"
              checked={values.immediatelyAvailable}
              onChange={() => {
                setFieldValue(
                  "immediatelyAvailable",
                  !values.immediatelyAvailable
                );
                setFieldValue("availableFrom", new Date());
              }}
              className="mr-2"
            />
            <label className="font-medium text-[11px]">
              Immediately Available
            </label>
          </div>
        </div>

        <div>
          <ATMSelect
            name={`jobPreference`}
            label="Job Preference"
            options={options}
            onChange={(newValue) => {
              setFieldValue(`jobPreference`, newValue.value);
            }}
            value={values?.jobPreference}
            placeholder="select job preference"
          />
        </div>
        <div>
          <ATMTextField
            type="text"
            name="candidate_linkdin_link"
            value={values.candidate_linkdin_link}
            label="Candidate Linkdin Link"
            placeholder="Enter Candidate Linkdin Link"
            onChange={(e) =>
              setFieldValue("candidate_linkdin_link", e.target.value)
            }
          />
        </div>
        <div>
          <ATMTextField
            type="text"
            name="source"
            value={values.source}
            label="Source"
            placeholder="Enter your source"
            onChange={(e) => setFieldValue("source", e.target.value)}
          />
        </div>
        <div>
          <ATMTextField
            type="text"
            name="hiring"
            value={values.hiring}
            label="Hiring Manger"
            placeholder="Enter Hiring Manger"
            onChange={(e) => setFieldValue("hiring", e.target.value)}
          />
        </div>
        <div className="lg:col-span-2 md:col-span-2">
          <ATMFieldLabel>Salary Expectation </ATMFieldLabel>
          <div className=" border rounded-md p-4 flex items-center  gap-4   ">
            <div className="flex gap-2 items-center">
              <input
                type="checkbox"
                name="asPerStandard"
                checked={values.asPerStandard}
                onChange={() => {
                  setFieldValue("asPerStandard", !values.asPerStandard);
                  setFieldValue("salaryCurrency", null);
                  setFieldValue("salaryTo", "");
                  setFieldValue("salaryFrom", "");
                }}
              />
              <ATMFieldLabel>As Per Standard</ATMFieldLabel>
            </div>

            <p className="font-bold text-md text-slate-500">OR</p>
            <div className="flex gap-1">
              <ATMCurrencyAmount
                currencyValue={values.salaryCurrency}
                onCurrencyChange={(newValue) =>
                  setFieldValue("salaryCurrency", newValue)
                }
                inputhide={false}
                disabled={values.asPerStandard}
                label="Currency"
              />
              <ATMCurrencyAmount
                name="salaryFrom"
                currencyhide={false}
                label="Minimum"
                value={values.salaryFrom || ""}
                onChange={(e) =>
                  setFieldValue("salaryFrom", Number(e.target.value))
                }
                placeholder="400000"
                disabled={values.asPerStandard}
              />
              <ATMCurrencyAmount
                name="salaryTo"
                currencyhide={false}
                label="Maximum"
                value={values.salaryTo}
                onChange={(e) =>
                  setFieldValue("salaryTo", Number(e.target.value))
                }
                placeholder="500000"
                disabled={values.asPerStandard}
              />
            </div>
          </div>
        </div>
        <div>
          <ATMFieldLabel>Add Miscellaneous Information</ATMFieldLabel>
          <textarea
            className="w-full h-[142px] border rounded-md p-2 outline-none"
            placeholder={`Tell us more Information`}
            value={values?.information}
            onChange={(e) => setFieldValue("information", e.target.value)}
          />
        </div>
      </div>

      {/* Current Location */}

      <div className="border rounded-md">
        <div className="bg-blue-100 px-5 py-1">
          <ATMFieldLabel>Current Location</ATMFieldLabel>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-5">
          <ATMSelect
            required
            name={`country`}
            label="Country"
            options={Country.getAllCountries().map((country) => ({
              value: country.isoCode,
              label: country.name,
            }))}
            onChange={(newValue) => {
              setFieldValue(`country`, newValue);
              setFieldValue(`state`, null);
              setFieldValue(`city`, null);
            }}
            value={values?.country}
          />

          <ATMSelect
            name={`state`}
            label="State"
            options={
              values.country && values.country.value
                ? State.getStatesOfCountry(values.country.value).map(
                    (state) => ({
                      value: state.isoCode,
                      label: state.name,
                    })
                  )
                : []
            }
            onChange={(newValue) => {
              setFieldValue(`state`, newValue);
              setFieldValue(`city`, null);
            }}
            value={values?.state}
          />

          <ATMSelect
            name={`city`}
            label="City"
            value={values?.city}
            options={
              values.country?.value && values.state?.value
                ? City.getCitiesOfState(
                    values.country?.value,
                    values.state?.value
                  ).map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))
                : []
            }
            onChange={(newValue) => {
              setFieldValue(`city`, newValue);
            }}
          />
          <div className="mt-[-8px]">
            <ATMTextField
              type="number"
              name="zipCode"
              value={values.zipCode || ""}
              label="Zip Code"
              placeholder="Enter your zip code"
              onChange={(e) => setFieldValue("zipCode", e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Preferred Location */}

      <div className="border rounded-md">
        <div className="bg-blue-100 px-5 py-1">
          <ATMFieldLabel>Preferred Locations</ATMFieldLabel>
        </div>
        <FieldArray name="preferredLocations">
          {({ push, remove }) => (
            <div className="p-5">
              {values.preferredLocations.map((location, index) => (
                <div key={index} className="flex flex-col gap-2 mb-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4                        gap-4">
                    <ATMSelect
                      name={`preferredLocations.${index}.country`}
                      value={values?.preferredLocations[index].country}
                      label="Country"
                      options={Country.getAllCountries().map((country) => ({
                        value: country.isoCode,
                        label: country.name,
                      }))}
                      onChange={(newValue) => {
                        setFieldValue(
                          `preferredLocations.${index}.country`,
                          newValue
                        );
                        setFieldValue(`preferredLocations.${index}.state`, "");
                        setFieldValue(`preferredLocations.${index}.city`, "");
                      }}
                    />
                    <ATMSelect
                      name={`preferredLocations.${index}.state`}
                      value={values?.preferredLocations[index].state}
                      label="State"
                      options={
                        values.preferredLocations[index].country &&
                        values.preferredLocations[index].country.value
                          ? State.getStatesOfCountry(
                              values.preferredLocations[index].country.value
                            ).map((state) => ({
                              value: state.isoCode,
                              label: state.name,
                            }))
                          : []
                      }
                      onChange={(newValue) => {
                        setFieldValue(
                          `preferredLocations.${index}.state`,
                          newValue
                        );
                        setFieldValue(`preferredLocations.${index}.city`, "");
                      }}
                    />
                    <ATMSelect
                      name={`preferredLocations.${index}.city`}
                      value={values?.preferredLocations[index].city}
                      label="City"
                      options={
                        values.preferredLocations[index].country?.value &&
                        values.preferredLocations[index].state?.value
                          ? City.getCitiesOfState(
                              values.preferredLocations[index].country?.value,
                              values.preferredLocations[index].state?.value
                            ).map((city) => ({
                              value: city.name,
                              label: city.name,
                            }))
                          : []
                      }
                      onChange={(newValue) => {
                        setFieldValue(
                          `preferredLocations.${index}.city`,
                          newValue
                        );
                      }}
                    />
                    {index > 0 && (
                      <button
                        type="button"
                        className="text-red-600 mt-2"
                        onClick={() => remove(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <button
                type="button"
                className="mt-2 bg-[#1CACBD] text-white py-2 px-4 rounded-lg"
                onClick={() => {
                  const lastLocation =
                    values.preferredLocations[
                      values.preferredLocations.length - 1
                    ];
                  if (
                    lastLocation.country &&
                    lastLocation.state &&
                    lastLocation.city
                  ) {
                    push({ country: "", state: "", city: "" });
                  } else {
                    toast.error(
                      "Please fill the last preferred location before adding more."
                    );
                  }
                }}
              >
                Add More Location
              </button>
            </div>
          )}
        </FieldArray>
      </div>

      <div>
        <TagJdListWrapper
          selectedJobs={selectedJobs}
          setSelectedJobs={setSelectedJobs}
        />
      </div>

      {/* Submit Button */}

      <div className="flex justify-end gap-4 items-center">
        <button
          onClick={() => navigate("/recruit/my-resumes")}
          type="button"
          className="border-[#1CACBD] border text-[#1CACBD] h-10 px-2 rounded-md text-center w-[100px]"
        >
          Back
        </button>
        <button
          type="submit"
          className="bg-[#1CACBD] text-white h-10 px-2 rounded-md text-center w-[100px]"
        >
          {submit ? (
            <div className="flex justify-center">
              {" "}
              <ATMCircularProgress size="small" color="white" />
            </div>
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};

export default EditSingleResumeForm;
